import { Snackbar } from "@mui/material";
import Alert from '@mui/material/Alert';
import { withStyles } from "@mui/styles";
import { useEffect } from "react";

const styles = () => ({
    msgWrapper: { display: "flex", alignItems: 'flex-start', justifyContent: "space-between" },
    toastIcon: { height: "24px", width: "24px" },
    crossIcon: { height: "20px", width: "20px", cursor: "pointer" },
    successTheme: {
        "& .MuiAlert-root": {
            borderLeft: "4px solid #167F50 !important",
            background: "#E5F3ED !important",
            padding: "1rem !important",
            display: "flex !important",
            alignItems: "center !important",
            minWidth: "300px !important",
            borderRadius: 0,
            minHeight: "80px",
            maxWidth: "450px !important",
        },
        "& .css-1pxa9xg-MuiAlert-message": {
            padding: "0 !important"
        },
    },
    errorTheme: {
        "& .MuiAlert-root": {
            borderLeft: "4px solid #CB2029 !important",
            background: "#FCEDEE !important",
            padding: "1rem !important",
            display: "flex !important",
            minWidth: "300px !important",
            alignItems: "center !important",
            borderRadius: 0,
            minHeight: "80px",
            maxWidth: "450px !important",
        },
        "& .css-1pxa9xg-MuiAlert-message": {
            padding: "0 !important"
        },
    },
    toastHeading: {
        fontSize: "16px",
        fontFamily: "Inter",
        fontWeight: "600",
        lineHeight: "24px",
        marginBottom: "0.5rem",
    },
    toastMessageText: {
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: "400",
        lineHeight: "20px",
    },
});
const ToastErr = "https://storage.googleapis.com/dd-ui-static-dev/Admin-dash/SupplierManagement/x-circle.png";
const ToastSuccess = "https://storage.googleapis.com/dd-ui-static-dev/Admin-dash/SupplierManagement/check-circle.png";
const ToastInProgress = "https://storage.googleapis.com/dd-ui-static-dev/images/deactivation_in_progress.svg";
const xMark = "https://storage.googleapis.com/dd-ui-static-dev/Admin-dash/SupplierManagement/x-mark.png";

const ToastMessage = (props) => {

    const { open, type, msg, heading, children, onClose,setOpenToast } = props
    const classes = props.classes;

    useEffect(() => {
        setTimeout(() => {
            setOpenToast(false);
        }, 5000);
    }, [open])

    const getIcon = (type) => {
        switch (type?.toLowerCase()) {
            case "error":
                return ToastErr
            case "success":
                return ToastSuccess
            default:
                return ToastInProgress
        }
    }

    return (
        <Snackbar
            className={type === "error" ? classes.errorTheme : classes.successTheme}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
        >
            <Alert severity="">
                {children || (
                    <div className={classes.msgWrapper}>
                        <div style={{ display: "flex", gap: "1rem" }}>
                            <img className={classes.toastIcon}
                                src={getIcon(type)}
                                alt={type === "error" ? "error" : "success"}
                            />
                            <div>
                                <div className={classes.toastHeading} style={{ width: "80%" }}>{heading}</div>
                                <div className={classes.toastMessageText}>{msg}</div>
                            </div>
                        </div>
                        <img
                            onClick={() => onClose()}
                            src={xMark}
                            alt="close"
                            className={classes.crossIcon}
                        />
                    </div>
                )}

            </Alert>
        </Snackbar>
    )
}

export default withStyles(styles)(ToastMessage);
