import { Button, styled } from "@mui/material";

export const StyledBaseButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "fullWidth" && prop !== "height",
})(({ theme, height, bgcolor, marginbottom, width, btnborder, textcolor, fontsize_, buttoncolor, borderradius, fontweight_, others }) => ({
  "&.MuiButtonBase-root": {
    height: height ? height : "auto",
    textTransform: "capitalize !important",
    backgroundColor: bgcolor ? bgcolor : buttoncolor,
    marginBottom: marginbottom ? marginbottom : 0,
    borderRadius: borderradius ? borderradius : 8,
    width: width ? width : "100%",
    border: btnborder ? btnborder : "none",
    color: textcolor ? textcolor : theme.palette.neutral.white,
    fontSize: fontsize_ ? fontsize_ : 16,
    cursor: others?.disabled ? "not-allowed" : "pointer",
    fontWeight: fontweight_ ? fontweight_ : theme.typography.fontWeight.semiBold
  },
  "&.Mui-disabled": {
    backgroundColor: theme.palette.primary.disabled,
    color: theme.palette.neutral.white,
    border: "none"
  }
}));

export const StyledLinkButton = styled("button")(({ theme }) => ({
  background: "none!important",
  border: "none",
  padding: "0!important",
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
  weight: theme.typography.fontWeight.regular,
  fontSize: 14,
}));
