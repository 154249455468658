import { Grid, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import LocalizedStrings from 'react-localization'
import { useDispatch, useSelector } from 'react-redux'
import { Controls } from '../../controls'
import { useBankList } from '../../customHook/useBankList'
import { useClientList } from '../../customHook/useClientList'
import { useProductList } from '../../customHook/useProductList'
import { MainPageTopSection, VerticalLine } from '../../globalStyles/global.styles'
import { setConfigOneFilter, setCurrentBank, setCurrentMerchantCode, setCurrentProduct, setPriceConfigSelectorOneToInitial } from '../../redux/slice/PriceConfigSlice'
import { BankList, localProductList } from '../../utils/const'
import LocalData from '../../utils/localization'
import { FilterGridItem, FilterRowWrapper, TopSectionLabel } from './filters.styles'


const PriceConfigSelectorOne = (props) => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const { setErrorText, setOpenErrorModal } = props
    const { merchantCode, product, bank, editableFlag } = useSelector(state => state.priceConfigData)
    const dispatch = useDispatch()
    const theme = useTheme()

    const { clientList, fetchClientList } = useClientList(setErrorText, setOpenErrorModal)
    const { bankList, fetchBankList } = useBankList(setErrorText, setOpenErrorModal)
    const { productList, fetchProductList } = useProductList(setErrorText, setOpenErrorModal)

    const onClearHandler = () => {
        if (!editableFlag) {
            dispatch(setPriceConfigSelectorOneToInitial({
                merchantCode: "",
                product: "",
                bank: ""
            }))
            dispatch(setConfigOneFilter(false))
        }
    }

    const disabledColor = () => {
        if (merchantCode) {
            return theme.palette.text.body
        }
        return theme.palette.text.textEight
    }

    const disabledButton = () => {
        return (merchantCode === "" || product === "" || bank === "" || editableFlag === true)
    }

    const onSubmitHandler = () => {
        if (!disabledButton()) {
            dispatch(setConfigOneFilter(true))
            // some calls here
        }
    }

    const editableTextColor = () => {
        if (editableFlag) {
            return theme.palette.text.textEight
        }
        return theme.palette.text.body
    }

    useEffect(() => {
        fetchClientList()
        fetchProductList()
    }, []);

    useEffect(() => {
        if (!editableFlag) {
            dispatch(setCurrentBank(''))
            dispatch(setCurrentProduct(''))
            if (merchantCode !== '') {
                fetchBankList()
            }
        }
    }, [merchantCode]);
    // console.log(productList.find((product_) => product_ === product_?.productId)?.productName)
    // console.log(productList.find((product_) => product_ === product?.productId))
    return (
        <MainPageTopSection flexdirection="column" alignitems="flex-start">
            <TopSectionLabel>{strings.SelectDetails}</TopSectionLabel>
            <Grid container item flexDirection={"row"} flexWrap={"nowrap"}>
                <FilterRowWrapper container item spacing={3} flexDirection={"column"} margin="26px 1rem 0px 0px">
                    <FilterGridItem item padding="0px">
                        {!editableFlag ?
                            <Controls.BaseSelectInput placeholderText={strings.SelectClient} label={strings.SelectClientName} value={merchantCode} onChange={e => dispatch(setCurrentMerchantCode(e.target.value))} values={clientList} labelfontweight={theme.typography.fontWeight.medium} labelcolor={theme.palette.text.body} labelmarginbottom="12px" height="45px" width="100%" inputpadding="0.5px 16px" />
                            :
                            <Controls.BaseTextField textColor={editableTextColor()} value={merchantCode} inputProps={{ readOnly: true, }} height="45px" label={strings.SelectClient} labelfontweight={theme.typography.fontWeight.medium} labelcolor={theme.palette.text.body} placeholder={strings.SelectClient} inputpadding="0.5px 16px" />
                        }
                    </FilterGridItem>
                </FilterRowWrapper>
                <VerticalLine />
                <FilterRowWrapper container item spacing={3} flexDirection={"column"} margin="26px 1rem 0px 0px">
                    <FilterGridItem item>
                        {!editableFlag ?
                            <Controls.BaseSelectInput placeholderText={strings.SelectProduct} label={strings.SelectProduct} value={product} onChange={e => dispatch(setCurrentProduct(e.target.value))} disabledselect={merchantCode === '' ? 'true' : 'false'} values={productList} labelfontweight={theme.typography.fontWeight.medium} labelcolor={disabledColor()} labelmarginbottom="12px" height="45px" width="100%" inputpadding="0.5px 16px" />
                            :
                            <Controls.BaseTextField textColor={editableTextColor()} value={localProductList.find((product_) => product_.code === product)?.name} inputProps={{ readOnly: true, }} height="45px" label={strings.SelectProduct} labelfontweight={theme.typography.fontWeight.medium} labelcolor={theme.palette.text.body} placeholder={strings.SelectProduct} inputpadding="0.5px 16px" />
                        }
                    </FilterGridItem>
                </FilterRowWrapper>
                <VerticalLine />
                <FilterRowWrapper container item spacing={3} flexDirection={"column"} margin="26px 1rem 0px 0px">
                    <FilterGridItem item>
                        {!editableFlag ?
                            <Controls.BaseSelectInput
                                label={strings.SelectBank}
                                value={bank}
                                placeholderText={strings.SelectBank}
                                onChange={e => dispatch(setCurrentBank(e.target.value))}
                                disabledselect={merchantCode === '' ? 'true' : 'false'}
                                values={bankList}
                                labelfontweight={theme.typography.fontWeight.medium}
                                labelcolor={disabledColor()}
                                labelmarginbottom="12px" height="45px"
                                width="100%" inputpadding="0.5px 16px" />
                            :
                            <Controls.BaseTextField textColor={editableTextColor()} value={BankList.find((bank_) => bank_.code === bank)?.name} inputProps={{ readOnly: true, }} height="45px" label={strings.SelectBank} labelfontweight={theme.typography.fontWeight.medium} labelcolor={theme.palette.text.body} placeholder={strings.SelectBank} inputpadding="0.5px 16px" />
                        }
                    </FilterGridItem>
                </FilterRowWrapper>
                <VerticalLine marginleft="4rem" />
                <FilterRowWrapper container item spacing={3} flexDirection={"column"} margin="0px 4rem 0px 0px" justifyContent={"center"} alignItems={"center"}>
                    <FilterGridItem item>
                        <Controls.BaseButton onClick={onSubmitHandler} disabled={disabledButton()} text={strings.Submit} height="40px" width="160px" marginbottom="13px" />
                    </FilterGridItem >
                    <FilterGridItem item>
                        <Controls.BaseButton onClick={onClearHandler} text={strings.Clear} textcolor={theme.palette.text.textEight} height="40px" width="160px" btnborder={`1px solid ${theme.palette.neutral.border}`} disablemenuitem="true" labelcolor={theme.palette.text.textEight} bgcolor={theme.palette.neutral.white} />
                    </FilterGridItem>
                </FilterRowWrapper >
            </Grid>
        </MainPageTopSection>
    )
}

export default PriceConfigSelectorOne