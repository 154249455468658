import { Grid } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from "react";
import { SubDateRangeField, CalendarButton } from "./filters.styles"

const EmptyIconPicker = () => {
    return <></>
}

const DateRangePicker = ({
    datePickerFormat="DD MMM YYYY",
    onChange,
    minDate=null, maxDate=null, disableHighlightToday=true,
    initialDate={startDate:null, endDate:null}
}) => {
    const [state, setState] = useState({
        startDate: initialDate.startDate,
        endDate: initialDate.endDate,
        openStartDate: false,
        openEndDate: false,
    });

    function updateState(newState) {
        setState(prev => {
            return {
            ...prev,
            ...newState
            }
        })
    } 

    function toggleState(stateKey) {
        setState(prev => {
            return {
            ...prev,
            [stateKey]: !prev[stateKey]
            }
        })
    }

    useEffect(() => {
        onChange({
            startDate: state.startDate,
            endDate: state.endDate
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.startDate, state.endDate]);

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid container alignItems="center" justifyContent="space-between" spacing={0} sx={{border: "1px #CCC solid", borderRadius: 2}}>
                    <Grid item xs={6}>
                        <SubDateRangeField value={state.startDate} onAccept={(newValue)=> {
                            updateState({startDate: newValue, openEndDate:true})
                            }}
                            open={state.openStartDate}
                            onClose={() => updateState({openStartDate: false})}
                            slots={{
                                openPickerButton: EmptyIconPicker
                                }}
                                
                            format={datePickerFormat}
                            minDate={minDate}
                            maxDate={maxDate}
                            disableHighlightToday={disableHighlightToday}
                        />
                    </Grid>
                    <Grid item sx={{width:"20px", color: "#777", fontWeight: 500, marginLeft: "-30px", marginRight: "30px"}}>
                        <span>to</span>
                    </Grid>
                    <Grid item xs>
                        <SubDateRangeField textAlign="right" 
                            sx={{paddingRight: '5px'}}
                            value={state.endDate} onAccept={(newValue)=> {
                            updateState({endDate: newValue})
                            }}
                            open={state.openEndDate}
                            onClose={() => updateState({openEndDate: false})}
                            slots={{
                                openPickerButton: EmptyIconPicker
                                }}
                            format={datePickerFormat}
                            minDate={state.startDate}
                            maxDate={maxDate}
                            disableHighlightToday={disableHighlightToday}
                        />
                    </Grid>
                    <Grid item sx={{width: "40px"}}>
                        <Grid container justifyContent="end">
                            <CalendarButton height="23px" onClick={() => toggleState('openStartDate')} />
                        </Grid>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </div>
    );
}

export default DateRangePicker;