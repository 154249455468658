import { useDispatch, useSelector } from "react-redux";
import BaseApi from "../api/BaseApi";
import { setConfigOneFilter, setPriceConfigSelectorOneToInitial, setPriceConfigSelectorTwoToInitial } from "../redux/slice/PriceConfigSlice";
import { generateUuidValue } from "../utils/utilFunctions";

export const useAddEditPricingConfiguration = (setErrorText, setOpenErrorModal, getConfigList) => {
  const { editableFlag, merchantCode, product, bank, bankFeesType, bankFees, ayoconnectFeesType, ayoconnectFees, clientFeesType, clientFees } =
    useSelector((state) => state.priceConfigData);

  const dispatch = useDispatch();

  const addEditPriceConfig = async () => {
    try {
      const setPayload = {
        transactionId: generateUuidValue(),
        referenceNumber: generateUuidValue(),
        merchantCode: merchantCode,
        productCode: product.toString(),
        bankCode: bank,
        bankFeeType: bankFeesType,
        bankFee: bankFees,
        ayoconnectFeeType: ayoconnectFeesType,
        ayoconnectFee: ayoconnectFees,
        merchantFeeType: clientFeesType,
        merchantFee: clientFees,
      };

      let response;

      if (editableFlag === true) {
        response = await BaseApi("patch", "/of-admin-dashboard/web/v1/pricing/config", "", {}, setPayload);
      } else {
        response = await BaseApi("post", "/of-admin-dashboard/web/v1/pricing/config/add", "", {}, setPayload);
      }

      if ((response?.data && response?.data?.code === 200) || response?.data?.code === 201) {
        getConfigList(50, 1);
        dispatch(
          setPriceConfigSelectorOneToInitial({
            merchantCode: "",
            product: "",
            bank: "",
          })
        );

        dispatch(
          setPriceConfigSelectorTwoToInitial({
            mdrType: "",
            totalMDR: "",
            bankFeesType: "",
            bankFees: "",
            ayoconnectFeesType: "",
            ayoconnectFees: "",
            clientFeesType: "",
            clientFees: "",
          })
        );
        if (response?.data?.mdrConfig?.message === "Successfully GET MDR Config") {
          setErrorText("Pricing Configuration is Already Present.");
          setOpenErrorModal(true);
        }
        dispatch(setConfigOneFilter(false));
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
    }
  };
  return { addEditPriceConfig };
};
