import { useDispatch, useSelector } from "react-redux";
import BaseApi from "../api/BaseApi";
import { setPaginationLimit, setPaginationPage, setTotalCount } from "../redux/slice/paginationSlice";
import { generateUuidValue } from "../utils/utilFunctions";

export function useTransferHistory(url, showLoader, setDisbursementList, setErrorText, setOpenErrorModal, getPageCount, setIsEmpty) {
  const dispatch = useDispatch();
  const { limit, page } = useSelector((state) => state.paginationData);

  const getTransactionList = async (currentLimit, currentPage, merchantCode, partnerReferenceNumber = null, filter = null) => {
    showLoader(true);
    try {
      const setParams = {
        transactionId: generateUuidValue(),
        referenceNumber: generateUuidValue(),
        page: currentPage || page,
        limit: currentLimit || limit,
        merchantCode: merchantCode,
        status: 0
      };

      if (partnerReferenceNumber) {
        Object.assign(setParams, { partnerReferenceNumber: partnerReferenceNumber });
      }
      if (filter) {
        Object.assign(setParams, Object.fromEntries(Object.entries(filter).filter(([_, value]) => value !== null)));
      }

      const response = await BaseApi("get", url, "", setParams, {});
      if (response?.data && response?.data?.code === 200) {
        if(!response.data?.listDisbursement) {
          setIsEmpty(true);
          setDisbursementList([]);
        } else {

          setIsEmpty(false);
          setDisbursementList(response.data?.listDisbursement);
        }
        dispatch(setPaginationLimit(response?.data?.pagination?.count));
        dispatch(setPaginationPage(response?.data?.pagination?.page));
        dispatch(setTotalCount(response.data?.pagination?.totalCount));
        getPageCount(response.data.pagination?.page, response.data.listDisbursement);
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
    }
    showLoader(false);
  };

  return { getTransactionList };
}
