import { createSlice } from "@reduxjs/toolkit";

export const vcFeatureSlice = createSlice({
  name: "vcTransaction",
  initialState: [],
  reducers: {
    setVcFeature: (state, action) => {
      return action.payload
    },
    resetVcFeature: (state, action) => {
      return []
    },
  },
});

export const {
  setVcFeature,
  resetVcFeature,
} = vcFeatureSlice.actions;
export default vcFeatureSlice.reducer;
