import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BaseApi from "../api/BaseApi";
import { setPaginationLimit, setPaginationPage } from "../redux/slice/paginationSlice";
import { generateUuidValue } from "../utils/utilFunctions";

export const useSettlementFilesList = (setTotalCount, setErrorText, setOpenErrorModal, showLoader, getPageCount) => {
  const [settlementList, setSettlementList] = useState([]);
  const { page, limit } = useSelector((state) => state.paginationData);
  const dispatch = useDispatch();

  async function getSettlementFilesList(currentLimit, currentPage) {
    showLoader(true);
    try {
      const setParams = {
        transactionId: generateUuidValue(),
        referenceNumber: generateUuidValue(),
        page: currentPage || page,
        limit: currentLimit || limit,
        active: "true",
      };
      const response = await BaseApi("get", "/of-admin-dashboard/web/v1/settlement-status/file", "", setParams);

      if (response?.data && response?.data?.code === 200) {
        setSettlementList(response?.data?.settlementFileDetail);
        dispatch(setPaginationLimit(response?.data?.pagination?.count));
        dispatch(setPaginationPage(response?.data?.pagination?.page));
        getPageCount(response?.data?.pagination?.page, response?.data?.settlementFileDetail);
        setTotalCount(response?.data?.pagination?.totalCount);
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
    }
    showLoader(false);
  }
  return { settlementList, getSettlementFilesList };
};
