import React, { useState } from 'react'
import List from '@mui/material/List';
import MuiDrawer from '@mui/material/Drawer';
import { CssBaseline } from '@mui/material';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { BROWSER_URLS } from '../../utils/browserUrls';
import MenuBar from './MenuBar';
import { useLocation } from 'react-router-dom';
import ExpandableMenuBar from './ExpandableMenuBar';
import { STATIC_ASSETS } from '../../utils/staticAssets';
import ExpandableCollapsableList from './ExpandableCollapsableList';
import { DrawerHeader, SidebarWrapper, StyledSidebarLogoIcon } from './Sidebar.styled';
import LocalData from '../../utils/localization';
import LocalizedStrings from 'react-localization';

const drawerWidth = 261;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 25px)`,
    },
});



const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const Sidebar = (props) => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const currentLocation = useLocation().pathname
    const [expandableMenubarState, setexpandableMenubarState] = useState(false);
    const [virtualCardExpandState, setvirtualCardExpandState] = useState(false);
    const biFastUrls = [BROWSER_URLS.ESCROW_ACCOUNT, BROWSER_URLS.CLIENT_BALANCE]
    const virtualCardUrls = [BROWSER_URLS.VIRTUAL_CARD_AYOLIMIT, BROWSER_URLS.VIRTUAL_CARD_CLIENT]
    const { open } = useSelector((state) => state.drawerData)
    const toggleFeature = {
        virtualCard: true
    }

    const bifastList = [
        // { label: strings.EscrowAccount, to: BROWSER_URLS.ESCROW_ACCOUNT }, 
        { id: "it-client-balance-submenu-item", label: strings.ClientAccount, to: BROWSER_URLS.CLIENT_BALANCE },
        { id: "it-transactions-submenu-item", label: strings.TransferHistory.Transactions, to: BROWSER_URLS.TRANSFER_HISTORY },
        { id: "it-supplier-submenu-item", label: strings.supplierManagement.menuName, to: BROWSER_URLS.SUPPLIER_MANAGEMENT }
    ]
    const virtualCardList = [
        { id: "vc-limit-submenu-item", label: "Ayoconnect Limit", to: BROWSER_URLS.VIRTUAL_CARD_AYOLIMIT },
        { id: "vc-client-account-submenu-item",label: strings.ClientAccount, to: BROWSER_URLS.VIRTUAL_CARD_CLIENT },
        { id: "vc-client-configuration-setting-submenu-item", label: strings.ClientConfigurationSetting, to: BROWSER_URLS.VIRTUAL_CARD_SETTING }
    ]
    return (
        <SidebarWrapper>
            <CssBaseline />
            <Drawer variant="permanent" open={open}>
                <DrawerHeader />
                <DrawerHeader />
                <List>
                    <ExpandableMenuBar
                        id={"instant-transfer-menu-item"}
                        active={biFastUrls.includes(currentLocation) ? 1 : 0}
                        main_label={strings.InstantTransfer}
                        subList={bifastList}
                        currentLocation={currentLocation}
                        expandableMenubarState={expandableMenubarState}
                        setexpandableMenubarState={setexpandableMenubarState}
                        main_icon={biFastUrls.includes(currentLocation) ? STATIC_ASSETS.BI_FAST_ACTIVE : STATIC_ASSETS.BI_FAST}
                    />
                    <ExpandableCollapsableList expandableMenubarState={expandableMenubarState} subList={bifastList} currentLocation={currentLocation} />

                    {
                        toggleFeature.virtualCard &&
                        <>
                            <ExpandableMenuBar
                                id={"virtual-card-menu-item"}
                                active={virtualCardUrls.includes(currentLocation) ? 1 : 0}
                                main_label={strings.VirtualCard}
                                subList={virtualCardList}
                                currentLocation={currentLocation}
                                expandableMenubarState={virtualCardExpandState}
                                setexpandableMenubarState={setvirtualCardExpandState}
                                main_icon={virtualCardUrls.includes(currentLocation) ? STATIC_ASSETS.VIRTUAL_CARD_ACTIVE : STATIC_ASSETS.VIRTUAL_CARD}
                            />
                            <ExpandableCollapsableList expandableMenubarState={virtualCardExpandState} subList={virtualCardList} currentLocation={currentLocation} />
                        </>
                    }
                    <MenuBar
                        id={"pricing-configuration-menu-item"}
                        active={currentLocation === BROWSER_URLS.PRICE_CONFIGURATION ? 1 : 0}
                        label={strings.PricingConfiguration}
                        to={BROWSER_URLS.PRICE_CONFIGURATION}
                        iconText={currentLocation === BROWSER_URLS.PRICE_CONFIGURATION ? <StyledSidebarLogoIcon active="true">Rp</StyledSidebarLogoIcon> : <StyledSidebarLogoIcon>Rp</StyledSidebarLogoIcon>}
                    />
                    {/* <MenuBar
                        active={currentLocation === BROWSER_URLS.EMBEDDED_PAYMENTS ? 1 : 0}
                        label={strings.EmbeddedPayments}
                        to={BROWSER_URLS.EMBEDDED_PAYMENTS}
                        icon={currentLocation === BROWSER_URLS.EMBEDDED_PAYMENTS ? STATIC_ASSETS.EMBEDDED_PAYMENTS_ICON_ACTIVE : STATIC_ASSETS.EMBEDDED_PAYMENTS_ICON}
                    /> */}
                    <MenuBar
                        id={"settlement-menu-item"}
                        active={currentLocation === BROWSER_URLS.SETTLEMENT ? 1 : 0}
                        label={strings.Settlement}
                        to={BROWSER_URLS.SETTLEMENT}
                        icon={currentLocation === BROWSER_URLS.SETTLEMENT ? STATIC_ASSETS.SETTLEMENT_ICON_ACTIVE : STATIC_ASSETS.SETTLEMENT_ICON}
                    />
                </List>
            </Drawer>
            <main>{props.children}</main>
        </SidebarWrapper>
    )
}

export default Sidebar