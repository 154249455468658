import { Dialog, styled } from "@mui/material";

export const DialogContainer = styled(Dialog)(({ mincontent }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    width: mincontent === "false" ? "-webkit-fill-available" : "min-content",
  },
}));

export const PopUpContainer = styled("div")(({ padding,borderRadius }) => ({
  padding: padding ? padding : "2rem 2.5rem",
  borderRadius: borderRadius ? borderRadius : "16px"
}));
