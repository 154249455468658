import { ListItemButton, ListItemIcon } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { STATIC_ASSETS } from '../../utils/staticAssets';
import ExpandableMenuPopover from './ExpandableMenuPopover';
import { ExpandableImg, HtmlTooltip, StyledListItem, StyledListItemText } from './menubar.styles';

const ListButtonItem = (handleClick, main_icon, main_label, active, expandableMenubarState) => {
    const itemId = `${main_label.split(" ").join("-").toLowerCase()}-item-text`;
    return <ListItemButton onClick={handleClick} sx={{
        height: 'inherit',
        justifyContent: 'initial',
        px: 2.5,
    }}>
        <ListItemIcon sx={{
            maxWidth: 16,
            mr: 3,
            justifyContent: 'center',
        }}
        >
            <img src={main_icon} alt={`${main_label}-icon`} />
        </ListItemIcon>
        <StyledListItemText id={itemId} primary={main_label} active={active} />

        {expandableMenubarState ? (<ExpandableImg src={STATIC_ASSETS.EXPAND_DOWN} alt="expand-down-img" />) : (<ExpandableImg src={STATIC_ASSETS.EXPAND_RIGHT} alt="expand-right-img" />)}
    </ListItemButton>
}

const ExpandableMenuBar = (props) => {
    const { open: drawerState } = useSelector((state) => state.drawerData)
    const { active, main_label, subList, currentLocation, main_icon, expandableMenubarState, setexpandableMenubarState
    } = props
    const handleClick = () => {
        if (drawerState) {
            setexpandableMenubarState(!expandableMenubarState);
        }
    };

    useEffect(() => {
        if (!drawerState) {
            setexpandableMenubarState(false)
        }
    }, [drawerState, setexpandableMenubarState]);

    return (
        drawerState ?
            <StyledListItem active={active} disablePadding {...props}>
                {ListButtonItem(handleClick, main_icon, main_label, active, expandableMenubarState)}
            </StyledListItem>
            : <StyledListItem active={active} disablePadding {...props}>
                <HtmlTooltip interactive="true" title={<ExpandableMenuPopover subList={subList} currentLocation={currentLocation} />} placement="right">
                    {ListButtonItem(handleClick, main_icon, main_label, active, expandableMenubarState)}
                </HtmlTooltip>
            </StyledListItem>
    )
}

export default ExpandableMenuBar