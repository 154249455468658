import { Controls } from "../../controls/index";
import React, { useState, useEffect, useCallback } from "react";
import { Components } from "../../components/index";
import { DrawerHeader } from '../../components/sidebar/Sidebar.styled';
import { ButtonsWrapper, MainBox, MainPageTopSection } from "../../globalStyles/global.styles";
import { Grid, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTopUpModal, setCurrentWithdrawalModal, setTopUpData, setWithdrawalData } from "../../redux/slice/modalSlice";
import { StyledSelectBoxHeader } from "./clientAccount.styles";
import { useBalanceData } from "../../customHook/useBalanceData";
import TopupWithdrawalTable from "./clientTables/TopupWithdrawalTable";
import { setCurrentLimit, setPaginationLimit, setPaginationPage, setShowData } from "../../redux/slice/paginationSlice";
import { paramConstants } from '../../utils/const'
import { useTopupWithdrawal } from "../../customHook/useTopUpWithDrawal";
import RefundTable from "./clientTables/RefundTable";
import { setCurrentClientBalance, setCurrentMerchantCode, setIsSubmitClicked, setOpenRefund } from "../../redux/slice/clientAccountSlice";
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { formatAmount } from '../../utils/utilFunctions';
import { useClientList } from "../../customHook/useClientList";

const ClientAccount = () => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const [openTopUp, setOpenTopUp] = useState(false);
    const [openWithdrawal, setOpenWithdrawal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [isClientNameSubmitted, setIsClientNameSubmitted] = useState(false);
    const { currentTopUpModal, currentWithdrawalModal, topUpAmount, withdrawalAmount, topUpSubmitDescription, withdrawalSubmitDescription } = useSelector((state) => state.modalData);
    const theme = useTheme();
    const dispatch = useDispatch();
    const [errorText, setErrorText] = useState("");
    const { handleTopupwithdrawal } = useTopupWithdrawal('/of-admin-dashboard/web/v1/merchant/balance', true, setErrorText)

    const { open } = useSelector(state => state.drawerData)
    const { merchantCode, currentClientBalance, openRefund, balanceLoader } = useSelector(state => state.clientAccountData)
    const { totalClientBalance } = useSelector(state => state.escrowAccountData)
    const { getClientBalance, getClientTotalBalance } = useBalanceData(setOpenErrorModal, setErrorText)
    const [tempMerchantCode, setTempMerchantCode] = useState(merchantCode);

    const { clientList, fetchClientList } = useClientList(setErrorText, setOpenErrorModal)

    const clientTopUp = useCallback(() => {
        const payload = { description: topUpSubmitDescription, amount: topUpAmount, mutationType: paramConstants.topUpMutation, transactionType: paramConstants.topUpType }
        handleTopupwithdrawal(payload)
        dispatch(setCurrentTopUpModal(2));
        getClientBalance()
        if (errorText) {
            setOpenErrorModal(true);
        }
    }, [topUpSubmitDescription, topUpAmount],)

    const clientWidthdrawal = useCallback(() => {
        const payload = { description: withdrawalSubmitDescription, amount: withdrawalAmount, mutationType: paramConstants.withdrawalMutation, transactionType: paramConstants.withdrawalType, }
        handleTopupwithdrawal(payload)
        dispatch(setCurrentWithdrawalModal(2));
        getClientBalance()
        if (errorText) {
            setOpenErrorModal(true);
        }
    }, [withdrawalSubmitDescription, withdrawalAmount],)

    useEffect(() => {
        fetchClientList()
        // Reset client name & balance
        setIsClientNameSubmitted(false)
        getClientTotalBalance()
        dispatch(setCurrentMerchantCode(null))

        return () => {
            dispatch(setPaginationLimit(50))
            dispatch(setCurrentLimit(50))
            dispatch(setPaginationPage(1))
            dispatch(setCurrentMerchantCode(''))
            dispatch(setCurrentClientBalance(0.00))
            dispatch(setShowData({ showFrom: 0, showTo: 0 }))
        };
    }, []);

    useEffect(() => {
        if(isClientNameSubmitted && merchantCode) {
            dispatch(setIsSubmitClicked(true))
            dispatch(setOpenRefund(false))
            getClientBalance()
        } // endif
    }, [merchantCode]);

    const handleSubmitButton = () => {
        if (tempMerchantCode) {
            dispatch(setCurrentMerchantCode(tempMerchantCode))
            setIsClientNameSubmitted(true)
        } else {
            setErrorText('Please select a merchant.')
            setOpenErrorModal(true)
        } // endif
    }

    const handleMainRefundButton = useCallback(() => {
        if (!openRefund) {
            if (merchantCode) {
                getClientBalance()
                dispatch(setOpenRefund(true))
            } else {
                setErrorText('Please select a merchant.')
                setOpenErrorModal(true)
            }
        }

    }, [merchantCode, openRefund],)

    const handleTopUpButton = useCallback(() => {
        dispatch(setOpenRefund(false))
        if (merchantCode) {
            getClientBalance()
            setOpenTopUp(true)
        } else {
            setErrorText('Please select a merchant.')
            setOpenErrorModal(true)
        }

    }, [getClientBalance, dispatch],)

    const handleWithdrawalButton = useCallback(() => {
        dispatch(setOpenRefund(false))
        if (merchantCode) {
            getClientBalance()
            setOpenWithdrawal(true)
        } else {
            setErrorText('Please select a merchant.')
            setOpenErrorModal(true)
        }
    }, [getClientBalance, dispatch],)

    const TopSectionButtonData = [{
        id: 'top-up-button', marginbottom: "0.8rem", text: "Top-Up",
        onClick: handleTopUpButton, modalState: openTopUp
    },
    {
        id: 'withdrawl-button', marginbottom: "0.8rem", text: strings.Withdrawal,
        onClick: handleWithdrawalButton, modalState: openWithdrawal
    },
    {
        id: 'refund-button', marginbottom: "0px", text: "Refund", onClick: handleMainRefundButton, modalState: openRefund
    }]

    const popupData = [
        {
            modalState: openTopUp,
            setModalState: setOpenTopUp,
            amount: topUpAmount,
            currentmodal: currentTopUpModal,
            setCurrentModal: setCurrentTopUpModal,
            submitModal: {
                setSubmitData: setTopUpData,
                modalHeader: strings.TopUp,
                modalTextAboveButton: strings.MoneyCreditClient
            },
            confirmModal: {
                modalHeader: 'Top-up',
                modalTextAboveButton: strings.ClickConfirmToProceed,
                AmountSentenceInitial: strings.YouAreAdding1,
                AmountSentenceEnding: strings.YouAreAdding2Client,
                mutationType: paramConstants.topUpMutation
            },
            doneModal: {
                modalHeader: strings.Success,
                AmountSentenceEnding: strings.SuccessfullyAddedClient
            }
        },
        {
            modalState: openWithdrawal,
            setModalState: setOpenWithdrawal,
            amount: withdrawalAmount,
            currentmodal: currentWithdrawalModal,
            setCurrentModal: setCurrentWithdrawalModal,
            submitModal: {
                setSubmitData: setWithdrawalData,
                modalHeader: strings.Withdrawal,
                modalTextAboveButton: strings.MoneyDebitClient
            },
            confirmModal: {
                modalHeader: strings.Withdrawal,
                modalTextAboveButton: strings.ClickConfirmToProceed,
                AmountSentenceInitial: strings.YouAreDebiting1,
                AmountSentenceEnding: strings.YouAreAdding2Client,
                mutationType: paramConstants.withdrawalMutation
            },
            doneModal: {
                modalHeader: strings.Success,
                AmountSentenceEnding: strings.SuccessfullyDebitedClient
            }
        }
    ]

    return (
        <Components.Layout>
            <DrawerHeader />
            <MainBox component="main" height={currentClientBalance && '100%'}>
                <MainPageTopSection>
                    <Components.TopCard showrightborder="true">
                        <Grid container justifyContent="center" alignItems={"center"} flexDirection="column">
                            <StyledSelectBoxHeader drawerstate={open}>{strings.SelectClientName}</StyledSelectBoxHeader>
                            <Controls.BaseAutoComplete
                                id="select-client-combo-box"
                                options={clientList.map(x => {
                                    return {id: x.code, label: [x.name, x.code].join(" - ") }
                                })}
                                label={strings.SelectName}
                                placeholder={strings.TypeToSearch}
                                value={tempMerchantCode} 
                                setValue={setTempMerchantCode}
                                onChangeValue={
                                    (newValue) => {
                                        // reset client submit status, once the user clear the dropdown
                                        if(! newValue) {
                                            setIsClientNameSubmitted(false)
                                            dispatch(setCurrentMerchantCode(null))
                                        } // endif
                                    }
                                } />
                            <Controls.BaseButton id="client-submit-button" height="46px" width="322px" text={strings.Submit} onClick={handleSubmitButton} />
                        </Grid>
                    </Components.TopCard>
                    <Components.TopCard
                        cardHeading={strings.TotalClientBalance}
                        cardAmount={(isClientNameSubmitted && merchantCode) ? `${formatAmount(currentClientBalance)}` : `${formatAmount(totalClientBalance)}`}
                        clientName={isClientNameSubmitted && merchantCode}
                        onRefresh={() => {
                            isClientNameSubmitted ? getClientBalance() : getClientTotalBalance()
                        }}
                        balanceLoader={balanceLoader}
                        showTooltip={! isClientNameSubmitted}
                    />
                    <Components.TopCard showleftborder="true">
                        <ButtonsWrapper>
                            {TopSectionButtonData.map((button, index) => (
                                <Controls.BaseButton
                                    id={button.id}
                                    key={index}
                                    height="47px"
                                    bgcolor={button.modalState ? theme.palette.primary.main : theme.palette.neutral.white}
                                    btnborder={button.modalState ? 'none' : `1px solid ${theme.palette.neutral.border}`}
                                    marginbottom={button.marginbottom}
                                    text={button.text}
                                    textcolor={button.modalState ? theme.palette.neutral.white : theme.palette.text.textEight}
                                    width="13.5rem"
                                    onClick={button.onClick}
                                />
                            ))}
                        </ButtonsWrapper>
                    </Components.TopCard>
                </MainPageTopSection>
                {currentClientBalance !== 0 && !openRefund && merchantCode && <TopupWithdrawalTable openRefund={openRefund} openTopUp={openTopUp} openWithdrawal={openWithdrawal} />}
                {openRefund && merchantCode && <RefundTable />}
                {popupData.map((popup, index) => (
                    <Components.BasePopup setOpen={popup.setModalState} show_dialog={popup.modalState} key={index} mincontent={popup.currentmodal !== 0 ? 'true' : 'false'}>
                        {popup.currentmodal === 0 && <Components.SubmitFormModal
                            isTooltip={true}
                            setCurrentModal={popup.setCurrentModal}
                            setData={popup.submitModal.setSubmitData}
                            setClose={popup.setModalState}
                            modalHeader={popup.submitModal.modalHeader}
                            modalTextAboveButton={popup.submitModal.modalTextAboveButton}
                            merchantCode={merchantCode}
                        />}
                        {popup.currentmodal === 1 && <Components.ConfirmModal setCurrentModal={popup.setCurrentModal} handleTopUp={clientTopUp} mutationType={popup.confirmModal.mutationType} handleWidthdrawal={clientWidthdrawal} setClose={popup.setModalState} amount={popup.amount} modalHeader={popup.confirmModal.modalHeader} modalTextAboveButton={popup.confirmModal.modalTextAboveButton} AmountSentenceInitial={popup.confirmModal.AmountSentenceInitial} AmountSentenceEnding={popup.confirmModal.AmountSentenceEnding} />}
                        {popup.currentmodal === 2 && <Components.DoneModal setCurrentModal={popup.setCurrentModal} setClose={popup.setModalState} amount={popup.amount} modalHeader={popup.doneModal.modalHeader} AmountSentenceEnding={popup.doneModal.AmountSentenceEnding} />}
                    </Components.BasePopup>
                ))}
                <Components.BasePopup setOpen={setOpenErrorModal} show_dialog={openErrorModal}>
                    <Components.ErrorHandlerModal errorText={errorText} setClose={setOpenErrorModal} setErrorText={setErrorText} />
                </Components.BasePopup>
            </MainBox>
        </Components.Layout>
    );
};

export default ClientAccount;
