import { Grid, InputAdornment, useTheme } from "@mui/material";
import jwt_decode from "jwt-decode";
import LocalizedStrings from "react-localization";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { usePasswordValidation } from "../../utils/newPasswordValidation";
import { Controls } from "../../controls";
import { StyledIconButton } from "../login/Login.styled";
import LocalData from "../../utils/localization";
import ExpireMessage from "../../components/cards/ExpireMessage";
import { CardPaper, CardWrapper, StyledButtonBlock } from "../../globalStyles/global.styles";
import { TypographyText, StyledValidateMessage, StyledValidateMessageDiv } from "./newPassword.styles";
import BaseApi from "../../api/BaseApi";
import { generateUuidValue } from "../../utils/utilFunctions";
import { setToken, setTokenResponse } from "../../redux/slice/authSlice";
import { Components } from "../../components";

const SetUpPassword = () => {
  const { currentLanguage } = useSelector((state) => state.languageData);
  const { token, tokenResponseData } = useSelector((state) => state.authData);
  const strings = new LocalizedStrings(LocalData);
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const [istokenVerify, setIstokenVerify] = useState(true);
  strings.setLanguage(currentLanguage);
  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });
  const [errorMessage, setError] = useState({});
  const [enable, setEnable] = useState(false);
  const [showPassword, setShowPassword] = useState({
    firstPassword: false,
    secondPassword: false,
  });
  const [validLength, hasNumber, upperCase, lowerCase, specialChar] =
    usePasswordValidation({
      firstPassword: password.firstPassword,
    });
  const setFirst = (event) => {
    const temp = { ...errorMessage };
    setPassword({ ...password, firstPassword: event.target.value });
    if (
      password.secondPassword !== "" &&
      !password.secondPassword.match(event.target.value)
    ) {
      temp.secondPassword = strings.Your_Password_And_Confirmation_Password_Must_Match;
      setError(temp);
    } else {
      temp.secondPassword = "";
      setError(temp);
    }
  };
  const setSecond = (event) => {
    let tempError = errorMessage;
    if (password.firstPassword.match(event.target.value)) {
      setPassword({ ...password, secondPassword: event.target.value });
      tempError.secondPassword = "";
    } else {
      tempError.secondPassword =
        strings.Your_Password_And_Confirmation_Password_Must_Match
      setPassword({ ...password, secondPassword: event.target.value });
    }
    return setError(tempError);
  };
  const defaultValidtionMessage = [
    {
      iconUrl: "",
      label: strings.Minimum_10_Characters,
      color: theme.palette.text.textSix,
    },
    {
      iconUrl: "",
      label: strings.Uppercase_Characters,
      color: theme.palette.text.textSix,
    },
    {
      iconUrl: "",
      label: strings.Lowercase_Characters,
      color: theme.palette.text.textSix,
    },
    {
      iconUrl: "",
      label: strings.A_Number,
      color: theme.palette.text.textSix,
    },
    {
      iconUrl: "",
      label: strings.Special_Characters,
      color: theme.palette.text.textSix,
    },
  ];
  useEffect(() => {
    if (password.firstPassword !== "" && password.firstPassword === password.secondPassword) {
      setEnable(false);
    }
    else {
      setEnable(true);
    }
  }, [password]);

  const getValidateColor = (value) => {
    if (value) {
      return theme.palette.green.main;
    }
    return theme.palette.red.main;
  };
  const getIconUrl = (value) => {
    if (value) {
      return STATIC_ASSETS.CHECK;
    }
    return STATIC_ASSETS.WRONG;
  };
  const validtionMessage = [
    {
      iconUrl: getIconUrl(validLength),
      label: strings.Minimum_10_Characters,
      color: getValidateColor(validLength),
    },
    {
      iconUrl: getIconUrl(upperCase),
      label: strings.Uppercase_Characters,
      color: getValidateColor(upperCase),
    },
    {
      iconUrl: getIconUrl(lowerCase),
      label: strings.Lowercase_Characters,
      color: getValidateColor(lowerCase),
    },
    {
      iconUrl: getIconUrl(hasNumber),
      label: strings.A_Number,
      color: getValidateColor(hasNumber),
    },
    {
      iconUrl: getIconUrl(specialChar),
      label: strings.Special_Characters,
      color: getValidateColor(specialChar),
    },
  ];
  const getValidationMessage = () => {
    if (password.firstPassword === "" && password.secondPassword === "") {
      return defaultValidtionMessage;
    }
    return validtionMessage;
  };

  const indormentFirstPasswordIcon = () => {
    if (password.firstPassword) {
      return <StyledIconButton
        edge="end"
        aria-label="Toggle password visibility"
        onClick={() => setShowPassword({ ...showPassword, firstPassword: !showPassword.firstPassword })}>
        {
          showPassword.firstPassword ? <img src={STATIC_ASSETS.EYE_ON} alt={'eye-on'} /> : <img src={STATIC_ASSETS.EYE_OFF} alt={'eye-off'} />
        }
      </StyledIconButton>
    }
  }

  const isVerifyToken = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    localStorage.setItem('token', params.token)
    try {
      const response = await BaseApi('get', '/of-admin-users/web/v1/tokens', "", { transactionId: generateUuidValue(), token: params.token }, {})
      const responseToken = response.data.token;
      const tokenData = jwt_decode(params.token);
      dispatch(setToken(responseToken))
      dispatch(setTokenResponse(tokenData.context))
      setIstokenVerify(true)
    } catch (e) {
      setIstokenVerify(false)
    }
  }
  useEffect(() => {
    isVerifyToken();
    // eslint-disable-next-line
  }, [])

  const cardHeaderTitle = () => {
    if (location.pathname === '/setup-password') {
      return strings.Set_New_Password
    }
    return strings.Reset_Password
  }
  const handleChangePassword = async () => {
    const params = { adminUser: tokenResponseData.adminCode, departmentCode: tokenResponseData.departmentCode }
    try {
      const response = await BaseApi('post', `/of-admin-users/web/v1/admin/${params.departmentCode}/users/${params.adminUser}/password`, "", {}, {
        transactionId: generateUuidValue(),
        password: password.firstPassword,
        token: token.token,
        userId: tokenResponseData.userId
      })
      if (response?.data && (response?.data?.code === 200 || response?.data?.code === 201)) {
        window.location.href = '/password-setup-success'
      }
    } catch (e) {
      setIstokenVerify(false)
    }
  }

  const DisplayCard = () => {
    if (istokenVerify) {
      return <CardPaper>
        <CardWrapper width={980}>
          <TypographyText>{cardHeaderTitle()}</TypographyText>
          <Grid item container spacing={4}>
            <Grid item md={8}>
              <Controls.BaseTextField
                fullWidth={true}
                placeholder={strings.Password_empty}
                idText="password"
                errorValue={errorMessage && errorMessage.firstPassword}
                helperText={errorMessage && errorMessage.firstPassword}
                onChange={(e) => setFirst(e)}
                value={password.firstPassword}
                type={showPassword.firstPassword ? "text" : "password"}
                label={strings.New_Password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {password.firstPassword && indormentFirstPasswordIcon()}
                    </InputAdornment>
                  ),
                }}
              />
              <Controls.BaseTextField
                fullWidth={true}
                placeholder={strings.Password_empty}
                idText="password2"
                errorValue={errorMessage && errorMessage.secondPassword}
                helperText={errorMessage && errorMessage.secondPassword}
                onChange={(e) => setSecond(e)}
                value={password.secondPassword}
                type={showPassword.secondPassword ? "text" : "password"}
                label={strings.Confirm_New_Password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {password.secondPassword && indormentSecondPasswordIcon()}
                    </InputAdornment>
                  ),
                }}
              />
              <StyledButtonBlock
              >
                <Controls.BaseButton
                  text={strings.Cancel}
                  width={140}
                  bgcolor='white'
                  textcolor={theme.palette.primary.black}
                  btnborder={theme.palette.primary.disabled}
                />
                <Controls.BaseButton
                  text={strings.Save}
                  width={140}
                  disabled={enable}
                  onClick={() => handleChangePassword()}
                />
              </StyledButtonBlock>
            </Grid>
            <Grid item md={4}>
              <StyledValidateMessage>
                <h6>{strings.Your_Password_Must_Have}</h6>
                {getValidationMessage().map((item, index) => (
                  <StyledValidateMessageDiv key={index} color={item.color}>
                    {password.firstPassword === "" &&
                      password.secondPassword === "" ? (
                      ""
                    ) : (
                      <img src={item.iconUrl} alt="" />
                    )}
                    {item.label}
                  </StyledValidateMessageDiv>
                ))}
              </StyledValidateMessage>
            </Grid>
          </Grid>
        </CardWrapper>
      </CardPaper>
    }
    return <ExpireMessage />
  }
  const indormentSecondPasswordIcon = () => {
    if (password.secondPassword) {
      return <StyledIconButton
        edge="end"
        aria-label="Toggle password visibility"
        onClick={() => setShowPassword({ ...showPassword, secondPassword: !showPassword.secondPassword })}>
        {
          showPassword.secondPassword ? <img src={STATIC_ASSETS.EYE_ON} alt={'eye-on'} /> : <img src={STATIC_ASSETS.EYE_OFF} alt={'eye-off'} />
        }
      </StyledIconButton>
    }
  }

  return (
    <>
      <Components.Appbar />
      {DisplayCard()}

    </>
  );
};
export default SetUpPassword;