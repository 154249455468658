import React, { useRef } from "react";
import { StyledCardRow0, StyledCardRow1, StyledCardRow2, StyledCardWrapper, StyledRefreshBtn } from "./cards.styles";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { Components } from "..";
import { SmallLoaderWrapper } from "../../globalStyles/global.styles";
import { Grid, Typography } from "@mui/material";

const TopBalanceValue = (props) => {
  const textElementRef = useRef();

  const { cardHeading, cardAmount, 
          clientName=null,
          tooltipPlacement="top",
          showrightborder, showleftborder, 
          disableRefresh=false,
          onRefresh, balanceLoader,
          cardwidth=null,
          variant="clientAccountPage",
          strings
        } = props;

  const refreshBtn = onRefresh ? <StyledRefreshBtn id="refresh-btn" className={disableRefresh ? "disabled" : null} onClick={disableRefresh ? null : onRefresh}>
    <img src={STATIC_ASSETS.REFRESH_ICON} alt="refresh" />
    <span>Refresh</span>
  </StyledRefreshBtn>
  : <></>

  const balanceElm = <StyledCardRow2>
    {!balanceLoader ? (
      <span className="fullwidth" ref={textElementRef}>{cardAmount}</span>
    ) : (
      <SmallLoaderWrapper>
        <img src={STATIC_ASSETS.LOADER} alt="loader-img" />
      </SmallLoaderWrapper>
    )}
  </StyledCardRow2>

  const balanceVariantClientAcc = <>
    {clientName && <StyledCardRow0>{clientName}</StyledCardRow0>}
    <Grid container justifyContent="space-between" sx={{marginBottom: "1rem"}}>
      <Grid item>
        <StyledCardRow1 sx={{fontSize: "22px"}}>
          {cardHeading}
          {
            props.balanceTooltip && <Components.HoverTooltip title={props.balanceTooltip} placement={tooltipPlacement} arrow />
          }
        </StyledCardRow1>
      </Grid>
      <Grid item>
        {
          refreshBtn
        }
      </Grid>
    </Grid>
    { balanceElm }
  </>

  const balanceVariantAyoLimit = <>
    <StyledCardRow1 sx={{marginBottom: "1rem", fontSize: "25px"}}>
      {cardHeading}
      {
        props.balanceTooltip && <Components.HoverTooltip title={props.balanceTooltip} placement={tooltipPlacement} arrow />
      }
    </StyledCardRow1>
    <Grid container justifyContent="space-between">
      <Grid item><Typography sx={{marginBottom: '1.6rem'}}>{strings.Balance}</Typography></Grid>
      <Grid item>
        {
          refreshBtn
        }
      </Grid>
    </Grid>
    { balanceElm }
  </>

  return (
    <StyledCardWrapper cardwidth={cardwidth} showrightborder={showrightborder} showleftborder={showleftborder}>
      {
        variant === "clientAccountPage" ? balanceVariantClientAcc : balanceVariantAyoLimit
      }
    </StyledCardWrapper>
  );
};

export default TopBalanceValue;
