import { ListItem, ListItemText, Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/styles";
import React from 'react';

export const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "disablePadding",
})(({ theme, active }) => ({
  "&.MuiListItem-root": {
    background: active ? theme.palette.primary.light : "transparent",
    display: "block",
    height: "4rem",
    "&::before": {
      content: '""',
      display: active ? "block" : "none",
      height: 54,
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 2,
      top: 5,
      left: 7,
      position: "absolute",
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export const StyledSubListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "disablePadding",
})(({ theme, active, popover }) => ({
  background: active ? theme.palette.neutral.hover : "transparent",
  display: "block",
  height: "inherit",
  // width: popover ? "10rem" : "auto",
  borderLeft: `${popover ? "1px" : "10px"} solid ${theme.palette.neutral.white
    }`,
  borderTop: `5px solid ${theme.palette.neutral.white}`,
  borderBottom: `${popover ? "5px" : "3px"} solid ${theme.palette.neutral.white
    }`,
  borderRight: `${popover ? "1px" : "10px"} solid ${theme.palette.neutral.white
    }`,
  borderRadius: popover ? 5 : 15,
}));

export const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== "primary" && prop !== "active",
})(({ theme, active }) => ({
  "& .MuiListItemText-primary": {
    color: active ? theme.palette.primary.main : theme.palette.text.body,
    fontWeight: theme.typography.fontWeight.medium,
    fontSize: theme.typography.fontSize.m1,
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: 0,
  },
}));

export const ExpandableImg = styled("img")({
  marginLeft: 10,
});

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.neutral.white,
    color: theme.palette.text.body,
    fontSize: theme.typography.fontSize.m2,
    fontWeight: theme.typography.fontWeight.regular,
    border: `2px solid ${theme.palette.primary.light}`,
    boxSizing: "border-box",
    boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
    width: "100%",
    padding: "15px 15px",
  },
}));
