import React from 'react'
import { Components } from '../../components'
import { DrawerHeader } from '../../components/sidebar/Sidebar.styled';
import { MainBox } from '../../globalStyles/global.styles'

const VirtualCard = () => {
    return (
        <Components.Layout>
            <DrawerHeader />
            <MainBox component="main">Virtual card</MainBox>
        </Components.Layout>
    )
}

export default VirtualCard