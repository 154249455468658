import { Button, Menu, MenuItem, useTheme } from '@mui/material'
import { Components } from '..'
import { StyledNoDataFound, StyledTableBody, StyledTableRow, StyledTableRowCell } from './PrimaryTable.styles';
import { StyledCell, LoaderWrapper } from '../../globalStyles/global.styles'
import React, { useState, useEffect, useCallback } from 'react';
import { STATIC_ASSETS } from '../../utils/staticAssets';
import { setPaginationLimit, setPaginationPage, setShowData } from '../../redux/slice/paginationSlice';
import { getOrEmpty, formatRupiah, generateCSV } from '../../utils/utilFunctions';
import { useVcTransactions } from '../../customHook/useVcTransactions';
import { TransactionKind, VcSpendTrxType, VcTopupTrxType } from '../../utils/const';
import { useRefundData } from '../../customHook/useRefundData';
import { useDispatch, useSelector } from "react-redux";
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { setCurrentRefundModal } from '../../redux/slice/modalSlice';
import Link from '@mui/material/Link';
import moment from 'moment/moment';

const VcClientTransactionTable = ({ 
    merchantCode, 
    multipleMerchant = false, 
    refreshMerchantBalance = null, 
    hasInit = false,
    handleTopupReverseBtn=null
}) => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const { filterPayload } = useSelector((state) => state.vcTransactionData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const defaultEmpty = "-"
    const theme = useTheme();
    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [detailModal, setDetailModal] = useState([]);
    const [loader, showLoader] = useState(true);
    const [refundModalShow, setRefundModalShow] = useState(false);
    const [reverseTopupMenuElm, setReverseTopupMenuElm] = useState(null);
    const openReverseTopupMenu = Boolean(reverseTopupMenuElm)
    const [lastTopupTrxIds, setLastTopupTrxIds] = useState([]);
    const [currentTopupReversal, setCurrentTopupReversal] = useState({
        trxId: null,
        amount: 0,
        reversedAmount: 0
    });

    const { showFrom, showTo, limit } = useSelector(state => state.paginationData)
    const { currentRefundModal } = useSelector(state => state.modalData)
    const { refundAmount, transactionReferenceNumber } = useSelector(state => state.refundRowData)

    const getPageCount = (pageNo, data) => {
        const dataLength = data?.length;
        const currentPage = pageNo - 1;
        const startCount = currentPage * limit + 1;
        if (pageNo === 1) {
            if (dataLength) {
                dispatch(setShowData({ showFrom: 1, showTo: dataLength }));

            } else {
                dispatch(setShowData({ showFrom: 0, showTo: 0 }));
            }
        } else if (pageNo > 1) {
            dispatch(
                setShowData({
                    showFrom: startCount,
                    showTo: startCount - 1 + dataLength,
                })
            );
        }
    };

    const getTableHeaders = (isSpend=false) => {
        let TableHeaders = []

        if(isSpend) {
            // spend table
            if(multipleMerchant) {
                // ayoconnect limit table
                TableHeaders = [
                    strings["DateAndTime"],
                    strings["TransactionId"],
                    strings["PurchaseId"],
                    strings["ClientName"],
                    strings["CardId"],
                    strings["TransactionType"],
                    strings["Amount"],
                    strings["Status"],
                ]
            } else {
                // client account table
                TableHeaders = [
                    strings["DateAndTime"],
                    strings["TransactionId"],
                    strings["PurchaseId"],
                    strings["CardId"],
                    strings["TransactionType"],
                    strings["Amount"],
                    strings["Status"],
                    // "", @notes: hide refund feature
                ]
            } // endif
        } else {
            // topup table
            if(multipleMerchant) {
                // ayoconnect limit table
                TableHeaders = [
                    strings["DateAndTime"],
                    strings["TransactionId"],
                    strings["ClientName"],
                    strings["TransactionType"],
                    strings["Amount"],
                    strings["Status"],
                ]
            } else {
                // client account table
                TableHeaders = [
                    strings["DateAndTime"],
                    strings["TransactionId"],
                    strings["TransactionType"],
                    strings["Amount"],
                    strings["AvailableBalance"],
                    strings["Status"],
                    strings["Action"]
                ]
            } // endif
        }

        return TableHeaders
    }

    const { getTransactionList, getLastTopupTrxIds } = useVcTransactions(showLoader, setList, setTotalCount, setErrorText, setOpenErrorModal, getPageCount);

    const isStatusApproved = (status) => {
        return status === "Approve"
    }

    const spendTrxType = (trxType) => {
        let result = null
        switch(trxType) {
            case VcSpendTrxType.fullReversal.id:
                result = VcSpendTrxType.fullReversal.label
            break
            case VcSpendTrxType.partialReversal.id:
                result = VcSpendTrxType.partialReversal.label
            break
            default:
                result = VcSpendTrxType.spend.label
            break
        }

        return result
    }

    const billingOrReversedAmount = (row) => {
        if([
            VcSpendTrxType.fullReversal.id, 
            VcSpendTrxType.partialReversal.id
            ].includes(row?.transactionType)
        ) {
            return row?.billingAmount - row?.replacementAmount
        } else {
            return row?.billingAmount
        }
    }

    async function afterRefundCallback() {
        // Refresh Table & Balance
        refreshMerchantBalance()
        await getTransactionList()
    }

    const { handleRefund } = useRefundData(
        setErrorText, setOpenErrorModal, null, "vc",
        setRefundModalShow, afterRefundCallback
    )

    const reverseTopupHandler = {
        click: useCallback((event, row) => {
            setReverseTopupMenuElm(event.currentTarget)
            setCurrentTopupReversal({
                trxId: row.transactionID,
                amount: row.amount,
                reversedAmount: row.reversedAmount
            })
        }, []),
        close: () => {
            setReverseTopupMenuElm(null)
        }
    }

    function getTransactionStatusText(row) {
        return isSuccessStatus(row) ? "Success" : "Failed"
    }

    function csvSpendCell(dateTime, row) {
        let result = []
        if (multipleMerchant) {
            result = [
                dateTime,
                getOrEmpty(row?.transactionID),
                getOrEmpty(row?.purchaseRequestID),
                `"${getOrEmpty(row?.clientName)}"`,
                getOrEmpty(row?.cardUid),
                transactionTypeStr(row),
                billingOrReversedAmount(row),
                getTransactionStatusText(row),
            ]
        } else {
            result = [
                dateTime,
                getOrEmpty(row?.transactionID),
                getOrEmpty(row?.purchaseRequestID),
                getOrEmpty(row?.cardUid),
                transactionTypeStr(row),
                billingOrReversedAmount(row),
                getTransactionStatusText(row),
            ]
        }
        return result
    }

    function csvTopupCell(dateTime, row) {
        let result = []
        // Topup transaction
        if (multipleMerchant) {
            result = [
                dateTime,
                getOrEmpty(row?.transactionID),
                `"${getOrEmpty(row?.merchantName)}"`,
                topupTrxType(row?.transactionType),
                row?.amount,
                getTransactionStatusText(row),
            ]
        } else {
            result = [
                dateTime,
                getOrEmpty(row?.transactionID),
                topupTrxType(row?.transactionType),
                row?.amount,
                (row?.limitAfter - row?.spendBefore),
                getTransactionStatusText(row),
            ]
        }
        return result
    }

    function mapCSVRows(parsedItems) {
        let headers = getTableHeaders(isSpendTrx()).filter(x => !!x) // ignore empty headers

        const contents = parsedItems.map(row => {
            const trxTimeObject = row?.trxTimeObject
            let dateTime = defaultEmpty
            if (trxTimeObject) {
                dateTime = trxTimeObject.dateDefault() + ' ' + trxTimeObject.timeDefault()
            } // endif

            let result = []
            if(isSpendTrx()) {
                result = csvSpendCell(dateTime, row)
            } else {
                if(! multipleMerchant) {
                    headers = headers.filter(x => x !== strings.Action)
                } // endif
                result = csvTopupCell(dateTime, row)
            }

            return result
        })

        let currentTime = new Date()
        currentTime = moment(currentTime).format('DD-MM-YYYY HH_mm_ss')

        let filename = [
            "CLIENT_TRANSACTION",
            merchantCode,
            currentTime
        ]
        if (multipleMerchant) {
            filename = [
                "AYOCONNECT_TRANSACTION",
                currentTime
            ]
        }

        filename = filename.join("_")

        return {
            filename,
            rows: [
                headers,
                ...contents
            ]
        }
    }

    function onDownload(parsedItem) {
        const { rows, filename } = mapCSVRows(parsedItem)
        generateCSV({ rows, filename })
    }

    useEffect(() => {
        dispatch(setPaginationLimit(50))
        dispatch(setPaginationPage(1))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // Prevent double call on first load, only call after parent hasInit=true
        if (hasInit) {

            if(! isSpendTrx()) {
                getLastTopupTrxIds().then(data => {
                    setLastTopupTrxIds(data)
                }).catch(e => {
                    console.error("Failed to fetch last topup trxId")
                })
            } // endif

            getTransactionList().catch(e => {
                console.error("Failed to fetch initial transaction", e)
            })
        } // endif
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterPayload])

    const showSpendDetail = (row) => {
        const defaultEmpty = "-"
        let transactionStatus = getTransactionStatusText(row)
        let transactionStatusColor = isStatusApproved(row?.responseCodeAction) ? theme.palette.green.main : theme.palette.red.main
        let transactionReason = isStatusApproved(row?.responseCodeAction) ? defaultEmpty : row?.responseCodeDescription
        if (row?.isRefund) {
            transactionStatus = "Success"
            transactionStatusColor = theme.palette.green.main
            transactionReason = defaultEmpty
        }

        const isSpendReversal = [VcSpendTrxType.fullReversal.id, VcSpendTrxType.partialReversal.id].includes(row?.transactionType)
        const tableContent = [
            {
                sectionTitle: strings.TransactionDetails,
                subData: [
                    {
                        label: strings.DateAndTime,
                        value: getOrEmpty(row?.dateTimeOneline)
                    },
                    {
                        label: strings.TransactionId,
                        value: getOrEmpty(row?.transactionID)
                    },
                    {
                        label: strings.TransactionType,
                        value: transactionTypeStr(row)
                    },
                    {
                        // @notes: need to add original trx amount (b4 reversal)
                        label: strings.TransactionAmount,
                        value: formatRupiah(row?.billingAmount)
                    },
                    {
                        label: strings.RefundAmount,
                        value: formatRupiah(row?.billingAmount - row?.replacementAmount),
                        hidden: ! isSpendReversal
                    },
                    {
                        label: strings.TransactionStatus,
                        value: transactionStatus,
                        valueColor: transactionStatusColor
                    },
                    {
                        label: strings.Reason,
                        value: isSpendReversal ? transactionTypeStr(row) : transactionReason
                    }
                ]
            },
            {
                sectionTitle: strings.CustomerDetails,
                subData: [
                    {
                        label: strings.CustomerId,
                        value: getOrEmpty(row?.customerUid)
                    },
                    {
                        label: strings.CardId,
                        value: getOrEmpty(row?.cardUid)
                    },
                    {
                        label: strings.CardLimit,
                        value: formatRupiah(row?.cardLimit)
                    },
                    {
                        label: strings.RemainingBalance,
                        value: formatRupiah(row?.cardBalanceAfter)
                    }
                ]
            },
            {
                sectionTitle: strings.ClientInfo,
                subData: [
                    {
                        label: strings.ClientName,
                        value: getOrEmpty(row?.clientName)
                    },
                    {
                        label: strings.ClientUpdatedBalance,
                        value: formatRupiah(row?.merchantBalanceAfter)
                    }
                ]
            }
        ]

        setDetailModal(tableContent)
        setOpenDetailModal(true)
    }

    const showTopupDetail = (row) => {
        const transactionStatus = "Success"
        const transactionStatusColor = theme.palette.green.main
        const isCustomerTopup = false // for now always false

        const trxTypeStr = () => {
            if(! row?.transactionType) {
                return VcTopupTrxType.topup.shortLabel
            } // endif

            let label = null
            switch(row?.transactionType) {
                case VcTopupTrxType.fullReversal.id:
                    label = VcTopupTrxType.fullReversal.shortLabel
                    break
                case VcTopupTrxType.partialReversal.id:
                    label = VcTopupTrxType.partialReversal.shortLabel
                    break
                case VcTopupTrxType.topup.id:
                    label = VcTopupTrxType.topup.shortLabel
                    break
                default:
                    label = 'Topup: ' + row?.transactionType
                    break
            }
            return label
        }

        const trxAmount = {
            label: strings.Amount,
            value: `+ ${formatRupiah(row?.amount)}`,
            valueColor: transactionStatusColor
        }
        const trxType = {
            label: strings.TransactionType,
            value: trxTypeStr()
        }
        const topupDoneVia = {
            label: strings.TopupDoneVia,
            value: "Cust/VA"
        }

        if([VcTopupTrxType.partialReversal.id, VcTopupTrxType.fullReversal.id].includes(row.transactionType)) {
            trxAmount.value = `- ${formatRupiah(row?.amount)}`
            trxAmount.valueColor = theme.palette.red.main
        } // endif

        let trxDetailSubData = [
            {
                label: strings.DateAndTime,
                value: getOrEmpty(row?.dateTimeOneline)
            },
            {
                label: strings.ClientName,
                value: getOrEmpty(row?.merchantName)
            },
            trxAmount,
            {
                label: strings.MerchantId,
                value: getOrEmpty(row?.merchantCode)
            },
            isCustomerTopup ? topupDoneVia : trxType,
            {
                label: strings.Status,
                value: transactionStatus,
                valueColor: transactionStatusColor
            },
        ]

        if(isCustomerTopup) {
            trxDetailSubData = [
                ...trxDetailSubData,
                {
                    label: strings.CustomerName,
                    value: "--"
                },
                {
                    label: strings.CardId,
                    value: "--"
                }
            ]
        } // endif

        const tableContent = [
            {
                sectionTitle: strings.TransactionDetails,
                subData: trxDetailSubData
            },
            {
                sectionTitle: strings.BalanceInfo,
                subData: [
                    {
                        label: strings.ClientUpdatedBalance,
                        value: formatRupiah(row?.limitAfter - row?.spendBefore)
                    }
                ]
            }
        ]

        setDetailModal(tableContent)
        setOpenDetailModal(true)
    }

    const rowLink = (row, label = '') => {
        function onClick() {
            if (isSpendTrx()) {
                showSpendDetail(row)
            } else {
                showTopupDetail(row)
            }
        }

        return <Link href="#" underline="hover" onClick={onClick}>
            {label}
        </Link>
    }

    function isSpendTrx() {
        return filterPayload?.transactionType === TransactionKind.spendRefund
    }

    function transactionTypeStr(row) {
        return spendTrxType(row?.transactionType)
    }

    function isSuccessStatus(row) {
        if (isSpendTrx()) {
            return isStatusApproved(row?.responseCodeAction)
        } else {
            return true
        } // endif
    }

    function getTimeStr(timeObject) {
        const tableRow = (
            <>
                <StyledCell
                    marginBottom="0.25rem"
                    color={theme.palette.text.body}
                    fontSize={theme.typography.fontSize.m1}
                >{timeObject.dateDefault()}</StyledCell>
                <StyledCell
                    color={theme.palette.text.textSix}
                    fontSize={theme.typography.fontSize.s}
                >{timeObject.timeDefault()}</StyledCell>
            </>
        )
        const oneLine = timeObject.dateDefault() + ', ' + timeObject.timeDefault()

        return {
            timeObject,
            tableRow,
            oneLine
        }
    }

    const getDateRow = (row, columnName='transactionCreatedAt') => {
        if (row[columnName]) {
            const {
                timeObject,
                tableRow,
                oneLine
            } = getTimeStr(row?.trxTimeObject)
            row.timeObject = timeObject
            row.dateTimeOneline = oneLine
            row.dateTimeRow = tableRow
            row.cardActualBalance = parseInt(row?.cardActualLimit) - parseInt(row?.cardActualSpend)
        }

        return row
    }

    const noTrxRow = (
        <StyledTableRow>
            <StyledTableRowCell colSpan={12}>
                <StyledNoDataFound>No Transactions</StyledNoDataFound>
            </StyledTableRowCell>
        </StyledTableRow>
    )

    const spendTable = (
        <StyledTableBody style={{ backgroundColor: 'black' }}>
            {
                list?.length < 1 ? noTrxRow : 
                (
                    list.map((row) => {
                        row = getDateRow(row)
                        return (
                            <StyledTableRow key={row.trxUid}>
                                {
                                    <StyledTableRowCell width="10%" align="left">{row.dateTimeRow ?? defaultEmpty}</StyledTableRowCell>
                                }
                                <StyledTableRowCell
                                    color={theme.palette.primary.main}
                                    align="left"
                                    width="15%"
                                >
                                    {
                                        row?.transactionID ? rowLink(row, row?.transactionID) : defaultEmpty
                                    }
                                </StyledTableRowCell>
                                <StyledTableRowCell align="left">
                                    { row?.purchaseRequestID }
                                </StyledTableRowCell>
                                {
                                    multipleMerchant ?
                                        <StyledTableRowCell width="15%" align="left">
                                            {row?.clientName ?? defaultEmpty}
                                        </StyledTableRowCell>
                                        : <></>
                                }
                                <StyledTableRowCell width={multipleMerchant ? "15%" : "20%"} align="left">
                                    {row?.cardUid ?? defaultEmpty}
                                </StyledTableRowCell>
                                <StyledTableRowCell align="left">
                                    {transactionTypeStr(row)}
                                </StyledTableRowCell>
                                <StyledTableRowCell align="left">
                                    {
                                        formatRupiah(billingOrReversedAmount(row))
                                    }
                                </StyledTableRowCell>
                                <StyledTableRowCell
                                    color={isSuccessStatus(row) ? theme.palette.green.main : theme.palette.red.main}
                                    align="left"
                                >
                                    {isSuccessStatus(row) ? "Success" : "Failed"}
                                </StyledTableRowCell>
                                {/* @notes: hide refund feature
                                    {
                                    multipleMerchant ? <></>
                                        : <StyledTableRowCell>
                                            {refundBtn(row)}
                                        </StyledTableRowCell>
                                } */}
                            </StyledTableRow>
                        )
                    })
                )
            }
        </StyledTableBody>
    )

    const visibleTopupReversalBtn = (row) => {
        const formattedRows = {
            transactionID: row.transactionID,
            transactionCreatedAt: row.transactionCreatedAt
        }

        const isMatch = lastTopupTrxIds.filter(lastTopup => {
            return lastTopup.transactionID === formattedRows.transactionID
                && lastTopup.transactionCreatedAt === formattedRows.transactionCreatedAt
        }).length > 0

        return isMatch && (row?.transactionType === VcTopupTrxType.topup.id)
    }
    const topupTrxType = (trxType) => {
        if(! trxType) {
            return VcTopupTrxType.topup.label
        } // endif

        let result = null
        switch(trxType) {
            case VcTopupTrxType.topup.id:
                result = VcTopupTrxType.topup.label
                break
            case VcTopupTrxType.partialReversal.id:
                result = VcTopupTrxType.partialReversal.label
                break
            case VcTopupTrxType.fullReversal.id:
                result = VcTopupTrxType.fullReversal.label
                break
            default:
                result = trxType
                break
        }
        return result
    }

    const handleTopupReversalClick = {
        fullReversal: () => {
            handleTopupReverseBtn({
                refundType: VcTopupTrxType.fullReversal.id, 
                trxId: currentTopupReversal.trxId, 
                topupInitialAmount: currentTopupReversal.amount,
                topupReversedAmount: currentTopupReversal.reversedAmount
            })
        },
        partialReversal: () => {
            handleTopupReverseBtn({
                refundType: VcTopupTrxType.partialReversal.id, 
                trxId: currentTopupReversal.trxId, 
                topupInitialAmount: currentTopupReversal.amount,
                topupReversedAmount: currentTopupReversal.reversedAmount
            })
        }
    }
    const topupPopover = (
        <>
            <Menu 
                anchorEl={reverseTopupMenuElm}
                id={openReverseTopupMenu ? "topup-btn-popover" : undefined}
                open={openReverseTopupMenu}
                onClose={reverseTopupHandler.close}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem 
                    disabled={currentTopupReversal.reversedAmount > 0 }
                    onClick={handleTopupReversalClick.fullReversal}>Full Refund</MenuItem>
                <MenuItem 
                    disabled={currentTopupReversal.reversedAmount >= currentTopupReversal.amount }
                    onClick={handleTopupReversalClick.partialReversal}>Partial Refund</MenuItem>
            </Menu>
        </>
    )
    
    const topupTrxId = (row) => {
        return row?.transactionType === VcTopupTrxType.topup.id ? row?.transactionID : row?.originalTransactionID
    }
    const multipleMerchantCell = (multipleMerchantContent=<></>, singleMerchantContent=<></>) => {
        return multipleMerchant ? multipleMerchantContent : singleMerchantContent
    }
    const topupTable = (
        <StyledTableBody style={{ backgroundColor: 'black' }}>
            {
                list?.length < 1 ? noTrxRow : 
                (
                    list.map((row) => {
                        row = getDateRow(row)
                        return (
                            <StyledTableRow key={row.trxUid}>
                                {
                                    <StyledTableRowCell width="15%" align="left">{row.dateTimeRow ?? defaultEmpty}</StyledTableRowCell>
                                }
                                <StyledTableRowCell
                                    align="left"
                                    width="20%"
                                >
                                    { topupTrxId(row) }
                                </StyledTableRowCell>
                                {
                                    multipleMerchantCell(
                                        <StyledTableRowCell width="15%" align="left">
                                            {row?.merchantName ?? defaultEmpty}
                                        </StyledTableRowCell>
                                    )
                                }
                                <StyledTableRowCell align="left">
                                    {topupTrxType(row?.transactionType)}
                                </StyledTableRowCell>
                                <StyledTableRowCell align="left">
                                    {
                                        rowLink(row, formatRupiah(row?.amount))
                                    }
                                </StyledTableRowCell>
                                {
                                    multipleMerchantCell(<></>, 
                                        <StyledTableRowCell align="left">
                                            { formatRupiah(row?.limitAfter - row?.spendBefore) }
                                        </StyledTableRowCell>
                                    )
                                }
                                <StyledTableRowCell
                                    color={isSuccessStatus(row) ? theme.palette.green.main : theme.palette.red.main}
                                    align="left"
                                >
                                    {isSuccessStatus(row) ? "Success" : "Failed"}
                                </StyledTableRowCell>
                                {
                                    multipleMerchantCell(<></>, 
                                        <StyledTableRowCell
                                            width="5%"
                                            align="right"
                                        >
                                            <Button
                                                sx={{
                                                    visibility: visibleTopupReversalBtn(row) ? "visible" : "hidden",
                                                    width: "50px"
                                                }}
                                                aria-describedby="topup-btn-popover"
                                                onClick={(e) => reverseTopupHandler.click(e, row)}>
                                                <img src={STATIC_ASSETS.VERTICAL_DOTS_ICON} alt="icon" />
                                            </Button>
                                        </StyledTableRowCell>
                                    )
                                }
                            </StyledTableRow>
                        )
                    })
                )
            }
        </StyledTableBody>
    )

    return (
        <>
            <Components.HeadRow
                getList={getTransactionList}
                tableName={strings.TransactionDetails}
                handleDownload={() => onDownload(list)}
                merchantCode={merchantCode}
                downloadBtnRequired={'true'}
            />
            {loader && (
                <LoaderWrapper data-testid="table-loader-image"><img src={STATIC_ASSETS.LOADER} alt={'loader'} /></LoaderWrapper>
            )}
            {!loader && (
                <Components.PrimaryTable headers={getTableHeaders(isSpendTrx())} listlength={list?.length}>
                    {
                        isSpendTrx() ? spendTable : topupTable
                    }
                </Components.PrimaryTable>
            )}
            <Components.BasePopup setOpen={setOpenErrorModal} show_dialog={openErrorModal}>
                <Components.ErrorHandlerModal errorText={errorText} setClose={setOpenErrorModal} />
            </Components.BasePopup>
            <Components.BasePopup maxWidth="md" setOpen={setOpenDetailModal} show_dialog={openDetailModal}>
                <Components.DetailModal modalHeader="Virtual Card" content={detailModal} setClose={setOpenDetailModal} />
            </Components.BasePopup>
            <Components.BasePopup setOpen={setRefundModalShow} show_dialog={refundModalShow} mincontent={'false'}>
                {currentRefundModal === 0 && <Components.ConfirmModal setCurrentModal={setCurrentRefundModal}
                    handleRefund={handleRefund}
                    setClose={setRefundModalShow}
                    amount={refundAmount}
                    modalHeader="Refund"
                    modalTextAboveButton={strings.ClickConfirmToProceed}
                    AmountSentenceInitial="You are updating "
                    AmountSentenceEnding={`as Refund against Transaction ID : ${transactionReferenceNumber} for “${merchantCode}”`}
                    refundPopup />}
                {currentRefundModal === 1 && <Components.DoneModal setCurrentModal={setCurrentRefundModal}
                    setClose={setRefundModalShow}
                    amount={refundAmount}
                    modalHeader={strings.Success}
                    AmountSentenceInitial="You are  Successfully updated "
                    AmountSentenceEnding={`as Refund against Transaction ID : ${transactionReferenceNumber} for “${merchantCode}”`} />}
            </Components.BasePopup>
            <Components.Pagination
                totalCount={totalCount}
                showTo={showTo} showFrom={showFrom}
                getList={getTransactionList}
            />

            {
                isSpendTrx() ? <></>
                : topupPopover
            }
        </>
    )
}

export default VcClientTransactionTable