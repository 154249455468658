import { createSlice } from "@reduxjs/toolkit";

export const refundRowSlice = createSlice({
  name: "refundRow",
  initialState: {
    customerId: "",
    beneficiaryId: "",
    transactionReferenceNumber: "",
    correlationId: "",
    refundAmount: "",
  },
  reducers: {
    setRefundRowData: (state, action) => {
      state.customerId = action.payload.customerId;
      state.beneficiaryId = action.payload.beneficiaryId;
      state.transactionReferenceNumber = action.payload.transactionReferenceNumber;
      state.correlationId = action.payload.correlationId;
      state.refundAmount = action.payload.refundAmount;
    },
  },
});

export const { setRefundRowData } = refundRowSlice.actions;
export default refundRowSlice.reducer;
