import { CardPaper, CardWrapper, MessageBlock, MessageSubTitle } from "../../globalStyles/global.styles";
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { useSelector } from "react-redux";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { Components } from "..";

const ExpireMessage = () => {
    const { currentLanguage } = useSelector((state) => state.languageData)
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage)

    return (<>
        <Components.Appbar />
        <CardPaper>
            <CardWrapper width={480}>
                <MessageBlock><img src={STATIC_ASSETS.CROSS_CIRCLE} alt="cross-icon" width={60} height={60} />
                    <MessageSubTitle><p> This URL has been expired. Please request a new one. </p></MessageSubTitle>
                </MessageBlock>
            </CardWrapper>
        </CardPaper>
    </>)
}
export default ExpireMessage;