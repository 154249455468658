import React from "react";
import { useTimer } from "react-timer-hook";
import { StyledTimerContainer } from "../globalStyles/global.styles";

function Timer({ expiryTimestamp, resendLink }) {
  const { seconds, minutes, restart } = useTimer({ expiryTimestamp, onExpire: (res) => console.log(res) });
  const handleReset = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 60);
    restart(time);
    resendLink();
  };

  if (seconds === 0) {
    return (
      <StyledTimerContainer>
        Dodn’t receive the link? <u onClick={() => handleReset()}> Resend Link 00:00</u>{" "}
      </StyledTimerContainer>
    );
  } else {
    return (
      <StyledTimerContainer>
        Dodn’t receive the link? Resend Link{" "}
        <span>
          {" "}
          0{minutes}:{seconds}{" "}
        </span>{" "}
      </StyledTimerContainer>
    );
  }
}
export default Timer;
