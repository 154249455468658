import { Collapse, List, ListItemButton, ListItemIcon } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { StyledListItemText, StyledSubListItem } from './menubar.styles'

const ExpandableCollapsableList = (props) => {
    const { expandableMenubarState, subList, currentLocation } = props
    return (
        <Collapse in={expandableMenubarState} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {subList.map((item, index) => (
                    <Link to={item.to} style={{ textDecoration: "none", height: 'inherit' }}>
                        <StyledSubListItem id={item.id || index} component='div' active={currentLocation === item.to ? 1 : 0} key={index}>
                            <ListItemButton
                                sx={{
                                    height: 'inherit',
                                    justifyContent: 'initial',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        maxWidth: 16,
                                        mr: 3,
                                        justifyContent: 'center',
                                    }}
                                >
                                </ListItemIcon>
                                <StyledListItemText id={item.id ? `${item.id}-text` : `${index}-text`} primary={item.label} title={item.label} />
                            </ListItemButton>
                        </StyledSubListItem>
                    </Link>
                ))
                }
            </List >
        </Collapse>
    )
}

export default ExpandableCollapsableList