import { Box, Grid, useTheme } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { StyledInputlabel } from '../../controls/inputs/BaseTextfield.styles'
import { StyledTooltipValue } from '../tooltip/Tooltip.styles'
import LocalData from "../../utils/localization"
import LocalizedStrings from 'react-localization'

const PriceConfigTooltipContent = (props) => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);
    const { toolTipList } = props
    const theme = useTheme()
    return (
        <Box sx={{ flexGrow: 1 }} data-testid="tooltip-content-wrapper">
            <Grid container>
                <Grid item xs={6} padding={'14px'}>
                    <StyledInputlabel data-testid="ayoconnect-fees-label" whitespace="wrap" fontsize_="14" lineheight_={"28px"} labelfontweight={theme.typography.fontWeight.inter}>{strings.AyoconnectFees}</StyledInputlabel>
                    <StyledTooltipValue data-testid="ayoconnect-fees-value">{toolTipList?.ayoconnectFeeType === 1 ? 'Rp ' + toolTipList?.ayoconnectFee : toolTipList?.ayoconnectFee + ' %'}</StyledTooltipValue>
                </Grid>
                <Grid item xs={6} padding={'14px'}>
                    <StyledInputlabel data-testid="bankfee-label" fontsize_="14" lineheight_={"28px"} labelfontweight={theme.typography.fontWeight.inter}>{strings.BankFees}</StyledInputlabel>
                    <StyledTooltipValue data-testid="bankfee-value">{toolTipList?.bankFeeType === 1 ? 'Rp ' + toolTipList?.bankFee : toolTipList?.bankFee + ' %'}</StyledTooltipValue>
                </Grid>
                <Grid item xs={6} padding={'14px'}>
                    <StyledInputlabel data-testid="clientfee-label" fontsize_="14" lineheight_={"28px"} labelfontweight={theme.typography.fontWeight.inter}>{strings.ClientFees}</StyledInputlabel>
                    <StyledTooltipValue data-testid="clientfee-value">{toolTipList?.clientFeeType === 1 ? 'Rp ' + toolTipList?.clientFee : toolTipList?.clientFee + ' %'}</StyledTooltipValue>
                </Grid>
            </Grid >
        </Box >
    )
}

export default PriceConfigTooltipContent