import TableRow from "@mui/material/TableRow";
import { StyledTableCell, StyledTableHeader, StyledTableMain, StyledTableWrapper } from "./PrimaryTable.styles";
import React from "react";

const PrimaryTable = (props) => {
  const { headers, listlength, secondary } = props;

  return (
    <StyledTableWrapper listlength={parseInt(listlength)}>
      <StyledTableMain>
        <StyledTableHeader secondarytable={secondary}>
          <TableRow>
            {headers.map((header, index) => (
              <StyledTableCell align="left" key={index} secondarytable={secondary}>
                {header}
              </StyledTableCell>
            ))}
          </TableRow>
        </StyledTableHeader>
        {props.children}
      </StyledTableMain>
    </StyledTableWrapper>
  );
};

export default PrimaryTable;
