import { useDispatch, useSelector } from "react-redux";
import BaseApi from "../api/BaseApi";
import { setFileAddedBoolean, setSizeErrorBoolean, setTypeErrorBoolean } from "../redux/slice/dragAndDropSlice";
import { generateUuidValue } from "../utils/utilFunctions";

export const useSettlementFileUpload = (getListApi, setSettlementFile, setOpenErrorModal, setErrorText) => {
  const { adminUser } = useSelector((state) => state.authData);
  const dispatch = useDispatch();

  const uploadSettleFileHandler = async (file) => {
    try {
      const setParams = {
        transactionId: generateUuidValue(),
        referenceNumber: generateUuidValue(),
      };

      const setPayload = new FormData();
      setPayload.append("user_id", adminUser?.id);
      setPayload.append("file", file);

      const response = await BaseApi("post", "/of-admin-dashboard/web/v1/settlement-status/file", "", setParams, setPayload, "multipart/form-data");

      if ((response?.data && response?.data?.code === 200) || response?.data?.code === 201) {
        getListApi(50, 1);
        setSettlementFile(null);
        dispatch(setFileAddedBoolean(false));
        dispatch(setTypeErrorBoolean(false));
        dispatch(setSizeErrorBoolean(false));
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
      setSettlementFile(null);
      dispatch(setFileAddedBoolean(false));
      dispatch(setTypeErrorBoolean(false));
      dispatch(setSizeErrorBoolean(false));
      getListApi(50, 1);
    }
  };
  return { uploadSettleFileHandler };
};
