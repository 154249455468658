import { createSlice } from "@reduxjs/toolkit";

export const rateLimiterSlice = createSlice({
  name: "rateLimiter",

  // retry after: in seconds
  initialState: {
    vcMerchantRetryAfter: 0,
  },
  reducers: {
    setVcMerchantBalanceRetry: (state, action) => {
      state.vcMerchantRetryAfter = action.payload
    },
  },
});

export const {
  setVcMerchantBalanceRetry
} = rateLimiterSlice.actions;
export default rateLimiterSlice.reducer;
