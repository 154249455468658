import { createSlice } from "@reduxjs/toolkit";
import { TransactionKind, TransactionStatus, VcFilterStorage } from "../../utils/const";

const defaultFilterPayload = () => {
  return {
    startDate: null,
    endDate: null,
    merchantCodes: [],
    transactionType: TransactionKind.spendRefund,
    transactionStatus: TransactionStatus.success,
    cardId: null,
    transactionId: null,
    postBody: {}
  }
}

export const vcTransactionSlice = createSlice({
  name: "vcTransaction",
  initialState: {
    filterPayload: defaultFilterPayload()
  },
  reducers: {
    setFilterPayload: (state, action) => {
      state.filterPayload = action.payload
      localStorage.setItem(VcFilterStorage, JSON.stringify(action.payload))
    },
    resetFilterPayload: (state, action) => {
      if(!action.payload) {
        action.payload = {};
      } // endif
      
      const newPayload = {
        ...defaultFilterPayload(),
        ...action.payload
      }
      state.filterPayload = newPayload
      localStorage.setItem(VcFilterStorage, JSON.stringify(newPayload))
    },
  },
});

export const {
  setFilterPayload,
  resetFilterPayload,
} = vcTransactionSlice.actions;
export default vcTransactionSlice.reducer;
