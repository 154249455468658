import React, { useState, useEffect } from "react";
import { Components } from "../../components/index";
import { DrawerHeader } from '../../components/sidebar/Sidebar.styled';
import { MainBox, MainPageTopSection } from "../../globalStyles/global.styles";
import { useDispatch, useSelector } from "react-redux";
import VcClientTransactionTable from "../../components/table/VcClientTransactionTable"
import { setCurrentLimit, setPaginationLimit, setPaginationPage, setShowData } from "../../redux/slice/paginationSlice";
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { setFilterPayload, resetFilterPayload } from '../../redux/slice/vcTransactionSlice';
import { Grid } from "@mui/material";
import VcTotalSpend from "../../components/cards/VcTotalSpend";
import { TransactionStatus } from "../../utils/const";
import VcClientAccFilter from "../../components/filters/VcClientAccFilter";
import { useVcBalance } from "../../customHook/useVcBalance";

const VcAyoLimit = () => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const [openErrorModal, setOpenErrorModal] = useState(false);
    const defaultEmpty = "-"
    const [state, setState] = useState({
        ayoLimitBalanceStr: defaultEmpty,
        totalClientBalanceStr: defaultEmpty,
        loadingBalance: false
    });
    const [hasInit, setHasInit] = useState(false);

    function updateState(newState) {
        setState(prev => {
            return {
                ...prev,
                ...newState
            }
        })
    }

    const dispatch = useDispatch();
    const [errorText, setErrorText] = useState("");

    const { fetchBalanceSummary } = useVcBalance({ updateState })

    function onApplyFilter(value) {
        if(! hasInit) {
            return false
        } // endif
        
        const [startDate, endDate] = [
            value.startDate ? value.startDate.toISOString() : null,
            value.endDate ? value.endDate.toISOString() : null,
        ]

        dispatch(setPaginationPage(1))
        dispatch(
            setFilterPayload({
                ...value,
                startDate,
                endDate
            })
        )
    }

    useEffect(() => {
        dispatch(setPaginationLimit(50))
        dispatch(setCurrentLimit(50))
        dispatch(setPaginationPage(1))
        dispatch(setShowData({ showFrom: 0, showTo: 0 }))

        fetchBalanceSummary().catch(e => {
            console.error("Failed to fetch initial balance", e)
        })
        setHasInit(true)
        
        setTimeout(() => {
            dispatch(resetFilterPayload({
                merchantCodes: [],
                transactionStatus: TransactionStatus.success,
                postBody: {
                    filter: { 
                        status: [ TransactionStatus.success ]
                    }
                }
            }))
        }
        , 200)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Components.Layout>
            <DrawerHeader />
            <MainBox component="main" height="100%">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <MainPageTopSection justifycontent="space-evenly">
                            <VcTotalSpend strings={strings} />
                        </MainPageTopSection>
                    </Grid>
                    <Grid item xs={6}>
                        <MainPageTopSection justifycontent="space-evenly">
                            {/* Keeping this, once product wants this feature back, will simply uncomment */}
                            {/* <Components.TopBalanceValue
                                cardwidth="60%"
                                showrightborder="true"
                                balanceTooltip={'Monthly credit limit / Remaining limit for the month (Monthly cycle from 27th to 26th)'}
                                cardHeading={'Ayoconnect Limit / Balance'}
                                cardAmount={state.ayoLimitBalanceStr}
                                balanceLoader={state.loadingBalance}
                            /> */}
                            <Components.TopBalanceValue
                                strings={strings}
                                cardwidth="100%"
                                balanceTooltip={'Remaining deposit amount with Ayoconnect'}
                                cardHeading={strings.TotalClientBalance}
                                cardAmount={state.totalClientBalanceStr}
                                onRefresh={fetchBalanceSummary}
                                balanceLoader={state.loadingBalance}
                                variant="ayoLimitPage"
                            />
                        </MainPageTopSection>
                    </Grid>
                </Grid>

                <VcClientAccFilter merchantCode={null} onApplyFilter={onApplyFilter} />
                <VcClientTransactionTable 
                    hasInit={hasInit}
                    merchantCode={null} multipleMerchant={true} />

                <Components.BasePopup setOpen={setOpenErrorModal} show_dialog={openErrorModal}>
                    <Components.ErrorHandlerModal errorText={errorText} setClose={setOpenErrorModal} setErrorText={setErrorText} />
                </Components.BasePopup>
            </MainBox>
        </Components.Layout>
    );
};

export default VcAyoLimit;
