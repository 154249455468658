import moment from 'moment-timezone';

export const timeToYMD = (timeObject) => {
  const [
    y,m,d
  ] = [
    timeObject.getFullYear(),
    timeObject.getMonth() + 1, // getMonth is index start from 0
    timeObject.getDate()
  ]
  const result = [y,m,d].join('-')
  return result
}

export const timetoString = (time, locale="en") => {
  const momentObj = moment(time).locale(locale)
  const finalObj = {}

  finalObj.customFormat = (format='') => {
    return momentObj.format(format)
  }
  finalObj.timeDefault = () => {
    return finalObj.customFormat('HH:mm:ss A')
  }
  finalObj.dateDefault = () => {
    return finalObj.customFormat('DD MMM YYYY')
  }

  return finalObj
}

export const defaultTz = () => {
  return "Asia/Jakarta"
}

export const unixTimeToString = (ts, timezone=null) => {
  if(! timezone) {
    timezone = defaultTz()
  }
  const momentObj = moment.unix(ts).tz(timezone)
  return timetoString(momentObj)
}

export const momentDateRange = (startDateObj, endDateObj, timezone=null) => {
  if(! timezone) {
    timezone = defaultTz()
  }
  let startDate = startDateObj.format('YYYY-M-D')
  startDate = moment.tz(startDate + ' 00:00', 'YYYY-M-D HH:mm', timezone)
  
  // let endDate = timeToYMD(endDateObj)
  let endDate = endDateObj.format('YYYY-M-D')
  endDate = moment.tz(endDate + ' 23:59', 'YYYY-M-D HH:mm', timezone)

  return {
    startDate,
    endDate
  }
}