import { Box, useTheme } from '@mui/material'
import React from 'react'
import { Controls } from '../../controls'
import { STATIC_ASSETS } from '../../utils/staticAssets';
import LocalData from "../../utils/localization";
import { useSelector } from "react-redux";
import LocalizedStrings from "react-localization";
import { ModalHeader, ModalWrapper, CloseIconGrid, LogoutBtnContainerGrid, LogoutIconWrapper } from './modal.styles'

const LogoutModal = ({ setClose }) => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);
    const theme = useTheme();
    const handleClose = () => {
        setClose(false)
    }
    const handleLogout = () => {
        localStorage.clear();
        window.location.href = "/"
    }

    return (
        <ModalWrapper data-testid="logout-modal-wrapper" container spacing={2} direction="column" justifyContent={'center'} alignItems={'center'}>
            <CloseIconGrid data-testid="logout-close-icon" item xs={12}
                alignItems={'right'}>
                <img src={STATIC_ASSETS.CLOSE_ICON} alt="close-icon" onClick={handleClose} />
            </CloseIconGrid>
            <LogoutIconWrapper>
                <img src={STATIC_ASSETS.LOGOUT} alt="logout" />
            </LogoutIconWrapper>
            <ModalHeader data-testid="modal-header" marginbottom="30px" center item xs={12}>
                <Box>
                    <p>{strings.Are_You_Sure_That}</p>
                    <h4>{strings.You_Want_To_Logout}</h4>
                </Box>
            </ModalHeader>
            <LogoutBtnContainerGrid >
                <Controls.BaseButton data-testid="close-btn" onClick={handleClose} width="140px" fontsize_={Number(14)} height="37px" text={strings.Cancel} bgcolor={theme.palette.neutral.white} textcolor={theme.palette.text.body} btnborder={`1px solid ${theme.palette.neutral.border}`} />
                <Controls.BaseButton data-testid="logout-btn" onClick={handleLogout} width="140px" fontsize_={Number(14)} height="37px" text={strings.Logout} bgcolor={theme.palette.red.secondary} />
            </LogoutBtnContainerGrid>
        </ModalWrapper>
    )
}

export default LogoutModal;