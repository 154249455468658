import { useSelector } from "react-redux";
import BaseApi from "../api/BaseApi";
import { paramConstants } from "../utils/const";
import { generateUuidValue } from "../utils/utilFunctions";

export const useTopupWithdrawal = (url, merchant, setErrorText) => {
  const { merchantCode } = useSelector((state) => state.clientAccountData);

  const handleTopupwithdrawal = async (payload) => {
    try {
      const body = {
        transactionId: generateUuidValue(),
        referenceNumber: generateUuidValue(),
        merchantCode: merchantCode || paramConstants.merchantCode,
      };

      const userId = localStorage.getItem('user_id');
      if (merchant) {
        Object.assign(body, {
          merchant: {
            amount: payload.amount,
            transactionType: payload.transactionType,
            mutationType: payload.mutationType,
            currency: paramConstants.currency,
            userId: userId,
            description: payload.description,
          },
        });
      } else {
        Object.assign(body, {
          escrow: {
            amount: payload.amount,
            transactionType: payload.transactionType,
            mutationType: payload.mutationType,
            bankCode: paramConstants.bankCode,
            productCode: paramConstants.productCode,
            currency: paramConstants.currency,
            userId: userId,
            description: payload.description,
          },
        });
      }
      const response = await BaseApi("patch", url, "", {}, body);

      if (response?.data && response?.data?.code === 200) {
        return await response?.data?.merchant?.amount;
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
    }
  };
  return { handleTopupwithdrawal };
};
