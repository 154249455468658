import {Typography,Grid,styled} from '@mui/material'
export const ForgotPasswordTitle = styled(Typography)(({ theme, width }) => ({
    fontSize: theme.typography.fontSize.l,
    fontWeight: theme.typography.fontWeight.xbold,
    lineHeight: '27px'
  }))
  export const ForgotPasswordSubTitle = styled(Typography)(({ theme, width }) => ({
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.m2,
    lineHeight: '19px',
    paddingBottom: '32px'
  }))
  export const StyledSuccessMessage = styled(Grid)(({ theme }) => (({
    textAlign: 'center',
    color: theme.palette.green.main,
    'div': {
      paddingBottom: "38px",
      paddingTop: "20px"
    }
  })))