import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    baseURL: "",
    adminUser: {},
    token: "sajj990990as",
    tokenResponseData: {},
  },
  reducers: {
    setBaseurl: (state, action) => {
      state.baseURL = action.payload;
    },
    setUserData: (state, action) => {
      state.adminUser = { ...action.payload };
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setTokenResponse: (state, action) => {
      state.tokenResponseData = { ...action.payload };
    },
  },
});

export const { setBaseurl, setUserData, setToken, setTokenResponse } = authSlice.actions;
export default authSlice.reducer;
