import { useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react'
import LocalizedStrings from 'react-localization';
import { useDispatch, useSelector } from 'react-redux';
import { Components } from '../../components'
import { DrawerHeader } from '../../components/sidebar/Sidebar.styled'
import { EmptyTableBackground, StyledTableBody, StyledTableRow, StyledTableRowCell } from '../../components/table/PrimaryTable.styles';
import { Controls } from '../../controls';
import { useSettlementFilesList } from '../../customHook/useSettlementFilesList';
import { LoaderWrapper, MainBox, MainPageTopSection, StyledCell } from '../../globalStyles/global.styles';
import { setShowData } from '../../redux/slice/paginationSlice';
import LocalData from '../../utils/localization';
import { STATIC_ASSETS } from '../../utils/staticAssets';
import { formatBytes, getDate, getTime } from '../../utils/utilFunctions';

const Settlement = () => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const theme = useTheme()
    const dispatch = useDispatch()

    const [errorText, setErrorText] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [loader, showLoader] = useState(false);
    const { showFrom, showTo, limit } = useSelector(state => state.paginationData)

    const getPageCount = (pageNo, data) => {
        const dataLength = data?.length;
        const currentPage = pageNo - 1;
        const startCount = currentPage * limit + 1;
        if (pageNo === 1) {
            if (dataLength) {
                dispatch(setShowData({ showFrom: 1, showTo: dataLength }));
            } else {
                dispatch(setShowData({ showFrom: 0, showTo: 0 }));
            }
        } else if (pageNo > 1) {
            dispatch(
                setShowData({
                    showFrom: startCount,
                    showTo: startCount - 1 + dataLength,
                })
            );
        }
    };

    const { settlementList, getSettlementFilesList } = useSettlementFilesList(setTotalCount, setErrorText, setOpenErrorModal, showLoader, getPageCount)

    const TableHeaders = [
        strings["UploadTime"],
        strings["UploadedFile"],
        strings["FileSize"],
        strings["UploadedBy"],
        strings["Status"],
    ]

    useEffect(() => {
        getSettlementFilesList(50, 1)
        return () => {
        };
    }, []);

    return (
        <Components.Layout>
            <DrawerHeader />
            <MainBox component="main" height="100%">
                <Components.HeadRow
                    tableName="Upload Settlement File"
                    onlyName

                />
                <MainPageTopSection flexdirection="column">
                    <Components.DragAndDrop getListApi={getSettlementFilesList} setOpenErrorModal={setOpenErrorModal} setErrorText={setErrorText} />
                </MainPageTopSection>
                <Components.HeadRow
                    tableName="File Upload History"
                    onlyName
                />
                {loader && (
                    <LoaderWrapper><img src={STATIC_ASSETS.LOADER} alt="loader-img" /></LoaderWrapper>
                )}
                {!loader && (
                    <Components.PrimaryTable headers={TableHeaders} listlength={settlementList?.length}>
                        <StyledTableBody>
                            {settlementList?.length > 0 ? (
                                settlementList.map((row) => {
                                    return (
                                        <StyledTableRow key={row?.id}>
                                            <StyledTableRowCell align="left">
                                                <StyledCell
                                                    marginBottom="0.25rem"
                                                    color={theme.palette.text.body}
                                                    fontSize={theme.typography.fontSize.m1}
                                                >{getDate(row?.uploadAt)}</StyledCell>
                                                <StyledCell
                                                    color={theme.palette.text.textSix}
                                                    fontSize={theme.typography.fontSize.s}
                                                >{getTime(row?.uploadAt)}</StyledCell>
                                            </StyledTableRowCell>
                                            <StyledTableRowCell color={theme.palette.primary.main} align="left">
                                                <Controls.LinkButton id={row?.id} value={row?.fileName} setErrorText={setErrorText} setOpenErrorModal={setOpenErrorModal} />
                                            </StyledTableRowCell>
                                            <StyledTableRowCell align="left">
                                                {formatBytes(row?.fileSize)}
                                            </StyledTableRowCell>
                                            <StyledTableRowCell align="left">
                                                {row?.uploadBy}
                                            </StyledTableRowCell>
                                            <StyledTableRowCell color={row?.status === 'SUCCESS' ? theme.palette.green.main : theme.palette.red.main} align="left">
                                                {row?.status}
                                            </StyledTableRowCell>
                                        </StyledTableRow>
                                    )
                                })
                            ) : (
                                <StyledTableRow>
                                    <StyledTableRowCell colSpan={12}>
                                        <EmptyTableBackground>
                                            <img src={STATIC_ASSETS.EMPTY_TABLE_IMAGE} alt="empty_table_image" />
                                            <div>No Settlement yet</div>
                                        </EmptyTableBackground>
                                    </StyledTableRowCell>
                                </StyledTableRow>
                            )}
                        </StyledTableBody>
                    </Components.PrimaryTable>
                )}
                <Components.Pagination
                    totalCount={totalCount}
                    showTo={showTo} showFrom={showFrom}
                    getList={getSettlementFilesList}
                />
                <Components.BasePopup setOpen={setOpenErrorModal} show_dialog={openErrorModal}>
                    <Components.ErrorHandlerModal errorText={errorText} setClose={setOpenErrorModal} setErrorText={setErrorText} />
                </Components.BasePopup>
            </MainBox>
        </Components.Layout>
    )
}

export default Settlement