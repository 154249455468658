import { Box, Grid, IconButton, styled } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { STATIC_ASSETS } from "../../utils/staticAssets";

export const StyledForgotPasswordBlock = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: "20px 0px",
  alignItems: "center",
  "& a": {
    textDecoration: "none",
    color: theme.palette.text.textEight,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "& img": {
      paddingRight: "6px",
      height: "16px",
    },
  },
}));
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: "0px",
  width: "35px",
}));

export const StyledReCaptcha = styled(ReCAPTCHA)(({ theme }) => ({
  width: "97%",
  transformOrigin: "0 0",
  transform: "scale(0.8)",
  "& div": {
    "& div": {
      "& div": {
        "& iframe": {
          backgroundColor: "transparent",
        },
      },
    },
  },
}));
export const LoginMainGrid = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDireaction: "row",
  fontFamily: theme.typography.fontFamily.inter,
  "& .MuiGrid-root:nth-of-type(1)": {
    backgroundColor: theme.palette.primary.main,
    padding: "24px 48px",
    color: theme.palette.neutral.white,
    fontSize: 40,
    fontWeight: theme.typography.fontWeight.semiBold,
    lineHeight: "48px",
    "& img:nth-of-type(1)": {
      paddingTop: "6px",
    },
    "& div:nth-of-type(1)": {
      marginTop: "58px",
    },
    "& div:nth-of-type(2)": {
      fontSize: 32,
      lineHeight: "39px",
      marginTop: "24px",
    },
    "& img:nth-of-type(2)": {
      width: "100%",
      marginTop: "12%",
      color: theme.palette.neutral.white,
    },
  },
}));

export const StyledLoginGridMain = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.white,
  height: "100vh",
  paddingRight: "48px",
}));

export const StyledLangMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  paddingTop: "22px",
  marginRight: "5%",
  "& .MuiButtonBase-root": {
    marginRight: "48px",
    color: theme.palette.text.body,
    fontWeight: 400,
    fontSize: theme.typography.fontWeight.m1,
    lineHeight: "16px",
    textTransform: "none",
  },
}));
export const StyledLoginWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "90%",
  paddingTop: "60px",
}));

export const LoginHeader = styled(Box)(({ theme }) => ({
  paddingBottom: "56px",
  fontWeight: 500,
  fontSize: 40,
  lineHeight: "48px",
  color: theme.palette.text.body,
  alignSelf: "baseline",
}));

export const StyledLoginForm = styled("form")(({ theme }) => ({
  margin: "auto",
  width: "65%",
  display: "flex",
  flexDirection: "column",
}));

export const StyledLoginLeftBlock = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${STATIC_ASSETS.LOGIN_PAGE_IMG_2})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right -150px top -50px",
}));
