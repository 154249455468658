import React from 'react'
import { useTheme } from '@mui/material'
import LocalizedStrings from 'react-localization'
import { useDispatch, useSelector } from 'react-redux'
import { Controls } from '../../controls'
import { setFileAddedBoolean, setSizeErrorBoolean, setTypeErrorBoolean } from '../../redux/slice/dragAndDropSlice'
import LocalData from '../../utils/localization'
import { STATIC_ASSETS } from '../../utils/staticAssets'
import { ErrorMessageWrapper, FileName, FileNameAndSize, FileSize, LeftElements, RightElements, UploadFileWrapper } from './dragAndDrop.styles'
import SelectFileComp from './SelectFileComp'
import { formatBytes } from '../../utils/utilFunctions'

const UploadFile = (props) => {
    const { fileTypes, file, handleUpload, setFile } = props
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);
    const { typeError, sizeError, isUploading } = useSelector(state => state.dragAndDropData)
    const theme = useTheme()
    const dispatch = useDispatch()

    function handleCancel() {
        dispatch(setFileAddedBoolean(false))
        dispatch(setTypeErrorBoolean(false))
        dispatch(setSizeErrorBoolean(false))
    }

    return (
        <UploadFileWrapper>
            <LeftElements>
                <img src={STATIC_ASSETS.FILE_PLUS_ICON} alt="file plus" />
                {!typeError && !sizeError ?
                    <FileNameAndSize>
                        <FileName>{file?.name}</FileName>
                        {isUploading && <Controls.ProgressBar />}
                        <FileSize>{formatBytes(file?.size)}</FileSize>
                    </FileNameAndSize>
                    :
                    <ErrorMessageWrapper typeError={typeError}>
                        <img src={STATIC_ASSETS.ERROR_INFO} alt="error info" />
                        {typeError &&
                            <div>Upload file in either .csv or .xlsx file format only.</div>}
                        {sizeError &&
                            <div>Max File Size is 5 MB.</div>}
                    </ErrorMessageWrapper>

                }
            </LeftElements>
            {!isUploading &&
                <RightElements error={!typeError && !sizeError}>
                    <Controls.BaseButton text={strings.Cancel} onClick={handleCancel} btnborder={`1px solid ${theme.palette.border.main}`} textcolor={theme.palette.text.body} bgcolor={theme.palette.neutral.white} width="101px" height="48px" />
                    {!typeError && !sizeError ?
                        <Controls.BaseButton text={strings.Upload} onClick={handleUpload} width="101px" height="46px" />
                        :
                        <SelectFileComp fileTypes={fileTypes} setFile={setFile} />
                    }
                </RightElements>
            }
        </UploadFileWrapper>
    )
}

export default UploadFile