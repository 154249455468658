import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { ResizableSelect, SelectMain } from './baseselectinput.styles';
import { OutlinedInput, useTheme } from '@mui/material';
import { StyledInputlabel } from '../inputs/BaseTextfield.styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 'auto',
        },
    },
};

export default function BasicSelect(props) {
    const { width_, width, height, value, onChange, placeholderText, marginbottom, labelfontweight, labelcolor, label, id, labelmarginbottom, values, disabledselect } = props
    const theme = useTheme()

    const val = values.find((value_) => value_.code === value)?.name || values.find((value_) => value_.productId === value)?.productName
    // check if the value is empty or not | return true if empty
    const isEmptyValue = ['', null].includes(value)

    return (
        <SelectMain disabledselect={disabledselect} width_={width_} width={width} marginbottom={marginbottom} height={height}>
            {label && (<StyledInputlabel labelmarginbottom={labelmarginbottom} labelfontweight={labelfontweight} labelcolor={labelcolor}>{label}</StyledInputlabel>)}
            <ResizableSelect
                disabled={disabledselect !== undefined && disabledselect === 'true' ? true : false}
                labelId="select-input"
                id={id? id : "select-input"}
                value={val ? val : value}
                onChange={onChange}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                displayEmpty={true}
                renderValue={(value) => (!isEmptyValue ? <div>{value}</div> : <div style={{ color: theme.palette.text.textEight }}>{placeholderText}</div>)}
            >
                {values.map((value_, index) => (
                    <MenuItem
                        value={value_?.code || value_?.productId}
                        key={index}
                    >
                        {value_?.name || value_?.productName}
                    </MenuItem>
                ))}
            </ResizableSelect>
        </SelectMain>
    );
}
