import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Popper, useTheme } from '@mui/material';
import { StyledInputlabel } from '../inputs/BaseTextfield.styles';
import { AutoCompleteWrapper, ResizableAutoComplete } from './baseautocomplete.styles';

const PopperMy = function (props) {
    return <Popper {...props} placement="bottom-start" style={{ maxWidth: "fit-content", height: "900px" }} />;
};

const TransferHistoryPopper = function (props) {
    return <Popper {...props} placement="bottom-start" style={{ maxWidth: "325px", height: "900px" }} />;
};

/**
 * 
 * @param options: [{id, label}]
 * @returns 
 */
const BaseAutoComplete = ({
    options = [],
    placeholder = "",
    value,
    label = "Select item",
    isTransferHistory,
    // function
    renderOption = null,
    setValue,
    onChangeValue = () => { },
    ...props
}) => {
    const theme = useTheme()
    const [internalValue, setInternalValue] = useState({});

    if (!renderOption) {
        renderOption = (props, option) => {
            return (
                <Box id={option.label} component="li" {...props}>
                    {option.label}
                </Box>
            )
        }
    }

    const onChange = (e, newValue) => {
        setValue(newValue?.id)
        onChangeValue(newValue)
    }

    const onInputChange = (e, newValue, reason) => {
        if (reason === "clear") {
            setInternalValue(null)
            setValue(null)
            onChangeValue(null)
        } // endif
    }

    useEffect(() => {
        const findValue = options.find(x => x.id === value)
        if (findValue) {
            setInternalValue(findValue)
        } // endif
    }, [value, options]);

    return (
        <AutoCompleteWrapper sx={{ marginBottom: "0.5rem" }}>
            {label && (<StyledInputlabel labelfontweight={500} labelmarginbottom="9px" labelcolor={theme.palette.text.textSix}>{label}</StyledInputlabel>)}
            <ResizableAutoComplete
                disablePortal
                id={props.id || "combo-box-ct"}
                value={internalValue}
                onChange={onChange}
                onInputChange={onInputChange}
                getOptionLabel={(option) => option.label || ""}
                options={options}
                renderOption={renderOption}
                fullWidth
                PopperComponent={isTransferHistory ? TransferHistoryPopper : PopperMy}
                renderInput={(params) => (
                    <TextField
                        {...props}
                        {...params}
                        variant="outlined"
                        placeholder={placeholder}
                        inputProps={{
                            ...params.inputProps
                        }}
                    />
                )}
            />
        </AutoCompleteWrapper>
    );
}

export default BaseAutoComplete;