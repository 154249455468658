import { useState } from "react";
import { useSelector } from "react-redux";
import BaseApi from "../api/BaseApi";
import { generateUuidValue } from "../utils/utilFunctions";

export const useBankList = (setErrorText, setOpenErrorModal) => {
  const [bankList, setBankList] = useState([]);
  const { merchantCode } = useSelector((state) => state.priceConfigData);

  const fetchBankList = async () => {
    try {
      const setParams = {
        transactionId: generateUuidValue(),
        referenceNumber: generateUuidValue(),
        merchantCode: merchantCode,
      };

      const response = await BaseApi("get", "/of-admin-dashboard/web/v1/banks", "", setParams);

      if (response?.data && response?.data?.code === 200) {
        const bankList = response?.data?.banks;
        if (Array.isArray(bankList) && bankList.length > 0) {
          setBankList(bankList);
        } else {
          setBankList([{ code: "", name: "" }]);
        }
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
    }
  };
  return { bankList, fetchBankList };
};
