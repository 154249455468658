import { Box, styled, Grid, Button } from "@mui/material";

export const StyledSelectBoxHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== "drawerstate",
})(({ theme, margintop, marginbottom }) => ({
  alignSelf: "baseline",
  marginTop: margintop ? margintop : "0px",
  marginBottom: marginbottom ? marginbottom : "0px",
  fontWeight: theme.typography.fontWeight.bold,
  fontSize: 20,
  lineHeight: "27.32px",
  color: theme.palette.text.body,
}));

export const StyledCenterBtn = styled(Grid,Button, {
  shouldForwardProp: (prop) => prop !== "drawerstate",
})(({ theme }) => ({
  gap: "20px",
  display: "flex",
  background: theme.palette.neutral.white,
  height: 80,
  width:350,
  alignItems:"center",
  justifyContent: "center",
  zIndex: 1000,
  borderRadius:"10px",
  position: "fixed",
  top: "90%",
  left: "45%",
  border: "1px solid #DFE2E6",
}));
