import { createSlice } from "@reduxjs/toolkit";

export const paginationSlice = createSlice({
  name: "pagination",
  initialState: {
    limit: 50,
    page: 1,
    showFrom: 0,
    showTo: 0,
    totalCount: 0,
    currentLimit: 50,
  },
  reducers: {
    setPaginationLimit: (state, action) => {
      state.limit = action.payload;
    },
    setPaginationPage: (state, action) => {
      state.page = action.payload;
    },
    setShowData: (state, action) => {
      state.showFrom = action.payload.showFrom;
      state.showTo = action.payload.showTo;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    setCurrentLimit: (state, action) => {
      state.currentLimit = action.payload;
    },
  },
});

export const { setPaginationLimit, setPaginationPage, setShowData, setTotalCount, setCurrentLimit } = paginationSlice.actions;
export default paginationSlice.reducer;
