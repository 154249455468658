import React, { useCallback } from "react";
import { Box, useTheme, Icon } from "@mui/material";
import { DownloadIcon, HeadRowFormControl, HeadRowWrapper } from "./HeadRow.styles";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { Controls } from "../../controls";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentLimit, setPaginationLimit, setPaginationPage } from "../../redux/slice/paginationSlice";
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { paginationArray } from "../../utils/const";

const HeadRow = (props) => {
  const { getList, tableName, onlyName, handleDownload, merchantCode, downloadBtnRequired } = props;
  const { currentLanguage } = useSelector((state) => state.languageData);
  const strings = new LocalizedStrings(LocalData);
  strings.setLanguage(currentLanguage);

  const theme = useTheme();
  const { currentLimit } = useSelector((state) => state.paginationData);
  const dispatch = useDispatch();
  const downloadIcon = (
    <Icon>
      <DownloadIcon src={STATIC_ASSETS.DOWNLOAD_ICON} alt="download_icon" />
    </Icon>
  );

  const onChange = useCallback(
    (e) => {
      dispatch(setPaginationLimit(e.target.value));
      dispatch(setCurrentLimit(e.target.value));
      dispatch(setPaginationPage(1));
      getList(e.target.value, 1);
    },
    [merchantCode]
  );

  return (
    <HeadRowWrapper>
      <Box data-testid="heading">{tableName}</Box>
      {!onlyName && (
        <Box>
          <Box>
            <Box>{strings.ShowEntries}</Box>
          </Box>
          <HeadRowFormControl downloadBtnRequired={downloadBtnRequired}>
            <Controls.BaseSelectInput
              value={currentLimit}
              onChange={onChange}
              values={paginationArray}
              labelmarginbottom="12px"
              height="32px"
              width="93px"
              inputpadding="0.5px 16px"
            />
          </HeadRowFormControl>
          {downloadBtnRequired && (
            <Controls.BaseButton
              id="download-btn"
              text={strings.Download}
              height="32px"
              width="10rem"
              bgcolor={theme.palette.neutral.white}
              textcolor={theme.palette.primary.main}
              startIcon={downloadIcon}
              onClick={handleDownload}
              btnborder={`1px solid ${theme.palette.neutral.border}`}
              fontSize={theme.typography.fontSize.m1}
            />
          )}
        </Box>
      )}
    </HeadRowWrapper>
  );
};

export default HeadRow;
