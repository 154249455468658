import { createSlice } from "@reduxjs/toolkit";

export const modalDataSlice = createSlice({
  name: "modalData",
  initialState: {
    currentTopUpModal: 0,
    currentWithdrawalModal: 0,
    currentRefundModal: 0,
    topUpAmount: "",
    topUpSubmitDescription: "",
    topUpTransactionID: "",
    withdrawalAmount: "",
    withdrawalSubmitDescription: "",
    refundAmount: "",
    refundTransactionID: "",
    refundName: ""
  },
  reducers: {
    setCurrentTopUpModal: (state, action) => {
      state.currentTopUpModal = action.payload;
    },
    setCurrentWithdrawalModal: (state, action) => {
      state.currentWithdrawalModal = action.payload;
    },
    setCurrentRefundModal: (state, action) => {
      state.currentRefundModal = action.payload;
    },
    setTopUpData: (state, action) => {
      state.topUpAmount = action.payload.amount;
      state.topUpSubmitDescription = action.payload.description;
      state.topUpTransactionID = action.payload?.transactionID;
    },
    setWithdrawalData: (state, action) => {
      state.withdrawalAmount = action.payload.amount;
      state.withdrawalSubmitDescription = action.payload.description;
    },
    setRefundData: (state, action) => {
      state.refundAmount = action.payload.amount;
      state.refundTransactionID = action.payload.transactionID;
      state.refundName = action.payload.name;
    },
    setInitialData: (state, action) => {
      state.topUpAmount = action.payload.amount;
      state.withdrawalAmount = action.payload.amount;
      state.topUpSubmitDescription = action.payload.description;
      state.withdrawalSubmitDescription = action.payload.description;
    },
  },
});

export const {
  setCurrentTopUpModal,
  setCurrentWithdrawalModal,
  setCurrentRefundModal,
  setTopUpData,
  setWithdrawalData,
  setRefundData,
  setInitialData,
} = modalDataSlice.actions;
export default modalDataSlice.reducer;
