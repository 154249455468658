import { createSlice } from "@reduxjs/toolkit";

export const priceConfigSlice = createSlice({
  name: "priceConfig",
  initialState: {
    merchantCode: "",
    product: "",
    bank: "",
    bankFeesType: "",
    bankFees: "",
    ayoconnectFeesType: "",
    ayoconnectFees: "",
    clientFeesType: "",
    clientFees: "",
    configOneFilter: false,
    editableFlag: false,
    productList: [],
  },
  reducers: {
    setCurrentMerchantCode: (state, action) => {
      state.merchantCode = action.payload;
    },
    setCurrentProduct: (state, action) => {
      state.product = action.payload;
    },
    setCurrentBank: (state, action) => {
      state.bank = action.payload;
    },
    setBankFeesType: (state, action) => {
      state.bankFeesType = action.payload;
    },
    setBankFees: (state, action) => {
      state.bankFees = action.payload;
    },
    setAyoconnectFeesType: (state, action) => {
      state.ayoconnectFeesType = action.payload;
    },
    setAyoconnectFees: (state, action) => {
      state.ayoconnectFees = action.payload;
    },
    setClientFeesType: (state, action) => {
      state.clientFeesType = action.payload;
    },
    setClientFees: (state, action) => {
      state.clientFees = action.payload;
    },
    setProductList: (state, action) => {
      state.productList = action.payload;
    },
    setPriceConfigSelectorOneToInitial: (state, action) => {
      return {
        ...state,
        merchantCode: action.payload.merchantCode,
        product: action.payload.product,
        bank: action.payload.bank,
      };
    },
    setPriceConfigSelectorTwoToInitial: (state, action) => {
      return {
        ...state,
        bankFeesType: action.payload.bankFeesType,
        bankFees: action.payload.bankFees,
        ayoconnectFeesType: action.payload.ayoconnectFeesType,
        ayoconnectFees: action.payload.ayoconnectFees,
        clientFeesType: action.payload.clientFeesType,
        clientFees: action.payload.clientFees,
      };
    },
    setAllDataForPricingConfig: (state, action) => {
      return {
        ...state,
        merchantCode: action.payload.merchantCode,
        product: action.payload.product,
        bankFeesType: action.payload.bankFeesType,
        bankFees: action.payload.bankFees,
        ayoconnectFeesType: action.payload.ayoconnectFeesType,
        ayoconnectFees: action.payload.ayoconnectFees,
        clientFeesType: action.payload.clientFeesType,
        clientFees: action.payload.clientFees,
      };
    },
    setConfigOneFilter: (state, action) => {
      state.configOneFilter = action.payload;
    },
    setEditableFlag: (state, action) => {
      state.editableFlag = action.payload;
    },
  },
});

export const {
  setCurrentMerchantCode,
  setCurrentProduct,
  setCurrentBank,
  setBankFeesType,
  setBankFees,
  setAyoconnectFeesType,
  setAyoconnectFees,
  setClientFeesType,
  setClientFees,
  setPriceConfigSelectorOneToInitial,
  setPriceConfigSelectorTwoToInitial,
  setConfigOneFilter,
  setAllDataForPricingConfig,
  setEditableFlag,
  setProductList,
} = priceConfigSlice.actions;
export default priceConfigSlice.reducer;
