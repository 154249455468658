import { styled, Box, Grid, Typography, Tab, TextField } from "@mui/material";

export const MainPageTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "fontSize",
})(({ theme, fontSize="20px" }) => ({
  fontSize,
  fontWeight: "bold",
  color: "#333333"
}));

export const BorderedBox = styled(Box, {
  shouldForwardProp: (prop) => !["padding", "borderRadius"].includes(prop),
})(({ theme, borderRadius="25px" }) => ({
  borderRadius,
  border: "1px #EBEDF0 solid",
  backgroundColor: theme.palette.neutral.white,
  width: "100%",
  "& .overflow": {
    height: "560px",
    overflowY: "auto",
    scrollBehavior: "smooth"
  }
}));

export const SectionBox = styled(Box, {
})(({ theme }) => ({
  marginBottom: "1rem",
  borderBottom: "1px #EBEDF0 solid",
  padding: "1rem 25px"
}));

export const VcSettingTab = styled(Tab, {
})(({ theme }) => ({
  textTransform: "unset",
  fontWeight: 600,
  fontSize: "16px",
  paddingLeft: "0 1.5rem"
}));

export const CustomTextField = styled(TextField, {
})(({ theme }) => ({
  "&.MuiOutlinedInput-root": {
    color: "green",
    "&.Mui-disabled": {
      backgroundColor: 'blue !important',
      color: 'red',
      border: "none"
    }
  }
}));