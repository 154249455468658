import React, { useEffect, useState } from 'react';
import { Components } from '../../components';
import { DrawerHeader } from '../../components/sidebar/Sidebar.styled';
import SupplierBankCard from '../../components/cards/SupplierCard';
import { BreadCrumbs } from '@ayoconnect/common-ui-components';
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { useSelector } from 'react-redux';
import { LoaderWrapper, MainBox } from '../../globalStyles/global.styles';
import { StyledSupplierHeading, StyledCardsWrapper, StyledBanner } from './supplierManagement.styles';
import ToastMessage from '../../components/alert/ToastMessage';
import { useGetSupplierList } from '../../customHook/useGetSupplierList';
import { STATIC_ASSETS } from '../../utils/staticAssets';
import SupplierDetailsView from '../../components/cards/SupplierDetailsView';

const nobuActive = "https://storage.googleapis.com/dd-ui-static-dev/Admin-dash/SupplierManagement/NOBU.png";
const briActive = "https://storage.googleapis.com/dd-ui-static-dev/Admin-dash/SupplierManagement/BRI.png"

const SupplierManagement = () => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const settingsIcon = "https://storage.googleapis.com/dd-ui-static-dev/Admin-dash/SupplierManagement/settings-icon.png";
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);


    const [openSwitchModal, setOpenSwitchModal] = useState(false);
    // const [openMaintenanceModal, setOpenMaintenanceModal] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [supplierData, setSupplierData] = useState([])
    const [selectedBankCode, setSelectedBankCode] = useState("");
    const [selectedBankState, setSelectedBankState] = useState("");
    const [totalBalanceBri, setTotalBalanceBri] = useState("");
    const [totalBalanceNobu, setTotalBalanceNobu] = useState("");
    const [isSystemMaintainance, setIsSystemMaintainance] = useState(false);
    const [loader, setLoader] = useState(false);
    const [toastType, setToastType] = useState("");
    const [toastHeading, setToastHeading] = useState("");
    const [toastMsg, setToastMsg] = useState("");
    const [cardLoader, setCardLoader] = useState(false);
    const [detailsView, setDetailsView] = useState(false);
    const [merchantsData, setMerchantsData] = useState({ isSearchSuccess: false, data: [] })

    const { getSupplierList, updateStatus } = useGetSupplierList(setOpenToast, setToastType, setToastHeading, setToastMsg);

    useEffect(() => {
        getAllData('all');
        return (() => {
            localStorage.removeItem('dataBri');
            localStorage.removeItem('dataNobu');
        })
    }, []);


    const getTotalBalance = (data) => {
        let totalBalance = 0;
        data?.length > 0 && data.forEach((item) => {
            totalBalance = totalBalance + parseInt(item.amount)
        })
        return totalBalance;
    }

    const getAllData = async (param, query = "") => {
        let briData = {};
        let nobuData = {};
        if (param === 'nobu') {
            setCardLoader(true);
            nobuData = await getSupplierList("503", query);
            if (query === "") {
                localStorage.setItem('dataNobu', JSON.stringify(nobuData));
                setMerchantsData({
                    isSearchSuccess: false,
                    data: []
                })
            }
            else {
                setMerchantsData({
                    isSearchSuccess: nobuData.isSuccess,
                    data: nobuData?.data?.merchants
                })
            }
            briData = localStorage.getItem('dataBri');
            briData = JSON.parse(briData);
        }
        else if (param === "bri") {
            setCardLoader(true);
            briData = await getSupplierList("002", query);
            if (query === "") {
                localStorage.setItem('dataBri', JSON.stringify(briData))
                setMerchantsData({
                    isSearchSuccess: false,
                    data: []
                })
            }
            else {
                setMerchantsData({
                    isSearchSuccess: briData.isSuccess,
                    data: briData?.data?.merchants
                })
            }
            nobuData = localStorage.getItem('dataNobu');
            nobuData = JSON.parse(nobuData);
        }
        else {
            setLoader(true);
            briData = await getSupplierList("002");
            localStorage.setItem('dataBri', JSON.stringify(briData))
            nobuData = await getSupplierList("503");
            localStorage.setItem('dataNobu', JSON.stringify(nobuData))
            setMerchantsData({
                isSearchSuccess: false,
                data: []
            })
        }
        let activeValSum = parseInt(nobuData.data?.BankDetails?.supplierActive) + parseInt(briData.data?.BankDetails?.supplierActive)
        if (activeValSum === 1) {
            setIsSystemMaintainance(true);
        }
        else {
            setIsSystemMaintainance(false);
        }
        if (briData.isSuccess && nobuData.isSuccess) {
            let tempData = [
                {
                    name: nobuData.data?.BankDetails?.name,
                    active: nobuData.data?.BankDetails?.supplierActive,
                    totalClients: nobuData.data?.merchants?.length,
                    icon: nobuActive,
                    deactivatedIcon: "https://storage.googleapis.com/dd-ui-static-dev/Admin-dash/SupplierManagement/NOBU-deactivated.png",
                    supplierCreated: nobuData.data?.BankDetails?.supplierCreated,
                    supplierUpdated: nobuData.data?.BankDetails?.supplierUpdated,
                    bankCode: "503",
                    disableBank: false,
                    duration: nobuData.data?.BankDetails?.duration,
                    type: "BI Fast"
                },
                {
                    name: briData.data?.BankDetails?.name,
                    active: briData.data?.BankDetails?.supplierActive,
                    totalClients: briData.data?.merchants?.length,
                    icon: briActive,
                    deactivatedIcon: "https://storage.googleapis.com/dd-ui-static-dev/Admin-dash/SupplierManagement/BRI-deactivated.png",
                    supplierCreated: briData.data?.BankDetails?.supplierCreated,
                    supplierUpdated: briData.data?.BankDetails?.supplierUpdated,
                    bankCode: "002",
                    disableBank: false,
                    duration: briData.data?.BankDetails?.duration,
                    type: "BI Fast"
                },
                {
                    name: "OY!",
                    active: 0,
                    totalClients: 0,
                    icon: "https://storage.googleapis.com/dd-ui-static-dev/Admin-dash/SupplierManagement/OYI-active.png",
                    deactivatedIcon: "https://storage.googleapis.com/dd-ui-static-dev/Admin-dash/SupplierManagement/OYI-inactive.png",
                    supplierCreated: nobuData.data?.BankDetails?.supplierCreated,
                    supplierUpdated: nobuData.data?.BankDetails?.supplierUpdated,
                    bankCode: "000",
                    disableBank: true,
                    duration: "",
                    type: "Online Transfer"
                }
            ];
            setSupplierData(tempData);
        }
        const briBalance = getTotalBalance(briData?.data?.balance);
        const nobuBalance = getTotalBalance(nobuData?.data?.balance);
        setTotalBalanceBri(briBalance);
        setTotalBalanceNobu(nobuBalance);
        setCardLoader(false);
        setLoader(false);
    }

    const setSelectedValues = (bCode, isActive) => {
        setSelectedBankCode(bCode);
        setSelectedBankState(isActive);
    }

    const onViewClient = (bCode) => {
        setSelectedBankCode(bCode);
        setDetailsView(true)
    }

    return (
        <Components.Layout>
            <DrawerHeader />
            <ToastMessage
                open={openToast}
                type={toastType}
                setOpenToast={setOpenToast}
                onClose={() => setOpenToast(false)}
                heading={toastHeading}
                msg={toastMsg}
            />

            {loader && (
                <LoaderWrapper data-testid="table-loader-image"><img src={STATIC_ASSETS.LOADER} alt={'loader'} /></LoaderWrapper>
            )}
            {!loader && (
                <MainBox bgColor="#fff" overflowY="scroll" component="main">
                    {detailsView ? (
                        <BreadCrumbs
                            items={[
                                { text: strings["InstantTransfer"] },
                                { text: strings.supplierManagement["supplierManagement"] },
                                { text: selectedBankCode === "503" ? "Nobu" : "BRI" },
                            ]}
                        />
                    ) : (
                        <BreadCrumbs
                            items={[
                                { text: strings["InstantTransfer"] },
                                { text: strings.supplierManagement["supplierManagement"] },
                            ]}
                        />
                    )}
                    <StyledSupplierHeading>{strings.supplierManagement.supplierManagement}</StyledSupplierHeading>
                    {!detailsView && (
                        <>
                            {(supplierData?.length > 0 && !supplierData.some((item) => parseInt(item.active) === 1)) && (
                                <StyledBanner>
                                    <img style={{ height: "40px", width: "40px" }} src={settingsIcon} alt="maintainance" />
                                    <div style={{ width: "85%", display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                                        <div>{strings.supplierManagement.bannerLine1}</div>
                                        <div>{strings.supplierManagement.bannerLine2}</div>
                                    </div>
                                </StyledBanner>
                            )}
                            <StyledCardsWrapper>
                                {supplierData.length > 0 && supplierData.map((item) => {
                                    return (
                                        <SupplierBankCard
                                            data={item}
                                            icon={item.icon}
                                            key={item.name}
                                            loader={cardLoader}
                                            bankName={item.name}
                                            isActive={item.active}
                                            totalClients={item.totalClients}
                                            deactivatedIcon={item.deactivatedIcon}
                                            setOpen={setOpenSwitchModal}
                                            onViewClient={() => onViewClient(item.bankCode)}
                                            selectedBankCode={selectedBankCode}
                                            setSelectedValues={() => setSelectedValues(item.bankCode, item.active)}
                                            disableBank={item.disableBank}
                                        />
                                    )
                                })}
                            </StyledCardsWrapper>
                        </>
                    )}
                    {detailsView && (
                        <SupplierDetailsView
                            merchantsData={merchantsData}
                            getAllData={getAllData}
                            lang={currentLanguage}
                            setDetailsView={setDetailsView}
                            selectedBankCode={selectedBankCode}
                            icon={selectedBankCode === "503" ? nobuActive : briActive}
                            totalBalance={selectedBankCode === "503" ? totalBalanceNobu : totalBalanceBri}
                        />
                    )}
                    <Components.BasePopup
                        setOpen={setOpenSwitchModal}
                        show_dialog={openSwitchModal}
                        padding="2rem 1.5rem 1.5rem "
                        borderRadius="8px"
                    >
                        <Components.SupplierSwitchModal
                            setClose={setOpenSwitchModal}
                            updateStatus={updateStatus}
                            getAllData={getAllData}
                            setLoader={setCardLoader}
                            bCode={selectedBankCode}
                            isActive={selectedBankState}
                            totalBalanceBri={totalBalanceBri}
                            totalBalanceNobu={totalBalanceNobu}
                            isSystemMaintainance={isSystemMaintainance}
                        />
                    </Components.BasePopup>
                </MainBox>
            )}
        </Components.Layout>
    )
}

export default SupplierManagement;