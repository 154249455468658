import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Components } from "../../components/index";

export default function Layout({ filteropen=false, children }) {
    return (
        <Box>
            <CssBaseline />
            <Components.Appbar profile="true" filteropen={filteropen} />
            <Components.Sidebar>
                {children}
            </Components.Sidebar>
        </Box >
    );
}
