import BaseApi from "../api/BaseApi";
import { generateUuidValue } from "../utils/utilFunctions";

export function useUpdateStatus(showLoader, setErrorText, setOpenErrorModal, handleUpdateResponse, row, setJustUpdated) {

    const UpdateTransactionStatus = async (url, statusParam, trxnRefNo, refNo, setEditing) => {
        showLoader(true);
        const user_id = localStorage.getItem("user_id");
        try {
            let body = {};
            if (parseInt(statusParam) === 4) {
                body = {
                    "transactionReferenceNumber": trxnRefNo,
                    "status": statusParam,
                    "userId": user_id,
                    "productCode": "FAST_DISBURSEMENT",
                    "ayoconnectError": "Transaction has failed from Bank.",
                    "bankError": "Failed Manually",
                    "ayoconnectErrorCode": "0921"
                }
            }
            else {
                body = {
                    "transactionReferenceNumber": trxnRefNo,
                    "status": statusParam,
                    "userId": user_id,
                    "productCode": "FAST_DISBURSEMENT",
                };
            }

            if (refNo !== "") {
                body["bankReferenceNumber"] = refNo;
            }

            const response = await BaseApi("patch", url, "", {}, body, "", "", "A-CORRELATION-ID");
            if (response?.data && response?.data?.code === 200) {
                console.log('res', response.data)
                setJustUpdated(true);
                setEditing(false);
                handleUpdateResponse(row);
            }
        } catch (e) {
            if (parseInt(e.response?.data?.code) === 401) {
                window.location.href = "/session-expired";
            }
            setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
            setOpenErrorModal(true);
        }
        showLoader(false);
    };
    return { UpdateTransactionStatus };
}
