import BaseButton from "./buttons/BaseButton";
import BaseSelectInput from "./selectInput/BaseSelectInput";
import BaseTextField from "./inputs/BaseTextField";
import DatePicker from "./inputs/DatePicker";
import SlideToggleButton from "./buttons/SlideToggleButton";
import ProgressBar from "./progressBar/ProgressBar";
import LinkButton from "./buttons/LinkButton";
import BaseAutoComplete from "./selectInput/BaseAutoComplete";

export const Controls = {
  BaseButton,
  BaseSelectInput,
  BaseTextField,
  DatePicker,
  SlideToggleButton,
  ProgressBar,
  LinkButton,
  BaseAutoComplete,
};
