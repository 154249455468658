import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentLanguage } from "../../redux/slice/languageSlice";
import { LocalisationBtn } from "../../components/modals/modal.styles";

function Localisation() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const { currentLanguage } = useSelector((state) => state.languageData);
  const strings = new LocalizedStrings(LocalData);
  strings.setLanguage(currentLanguage);
  const handlelanguageData = (value) => {
    dispatch(setCurrentLanguage(value));
    setAnchorEl(null);
  };

  const languageText = () => {
    if (currentLanguage === "it") {
      return "indonesia";
    }
    return "English";
  };
  const languageSelect = () => {
    if (currentLanguage === "it") {
      return (
        <MenuItem id="menu-en" onClick={() => handlelanguageData("en")}>English</MenuItem>
      );
    }
    return (
      <MenuItem id="menu-id" onClick={() => handlelanguageData("it")}>Indonesia</MenuItem>
    );
  };
  const areaControlled = () => {
    if (open) {
      return "basic-menu";
    }
  };
  const areaExpand = () => {
    if (open) {
      return true;
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <LocalisationBtn
        edge="start"
        id="basic-button"
        aria-controls={areaControlled()}
        aria-haspopup="true"
        aria-expanded={areaExpand()}
        onClick={handleClick}
        startIcon={<img src={STATIC_ASSETS.GLOBE} alt="language" />}
        endIcon={
          <img
            src={STATIC_ASSETS.DOWN_ARROW}
            alt="down"
          />
        }
      >
        {languageText()}
      </LocalisationBtn>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {languageSelect()}
      </Menu>
    </>
  );
}
export default Localisation;
