import axios from "axios";

const callConfig = () => {
  axios
    .get(process.env.NODE_ENV === "production" ? "/config" : "http://localhost:8787/config")
    .then((response) => {
      localStorage.setItem("API_BASE_URL", response.data.API_BASE_URL);
      localStorage.setItem("CAPTCHA", response.data.CAPTCHA);
    })
    .catch((e) => {
      console.log("config-error", e);
    });
};

export default callConfig;
