import BaseApi from "../api/BaseApi";
import { generateUuidValue } from "../utils/utilFunctions";

export const useSettlementFileDownload = (setErrorText, setOpenErrorModal) => {
  const handleSettlementFileDownload = async (id) => {
    try {
      const setParams = {
        transactionId: generateUuidValue(),
        merchantCode: "AYOCON",
      };

      const response = await BaseApi("get", `/of-admin-dashboard/web/v1/settlement-status/download/file/${id}`, "", setParams, {});

      if ((response?.data && response?.data?.code === 200) || response?.data?.code === 201) {
        window.location.assign(response?.data?.data?.signed_url);
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
    }
  };
  return { handleSettlementFileDownload };
};
