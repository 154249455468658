import { createSlice } from "@reduxjs/toolkit";

export const dragAndDropSlice = createSlice({
  name: "dragAndDrop",
  initialState: {
    fileAdded: false,
    typeError: false,
    sizeError: false,
    isUploading: false,
    isUploaded: false,
    progressBarValue: 0,
  },
  reducers: {
    setFileAddedBoolean: (state, action) => {
      state.fileAdded = action.payload;
    },
    setTypeErrorBoolean: (state, action) => {
      state.typeError = action.payload;
    },
    setSizeErrorBoolean: (state, action) => {
      state.sizeError = action.payload;
    },
    setIsUploadingBoolean: (state, action) => {
      state.isUploading = action.payload;
    },
    setIsUploadedBoolean: (state, action) => {
      state.isUploaded = action.payload;
    },
    setProgressBarValue: (state, action) => {
      state.progressBarValue = action.payload;
    },
    triggerProgressBar: async (state) => {
      let timer;
      // if (isUploading) {
      timer = setInterval(() => {
        state.setProgressBarValue = state.setProgressBarValue >= 100 ? 0 : state.setProgressBarValue + 10;
        if (state.setProgressBarValue === 100) {
          clearInterval(timer);
          state.isUploading = false;
          state.isUploaded = true;
        }
        // setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
      }, 800);
    },
  },
});

export const { setFileAddedBoolean, setTypeErrorBoolean, setSizeErrorBoolean, setIsUploadingBoolean, setIsUploadedBoolean, triggerProgressBar } =
  dragAndDropSlice.actions;

export default dragAndDropSlice.reducer;
