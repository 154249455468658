import { styled, Box, FormControl } from "@mui/material";

export const DownloadIcon = styled("img")({
  marginBottom: 7,
  paddingLeft: 2,
});

export const HeadRowWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "12px 0",
  "& .MuiBox-root:nth-of-type(1)": {
    fontFamily: theme.typography.fontFamily.inter,
    fontStyle: "normal",
    fontWeight: theme.typography.fontWeight.medium,
    fontSize: theme.typography.fontSize.xl,
    color: theme.palette.text.body,
  },
  "& .MuiBox-root:nth-of-type(2)": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiBox-root": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontFamily: theme.typography.fontFamily.inter,
      fontWeight: theme.typography.fontWeight.bold,
      fontSize: theme.typography.fontSize.s,
      color: theme.palette.text.body,
      "& span": {
        marginRight: "0.25rem",
      },
    },
  },
}));

export const HeadRowFormControl = styled(FormControl)(({ theme, downloadBtnRequired }) => ({
  "&.MuiFormControl-root": {
    marginLeft: "0.5rem",
    marginRight: downloadBtnRequired === "true" ? "1.5rem" : "0rem",
    backgroundColor: theme.palette.neutral.white,
    "& .MuiInputBase-root": {
      minWidth: 90,
      border: `1px solid ${theme.palette.neutral.border}`,
    },
  },
}));
