import { Box } from "@mui/material";
import React from "react";
import { Controls } from "../../controls";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { ModalHeader, ModalWrapper, ErrorIconWrapper, CloseIconGrid } from "./modal.styles";
import LocalData from '../../utils/localization';
import LocalizedStrings from 'react-localization';
import { useSelector } from 'react-redux';


const ErrorHandlerModal = ({ setClose, errorText, setErrorText, imgIcon }) => {

  const { currentLanguage } = useSelector((state) => state.languageData);
  const strings = new LocalizedStrings(LocalData);
  strings.setLanguage(currentLanguage);
  const handleClose = () => {
    setClose(false);
    setErrorText("");
  };

  return (
    <ModalWrapper data-testid="error-modal-wrapper" container spacing={2} direction="column" justifyContent={"center"} alignItems={"center"}>
      <CloseIconGrid id="cancel-btn" data-testid="error-close-icon" item xs={12} alignItems={"right"}>
        <img src={STATIC_ASSETS.CLOSE_ICON} alt="close-icon" onClick={handleClose} />
      </CloseIconGrid>
      <ErrorIconWrapper>
        <img src={imgIcon ? imgIcon : STATIC_ASSETS.ERROR} alt="success-icon" />
      </ErrorIconWrapper>
      <ModalHeader data-testid="modal-header" marginbottom="30px" center item xs={12} fontsize_={Number(18)}>
        <Box>{errorText}</Box>
      </ModalHeader>
      <Controls.BaseButton id="close-btn" data-testid="close-btn" onClick={handleClose} width="301px" fontsize_={Number(14)} height="37px" text={strings.Okay} />
    </ModalWrapper>
  );
};

export default ErrorHandlerModal;
