import React from 'react';
import { CardWrapper } from '../../globalStyles/global.styles';
import { StyledSupplierBorderSeparation, StyledSupplierCardTopRow, StyledSupplierTotalClients, StyledTimeHeading, StyledTimeValue } from './cards.styles';
import SlideToggleButton from '../../controls/buttons/SlideToggleButton';
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { useSelector } from 'react-redux';
import moment from 'moment';
import { STATIC_ASSETS } from '../../utils/staticAssets';
import { Controls } from '../../controls';
import { useTheme } from "@mui/material";
import { Components } from '..';

const momentTimeZone = require("moment-timezone");

const SupplierBankCard = ({ data, icon, bankName, isActive, loader, totalClients, deactivatedIcon, setOpen, key, setSelectedValues, selectedBankCode, onViewClient, disableBank }) => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const infoIcon = "https://storage.googleapis.com/dd-ui-static-dev/Admin-dash/SupplierManagement/information-circle.png";
    const disabledSwitch = "https://storage.googleapis.com/dd-ui-static-dev/Admin-dash/SupplierManagement/toggle_disabled.png";
    const theme = useTheme();

    const id = bankName.split(" ").join("-").toLowerCase();

    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const convertToIndonesiaTime = (trnDate, value) => {
        const utcTime = momentTimeZone.utc(trnDate);

        const jakartaTime = utcTime.tz("Asia/Jakarta");

        const formattedJakartaTime24 = jakartaTime.format("YYYY-MM-DD HH:mm");

        const [, timePart24] = formattedJakartaTime24.split(" ");

        return timePart24;
    };

    const getSuffix = (val, type) => {
        if (type === "day")
            return val == 1 ? " day, " : " Days, "
        else if (type === "hour")
            return val == 1 ? " hour, " : " Hours, "
        return val == 1 ? " minute, " : " Minutes "
    }

    const secondsToDhms = (durationValue) => {
        let seconds = Number(durationValue);
        const d = Math.floor(seconds / (3600 * 24));
        const h = Math.floor(seconds % (3600 * 24) / 3600);
        const m = Math.floor(seconds % 3600 / 60);

        const dDisplay = d > 0 ? d + getSuffix(d, 'day') : "00 Days";
        const hDisplay = h > 0 ? h + getSuffix(h, 'hour') : "00 Hours";
        const mDisplay = m > 0 ? m + getSuffix(m, 'minute') : "00 Minutes";
        return dDisplay + " - " + hDisplay + " - " + mDisplay;
    }

    const getTooltip = (data) => {
        return (
            <>
                <StyledTimeHeading>{strings.supplierManagement.lastActive}</StyledTimeHeading>
                <StyledTimeValue>{moment(data.supplierUpdated).format("DD MMM YYYY")} {convertToIndonesiaTime(data.supplierUpdated, "time12")} WIB</StyledTimeValue>
                <StyledTimeHeading>{strings.supplierManagement.downTime}</StyledTimeHeading>
                <StyledTimeValue>{secondsToDhms(data.duration)}</StyledTimeValue>
            </>
        )
    }

    const onSwitchSupplier = async () => {
        setSelectedValues();
        setTimeout(() => {
            setOpen(true);
        }, 1000)
    }

    return (
        <CardWrapper key={key} width="360px" paddingSpacing={3}>
            {(loader && selectedBankCode === data.bankCode) ?
                (
                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img style={{ height: "72px", width: "72px" }} src={STATIC_ASSETS.LOADER} alt={'loader'} />
                    </div>
                )
                :
                (
                    <>
                        <StyledSupplierCardTopRow>
                            <img style={{ height: "46px" }} src={isActive ? icon : deactivatedIcon} alt={`${bankName}-icon`} />
                            {disableBank ? (
                                <Components.HoverTooltip
                                    customStyles={{ cursor: 'not-allowed' }}
                                    title="Transaction with OY! is under development."
                                    placement="bottom"
                                    arrow
                                    maxWidth="300px"
                                    icon={disabledSwitch}
                                    bgColor="#000"
                                />
                            ) : (
                                <SlideToggleButton
                                    id={`${id}-toggle-switch`}
                                    checked={isActive}
                                    onChange={() => { onSwitchSupplier() }}
                                    disabled={disableBank}
                                    isSupplier={true}
                                />
                            )}

                        </StyledSupplierCardTopRow>

                        <StyledSupplierCardTopRow marginBottom="0.75rem">
                            <div>{strings.supplierManagement.status}</div>
                            <div style={{ display: "flex", alignItems: 'center', gap: "0.25rem" }}>
                                {isActive ? strings.supplierManagement.active : strings.supplierManagement.inactive}
                                {!isActive && !disableBank && (
                                    // <img style={{ cursor: "pointer", height: "16px", width: "16px" }} src={infoIcon} alt="info-icon" />
                                    <Components.HoverTooltip title={getTooltip(data)} placement="bottom" arrow icon={infoIcon} bgColor="#fff" />
                                )}
                            </div>
                        </StyledSupplierCardTopRow>
                        <StyledSupplierBorderSeparation />
                        <StyledSupplierCardTopRow marginBottom="0.75rem">
                            <div>API Rail</div>
                            <div style={{ display: "flex", alignItems: 'center', gap: "0.25rem" }}>
                                {data.type}
                            </div>
                        </StyledSupplierCardTopRow>
                        <StyledSupplierBorderSeparation />
                        <StyledSupplierTotalClients>
                            <div>{strings.supplierManagement.totalClients}</div>
                            <div>{totalClients}</div>
                        </StyledSupplierTotalClients>
                        <Controls.BaseButton
                            id={`${id}-view-clients-btn`}
                            disabled={disableBank}
                            data-testid="view-clients-btn"
                            fontweight_="500"
                            onClick={() => onViewClient()}
                            width="100%"
                            fontsize_={theme.typography.fontSize.m1}
                            height="40px"
                            text={strings.supplierManagement.viewClients}
                            bgcolor={theme.palette.neutral.white}
                            textcolor={theme.palette.text.body}
                            btnborder={`1px solid ${theme.palette.neutral.border}`}
                        />
                    </>
                )}
        </CardWrapper>
    )
}

export default SupplierBankCard;