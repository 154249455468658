import React from "react";
import { StyledTooltip } from "./Tooltip.styles";
import { STATIC_ASSETS } from "../../utils/staticAssets";

const HoverTooltip = (props) => {
  const { title, placement, arrow, icon, bgColor, fontColor, maxWidth, customStyles } = props;
  return (
    <StyledTooltip maxWidth={maxWidth} fontColor={fontColor} title={title} placement={placement} arrow={arrow} bgColor={bgColor}>
      <img style={props.customStyles} src={icon ? icon : STATIC_ASSETS.INFO_ICON} alt="info" />
    </StyledTooltip>
  );
};

export default HoverTooltip;
