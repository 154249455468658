import React from 'react';
import { makeStyles } from '@mui/styles';
import { MenuItem, ListItemText } from '@mui/material';
import { AllSelected } from '../../utils/const';
import { StyledFormControl, StyledCheckbox, StyledSelect } from '../../components/filters/filters.styles';

// @notes: need to update this to not use makeStyles
const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: 500
  }
}));

const MultipleSelect = React.forwardRef((props, ref) => {
  const {options=[], initialValue=[], title='Item'} = props
  const [currentValue, setCurrentValue] = React.useState(initialValue);
  const classes = useStyles();

  const handleChange = (event) => {
    const newValues = event.target.value
    setCurrentValue(prev => {
      let appliedValues = newValues
      if((!prev.includes(AllSelected)) && newValues.includes(AllSelected)) {
        // Select all options
        appliedValues = options.map(item => item.id)
        appliedValues.push(AllSelected)
      } else if(prev.includes(AllSelected) && (!newValues.includes(AllSelected))) {
        // Select none
        appliedValues = []
      } else if(prev.includes(AllSelected) && (newValues.length !== prev.length)) {
        // Uncheck 'all' if some value unchecked
        appliedValues = appliedValues.filter(id => id !== AllSelected)
      } else if((!newValues.includes(AllSelected)) && (newValues.length === options.length)) {
        // check 'all' if all option checked
        appliedValues.push(AllSelected)
      } // endif
      return appliedValues
    });
  };

  const getValue = () => {
    return currentValue
  }

  const renderedValue = (selected) => {
    let counterStr = selected.length + ' item(s)'
    if(selected.includes(AllSelected)) {
      counterStr = 'All item(s)'
    } // endif

    return counterStr + ' selected'
  }

  React.useImperativeHandle(ref, () => ({
    getValue,
    setCurrentValue
  }))

  return (
    <StyledFormControl fullWidth>
      <StyledSelect
        multiple
        displayEmpty
        value={currentValue}
        variant="outlined"
        onChange={handleChange}
        renderValue={renderedValue}
        MenuProps={{ classes: { root: classes.menuPaper } }}
        sx={{padding: '5px 1rem'}}
        {...props}
      >
        <MenuItem value={AllSelected}>
          <StyledCheckbox 
            checked={currentValue.indexOf(AllSelected) > -1} />
          <ListItemText primary={("All " + title)} />
        </MenuItem>
        {options.map((optItem) => (
          <MenuItem key={optItem.id} value={optItem.id}>
            <StyledCheckbox checked={currentValue.indexOf(optItem.id) > -1} />
            <ListItemText primary={optItem.label} />
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
})

export default MultipleSelect;