import React, { useState, useEffect } from "react";
import { Components } from "../../components/index";
import { DrawerHeader } from '../../components/sidebar/Sidebar.styled';
import { MainBox } from "../../globalStyles/global.styles";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setVcFeature } from "../../redux/slice/vcFeatureSlice";
import { BtnApply, StyledCheckbox } from "../../components/filters/filters.styles";
import { StyledNoDataFound, StyledTableBody, StyledTableRow, StyledTableRowCell } from '../../components/table/PrimaryTable.styles';
import { VcFeatureList } from "../../utils/const";
import { HeadRowWrapper } from "../../components/headRow/HeadRow.styles";

const SwitchElm = ({item, updateListState}) => {
    const onChange = (event) => {
        updateListState({
            name: item.name,
            isActive: event.target.checked
        })
    }
    return (
        <StyledCheckbox
            size="medium"
            checked={item.isActive} 
            fontSize={25}
            color="rgb(255, 23, 68)"
            onChange={onChange} 
            inputProps={{ 'aria-label': 'controlled' }} />
    )
}

const VcFeature = () => {
    const vcFeatureRedux = useSelector((state) => state.vcFeatureData)
    const dispatch = useDispatch()
    const tableHeaders = [
        '#',
        'Variable',
        'Description',
        'Active'
    ]
    const [currentFeat, setCurrentFeat] = useState([
        {
            name: 'name',
            isActive: false,
            description: ''
        }
    ]);
    const featDescriptions = [
        {
            name: VcFeatureList.VC_SETTING_OTP,
            desc: 'Client Configuration > Update Setting (OTP)',
            isActive: false
        }
    ]
    const updateListState = ({name, isActive}) => {
        setCurrentFeat(prev => {
            const newList = prev.map(feat => {
                if(feat.name === name) {
                    feat.isActive = isActive
                } // endif

                return feat
            })

            return newList
        })
    }
    
    const mapStorageData = () => {
        const currentFeatures = vcFeatureRedux
        const currentFeatNames = currentFeatures.map(x => x.name)
        const notInCurrentFeatures = featDescriptions.filter(x => {
            return ! currentFeatNames.includes(x.name)
        }).map(x => {
            return {
                isActive: false,
                description: x.desc,
                name: x.name
            }
        })
        let mergedFeatures = currentFeatures.map(curr => {
            const name = curr.name
            const isActive = curr.status === 1
            let descriptionObj = featDescriptions.find(featDesc => curr.name === featDesc.name)
            const description = descriptionObj ? descriptionObj?.desc : '-'

            return {
                isActive,
                description,
                name
            }
        })
        mergedFeatures = [
            ...mergedFeatures,
            ...notInCurrentFeatures
        ]

        setCurrentFeat(mergedFeatures)
    }

    const applyToStorage = () => {
        const newList = currentFeat.map(item => {
            return {
                name: item.name,
                status: item.isActive ? 1 : 0
            }
        })

        dispatch(setVcFeature(newList))
        window.location.reload()
    }

    useEffect(() => {
        mapStorageData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const noTrxRow = (
        <StyledTableRow>
            <StyledTableRowCell colSpan={12}>
                <StyledNoDataFound>No Feature Available</StyledNoDataFound>
            </StyledTableRowCell>
        </StyledTableRow>
    )

    return (
        <Components.Layout>
            <DrawerHeader />
            <MainBox component="main" height="100%">
                <Grid container justifyContent="space-between" sx={{marginBottom: '1rem'}}>
                    <Grid item xs>
                        <HeadRowWrapper>
                            <Box>Feature Toggle</Box>
                        </HeadRowWrapper>
                    </Grid>
                    <Grid item>
                        <BtnApply onClick={applyToStorage}>Apply & Refresh</BtnApply>
                    </Grid>
                </Grid>
                <Components.PrimaryTable 
                    headers={tableHeaders} 
                    listlength={20}>
                    <StyledTableBody>
                        {
                            currentFeat?.length < 1 ? noTrxRow :
                            (
                                currentFeat.map((row, idx) => {
                                    return (
                                        <StyledTableRow key={row.name}>
                                            <StyledTableRowCell> { idx + 1} </StyledTableRowCell>
                                            <StyledTableRowCell> { row.name } </StyledTableRowCell>
                                            <StyledTableRowCell> { row.description } </StyledTableRowCell>
                                            <StyledTableRowCell>
                                                <SwitchElm item={row} updateListState={updateListState} />
                                            </StyledTableRowCell>
                                        </StyledTableRow>
                                    )
                                })
                            )
                        }
                    </StyledTableBody>
                </Components.PrimaryTable>
            </MainBox>
        </Components.Layout>
    );
}

export default VcFeature;