import { Box } from '@mui/material'
import React from 'react'
import { Controls } from '../../controls'
import { STATIC_ASSETS } from '../../utils/staticAssets'
import { AmountGrid, ModalHeader, ModalWrapper, SuccessIconWrapper } from './modal.styles'

const SuccessModal = (props) => {
    const { modalHeader, modalText, setClose } = props

    const handleClose = () => {
        setClose(false)
    }
    return (
        <ModalWrapper data-testid="modal-wrapper" container spacing={2} direction="column" justifyContent={'center'} alignItems={'center'}>
            <SuccessIconWrapper><img src={STATIC_ASSETS.SUCCESS} alt="success-icon" /></SuccessIconWrapper>
            <ModalHeader data-testid="modal-header" marginbottom="14px" center item xs={12}><Box>{modalHeader}</Box></ModalHeader>
            <AmountGrid data-testid="amount-grid" marginbottom="40px" item xs={12}><Box>{modalText}</Box></AmountGrid>
            <Controls.BaseButton data-testid="close-btn" onClick={handleClose} width="301px" fontsize_={Number(14)} height="37px" text="Done" />
        </ModalWrapper>
    )
}

export default SuccessModal