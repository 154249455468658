import React, { useRef } from 'react'
import LocalizedStrings from 'react-localization';
import { useDispatch, useSelector } from 'react-redux';
import { Controls } from '../../controls'
import { setFileAddedBoolean, setSizeErrorBoolean, setTypeErrorBoolean } from '../../redux/slice/dragAndDropSlice'
import LocalData from '../../utils/localization';
import { checkFileTypes } from '../../utils/utilFunctions';

const SelectFileComp = ({ fileTypes, setFile }) => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const inputRef = useRef(null)

    const dispatch = useDispatch()

    function selectFileHandler() {
        const selectInput = inputRef.current
        selectInput.click()
    }

    function handleSelectInputChange(e) {
        // file = e.target.files[0]
        handleFiles(e.target.files[0])
    }

    function handleFiles(file) {
        if (file) {
            dispatch(setFileAddedBoolean(true))
            // console.log(file)
            if (!checkFileTypes(file.type, fileTypes)) {
                dispatch(setTypeErrorBoolean(true))
            } else if (file.size / 1024 > 5 * 1024) {
                dispatch(setSizeErrorBoolean(true))
            } else {
                dispatch(setTypeErrorBoolean(false))
                dispatch(setSizeErrorBoolean(false))
                // dispatch(setFile(file))
                setFile(file)
            }
        }
    }

    return (
        <>
            <input type="file" ref={inputRef} onChange={e => handleSelectInputChange(e)} />
            <Controls.BaseButton onClick={selectFileHandler} height="48px" width="172px" text={strings.SelectFile} borderradius="5px" />
        </>
    )
}

export default SelectFileComp