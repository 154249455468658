import { useDispatch, useSelector } from "react-redux";
import BaseApi from "../api/BaseApi";
import { setCurrentRefundModal } from "../redux/slice/modalSlice";
import { generateUuidValue, vcBaseApi } from "../utils/utilFunctions";
import axios from "axios";

export const useRefundData = (
  setErrorText,
  setOpenErrorModal,
  getRefundList,
  refundFor = "",
  setRefundModalShow = null,
  afterRefundCallback = null
) => {
  const dispatch = useDispatch();
  const { merchantCode } = useSelector((state) => state.clientAccountData);
  const { adminUser } = useSelector((state) => state.authData);
  const { customerId, beneficiaryId, transactionReferenceNumber, correlationId } = useSelector((state) => state.refundRowData);
  const handleRefund = async () => {
    try {
      if (refundFor === "vc") {
        const url = vcBaseApi("/admin/refund");
        await axios.post(url, { transactionID: transactionReferenceNumber });
        dispatch(setCurrentRefundModal(1));
        if (afterRefundCallback) afterRefundCallback();
      } else {
        const setPayload = {
          transactionId: generateUuidValue(),
          merchantCode: merchantCode,
          customerId: customerId,
          beneficiaryId: beneficiaryId,
          transactionReferenceNumber: transactionReferenceNumber,
          correlationId: correlationId,
          adminUserId: adminUser?.id,
          currency: "IDR",
        };
        const response = await BaseApi("post", "/of-admin-dashboard/web/v1/merchant/refund", "", {}, setPayload);
        if ((response?.data && response?.data?.code === 200) || response?.data?.code === 201) {
          getRefundList(50, 1, true);
          dispatch(setCurrentRefundModal(1));
        }
      } // endif
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      if (refundFor === "vc") {
        setErrorText(e?.response?.data?.result?.resolution || "Something went wrong");
        if (setRefundModalShow) setRefundModalShow(false);
      } else {
        setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      } // endif
      setOpenErrorModal(true);
    }
  };
  return { handleRefund };
};
