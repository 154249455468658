import { Controls } from "../../controls/index";
import React, { useState, useEffect, useCallback } from "react";
import { Box, Grid, Typography, TextField, FormControl, Switch, FormControlLabel, FormHelperText } from "@mui/material";
import { useSelector } from "react-redux";
import { VcSettingPayload, VcSettingTabName } from '../../utils/const'
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { batchUpdateState, formatRupiah, getNumberOnly, validateEmail } from '../../utils/utilFunctions';
import { v4 as uuidv4 } from "uuid";
import { BorderedBox, CustomTextField, MainPageTitle, SectionBox } from "./vcClientSetting.styles";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { useFormik } from 'formik';
import * as yup from 'yup';

const VcClientSettingForm = ({
    activeTab,
    merchantCode,
    disableForm,
    prevFormValue,

    // function
    setPrevFormValue,
    showError,
    showSuccess,
    savePayloadSendOTP
}) => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const errMessages = {
        required: 'This field is required'
    }

    const [pageState, setPageState] = useState({
        isSubmittingRules: false,
        isSubmittingEmail: false,
        isSubmittingAlert: false,
        isSubmittingBufferAmount: false
    })

    // raw value, saved on this state
    const [state, setState] = useState(VcSettingPayload());

    // formatted value, used to display on dom
    const [displayState, setDisplayState] = useState({
        clientThreshold: "",
        cardIssuePerDay: "",
        numberOfCardPerUser: "",
        maxLimitPerCardPerMonth: "",
        maxAmountPerTrx: "",
        reduceLimitOnCard: false,
        emails: [],
        alertLevels: [],
        bufferAmount: ""
    });

    function updateState(newState) {
        batchUpdateState(setState, newState)
    }

    function updateDisplayState(newState) {
        batchUpdateState(setDisplayState, newState)
    }

    function updatePageState(newState) {
        batchUpdateState(setPageState, newState)
    }

    const yupHelper = () => {
        return {
            requiredNumberStr: yup.string()
                .test({
                    name: 'isset-rupiah',
                    skipAbsent: false,
                    test(value, ctx) {
                        if(parseInt(getNumberOnly(value)) < 1) {
                            return ctx.createError({message: errMessages.required})
                        } // endif
                        return true
                    }
                }),
            requiredNumber: yup.number().min(1, errMessages.required).typeError("Enter a number").required(errMessages.required)
        }
    }

    const onInputs = {
        numberOnly: (e) => {
            e.target.value = getNumberOnly(e.target.value)
        },
        rupiahFormat: (e) => {
            let numberOnly = getNumberOnly(e.target.value)
            e.target.value = formatRupiah(numberOnly)
        }
    }
    const mapMixedObjectToInt = (obj) => {
        const intValues = {}
        Object.entries(obj).forEach(val => {
            if (typeof val[1] === "string") {
                intValues[val[0]] = parseInt(getNumberOnly(val[1]))
            } else {
                intValues[val[0]] = val[1]
            }
        })
        return intValues
    }

    const formik = {
        rulesNConfig: useFormik({
            initialValues: displayState,
            validationSchema: yup.object({
                clientThreshold: yupHelper().requiredNumberStr,
                cardIssuePerDay: yupHelper().requiredNumber,
                numberOfCardPerUser: yupHelper().requiredNumber,
                maxLimitPerCardPerMonth: yupHelper().requiredNumberStr,
                maxAmountPerTrx: yupHelper().requiredNumberStr
            }),
            onSubmit: async (values) => {
                setLoadingBtnOnSection(VcSettingTabName.rulesNConfig, true)
                const payload = mapMixedObjectToInt(values);
                await savePayloadSendOTP({payload, successMsg: "Successfully setup your rules"})
                setLoadingBtnOnSection(VcSettingTabName.rulesNConfig, false)
            }
        }),
        bufferAmountOnDepo: useFormik({
            initialValues: displayState,
            validationSchema: yup.object({
                bufferAmount: yupHelper().requiredNumberStr
            }),
            onSubmit: async (values) => {
                setLoadingBtnOnSection(VcSettingTabName.bufferAmountOnDepo, true)
                const payload = mapMixedObjectToInt(values);
                await savePayloadSendOTP({payload, successMsg: "Successfully setup your rules"})
                setLoadingBtnOnSection(VcSettingTabName.bufferAmountOnDepo, false)
            }
        })
    }

    const strToIntAmount = (numberStr) => {
        if(! numberStr) {
            return 0
        } // endif - handle NaN error issue
        const rawValue = parseInt(numberStr.replace(/\D+/g, ''))

        // limiter max number is 1.000.000.000.000
        if(rawValue >= Math.pow(10, 12)) {
            return Math.pow(10, 12)
        } // endif

        return rawValue
    }

    const onDeleteEmail = useCallback((idx) => {
        if(! window.confirm("Delete email?")) {
            return false
        } // endif
        const emails = state.emails
        // remove 1 item at index=idx
        emails.splice(idx, 1)
        updateState({ emails })
    }, [state.emails])

    const validateSingleEmailObj = (emailObj) => {
        emailObj.errMsg = ""
        if(! validateEmail(emailObj.val)) {
            emailObj.errMsg = "Please input a valid email address"
        } // endif

        return emailObj
    }

    const onEmailChange = useCallback((e, idx) => {
        const emails = state.emails

        emails[idx].val = e.target.value
        emails[idx] = validateSingleEmailObj(emails[idx])
        updateState({ emails })
    }, [state.emails])

    const onAddEmail = () => {
        const emails = state.emails
        emails.push({id: uuidv4(), val: ""})
        updateState({ emails })
    }

    const validateSingleAlertObj = (alertObj) => {
        alertObj.errMsg = ""
        if(! alertObj.val) {
            alertObj.errMsg = errMessages.required
        } // endif

        return alertObj
    }

    const onAlertLevelChange = useCallback((e, idx) => {
        const alertLevels = state.alertLevels
        alertLevels[idx].val = strToIntAmount(e.target.value)
        alertLevels[idx] = validateSingleAlertObj(alertLevels[idx])

        updateState({ alertLevels })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.alertLevels])

    const arrayToIdVal = (arr) => {
        const result = []
        arr.forEach(em => {
            result.push({
                id: uuidv4(),
                val: em,
                errMsg: ""
            })
        })
        return result
    }

    const validateEmails = () => {
        const emails = state.emails.map(em => {
            return validateSingleEmailObj(em)
        })

        updateState({ emails })
        return emails.filter(x => !! x.errMsg).length < 1
    }

    const validateAlertLevels = () => {
        const alertLevels = state.alertLevels.map(item => {
            return validateSingleAlertObj(item)
        })

        updateState({ alertLevels })
        return alertLevels.filter(x => !! x.errMsg).length < 1
    }

    const getPayloadForSection = (sectionKey) => {
        const {
            emails,
            alertLevels
        } = state

        let result = {}
        let successMsg = "Successfully setup your rules"
        let isValidate = false
        switch(sectionKey) {
            case VcSettingTabName.emailAlerts:
                result = { emails: emails.map(x => x.val) }
                isValidate = validateEmails()
                break
            case VcSettingTabName.alertNDeposit:
                result = { alertLevels: alertLevels.map(x => x.val) }
                isValidate = validateAlertLevels()
                break
            default:
                break
        }

        return {
            payload: result,
            isValidate,
            successMsg
        }
    }

    const onResetForm = useCallback((sectionKey) => {
        const {
            clientThreshold,
            cardIssuePerDay,
            numberOfCardPerUser,
            maxLimitPerCardPerMonth,
            maxAmountPerTrx,
            reduceLimitOnCard,
            emails,
            alertLevels,
            bufferAmount
        } = prevFormValue

        if(! window.confirm('Reset form on this section?')) {
            return false
        } // endif

        let newState = {}
        switch(sectionKey) {
            case VcSettingTabName.rulesNConfig:
                newState = {
                    clientThreshold,
                    cardIssuePerDay,
                    numberOfCardPerUser,
                    maxLimitPerCardPerMonth,
                    maxAmountPerTrx,
                    reduceLimitOnCard
                }
                break
            case VcSettingTabName.emailAlerts:
                newState = { emails: arrayToIdVal(emails) }
                break
            case VcSettingTabName.alertNDeposit:
                newState = { alertLevels: arrayToIdVal(alertLevels) }
                break
            case VcSettingTabName.bufferAmountOnDepo:
                newState = { bufferAmount }
                break
            default:
                break
        }

        updateState(newState)
    }, [prevFormValue])

    const setLoadingBtnOnSection = (sectionKey, isLoading=false) => {
        const newState = {}
        switch(sectionKey) {
            case VcSettingTabName.rulesNConfig:
                newState['isSubmittingRules'] = isLoading
                break
            case VcSettingTabName.emailAlerts:
                newState['isSubmittingEmail'] = isLoading
                break
            case VcSettingTabName.alertNDeposit:
                newState['isSubmittingAlert'] = isLoading
                break
            case VcSettingTabName.bufferAmountOnDepo:
                newState['isSubmittingBufferAmount'] = isLoading
                break
            default:
                break
        }
        updatePageState(newState)
    }

    const onSubmitForm = useCallback(async (sectionKey) => {
        const {payload, successMsg, isValidate} = getPayloadForSection(sectionKey)

        if(! isValidate) {
            return false
        } // endif

        setLoadingBtnOnSection(sectionKey, true)
        await savePayloadSendOTP({payload, successMsg});
        setLoadingBtnOnSection(sectionKey, false)

        // if(errorMsg) {
        //     showError(errorMsg)
        // } else {
        //     updateParentFormValue(payload)
        //     showSuccess(successMsg)
        // } // endif
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    useEffect(() => {
        if(prevFormValue.alertLevels.length < 1) {
            const alertLevels = [0,0,0,0,0,0,0]
            updateState({ alertLevels: arrayToIdVal(alertLevels) })
        } else {
            const alertLevels = prevFormValue.alertLevels
            const remainingAlert = alertLevels.length <= 7 ? 7 - alertLevels.length : 0
            for (let i = 0; i < remainingAlert; i++) {
                alertLevels.push(0)
            } // end for
            updateState({ alertLevels: arrayToIdVal(alertLevels) })
        }
    }, [prevFormValue.alertLevels]);

    useEffect(() => {
        if(prevFormValue.emails.length < 1) {
            updateState({
                emails: [{
                    id: uuidv4(),
                    val: ""
                }]
            })
        } else {
            const emails = arrayToIdVal(prevFormValue.emails)
            updateState({ emails })
        }
    }, [prevFormValue.emails])

    useEffect(() => {
        const newState = {
            clientThreshold: prevFormValue.clientThreshold, 
            cardIssuePerDay: prevFormValue.cardIssuePerDay, 
            numberOfCardPerUser: prevFormValue.numberOfCardPerUser, 
            maxLimitPerCardPerMonth: prevFormValue.maxLimitPerCardPerMonth, 
            maxAmountPerTrx: prevFormValue.maxAmountPerTrx, 
            reduceLimitOnCard: prevFormValue.reduceLimitOnCard, 
            bufferAmount: prevFormValue.bufferAmount
        }
        updateState(newState)
    }, [ prevFormValue ])

    useEffect(() => {
        formik.rulesNConfig.setValues({
            clientThreshold: formatRupiah(state.clientThreshold),
            cardIssuePerDay: state.cardIssuePerDay ?? 0,
            numberOfCardPerUser: state.numberOfCardPerUser ?? 0,
            maxLimitPerCardPerMonth: formatRupiah(state.maxLimitPerCardPerMonth),
            maxAmountPerTrx: formatRupiah(state.maxAmountPerTrx),
            reduceLimitOnCard: state.reduceLimitOnCard
        })

        formik.bufferAmountOnDepo.setValues({
            bufferAmount: formatRupiah(state.bufferAmount)
        })
        const newState = {
            emails: state.emails,
            alertLevels: []
        }
        state.alertLevels.forEach(item => {
            newState.alertLevels.push({
                id: item.id,
                val: formatRupiah(item.val),
                errMsg: item?.errMsg
            })
        })

        updateDisplayState(newState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    const rulesNConfigForm = (
        <form onSubmit={formik.rulesNConfig.handleSubmit} id={VcSettingTabName.rulesNConfig}>
            <SectionBox>
                <MainPageTitle marginBottom="1rem">Set Rules & Configuration</MainPageTitle>
                <FormikField 
                    name="clientThreshold" 
                    disabled={disableForm}
                    formik={formik.rulesNConfig}
                    onInput={onInputs.rupiahFormat}
                    label="Client Threshold" />
                <FormikField
                    name="cardIssuePerDay" 
                    disabled={disableForm}
                    formik={formik.rulesNConfig}
                    onInput={onInputs.numberOnly}
                    label="Card Issuance per day" />
                <FormikField 
                    name="numberOfCardPerUser" 
                    disabled={disableForm} 
                    formik={formik.rulesNConfig}
                    onInput={onInputs.numberOnly}
                    label="Number of cards per user" />
                <FormikField 
                    name="maxLimitPerCardPerMonth" 
                    formik={formik.rulesNConfig}
                    disabled={disableForm}
                    onInput={onInputs.rupiahFormat}
                    label="Maximum Limit per card per month" />
                <FormikField 
                    name="maxAmountPerTrx" 
                    formik={formik.rulesNConfig}
                    disabled={disableForm}
                    onInput={onInputs.rupiahFormat}
                    label="Maximum Amount per transaction" />
                <Grid container justifyContent="space-between" alignItems="center" marginBottom="1rem">
                    <Grid item>
                        <Typography variant="h4" fontWeight={600} color="#333333">Reduce limit on card</Typography>
                    </Grid>
                    <Grid item>
                    <FormControl component="fieldset" variant="standard">
                        <FormControlLabel
                            sx={{marginRight: "-10px", marginLeft: "10px"}}
                            control={
                                <Switch 
                                    disabled={disableForm}
                                    checked={formik.rulesNConfig.values.reduceLimitOnCard} 
                                    onChange={formik.rulesNConfig.handleChange} 
                                    onBlur={formik.rulesNConfig.handleBlur}
                                    name="reduceLimitOnCard"
                                    id="reduceLimitOnCard" />
                            }
                        />
                    </FormControl>
                    </Grid>
                </Grid>
            </SectionBox>
            <SectionBox>
                <VcSettingButtons strings={strings} 
                    onSubmit={formik.rulesNConfig.submitForm}
                    onReset={() => onResetForm(VcSettingTabName.rulesNConfig)}
                    isLoadingSubmit={pageState.isSubmittingRules}
                    disableReset={disableForm}
                    disableSubmit={disableForm}
                />
            </SectionBox>
        </form>
    )

    const bufferAmountOnDepoForm = (
        <form onSubmit={formik.bufferAmountOnDepo.handleSubmit} id={VcSettingTabName.bufferAmountOnDepo}>
            <SectionBox>
                <MainPageTitle marginBottom="1rem">Buffer Amount on deposit</MainPageTitle>
                <FormikField 
                    name="bufferAmount" 
                    disabled={disableForm}
                    formik={formik.bufferAmountOnDepo}
                    onInput={onInputs.rupiahFormat}
                    label="Add Buffer Amount"
                    placeholder="Enter Threshold" />
            </SectionBox>
            <SectionBox>
                <VcSettingButtons 
                    onSubmit={formik.bufferAmountOnDepo.submitForm}
                    onReset={() => onResetForm(VcSettingTabName.bufferAmountOnDepo)}
                    isLoadingSubmit={pageState.isSubmittingBufferAmount}
                    disableReset={disableForm}
                    disableSubmit={disableForm}
                    strings={strings} />
            </SectionBox>
        </form>
    )

    const emailsForm = (
        <>
            <SectionBox id={VcSettingTabName.emailAlerts}>
                <MainPageTitle marginBottom="1rem">Email Alerts</MainPageTitle>
                {
                    displayState.emails.map((email, idx) => (
                        idx < 1 ?
                            <VcSettingField 
                                id={`email-field-${idx+1}`}
                                key={email.id} 
                                label={"Email " + (idx+1)}
                                value={email.val}
                                disabled={disableForm}
                                errMsg={email.errMsg}
                                onChange={(e) => onEmailChange(e, idx)} />
                        : 
                            <VcSettingFieldWithDelete
                                id={`email-field-${idx+1}`}
                                key={email.id} 
                                label={"Email " + (idx+1)}
                                value={email.val}
                                errMsg={email?.errMsg}
                                disabled={disableForm}
                                onChange={(e) => onEmailChange(e, idx)}
                                onDelete={() => onDeleteEmail(idx)} />
                    ))
                }

                <Box marginTop="1rem" hidden={(displayState.emails.length >= 5) || disableForm}>
                    <Controls.BaseButton 
                        height="46px" width="100%" 
                        bgcolor="#FFFFFF"
                        textcolor="#333333"
                        btnborder="1px solid rgba(0, 0, 0, 0.10)"
                        text={'+ Add More Fields'} 
                        onClick={onAddEmail} />
                </Box>
            </SectionBox>
            <SectionBox>
                <VcSettingButtons 
                    onSubmit={() => onSubmitForm(VcSettingTabName.emailAlerts)}
                    onReset={() => onResetForm(VcSettingTabName.emailAlerts)}
                    isLoadingSubmit={pageState.isSubmittingEmail}
                    disableReset={disableForm}
                    disableSubmit={disableForm}
                    strings={strings} />
            </SectionBox>
        </>
    )

    const alertNDepositForm = (
        <>
            <SectionBox id={VcSettingTabName.alertNDeposit}>
                <MainPageTitle marginBottom="1rem">Alert and Deposit</MainPageTitle>
                {
                    displayState.alertLevels.map((item, idx) => (
                        <VcSettingField
                            id={`threshold-field-${idx+1}`}
                            key={item.id} 
                            label={"Threshold " + (idx+1)}
                            value={item.val}
                            disabled={disableForm}
                            errMsg={item?.errMsg}
                            onChange={(e) => onAlertLevelChange(e, idx)} />
                    ))
                }
            </SectionBox>
            <SectionBox>
                <VcSettingButtons 
                    onSubmit={() => onSubmitForm(VcSettingTabName.alertNDeposit)}
                    onReset={() => onResetForm(VcSettingTabName.alertNDeposit)}
                    isLoadingSubmit={pageState.isSubmittingAlert}
                    disableReset={disableForm}
                    disableSubmit={disableForm}
                    strings={strings} />
            </SectionBox>
        </>
    )

    const formElm = (
        <BorderedBox className="overflow" borderRadius="16px" id="vcTabContentWrapper">
            { rulesNConfigForm }
            { emailsForm }
            { alertNDepositForm }
            { bufferAmountOnDepoForm }
        </BorderedBox>
    );

    return formElm
}

const VcSettingField = ({
    label, 
    placeholder=null, 
    name=null, 
    value, 
    errMsg=null,
    disabled=false, 
    onChange,
    ...props
}) => {
    const fieldProps = {
        value,
        onChange,
        ...props,
        placeholder: placeholder ?? ("Enter " + label)
    }
    if(name) {
        fieldProps.name = name
    } // endif

    return (
        <Box marginBottom="1.2rem">
            <Typography 
                marginBottom="0.4rem"
                variant="h4" fontWeight={600} color="#333333">{label}</Typography>
            <CustomTextField 
                error={!!errMsg}
                autoComplete="off"
                fullWidth size="small" {...fieldProps} disabled={disabled}></CustomTextField>
                {
                    errMsg &&
                    <FormHelperText error={true}>{ errMsg }</FormHelperText>
                }
        </Box>
    )
}

const FormikField = ({
    label, 
    placeholder=null, 
    name, 
    formik,
    disabled=false,
    maxLength=20,
    onInput=null,
    onChange=null
}) => {
    const fieldProps = {
        placeholder: placeholder ?? ("Enter " + label),
        name,
        id: name,
        value: formik.values[name],
        onChange: onChange || formik.handleChange,
        onBlur: formik.handleBlur,
        error: formik.touched[name] && Boolean(formik.errors[name])
    }

    if(onInput) {
        fieldProps.onInput = onInput
    } // endif

    return (
        <Box marginBottom="1.2rem">
            <Typography 
                marginBottom="0.4rem"
                variant="h4" fontWeight={600} color="#333333">{label}</Typography>
            <CustomTextField 
                autoComplete="off"
                inputProps={{
                    maxLength
                }}
                fullWidth size="small" {...fieldProps} disabled={disabled}></CustomTextField>
                {
                    formik.touched[name] &&
                    <FormHelperText error={true}>{ formik.errors[name] }</FormHelperText>
                }
        </Box>
    )
}

const VcSettingFieldWithDelete = ({
    label, 
    placeholder=null,
    value=null,
    errMsg=null,
    disabled=false,
    onChange=()=>{},
    onDelete=()=>{}},
    ...props
) => {
    return (
        <Grid container 
            marginBottom="1.2rem"
            justifyContent="space-between" 
            alignItems="flex-start" spacing={1}>
            <Grid item xs>
                <Typography 
                    marginBottom="0.8rem"
                    variant="h4" fontWeight={600} color="#333333">{label}</Typography>
                <TextField fullWidth size="small" 
                    value={value}
                    error={!! errMsg}
                    onChange={onChange}
                    disabled={disabled}
                    placeholder={placeholder ?? ("Enter " + label)} {...props}></TextField>
                { errMsg && <FormHelperText error={true}>{ errMsg }</FormHelperText> }
            </Grid>
            <Grid item xs={1} marginTop="2rem">
                <Controls.BaseButton 
                    id="textfield-delete-btn"
                    height="41px" width="41px" 
                    bgcolor="#FFFFFF"
                    btnborder="1px solid rgba(0, 0, 0, 0.25)"
                    borderradius="5px"
                    text={
                        <img src={STATIC_ASSETS.TRASH_RED_ICON} alt="delete" />
                    } 
                    onClick={disabled ? null : onDelete} />
            </Grid>
        </Grid>
    )
}

const VcSettingButtons = ({
    strings,
    disableReset=false,
    disableSubmit=false,
    isLoadingSubmit=false,
    onSubmit=() => {}, 
    onReset=() => {}
}) => {
    return (
        <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
            <Grid item xs={6}>
                <Controls.BaseButton
                    id="vc-reset-btn"
                    height="46px" width="100%" 
                    bgcolor="#FFFFFF"
                    textcolor="#CB2029"
                    btnborder="1px solid rgba(0, 0, 0, 0.10)"
                    text={strings.Reset} 
                    disabled={disableReset}
                    onClick={onReset} />
            </Grid>
            <Grid item xs={6}>
                <Controls.BaseButton
                    id="vc-save-btn"
                    height="46px" width="100%" 
                    text={isLoadingSubmit ? 'Loading...' : strings.Save} 
                    disabled={disableSubmit || isLoadingSubmit}
                    onClick={onSubmit} />
            </Grid>
        </Grid>
    )
}

export default VcClientSettingForm;