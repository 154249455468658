const [
  EXPAND_DOWN,
  EXPAND_UP,
  EXPAND_RIGHT,
  GLOBE,
  BI_FAST,
  BI_FAST_ACTIVE,
  HOME,
  HOME_ACTIVE,
  INFO_ICON,
  ARROW_LEFT,
  DOWNLOAD_ICON,
  REFRESH_ICON,
  DIRECT_DEBIT,
  DIRECT_DEBIT_ACTIVE,
  VIRTUAL_CARD,
  VIRTUAL_CARD_ACTIVE,
  LOGO_WHITE,
  DOWN_ARROW,
  LOGIN_PAGE_IMAGE,
  LOCK,
  EYE_OFF,
  EYE_ON,
  CHECK_CIRCLE,
  CANCELLED,
  CHECK,
  WRONG,
  SUCCESS,
  CLOSE_ICON,
  LOGIN_PAGE_IMG_2,
  DISABLED_LEFT,
  DISABLED_RIGHT,
  ERROR,
  LOADER,
  CALENDER_ICON,
  LOGOUT,
  BLUELOGO,
  CROSS_CIRCLE,
  SETTLEMENT_ICON,
  SETTLEMENT_ICON_ACTIVE,
  EMPTY_TABLE_IMAGE,
  UPLOAD_ICON,
  DRAG_COPY_ICON,
  FILE_PLUS_ICON,
  ERROR_INFO,
  SMALL_LOADER,
  HUMBURGER,
  IC_RIGHT_CHEV,
  EMBEDDED_PAYMENTS_ICON,
  EMBEDDED_PAYMENTS_ICON_ACTIVE,
  SAVE_ICON,
  SUCCESS_ICON,
  ARROW_CROSS_ICON,
  FILE_OUTLINE_ICON,
  MONEYS_ICON,
  VERTICAL_DOTS_ICON,
  SESSION_END_ICON,
  HORIZONTAL_DOTS_CIRCLE_ICON,
  WALLET_OUTLINE,
  TRASH_RED_ICON,
  USERS_OUTLINE_ICON
] = [
  "https://storage.googleapis.com/dd-ui-static-dev/images/arrow_down.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/arrow_up.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/arrow.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/globe.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/bi-fast.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/bi-fast-active.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/home.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/home-active.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/infoIconAdmin.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/arrow_left.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/download_icon.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/refreshIcon.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/direct-debit-logo.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/direct-debit-logo-active.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/virtual-card.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/virtual-card-active.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/white-logo.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/down_arrow.png",
  "https://storage.googleapis.com/dd-ui-static-dev/images/login-page-img-1.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/lock.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/eye-off.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/eye-on.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/check-circle.png",
  "https://storage.googleapis.com/dd-ui-static-dev/images/cancelled.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/check.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/x_red.svg",
  "https://storage.googleapis.com/direct-debit-ui-resources/images/check-circle.png",
  "https://storage.googleapis.com/dd-ui-static-dev/images/close_icon.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/login-page-img-2.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/disabledLeft.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/disableright.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/error.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/whiteLoader.gif",
  "https://storage.googleapis.com/dd-ui-static-dev/images/calender_icon.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/logout_icon.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/Logo.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/cross_circle.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/settlement_icon.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/settlement_icon_active.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/empty_table_img.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/uploadFile_icon.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/dragCopy_icon.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/file_plus.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/error_info.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/small_loader.gif",
  "https://storage.googleapis.com/dd-ui-static-dev/images/humburger.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/icRightChev.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/embedded-payments.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/embedded-payments-active.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/SaveIcon.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/SuccessIcon.svg",
  "https://storage.googleapis.com/vcn-static-ui/dashboard_icon/arrowcross.svg",
  "https://storage.googleapis.com/vcn-static-ui/dashboard_icon/fileoutline.svg",
  "https://storage.googleapis.com/vcn-static-ui/dashboard_icon/money.svg",
  "https://storage.googleapis.com/vcn-static-ui/dashboard_icon/three-dots-vertical.svg",
  "https://storage.googleapis.com/dd-ui-static-dev/images/sessionEnd.svg",
  "https://storage.googleapis.com/vcn-static-ui/dashboard_icon/ellipsis-horizontal-circle.svg",
  "https://storage.googleapis.com/vcn-static-ui/dashboard_icon/wallet-outline.svg",
  "https://storage.googleapis.com/vcn-static-ui/dashboard_icon/trash-red-icon.svg",
  "https://storage.googleapis.com/vcn-static-ui/dashboard_icon/users-outline.svg"
];
export const STATIC_ASSETS = {
  EXPAND_DOWN,
  EXPAND_UP,
  EXPAND_RIGHT,
  GLOBE,
  BI_FAST,
  BI_FAST_ACTIVE,
  HOME,
  HOME_ACTIVE,
  INFO_ICON,
  ARROW_LEFT,
  DOWNLOAD_ICON,
  REFRESH_ICON,
  DIRECT_DEBIT,
  DIRECT_DEBIT_ACTIVE,
  VIRTUAL_CARD,
  VIRTUAL_CARD_ACTIVE,
  LOGO_WHITE,
  DOWN_ARROW,
  LOGIN_PAGE_IMAGE,
  LOCK,
  EYE_OFF,
  EYE_ON,
  CHECK_CIRCLE,
  CANCELLED,
  CHECK,
  WRONG,
  SUCCESS,
  CLOSE_ICON,
  LOGIN_PAGE_IMG_2,
  DISABLED_LEFT,
  DISABLED_RIGHT,
  ERROR,
  LOADER,
  CALENDER_ICON,
  LOGOUT,
  BLUELOGO,
  CROSS_CIRCLE,
  SETTLEMENT_ICON,
  SETTLEMENT_ICON_ACTIVE,
  EMPTY_TABLE_IMAGE,
  UPLOAD_ICON,
  DRAG_COPY_ICON,
  FILE_PLUS_ICON,
  ERROR_INFO,
  SMALL_LOADER,
  HUMBURGER,
  IC_RIGHT_CHEV,
  EMBEDDED_PAYMENTS_ICON,
  EMBEDDED_PAYMENTS_ICON_ACTIVE,
  SAVE_ICON,
  SUCCESS_ICON,
  ARROW_CROSS_ICON,
  FILE_OUTLINE_ICON,
  MONEYS_ICON,
  VERTICAL_DOTS_ICON,
  SESSION_END_ICON,
  HORIZONTAL_DOTS_CIRCLE_ICON,
  WALLET_OUTLINE,
  TRASH_RED_ICON,
  USERS_OUTLINE_ICON
};
