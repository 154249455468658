import { useState } from "react";
import BaseApi from "../api/BaseApi";
import { generateUuidValue } from "../utils/utilFunctions";

export const useClientList = (setErrorText, setOpenErrorModal) => {
  const [clientList, setClientList] = useState([]);

  async function fetchClientList() {
    try {
      const setParams = {
        transactionId: generateUuidValue(),
        referenceNumber: generateUuidValue(),
        active: "true",
      };
      const response = await BaseApi("get", "/of-admin-dashboard/web/v1/merchants", "", setParams);

      if (response?.data && response?.data?.code === 200) {
        setClientList(response?.data?.merchants);
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
    }
  }
  return { clientList, fetchClientList };
};
