import React, { useEffect, useState } from 'react';
import { formatAmount } from '../../utils/utilFunctions';
import { Table, ChipCell, SearchInput } from "@ayoconnect/common-ui-components";
import { SupplierDetailsBackButton, SupplierDetailsBankName, SupplierDetailsRow, SupplierDetailsRowKey, SupplierDetailsRowValue, SupplierDetailsSubHeading, SupplierDetailsTableHeading, SupplierDetailsTableWrapper, SupplierDetailsTopCard, SupplierDetailsTopRow } from './cards.styles';
import { STATIC_ASSETS } from '../../utils/staticAssets';
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
// import HoverTooltip from '../tooltip/Tooltip';

const infoIcon = "https://storage.googleapis.com/dd-ui-static-dev/Admin-dash/SupplierManagement/information-circle.png";

const SupplierDetailsView = ({ merchantsData, getAllData, lang, setDetailsView, selectedBankCode, icon, totalBalance }) => {
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(lang);

    const [keyValueData, setKeyValueData] = useState([]);
    const [bankName, setBankName] = useState("");
    const [tableData, setTableData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searched, setSearched] = useState(false);

    useEffect(() => {
        let data = {};
        if (selectedBankCode === "503") {
            data = localStorage.getItem('dataNobu');
            data = JSON.parse(data);
        }
        else {
            data = localStorage.getItem('dataBri');
            data = JSON.parse(data);
        }
        setBankName(data?.data?.BankDetails?.name)
        const temp = [
            {
                key: "Status",
                value: data?.data?.BankDetails?.supplierActive === 1 ? strings.supplierManagement.active : strings.supplierManagement.inactive
            },
            {
                key: strings.supplierManagement.totalClients,
                value: data?.data?.merchants?.length
            },
            {
                key: strings.supplierManagement.TotalActiveClientBalance,
                value: `IDR ${formatAmount(getTotalBalance(data?.data?.balance))}`
            },
        ];
        setKeyValueData(temp);
        getTableData(data?.data);
    }, [selectedBankCode, lang, getAllData])

    const getTotalBalance = (data) => {
        let totalBalance = 0;
        data?.length > 0 && data.forEach((item) => {
            totalBalance = totalBalance + parseInt(item.amount)
        })
        return totalBalance;
    }

    const getData = (value) => {
        if (merchantsData.isSearchSuccess && merchantsData.data?.length > 0) {
            return merchantsData.data
        }
        else if (!merchantsData.isSearchSuccess && !merchantsData?.data) {
            return [];
        }
        return value?.merchants;
    }

    const getTableData = (value) => {
        let data = getData(value);
        let temp = [];
        const primarySupplier = value?.BankDetails?.name;
        if (data?.length > 0) {
            data.forEach((item) => {
                let tempObj = {
                    merchantName: item?.merchantName,
                    merchantCode: item?.merchantCode,
                    balance: `Rp ${formatAmount(getBalance(value, item?.merchantCode))}`,
                    status: item.status === 1 ? strings.supplierManagement.active : strings.supplierManagement.inactive,
                    primarySupplier: primarySupplier
                }
                temp.push(tempObj);
            })
        }
        setTableData(temp);
    }

    const keyValueColumns = (key, value, isBorder) => {
        return (
            <SupplierDetailsRow isBorder={isBorder}>
                <SupplierDetailsRowKey>{key}</SupplierDetailsRowKey>
                <SupplierDetailsRowValue>{value}</SupplierDetailsRowValue>
                <div></div>
            </SupplierDetailsRow>
        )
    };

    const getBalance = (data, code) => {
        let balance = "";
        if (data?.balance?.length > 0) {
            const value = data?.balance.filter((item) => {
                return item.merchantCode === code
            })
            balance = value[0]?.amount || "0"
        }
        return balance;
    }

    const TableHeaders = [
        {
            field: "merchantName",
            headerName: strings.supplierManagement.merchantName,
            flex: 2,
            minWidth: 110,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
        },
        {
            field: "merchantCode",
            headerName: strings.supplierManagement.merchantCode,
            flex: 0.4,
            minWidth: 250,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <div
                    style={{ color: "#19A9E1" }}
                    id={`link-${params.value}`}
                >
                    {params.value}
                </div>
            ),
        },
        {
            field: "balance",
            headerName: strings.supplierManagement.balance,
            flex: 1,
            minWidth: 110,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            minWidth: 110,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <ChipCell
                    label={params.value}
                    sx={{
                        color: (params.value?.toLowerCase() === "active" || params.value?.toLowerCase() === "aktif") ? "#0F5938" : "#CB2029",
                        backgroundColor: (params.value?.toLowerCase() === "active" || params.value?.toLowerCase() === "aktif") ? "#E5F3ED" : "#FCEDEE"
                    }}
                />

            ),
        },
        {
            field: "primarySupplier",
            flex: 1,
            minWidth: 110,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderHeader: () => (
                <>
                    <div style={{ marginRight: "0.25rem" }}>{strings.supplierManagement.primarySupplier}</div>
                    {/* <HoverTooltip maxWidth="250px" fontColor="#333" title={getTooltipText(selectedBankCode)} placement="bottom" arrow icon={infoIcon} bgColor="#fff" /> */}
                </>
            ),
        },
    ]


    // const getTooltipText = (bCode) => {
    //     return `${strings.supplierManagement.tooltipText1} ${bCode === "503" ? "Bank Nobu" : "Bank BRI"} ${strings.supplierManagement.tooltipText2} ${bCode === "503" ? "Bank Nobu" : "Bank BRI"} ${strings.supplierManagement.tooltipText3}`
    // }

    return (
        <>
            <SupplierDetailsTopRow>
                <SupplierDetailsBankName>{bankName}</SupplierDetailsBankName>
                <SupplierDetailsBackButton id="back-btn" onClick={() => {
                    if (searched) {
                        setSearched(false);
                        getAllData('all')
                    }
                    setDetailsView(false)
                }}
                >
                    <img src={STATIC_ASSETS.ARROW_LEFT} alt="previous" />
                    {strings.supplierManagement.back}
                </SupplierDetailsBackButton>
            </SupplierDetailsTopRow>
            {/* <SupplierDetailsSubHeading >{bankName} Informamtion</SupplierDetailsSubHeading> */}
            <SupplierDetailsTopCard>
                <img style={{ height: "46px", width: "120px" }} src={icon} alt={`${bankName}-icon`} />
                {keyValueData?.length > 0 && keyValueData.map((item, index) => {
                    return keyValueColumns(item.key, item.value, index !== keyValueData?.length - 1)
                })}
            </SupplierDetailsTopCard>
            <SupplierDetailsTableHeading>{strings.supplierManagement.tableSub1} {bankName} {strings.supplierManagement.tableSub2}</SupplierDetailsTableHeading>
            <SupplierDetailsTableWrapper>
                <SearchInput
                    style={{ padding: "1rem", borderRadius: "8px" }}
                    id={'search-input-transaction-id'}
                    placeholder={strings.supplierManagement.SearchMerchantNameOrCode}
                    value={searchQuery}
                    searched={searched}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onSearch={() => {
                        setSearched(true);
                        getAllData(selectedBankCode === "503" ? "nobu" : "bri", searchQuery)
                    }}
                    onClear={() => {
                        setSearchQuery("");
                        if (searched) {
                            getAllData(selectedBankCode === "503" ? "nobu" : "bri", "")
                            setSearched(false);
                        }
                    }}
                />
                {<Table id={"merchantCode"} rows={tableData?.length > 0 ? tableData : []} columns={TableHeaders} />}
            </SupplierDetailsTableWrapper>
        </>
    )
}

export default SupplierDetailsView;