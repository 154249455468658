import React from 'react';
import { DialogContainer, PopUpContainer } from './Popup.styled';
const BasePopup = (props) => {
    return (
        <DialogContainer
            open={props?.show_dialog}
            aria-labelledby="customized-dialog-title"
            fullWidth
            mincontent={props?.mincontent}
            onClose={props?.dialogOnClose && props?.setOpen(false)}
            maxWidth={props?.maxWidth ?? "sm"}>
            <PopUpContainer borderRadius={props?.borderRadius} padding={props?.padding}>{props.children}</PopUpContainer>
        </DialogContainer>
    )
}

export default BasePopup;