import React, { useEffect, useRef, useState } from "react";
import { NoMaxWidthTooltip, StyledCardRow0, StyledCardRow1, StyledCardRow2, StyledCardWrapper } from "./cards.styles";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { useSelector } from "react-redux";
import { Components } from "..";
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { LoaderWrapper, SmallLoaderWrapper } from "../../globalStyles/global.styles";

const TopCard = (props) => {
  const { currentLanguage } = useSelector((state) => state.languageData);
  const strings = new LocalizedStrings(LocalData);
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();

  strings.setLanguage(currentLanguage);

  const { cardHeading, cardAmount, showrightborder, showleftborder, clientName, onRefresh, balanceLoader } = props;
  const showTooltip = props?.showTooltip ?? true

  useEffect(() => {
    if (textElementRef.current && textElementRef.current.scrollWidth) {
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }
  }, []);

  const instantTransferTooltip = (
    showTooltip ? <Components.HoverTooltip title={props?.escrow ? strings.EscrowTooltip : strings.ClientTooltip} placement="right" arrow />
    : <></>
  )

  return (
    <StyledCardWrapper showrightborder={showrightborder} showleftborder={showleftborder}>
      {props?.children ? (
        <>{props?.children}</>
      ) : (
        <>
          {clientName && <StyledCardRow0>{clientName}</StyledCardRow0>}
          <StyledCardRow1>
            {cardHeading}
            {
              props.balanceTooltip ? 
              <Components.HoverTooltip title={props.balanceTooltip} placement="right" arrow />
              : 
              instantTransferTooltip
            }
          </StyledCardRow1>
          <StyledCardRow2>
            {!balanceLoader ? (
              <NoMaxWidthTooltip title={`Rp ${cardAmount}`} disableHoverListener={!isOverflowed} placement="right" arrow>
                <span ref={textElementRef}>Rp {cardAmount}</span>
              </NoMaxWidthTooltip>
            ) : (
              <SmallLoaderWrapper>
                <img src={STATIC_ASSETS.LOADER} alt="loader-img" />
              </SmallLoaderWrapper>
            )}
            <img src={STATIC_ASSETS.REFRESH_ICON} alt="refresh" onClick={onRefresh} id="refresh-icon"/>
          </StyledCardRow2>
        </>
      )}
    </StyledCardWrapper>
  );
};

export default TopCard;
