import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const StyledCardWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "children",
})(({ theme, showrightborder, cardwidth, showleftborder }) => ({
  paddingRight: "2.62rem",
  paddingLeft: "2.5rem",
  width: cardwidth ? cardwidth : "33.33%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  borderLeft: showleftborder && `1px solid ${theme.palette.border.main}`,
  borderRight: showrightborder && `1px solid ${theme.palette.border.main}`,
}));

export const StyledSupplierCardTopRow = styled(Box)(({ theme, marginBottom }) => ({
  display: 'flex',
  justifyContent: "space-between",
  alignItems: 'center',
  marginBottom: marginBottom ? marginBottom : "2.25rem",
  position:'relative'
}));

export const StyledSupplierTotalClients = styled(Box)(({ theme, marginBottom }) => ({
  display: 'flex',
  justifyContent: "space-between",
  alignItems: 'center',
  marginBottom: marginBottom ? marginBottom : "2rem",
}));

export const StyledSupplierBorderSeparation = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.border.cardSeparation,
  marginBottom: "1rem"
}));

export const StyledTimeHeading = styled(Box)(({ theme }) => ({
  fontWeight: theme.typography.fontWeight.semiBold,
  fontSize: 14,
  lineHeight: "20px",
  color: theme.palette.text.body,
  marginBottom: "0.5rem",
}));

export const StyledTimeValue = styled(Box)(({ theme }) => ({
  fontWeight: theme.typography.fontWeight.regular,
  fontSize: 14,
  lineHeight: "20px",
  color: theme.palette.text.textSix,
  marginBottom: "0.5rem",
}));

export const StyledCardRow0 = styled(Box)(({ theme }) => ({
  fontWeight: theme.typography.fontWeight.bold,
  fontSize: 24,
  lineHeight: "32.78px",
  fontColor: theme.palette.text.body,
  marginBottom: "36px",
}));

export const StyledCardRow1 = styled("div", {
  shouldForwardProp: (prop) => prop !== "children",
})(({ theme, sx }) => ({
  fontFamily: theme.typography.fontFamily.inter,
  fontStyle: "normal",
  fontWeight: theme.typography.fontWeight.medium,
  fontSize: theme.typography.fontSize.l,
  color: theme.palette.text.body,
  display: "flex",
  alignItems: "center",
  "& img": {
    marginLeft: "0.5rem",
    height: "20px",
    width: "20px",
  },
  ...sx
}));

export const StyledCardRow2 = styled("div", {
  shouldForwardProp: (prop) => prop !== "children",
})(({ theme }) => ({
  fontFamily: theme.typography.fontFamily.inter,
  fontStyle: "normal",
  fontWeight: theme.typography.fontWeight.medium,
  fontSize: theme.typography.fontSize.xxl,
  color: theme.palette.text.body,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  "& span": {
    display: "inline-block",
    width: "20rem",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
  "& span.fullwidth": {
    width: "100% !important",
    textOverflow: "unset",
  },
  "& img": {
    height: "37px",
    width: "37px",
    cursor: "pointer",
  },
}));

export const StyledRefreshBtn = styled("div", {
  shouldForwardProp: (prop) => prop !== "children",
})(({ theme, sx }) => ({
  fontFamily: theme.typography.fontFamily.inter,
  fontStyle: "normal",
  fontSize: theme.typography.fontSize.m3,
  color: theme.palette.text.body,
  display: "flex",
  alignItems: "center",
  gap: "5px",
  width: "8rem",
  borderRadius: "6px",
  paddingLeft: "4px",
  backgroundColor: "#EBEDF0",
  cursor: "pointer",
  ...sx,
  "&:hover": {
    opacity: "0.8"
  },
  "& img": {
    height: "37px",
    width: "37px"
  },
  "&.disabled": {
    opacity: "0.5",
    cursor: "not-allowed"
  }
}));

export const NoMaxWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

export const SupplierDetailsTopRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: 'space-between',
  width: "100%",
  marginBottom: "2rem",
  alignItems: "center"
}));

export const SupplierDetailsBankName = styled(Box)(({ theme }) => ({
  fontWeight: theme.typography.fontWeight.medium,
  fontSize: 20,
  lineHeight: "30px",
  fontColor: theme.palette.text.body,
}));

export const SupplierDetailsSubHeading = styled(Box)(({ theme }) => ({
  fontWeight: theme.typography.fontWeight.medium,
  fontSize: 18,
  lineHeight: "28px",
  fontColor: theme.palette.text.body,
}));

export const SupplierDetailsTopCard = styled(Box)(({ theme }) => ({
  marginTop: "1rem",
  marginBottom: "2rem",
  padding: "1rem",
  borderRadius: "8px",
  border: "1px solid rgba(0,0,0,0.1)"
}));

export const SupplierDetailsRow = styled(Box)(({ theme, isBorder }) => ({
  borderBottom: isBorder ? "1px solid rgba(0,0,0,0.1)" : "none",
  display: "flex",
  justifyContent: "space-between",
  padding: "1rem 0"
}));

export const SupplierDetailsTableHeading = styled(Box)(({ theme }) => ({
  fontWeight: theme.typography.fontWeight.medium,
  fontSize: 18,
  lineHeight: "28px",
  fontColor: theme.palette.text.body,
  marginBottom: "1rem"
}));

export const SupplierDetailsRowKey = styled(Box)(({ theme }) => ({
  fontWeight: theme.typography.fontWeight.medium,
  fontSize: 14,
  lineHeight: "20px",
  width: "30%",
  fontColor: theme.palette.text.body,
}));

export const SupplierDetailsRowValue = styled(Box)(({ theme }) => ({
  fontWeight: theme.typography.fontWeight.regular,
  fontSize: 14,
  lineHeight: "20px",
  width: "20%",
  fontColor: theme.palette.text.body,
}));

export const SupplierDetailsBackButton = styled(Box)(({ theme }) => ({
  padding: "0.5rem",
  display: 'flex',
  alignItems: 'center',
  border: "1px solid rgba(0,0,0,0.1)",
  cursor: "pointer",
  fontWeight: theme.typography.fontWeight.regular,
  fontSize: 18,
  lineHeight: "28px",
  fontColor: theme.palette.text.body,
  borderRadius: "8px"
}));

export const SupplierDetailsTableWrapper = styled(Box)(({ theme, isBorder }) => ({
  border: "1px solid rgba(0,0,0,0.1)",
  borderRadius: "8px"
}));