import { styled } from "@mui/material";

export const SidebarWrapper = styled("div")(({ theme }) => ({
  display: "flex",
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const StyledSidebarLogoIcon = styled("h2", {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => ({
  color: active ? theme.palette.primary.main : theme.palette.text.body,
  fontSize: 14,
}));
