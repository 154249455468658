import { Controls } from "../../controls/index";
import React, { useState, useEffect } from "react";
import { Components } from "../../components/index";
import { DrawerHeader } from '../../components/sidebar/Sidebar.styled';
import { MainBox, MainPageSection } from "../../globalStyles/global.styles";
import { Box, Grid, Tabs, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { VcFeatureList, VcSettingPayload, VcSettingTabName } from '../../utils/const'
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { useVcBalance } from "../../customHook/useVcBalance";
import { BorderedBox, MainPageTitle, VcSettingTab } from "./vcClientSetting.styles";
import VcClientSettingForm from "./VcClientSettingForm";
import SelectWithPreIcon from "../../controls/selectInput/SelectWithPreIcon";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import OtpModal from "../../components/modals/OtpModal";
import { batchUpdateState } from "../../utils/utilFunctions";
import { useVcFeatures } from "../../customHook/useVcFeatures";

const VcClientSetting = () => {
    const { currentLanguage } = useSelector((state) => state.languageData)
    const { adminUser } = useSelector((state) => state.authData)

    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const [openErrorModal, setOpenErrorModal] = useState(false)
    const [errorText, setErrorText] = useState("")
    const [openSuccessModal, setOpenSuccessModal] = useState(false)
    const [successText, setSuccessText] = useState("")

    const [state, setState] = useState({
        merchantCode: null,
        merchantName: null,
        activeTab: 0,
        clientList: [],
        disableForm: true,
        isLoadingSubmitClient: false,
        tempPayload: {},
        tempSuccessMsg: null
    })
    const [prevFormValue, setPrevFormValue] = useState(VcSettingPayload());
    const [otpState, setOtpState] = useState({
        showModal: false,
        errorText: null,
        disableField: false,
        timerInit: 60
    });
    const setOpenOtpModal = (show) => {
        batchUpdateState(setOtpState, {
            showModal: show
        })
    }

    function updateState(newState) {
        setState(prev => {
            return {
              ...prev,
              ...newState
            };
        });
    }

    const { getMerchantList, getVcSetting, generateVcSettingOTP, updateVcSetting } = useVcBalance({ updateState })
    const { isFeatActive } = useVcFeatures()

    const showError = (text) => {
        setErrorText(text);
        setOpenErrorModal(true)
    }

    const showSuccess = (text) => {
        setSuccessText(text);
        setOpenSuccessModal(true)
    }

    const sendOTP = async (isResend=false) => {
        const generatePayload = {
            merchantCode: state.merchantCode,
            merchantName: state.merchantName,
            userName: adminUser?.name,
            userEmail: adminUser?.email
        }
        const generateOtp = await generateVcSettingOTP(generatePayload)

        if (isResend && generateOtp.errorMsg) {
            batchUpdateState(setOtpState, {
                errorText: generateOtp.errorMsg,
                disableField: true
            })
        } else {
            return generateOtp
        }
    }

    const savePayloadSendOTP = async ({payload, successMsg}) => {
        const generateOtp = await sendOTP()

        updateState({
            tempPayload: payload,
            tempSuccessMsg: successMsg
        })

        if(generateOtp.errorMsg) {
            batchUpdateState(setOtpState, {
                showModal: true,
                errorText: generateOtp.errorMsg,
                disableField: true
            })
        } else {
            batchUpdateState(setOtpState, {
                showModal: true,
                disableField: false,
                errorText: null
            })
        }
    }

    const updateSettingWithOTP = async (otpCode) => {
        batchUpdateState(setOtpState, {
            errorText: null,
            disableField: true
        })

        const { errorMsg } = await updateVcSetting({
            merchantCode: state.merchantCode,
            userEmail: adminUser?.email,
            otpCode,
            payload: state.tempPayload
        })

        if (errorMsg) {
            batchUpdateState(setOtpState, {
                errorText: errorMsg,
                disableField: false
            })
        } else {
            batchUpdateState(setOtpState, {
                errorText: null,
                disableField: false,
                showModal: false
            })
            showSuccess(state.tempSuccessMsg)
        } // endif
    }

    async function setClientList() {
        const {data, errorMsg} = await getMerchantList();
        if(errorMsg) {
            showError(errorMsg)
        } else {
            updateState({clientList: data})
        } // endif
    }

    function merchantEmptyErr() {
        setErrorText('Please select a merchant.')
        setOpenErrorModal(true)
    }
    
    function tabProps(index) {
        return {
            id: `rules-tab-${index}`,
            'aria-controls': `rules-tabpanel-${index}`,
        }
    }

    const fetchClientSetting = async (merchantCode) => {
        updateState({isLoadingSubmitClient: true})
        const {data, errorMsg} = await getVcSetting(merchantCode);
        if(errorMsg) {
          setErrorText(errorMsg)
          setOpenErrorModal(true)
          updateState({isLoadingSubmitClient: false})
        } else {
          if(! data.alertLevels) {
            data.alertLevels = []
          } // endif

          if(! data.emails) {
            data.emails = []
          } // endif
          
          setPrevFormValue(data)
          const disableForm = ! isFeatActive(VcFeatureList.VC_SETTING_OTP)
          updateState({disableForm, isLoadingSubmitClient: false})
        } // endif
    }

    const onSubmitClient = async () => {
        if (state.merchantCode) {
            await fetchClientSetting(state.merchantCode)
        } else {
            merchantEmptyErr()  
        }
    }

    const handleTabChange = (e, newValue) => {
        updateState({activeTab: newValue})
        
        const tabList = [
            VcSettingTabName.rulesNConfig,
            VcSettingTabName.emailAlerts,
            VcSettingTabName.alertNDeposit,
            VcSettingTabName.bufferAmountOnDepo
        ]
        
        const targetElm = document.getElementById(tabList[newValue])
        targetElm.parentNode.scrollTop = targetElm.offsetTop - 500
    }

    useEffect(() => {
        setClientList().catch(e => {
            console.error("Failed to set client list dropdown", e)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const vcFormProps = {
        activeTab: state.activeTab,
        merchantCode: state.merchantCode,
        disableForm: state.disableForm,
        prevFormValue,
        setPrevFormValue,
        showError,
        showSuccess,
        savePayloadSendOTP
    }

    return (
        <Components.Layout>
            <DrawerHeader />
            <MainBox component="main" height="100%">
                <MainPageSection>
                    <MainPageTitle>Virtual Card Setting</MainPageTitle>
                    <Grid container justifyContent="center" marginBottom="24px">
                        <Grid item xs={4}>
                            <Box borderRadius="19px" border="1px solid #EBEDF0" padding="24px">
                                <MainPageTitle fontSize="18px" marginBottom="1rem">Select Client Name</MainPageTitle>
                                <InputLabel sx={{color: "#333", fontWeight: 500}}>{strings.SelectName}</InputLabel>
                                <SelectWithPreIcon
                                    id="select-client-name"
                                 fullWidth value={state.merchantCode}
                                  onChange={(e) => 
                                    updateState({
                                        merchantCode: e.target.value,
                                        merchantName: state.clientList.find(client => client.code === e.target.value).name,
                                        disableForm: true
                                    })}
                                  placeholder={strings.SelectClientName}
                                  list={state.clientList}
                                  iconElm={<img src={STATIC_ASSETS.USERS_OUTLINE_ICON} alt="i" />}
                                  sx={{borderRadius: "10px", marginBottom: "1rem", marginTop: "0.2rem"}}
                                />
                                <Controls.BaseButton
                                    id="select-client-submit"
                                    height="46px" width="100%" 
                                    text={state.isLoadingSubmitClient ? 'Loading...' : strings.Submit} 
                                    disabled={(!state.merchantCode) || state.isLoadingSubmitClient} 
                                    onClick={onSubmitClient} />

                            </Box>
                        </Grid>
                    </Grid>

                    <BorderedBox>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={state.activeTab} onChange={handleTabChange}>
                                <VcSettingTab label="Set rules & configurations" {...tabProps(0)} />
                                <VcSettingTab label="Email Alerts" {...tabProps(1)} />
                                <VcSettingTab label="Alert and deposit" {...tabProps(2)} />
                                <VcSettingTab label="Buffer Amount on deposit" {...tabProps(3)} />
                            </Tabs>
                            <Grid container justifyContent="center" margin="2rem auto">
                                <Grid item xs={6}>
                                    <VcClientSettingForm {...vcFormProps} />
                                </Grid>
                            </Grid>
                        </Box>
                    </BorderedBox>

                    <Components.BasePopup setOpen={setOpenErrorModal} show_dialog={openErrorModal}>
                        <Components.ErrorHandlerModal errorText={errorText} setClose={setOpenErrorModal} />
                    </Components.BasePopup>
                    <Components.BasePopup maxWidth="lg" setOpen={setOpenOtpModal} show_dialog={otpState.showModal}>
                        <OtpModal 
                            errorText={otpState.errorText} 
                            disableField={otpState.disableField}
                            timerInit={otpState.timerInit}
                            otpEmailAddr={adminUser?.email}
                            sendOTP={sendOTP}
                            updateSettingWithOTP={updateSettingWithOTP}
                            setClose={setOpenOtpModal} />
                    </Components.BasePopup>
                    <Components.BasePopup setOpen={setOpenSuccessModal} show_dialog={openSuccessModal}>
                        <Components.SuccessModal modalHeader="Success" modalText={successText} setClose={setOpenSuccessModal} />
                    </Components.BasePopup>
                </MainPageSection>
            </MainBox>
        </Components.Layout>
    );
}

export default VcClientSetting;