import { useDispatch, useSelector } from "react-redux";
import BaseApi from "../api/BaseApi";
import { setRefundList } from "../redux/slice/clientAccountSlice";
import { setPaginationLimit, setPaginationPage } from "../redux/slice/paginationSlice";
import { generateUuidValue } from "../utils/utilFunctions";

export const useRefundList = (showLoader, setTotalCount, setErrorText, setOpenErrorModal, getPageCount, escrow) => {
  const dispatch = useDispatch();
  const { page, limit } = useSelector((state) => state.paginationData);
  const { beneficiaryBankName, transactionType, startDate, endDate, customerPhone, merchantCode, partnerReferenceNumber } = useSelector(
    (state) => state.clientAccountData
  );
  const getRefundList = async (currentLimit, currentPage, default_) => {
    showLoader(true);
    try {
      const setParams = {};
      if (default_) {
        // prettier-ignore
        Object.assign(setParams, {transactionId: generateUuidValue(),referenceNumber: generateUuidValue(),page: currentPage || page,limit: currentLimit || limit, });
        // with filters
      } else {
        // prettier-ignore
        Object.assign(setParams, {transactionId: generateUuidValue(),referenceNumber: generateUuidValue(),page: currentPage || page,limit: currentLimit || limit,});
        if (beneficiaryBankName !== "") {
          Object.assign(setParams, { beneficiaryBankName: beneficiaryBankName });
        }
        if (transactionType !== "") {
          Object.assign(setParams, { transactionType: transactionType });
        }
        if (startDate !== null) {
          Object.assign(setParams, { startDate: startDate });
        }
        if (endDate !== null) {
          Object.assign(setParams, { endDate: endDate });
        }
        if (customerPhone !== "") {
          Object.assign(setParams, { customerPhone: customerPhone });
        }
        if (partnerReferenceNumber !== "") {
          Object.assign(setParams, { partnerReferenceNumber: partnerReferenceNumber });
        }
      }

      const response = await BaseApi("get", `/of-admin-dashboard/web/v1/merchant/${merchantCode}/disbursement`, "", setParams, {});
      if (response?.data && response?.data?.code === 200) {
        dispatch(setRefundList(response?.data?.disbursementTransactions));
        dispatch(setPaginationLimit(response?.data?.pagination?.count));
        dispatch(setPaginationPage(response?.data?.pagination?.page));
        getPageCount(response?.data?.pagination?.page, response?.data?.disbursementTransactions);
        setTotalCount(response?.data?.pagination?.totalCount);
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
    }
    showLoader(false);
  };
  return { getRefundList };
};
