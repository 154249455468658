import React from 'react'
import { useTheme } from '@mui/material'
import { Components } from "../../components/index";
import { StyledBaseTextField, StyledInputlabel } from './BaseTextfield.styles'

const BaseTextField = (props) => {
    const { textColor, width_, height, idText, variant, label, placeholder, fontsize_, value, onChange, type, helperText, labelcolor, labelfontweight, errorValue, disabledtextfield, isTooltip, tooltipText, ...others } = props
    const theme = useTheme()

    const normalborder = () => {
        if (errorValue) {
            return `1px solid ${theme.palette.red.main}`
        }
        return `1px solid ${theme.palette.border.main}`
    }
    const hoverborder = () => {
        if (errorValue) {
            return `1px solid ${theme.palette.red.main}`
        } else if (disabledtextfield === 'true') {
            return `1px solid ${theme.palette.border.main}`
        }
        return `1px solid ${theme.palette.border.focus}`
    }
    return (<>
        {label && (
            <div style={{display: 'flex', alignItems: "center", gap: "6px"}}>
                <StyledInputlabel labelfontweight={labelfontweight} labelcolor={labelcolor}>{label} {errorValue && '*'}</StyledInputlabel>
                { isTooltip && <Components.HoverTooltip title={tooltipText || "Max Rp 3.000.000.000"} placement="right" arrow/> }
            </div>
        )}
        
        <StyledBaseTextField
            id={`${idText}-input`}
            type={type ? type : "text"}
            variant="filled"
            required
            placeholder={placeholder}
            helperText={helperText}
            error={errorValue}
            value={value}
            onChange={onChange}
            normalborder={normalborder()}
            hoverborder={hoverborder()}
            fontsize_={fontsize_}
            height={height}
            width_={width_}
            textColor={textColor}
            disabled={disabledtextfield !== undefined && disabledtextfield === 'true' ? true : false}
            {...others}
        />
    </>
    )
}

export default BaseTextField