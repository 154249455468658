import { styled, Toolbar } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";

export const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, filteropen }) => ({
  zIndex: filteropen ? theme.zIndex.drawer : theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  "& .MuiBox-root": {
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root": {
      marginRight: "1rem",
      width: "32px",
      height: "32px",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.neutral.white,
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.05)'
    },
  },
}));

export const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  flexGrow: 1,
  justifyContent: "space-between",
  backgroundColor: theme.palette.neutral.white,
  color: theme.palette.text.heading,
}));

export const StyledLogoImg = styled("img")(({ theme }) => ({
  cursor: "pointer"
}));