import { createSlice } from "@reduxjs/toolkit";

export const clientAccountSlice = createSlice({
  name: "clientAccount",
  initialState: {
    merchantCode: "",
    currentClientBalance: 0,
    refundList: [],
    beneficiaryBankName: "",
    transactionType: "",
    startDate: null,
    endDate: null,
    customerPhone: "",
    partnerReferenceNumber: "",
    isSubmitClicked: false,
    openRefund: false,
    balanceLoader: false,
  },
  reducers: {
    setCurrentMerchantCode: (state, action) => {
      state.merchantCode = action.payload;
    },
    setCurrentClientBalance: (state, action) => {
      state.currentClientBalance = action.payload;
    },
    setRefundList: (state, action) => {
      state.refundList = action.payload;
    },
    setbeneficiaryBankName: (state, action) => {
      state.beneficiaryBankName = action.payload;
    },
    setTransactionType: (state, action) => {
      state.transactionType = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setCustomerPhone: (state, action) => {
      state.customerPhone = action.payload;
    },
    setpartnerReferenceNumber: (state, action) => {
      state.partnerReferenceNumber = action.payload;
    },
    setIsSubmitClicked: (state, action) => {
      state.isSubmitClicked = action.payload;
    },
    setOpenRefund: (state, action) => {
      state.openRefund = action.payload;
    },
    setBalanceLoader: (state, action) => {
      state.balanceLoader = action.payload;
    },
    setRefundParams: (state, action) => {
      return {
        ...state,
        beneficiaryBankName: action.payload.beneficiaryBankName,
        transactionType: action.payload.transactionType,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        customerPhone: action.payload.customerPhone,
        partnerReferenceNumber: action.payload.partnerReferenceNumber,
      };
    },
  },
});

export const {
  setCurrentMerchantCode,
  setCurrentClientBalance,
  setRefundList,
  setbeneficiaryBankName,
  setTransactionType,
  setStartDate,
  setEndDate,
  setCustomerPhone,
  setpartnerReferenceNumber,
  setRefundParams,
  setIsSubmitClicked,
  setOpenRefund,
  setBalanceLoader,
} = clientAccountSlice.actions;
export default clientAccountSlice.reducer;
