import { Grid, styled, Typography } from "@mui/material";

export const TypographyText = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: "70px",
}));
export const StyledValidateMessage = styled(Grid)(({ theme, color }) => ({
  display: "flex",
  flexDirection: "column",
  lineHeight: "20px",
  h6: {
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.m1,
    color: theme.palette.text.body,
  },
}));

export const StyledValidateMessageDiv = styled("div", {
  shouldForwardProp: (prop) => prop !== "key",
})(({ color }) => ({
  color: color,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "10px",
  lineHeight: "30px",
}));
