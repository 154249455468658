import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const BaseApi = async (method, path, token, params = {}, payload = {}, contentType, newHeader, customIdName, userId) => {
  const baseUrl = localStorage.getItem("API_BASE_URL");
  const accessToken = localStorage.getItem("API_TOKEN");
  const uuidValue = uuidv4().replaceAll("-", "");
  const idKeyName = customIdName ? customIdName : "X-CORRELATION-ID";
  const headers = {
    Accept: "application/json",
    "Content-Type": contentType || "application/json",
  };
  headers[idKeyName] = uuidValue;

  const headerParams = token
    ? {
      ...headers,
      Authorization: `Bearer ${token}`,
      "A-Merchant-Code": newHeader ? newHeader : ""
    }
    : {
      ...headers,
      Authorization: accessToken,
    };
  if (userId && userId !== "") {
    headerParams["userId"] = userId;
  }

  switch (method.toLowerCase()) {
    case "get":
      return await axios.get(baseUrl + path, {
        headers: headerParams,
        params: params,
        data: {},
      });
    case "post":
      return await axios.post(baseUrl + path, payload, {
        headers: headerParams,
        params: params,
      });
    case "patch":
      return await axios.patch(baseUrl + path, payload, {
        headers: headerParams,
        params: params,
      });
    default:
      return;
  }
};

export default BaseApi;
