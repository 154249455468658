import { Grid } from "@mui/material";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { momentDateRange } from "../../utils/timezoneUtil"
import React, { useEffect, useState } from "react";
import { BtnApply } from "../filters/filters.styles"
import { SmallLoaderWrapper } from "../../globalStyles/global.styles";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { StyledCardRow1, StyledCardRow2 } from "./cards.styles";
import { formatRupiah } from "../../utils/utilFunctions";
import { useVcBalance } from "../../customHook/useVcBalance";
import DateRangePicker from "../filters/DateRangePicker";
import { StyledTooltip } from "../tooltip/Tooltip.styles";

const VcTotalSpend = ({strings}) => {
    const [dateState, setDateState] = useState({
        startDate: null,
        endDate: null,
    });
    const last30Days = () => {
        const endDate = moment()
        const startDate = endDate.clone().add(-30, 'days')
        return {
            startDate, endDate
        }
    }
    const [balanceState, setBalanceState] = useState({
        loader: false,
        amount: 0
    });

    function updateBalanceState(newState) {
        setBalanceState(prev => {
            return {
            ...prev,
            ...newState
            }
        })
    } 

    function onDateChange(dateObject) {
        setDateState(dateObject)
    }

    const { getAyoSpends } = useVcBalance({updateState: updateBalanceState})

    async function onSetDate(params) {
        let queryParam = {
            from: 0,
            to: 0
        }

        if(!params.startDate || !params.endDate) {
            params.startDate = dateState.startDate
            params.endDate = dateState.endDate
        } // endif

        if(params.startDate && params.endDate) {
            const {
                startDate,
                endDate
            } = momentDateRange(params.startDate, params.endDate)
        
            queryParam = {
                from: startDate.unix(),
                to: endDate.unix()
            }

            await getAyoSpends(queryParam)
        } // endif

    }

    function isValidDateRange() {
        let range = 0
        if(dateState.startDate && dateState.endDate) {
            range = dateState.endDate.diff(dateState.startDate, 'days')
        } // endif
        return range <= 30
    }

    useEffect(() => {
        onSetDate(last30Days()).catch(e => {
            console.error('Failed to fetch inital VcTotalSpend', e)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    return (
        <div>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <StyledCardRow1 sx={{marginBottom: "1rem", fontSize: "25px"}}>
                {strings.TotalSpends}
            </StyledCardRow1>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs>
                    {/* Mininum Date: not set, Maximum Date: today */}
                    <DateRangePicker onChange={onDateChange} 
                        initialDate={last30Days()}
                        datePickerFormat="DD MMM YYYY"
                        minDate={null}
                        maxDate={moment()}
                        disableHighlightToday={true} />
                </Grid>
                <Grid item sx={{ml: 2}}>
                    {
                        isValidDateRange() ?
                        <BtnApply id="set-date-apply-btn" onClick={onSetDate}>{strings.SetDate}</BtnApply> :
                        <StyledTooltip title="Please select only 30 days of date range" placement="top" arrow>
                            <div><BtnApply disabled onClick={onSetDate}>{strings.SetDate}</BtnApply></div>
                        </StyledTooltip>

                    }
                    
                </Grid>
            </Grid>
            <div style={{marginTop: '1rem', marginBottom: '-0.7rem'}}>
                <StyledCardRow2>
                    {!balanceState.loader ? (
                        <span className="fullwidth">{formatRupiah(balanceState.amount)}</span>
                    ) : (
                        <SmallLoaderWrapper>
                        <img src={STATIC_ASSETS.LOADER} alt="loader-img" />
                        </SmallLoaderWrapper>
                    )}
                </StyledCardRow2>
            </div>
        </LocalizationProvider>
        </div>
    );
}

export default VcTotalSpend;