import { useTheme } from '@mui/material'
import { Components } from '../../../components'
import { StyledNoDataFound, StyledTableBody, StyledTableRow, StyledTableRowCell } from '../../../components/table/PrimaryTable.styles';
import { StyledCell, LoaderWrapper } from '../../../globalStyles/global.styles'
import React, { useState, useEffect, useCallback } from 'react';
import { STATIC_ASSETS } from '../../../utils/staticAssets';
import { setPaginationLimit, setPaginationPage, setShowData } from '../../../redux/slice/paginationSlice';
import { getDate, getTime, formatAmount } from '../../../utils/utilFunctions';
import { useDispatch, useSelector } from "react-redux";
import { Controls } from '../../../controls';
import { useRefundList } from '../../../customHook/useRefundList';
import LocalizedStrings from "react-localization";
import LocalData from '../../../utils/localization';
import { setCurrentRefundModal } from '../../../redux/slice/modalSlice';
import { useRefundData } from '../../../customHook/useRefundData';
import { setRefundRowData } from '../../../redux/slice/refundRowSlice';
import { useDownloadData } from '../../../customHook/useDownloadData';

const RefundButton = (props) => {
    const { refundHandler, rowData } = props
    const dispatch = useDispatch()

    const setReduxRefundData = useCallback(() => {
        dispatch(setRefundRowData({
            customerId: rowData?.customerId,
            beneficiaryId: rowData?.beneficiaryId,
            transactionReferenceNumber: rowData?.transactionId,
            correlationId: rowData?.correlationId,
            refundAmount: rowData?.amount,
        }))
        refundHandler()
    }, [],)

    return <Controls.BaseButton borderradius="4px" text="Refund" width="58px" height="28px" fontsize_={Number(12)} onClick={setReduxRefundData} {...props} />
}

const RefundTable = () => {
    const { currentLanguage } = useSelector((state) => state.languageData)
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage)

    const theme = useTheme();
    const dispatch = useDispatch();
    const [totalCount, setTotalCount] = useState(0);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [openRefundModal, setOpenRefundModal] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [loader, showLoader] = useState(false);
    const { limit, showFrom, showTo } = useSelector(state => state.paginationData)
    const { refundList, merchantCode } = useSelector(state => state.clientAccountData);
    const { currentRefundModal } = useSelector(state => state.modalData);
    const { refundAmount, transactionReferenceNumber } = useSelector(state => state.refundRowData)
    const { refundDownloadList } = useDownloadData("/of-admin-dashboard/web/v1/merchant/AYORPM/disbursement/file", setErrorText, setOpenErrorModal)

    const RefundTableHeaders = [
        strings["DateAndTime"],
        strings["TransactionId"],
        strings["TransactionType"],
        strings["Description"],
        strings["Amount"],
        strings["CustSendMobNum"],
        strings["Status"],
        strings["BenificiaryName"],
        "",
    ]

    const getPageCount = (pageNo, data) => {
        const dataLength = data?.length;
        const currentPage = pageNo - 1;
        const startCount = currentPage * limit + 1;
        if (pageNo === 1) {
            if (dataLength) {
                dispatch(setShowData({ showFrom: 1, showTo: dataLength }));
            } else {
                dispatch(setShowData({ showFrom: 0, showTo: 0 }));
            }
        } else if (pageNo > 1) {
            dispatch(
                setShowData({
                    showFrom: startCount,
                    showTo: startCount - 1 + dataLength,
                })
            );
        }
    };

    const { getRefundList } = useRefundList(showLoader, setTotalCount, setErrorText, setOpenErrorModal, getPageCount)
    const { handleRefund } = useRefundData(setErrorText, setOpenErrorModal, getRefundList)

    const refundHandler = useCallback(() => {
        setOpenRefundModal(true)
        dispatch(setCurrentRefundModal(0))
    }, [],)

    const selectStatusColor = (status) => {
        if (status === 'Failed') {
            return theme.palette.red.main
        } else if (status === 'Processing') {
            return theme.palette.yellow.main
        }
        return theme.palette.green.main

    }

    useEffect(() => {
        getRefundList(50, 1, true)
        return () => {
            dispatch(setPaginationLimit(50))
            dispatch(setPaginationPage(1))
        };
    }, [])

    return (
        <>
            <Components.TableFilter getRefundList={getRefundList} />
            <Components.HeadRow
                getList={getRefundList}
                tableName={strings.TransactionDetails}
                handleDownload={refundDownloadList}
                merchantCode={merchantCode}
                downloadBtnRequired={'true'}
            />
            {loader && (
                <LoaderWrapper data-testid="table-loader-image"><img src={STATIC_ASSETS.LOADER} alt={'loader'} /></LoaderWrapper>
            )}
            {!loader && (
                <Components.PrimaryTable headers={RefundTableHeaders} listlength={refundList?.length}>
                    <StyledTableBody>
                        {refundList?.length > 0 ? (
                            refundList.map((row, index) => {
                                return (
                                    <StyledTableRow key={index}>
                                        <StyledTableRowCell >
                                            <StyledCell
                                                marginBottom="0.25rem"
                                                color={theme.palette.text.body}
                                                fontSize={theme.typography.fontSize.m1}
                                            >{getDate(row?.transactionDate)}</StyledCell>
                                            <StyledCell
                                                color={theme.palette.text.textSix}
                                                fontSize={theme.typography.fontSize.s}
                                            >{getTime(row?.transactionDate)}</StyledCell>
                                        </StyledTableRowCell>
                                        <StyledTableRowCell color={theme.palette.primary.main}>
                                            {row.transactionId}
                                        </StyledTableRowCell>
                                        <StyledTableRowCell >
                                            {row.trasactionType}
                                        </StyledTableRowCell>
                                        <StyledTableRowCell >
                                            {row?.remark}
                                        </StyledTableRowCell>
                                        <StyledTableRowCell >
                                            {`Rp ${formatAmount(row.amount)}`}
                                        </StyledTableRowCell>
                                        <StyledTableRowCell >
                                            {row.customerPhone}
                                        </StyledTableRowCell>
                                        <StyledTableRowCell color={selectStatusColor(row.status)} >
                                            {row.status}
                                        </StyledTableRowCell>
                                        <StyledTableRowCell >
                                            {row.bankName}
                                        </StyledTableRowCell>
                                        <StyledTableRowCell>
                                            {row.status === 'Success' && <RefundButton id={`refund-button-${index+1}`} rowData={row} refundHandler={refundHandler} />}
                                        </StyledTableRowCell>
                                    </StyledTableRow>
                                )
                            })
                        ) : (
                            <StyledTableRow>
                                <StyledTableRowCell colSpan={12}>
                                    <StyledNoDataFound>No Transactions</StyledNoDataFound>
                                </StyledTableRowCell>
                            </StyledTableRow>
                        )}
                    </StyledTableBody>
                </Components.PrimaryTable>
            )}
            <Components.Pagination
                totalCount={totalCount}
                showTo={showTo} showFrom={showFrom}
                getList={getRefundList}
            />
            <Components.BasePopup setOpen={setOpenErrorModal} show_dialog={openErrorModal}>
                <Components.ErrorHandlerModal errorText={errorText} setClose={setOpenErrorModal} setErrorText={setErrorText} />
            </Components.BasePopup>
            <Components.BasePopup setOpen={setOpenRefundModal} show_dialog={openRefundModal} mincontent={'false'}>
                {currentRefundModal === 0 && <Components.ConfirmModal setCurrentModal={setCurrentRefundModal} handleRefund={handleRefund} setClose={setOpenRefundModal} amount={refundAmount} modalHeader="Refund" modalTextAboveButton={strings.ClickConfirmToProceed} AmountSentenceInitial="You are updating " AmountSentenceEnding={`as Refund against Transaction ID : ${transactionReferenceNumber} for “${merchantCode}”`} refundPopup />}
                {currentRefundModal === 1 && <Components.DoneModal setCurrentModal={setCurrentRefundModal} setClose={setOpenRefundModal} amount={refundAmount} modalHeader={strings.Success} AmountSentenceInitial="You are  Successfully updated " AmountSentenceEnding={`as Refund against Transaction ID : ${transactionReferenceNumber} for “${merchantCode}”`} />}
            </Components.BasePopup>
        </>
    )
}

export default RefundTable 