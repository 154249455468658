import { Avatar, Box, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Components } from "../../components/index";
import { setDrawerState } from '../../redux/slice/drawerSlice';
import { StyledAppBar, StyledToolBar, StyledLogoImg } from './appbar.styles';
import Localisation from '../localisation/LocalizationDropdown';
import { STATIC_ASSETS } from '../../utils/staticAssets';

const Appbar = (props) => {
    const { open } = useSelector((state) => state.drawerData)
    const { adminUser } = useSelector((state) => state.authData)

    const dispatch = useDispatch();
    const [openLogout, setOpenLogout] = useState(false);
    const [menuState, setMenuState] = useState(false);
    const userName = adminUser?.name || "A"
    const handleDrawerOpen = () => {
        dispatch(setDrawerState())
    };

    const handleLogout = () => {
        setMenuState(false)
        setOpenLogout(true)
    }

    return (
        <StyledAppBar position='fixed' open={open} filteropen={props.filteropen}>
            <StyledToolBar data-testid="toolbar-main">
                <Box>
                    {props.profile && <IconButton
                        id={"sidebar-menu-button"}
                        data-testid="hamburger-icon"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open),
                            cursor: 'pointer'
                        }}
                    >
                        <img id="hamburger-button-icon" src={STATIC_ASSETS.HUMBURGER} alt="humburger-icon" />
                    </IconButton>}

                    <StyledLogoImg src={STATIC_ASSETS.BLUELOGO} alt="logo" onClick={() => { window.location.href = "/" }} />
                </Box>
                <Box >
                    <Localisation />
                    {props.profile && <><Avatar data-testid="avatar-icon" sx={{ marginLeft: "20px" }} onClick={e => setMenuState(true)}>{userName.trim().charAt(0).toUpperCase()}</Avatar>
                        <img src={STATIC_ASSETS.IC_RIGHT_CHEV} alt="dropdown-img" onClick={e => setMenuState(true)} /> </>}
                </Box>
            </StyledToolBar>
            {props.profile && <Menu
                data-testid="menu-dropdown"
                open={menuState}
                onClose={e => setMenuState(false)}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}

                sx={{
                    mt: 6,

                }}
            >
                <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
            </Menu>}
            <Components.BasePopup setOpen={setOpenLogout} show_dialog={openLogout}>
                <Components.LogoutModal setClose={setOpenLogout} />
            </Components.BasePopup>
        </StyledAppBar>
    )
}

export default Appbar