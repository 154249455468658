import { useDispatch, useSelector } from "react-redux";
import BaseApi from "../api/BaseApi";
import { setPaginationLimit, setPaginationPage } from "../redux/slice/paginationSlice";
import { paramConstants } from "../utils/const";
import { generateUuidValue } from "../utils/utilFunctions";

export function useTransactions(url, showLoader, setList, setTotalCount, setErrorText, setOpenErrorModal, getPageCount, escrow) {
  const dispatch = useDispatch();
  const { limit, page } = useSelector((state) => state.paginationData);
  const { merchantCode } = useSelector((state) => state.clientAccountData);

  const getTransactionList = async (currentLimit, currentPage) => {
    showLoader(true);
    try {
      const setParams = {
        transactionId: generateUuidValue(),
        referenceNumber: generateUuidValue(),
        page: currentPage || page,
        limit: currentLimit || limit,
      };
      if (escrow) {
        Object.assign(setParams, {
          bankCode: paramConstants.bankCode,
          productCode: paramConstants.productCode,
        });
      } else {
        Object.assign(setParams, { merchantCode: merchantCode });
      }

      const response = await BaseApi("get", url, "", setParams, {});
      if (response?.data && response?.data?.code === 200) {
        setList(escrow ? response?.data?.escrow : response?.data?.merchants);
        dispatch(setPaginationLimit(response?.data?.pagination?.count));
        dispatch(setPaginationPage(response?.data?.pagination?.page));
        getPageCount(response?.data?.pagination?.page, escrow ? response?.data?.escrow : response?.data?.merchants);
        setTotalCount(response?.data?.pagination?.totalCount);
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
    }
    showLoader(false);
  };
  return { getTransactionList };
}
