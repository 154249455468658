import { Controls } from "../../controls/index";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Components } from "../../components/index";
import { DrawerHeader } from '../../components/sidebar/Sidebar.styled';
import { ButtonsWrapper, MainBox, MainPageTopSection } from "../../globalStyles/global.styles";
import { Box, Grid, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTopUpModal, setTopUpData } from "../../redux/slice/modalSlice";
import { StyledSelectBoxHeader } from "./vcClientAccount.styles";
import VcClientTransactionTable from "../../components/table/VcClientTransactionTable";
import { setCurrentLimit, setPaginationLimit, setPaginationPage, setShowData } from "../../redux/slice/paginationSlice";
import { paramConstants, TransactionKind, TransactionStatus, VcTopupTrxType } from '../../utils/const'
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { authTokenHeader, formatRupiah, vcBaseApi } from '../../utils/utilFunctions';
import { setFilterPayload, resetFilterPayload } from '../../redux/slice/vcTransactionSlice';
import { setVcMerchantBalanceRetry } from "../../redux/slice/rateLimiterSlice";
import VcClientAccFilter from "../../components/filters/VcClientAccFilter";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useVcBalance } from "../../customHook/useVcBalance";

const ClientAccountVc = () => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const { vcMerchantRetryAfter } = useSelector((state) => state.rateLimiterData);

    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [state, setState] = useState({
        clientList: [],
        merchantCode: null,
        merchantName: null,
        merchantBalance: false,
        showTransactionTable: false,
        topupModalKind: VcTopupTrxType.topup.id,
        topupInitialTrxID: null,
        topupInitialAmount: 0,
        topupReversedAmount: 0,
        disableRefreshMerchantBalance: false
    });
    const [isLoadingBalance, setIsLoadingBalance] = useState(false);
    const [hasInit, setHasInit] = useState(false);
    const filterRef = useRef()

    function updateState(newState) {
        setState(prev => {
            return {
              ...prev,
              ...newState
            };
        });
    }

    const { getMerchantList, getMerchantBalance } = useVcBalance({ updateState })

    async function setClientList() {
        const {data, errorMsg} = await getMerchantList();
        if(errorMsg) {
            setErrorText(errorMsg);
            setOpenErrorModal(true)
        } else {
            updateState({clientList: data})
        } // endif
    }

    function merchantEmptyErr() {
        setErrorText('Please select a merchant.')
        setOpenErrorModal(true)
    }

    async function setMerchantBalance() {
        if(! state.merchantCode) {
            merchantEmptyErr()
            return false
        } // endif
        
        setIsLoadingBalance(true)
        const {data, errorMsg} = await getMerchantBalance(state.merchantCode);
        if(errorMsg) {
            setErrorText(errorMsg);
            setOpenErrorModal(true)
            setIsLoadingBalance(false)
        } else {
            const merchantBalance = data.limit - data.spend
            updateState({merchantBalance})
            setIsLoadingBalance(false)
        } // endif
    }

    const [openTopUp, setOpenTopUp] = useState(false);
    const { currentTopUpModal, topUpAmount, topUpTransactionID, topUpSubmitDescription } = useSelector((state) => state.modalData);
    const theme = useTheme();
    const dispatch = useDispatch();

    const { open } = useSelector(state => state.drawerData)

    const onSubmitClient = () => {
        if (state.merchantCode) {
            setMerchantBalance().catch(e => {
                console.error("Failed to fetch client balance", e)
            })
            updateState({showTransactionTable: true})
            
            if(filterRef.current) {
                filterRef.current.onClearClick()
            } // endif

            const newPayload = {
                merchantCodes: [
                    state.merchantCode
                ],
                transactionStatus: TransactionStatus.success,
                transactionType: TransactionKind.spendRefund,
                postBody: {
                    filter: { 
                        merchant_code: [ state.merchantCode ],
                        status: [ TransactionStatus.success ]
                    }
                }
            }
            dispatch(resetFilterPayload(newPayload))
        } else {
            merchantEmptyErr()  
        }
    }

    function onApplyFilter(value) {
        const [startDate, endDate] = [
            value.startDate ? value.startDate.toISOString() : null,
            value.endDate ? value.endDate.toISOString() : null,
        ]

        dispatch(setPaginationPage(1))
        dispatch(
            setFilterPayload({
                ...value,
                startDate,
                endDate
            })
        )
    }

    const clientTopUp = useCallback(async() => {
        try {
            const merchantCode = state.merchantCode.toUpperCase()
            const url = vcBaseApi("/admin/topup/merchant/" + merchantCode)
            await axios.put(url, { 
                amount: parseInt(topUpAmount),
                transactionID: topUpTransactionID,
                description: topUpSubmitDescription
            }, {
                headers: authTokenHeader()
            })
            dispatch(setCurrentTopUpModal(2));
            await setMerchantBalance();
        } catch (err) {
            let errText = err?.response?.data?.reason
            if(! errText) {
                errText = "Failed to Topup"
            } // endif
            setErrorText(errText)
            setOpenErrorModal(true)
            setOpenTopUp(false)
            dispatch(setCurrentTopUpModal(0))
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topUpSubmitDescription, topUpTransactionID, topUpAmount])

    const clientReverseTopUp = useCallback(async() => {
        try {
            const merchantCode = state.merchantCode.toUpperCase()
            const postData = { 
                amount: parseInt(topUpAmount),
                transactionID: topUpTransactionID,
                description: topUpSubmitDescription
            }
            const url = vcBaseApi("/admin/topups/refund?code=" + merchantCode)
            await axios.post(url, postData, {
                headers: authTokenHeader()
            })
            dispatch(setCurrentTopUpModal(2));
            await setMerchantBalance();
        } catch (err) {
            let errText = err?.response?.data?.errors
            if(! errText) {
                errText = "Failed to Reverse Top-up"
            } // endif
            setErrorText(errText)
            setOpenErrorModal(true)
            setOpenTopUp(false)
            dispatch(setCurrentTopUpModal(0))
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topUpSubmitDescription, topUpTransactionID, topUpAmount])

    const handleTopUpButton = () => {
        if (state.merchantCode && state.showTransactionTable) {
            updateState({
                topupModalKind: VcTopupTrxType.topup.id,
                topupInitialTrxID: null,
                topupInitialAmount: 0,
                topupReversedAmount: 0
            })
            setOpenTopUp(true)
        } // endif
    }

    const handleTopupReverseBtn = ({refundType, trxId, topupInitialAmount=0, topupReversedAmount=0}) => {
        setMerchantBalance().catch(e => {
            console.error("Failed to fetch client balance", e)
        })
        updateState({
            topupModalKind: refundType,
            topupInitialTrxID: trxId,
            topupInitialAmount: topupInitialAmount,
            topupReversedAmount
        })
        setOpenTopUp(true)
    }

    const isTopupReversal = () => {
        return state.topupModalKind !== VcTopupTrxType.topup.id
    }

    const popupData = [
        {
            id: 'topupModal',
            modalState: openTopUp,
            setModalState: setOpenTopUp,
            amount: topUpAmount,
            currentmodal: currentTopUpModal,
            setCurrentModal: setCurrentTopUpModal,
            submitModal: {
                setSubmitData: setTopUpData,
                modalHeader: strings.TopUp,
                modalTextAboveButton: "This money will be credited in the Client balance."
            },
            confirmModal: {
                modalHeader: isTopupReversal() ? 'Reversal' : 'Top-up',
                modalTextAboveButton: strings.ClickConfirmToProceed,
                AmountSentenceInitial: isTopupReversal() ? strings.YouWantReverse : strings.YouAreAdding1,
                AmountSentenceEnding: strings.ToThe,
                mutationType: paramConstants.topUpMutation
            },
            doneModal: {
                modalHeader: strings.Success,
                AmountSentenceEnding: isTopupReversal() ? strings.SuccessfullyReversedTo : strings.SuccessfullyAddedTo
            }
        }
    ]

    useEffect(() => {
        if(vcMerchantRetryAfter > 0) {
            updateState({disableRefreshMerchantBalance: true})
            setTimeout(() => {
                dispatch(setVcMerchantBalanceRetry(0))
            }, (vcMerchantRetryAfter * 1000))
        } else {
            updateState({disableRefreshMerchantBalance: false})
        } // endif

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vcMerchantRetryAfter]);

    useEffect(() => {
        setClientList().catch(e => {
            console.error("Failed to set client list dropdown", e)
        })
        setHasInit(true)
        return () => {
            updateState({disableRefreshMerchantBalance: false})
            dispatch(setPaginationLimit(50))
            dispatch(setCurrentLimit(50))
            dispatch(setPaginationPage(1))
            dispatch(setShowData({ showFrom: 0, showTo: 0 }))
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Components.Layout>
            <DrawerHeader />
            <MainBox component="main" height="100%">
                <MainPageTopSection>
                    <Components.TopCard showrightborder="true">
                        <Grid container justifyContent="center" alignItems={"start"} flexDirection="column" sx={{paddingLeft: open ? "0" : "20px"}}>
                            <Grid item>
                                <StyledSelectBoxHeader drawerstate={open}>{strings.SelectClientName}</StyledSelectBoxHeader>
                            </Grid>
                            <Grid item>
                                <Controls.BaseSelectInput
                                    id="select-client-name-input"
                                    value={state.merchantCode} 
                                    onChange={(e) => 
                                        updateState({
                                            merchantCode: e.target.value,
                                            merchantName: state.clientList.find(client => client.code === e.target.value).name
                                        })
                                    } 
                                    values={state.clientList} 
                                    disablemenuitem="true" 
                                    disablemenuitemvalue={"client name"} 
                                    marginbottom="24.4px" 
                                    label={strings.SelectName} 
                                    labelfontweight="500" 
                                    labelmarginbottom="9px" 
                                    placeholderText={strings.SelectClient}
                                    labelcolor={theme.palette.text.textSix} />
                            </Grid>
                            <Controls.BaseButton
                                id="client-name-submit-btn"
                                height="46px" width="322px" 
                                text={strings.Submit} 
                                disabled={!state.merchantCode} 
                                onClick={onSubmitClient} />
                        </Grid>
                    </Components.TopCard>
                    <Components.TopBalanceValue
                        strings={strings}
                        showrightborder="true"
                        balanceTooltip={'Remaining deposit amount with Ayoconnect'}
                        cardHeading={strings.TotalClientBalance}
                        cardAmount={ formatRupiah(state.merchantBalance) }
                        clientName={ state.showTransactionTable ? state.merchantName : null }
                        disableRefresh={ state.disableRefreshMerchantBalance }
                        onRefresh={ setMerchantBalance }
                        balanceLoader={ isLoadingBalance }
                        variant="clientAccountPage"
                    />
                    <Components.TopCard>
                        <ButtonsWrapper>
                            <Controls.BaseButton
                                id="vc-topup-btn"
                                key={uuidv4()}
                                height="47px"
                                bgcolor={state.showTransactionTable ? theme.palette.primary.main : theme.palette.neutral.white}
                                btnborder={state.showTransactionTable ? 'none' : `1px solid ${theme.palette.neutral.border}`}
                                marginbottom="0.8rem"
                                text="+ Top-Up"
                                textcolor={state.showTransactionTable ? theme.palette.neutral.white : theme.palette.text.textEight}
                                width="13.5rem"
                                onClick={handleTopUpButton}
                            />
                        </ButtonsWrapper>
                    </Components.TopCard>
                </MainPageTopSection>

                {
                    state.showTransactionTable ?
                    <>
                        <VcClientAccFilter merchantCode={state.merchantCode} 
                            onApplyFilter={onApplyFilter}
                            ref={filterRef} />
                        <VcClientTransactionTable 
                            hasInit={hasInit}
                            merchantCode={state.merchantCode}
                            refreshMerchantBalance={setMerchantBalance}
                            handleTopupReverseBtn={handleTopupReverseBtn} />
                    </>
                    : <Box sx={{height: "60vh"}} />
                }

                {popupData.map((popup) => (
                    <Components.BasePopup setOpen={popup.setModalState} show_dialog={popup.modalState} 
                        key={popup.id} mincontent={popup.currentmodal !== 0 ? 'true' : 'false'}>
                        {popup.currentmodal === 0 && <Components.VCTopupFormModal
                            setCurrentModal={popup.setCurrentModal}
                            setData={popup.submitModal.setSubmitData}
                            setClose={popup.setModalState}
                            merchantName={state.merchantName}
                            merchantBalance={state.merchantBalance}
                            refreshBalance={setMerchantBalance}
                            isLoadingBalance={isLoadingBalance}
                            modalTextAboveButton={popup.submitModal.modalTextAboveButton}
                            topupModalKind={state.topupModalKind}
                            topupInitialTrxID={state.topupInitialTrxID}
                            topupInitialAmount={state.topupInitialAmount}
                            topupReversedAmount={state.topupReversedAmount}
                        />}
                        {popup.currentmodal === 1 && 
                            <Components.ConfirmModal setCurrentModal={popup.setCurrentModal} 
                                handleTopUp={isTopupReversal() ? clientReverseTopUp : clientTopUp} 
                                mutationType={popup.confirmModal.mutationType} 
                                setClose={popup.setModalState} 
                                amount={popup.amount} 
                                modalHeader={popup.confirmModal.modalHeader} 
                                modalTextAboveButton={popup.confirmModal.modalTextAboveButton} 
                                AmountSentenceInitial={popup.confirmModal.AmountSentenceInitial} 
                                AmountSentenceEnding={popup.confirmModal.AmountSentenceEnding + " " + state.merchantName} />
                        }
                        {popup.currentmodal === 2 && 
                            <Components.DoneModal setCurrentModal={popup.setCurrentModal} 
                                setClose={popup.setModalState} 
                                amount={popup.amount} 
                                modalHeader={popup.doneModal.modalHeader} 
                                AmountSentenceEnding={popup.doneModal.AmountSentenceEnding + state.merchantName} />
                        }
                    </Components.BasePopup>
                ))}
                <Components.BasePopup setOpen={setOpenErrorModal} show_dialog={openErrorModal}>
                    <Components.ErrorHandlerModal errorText={errorText} setClose={setOpenErrorModal} setErrorText={setErrorText} />
                </Components.BasePopup>
            </MainBox>
        </Components.Layout>
    );
};

export default ClientAccountVc;
