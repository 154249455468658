import { styled, Tooltip, tooltipClasses } from "@mui/material";
import React from "react";

export const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme, bgColor, fontColor, maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "1rem",
    backgroundColor: bgColor || theme.palette.text.body,
    color: fontColor || theme.palette.neutral.white,
    borderRadius: "8px",
    fontSize: theme.typography.fontSize.m2,
    fontWeight: theme.typography.fontWeight.regular,
    fontFamily: theme.typography.fontFamily.inter,
    maxWidth: maxWidth || "none",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      backgroundColor: bgColor || theme.palette.text.body,
    },
  },
  "& img": {
    height: "20px",
    width: "20px",
    cursor: "pointer",
  },
}));

export const StyledTooltipValue = styled("div")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily.inter,
  fontWeight: theme.typography.fontWeight.regular,
  fontSize: 14,
  lineHeight: "28px",
}));
