import { styled, Box, Grid, Typography } from "@mui/material";

export const MainPageTopSection = styled("div", {
  shouldForwardProp: (prop) => prop !== "flexdirection",
})(({ theme, flexdirection, alignitems, justifycontent }) => ({
  borderRadius: "16px",
  width: "100%",
  backgroundColor: theme.palette.neutral.white,
  display: "flex",
  justifyContent: justifycontent ? justifycontent : "space-between",
  padding: "2.1875rem",
  marginBottom: "1.5rem",
  flexDirection: flexdirection ? flexdirection : "row",
  flexWrap: "nowrap",
  alignItems: alignitems ? alignitems : "center",
}));

export const MainPageSection = styled(Box, {
})(({ theme }) => ({
  borderRadius: "16px",
  width: "100%",
  backgroundColor: theme.palette.neutral.white,
  padding: "2.1rem",
}));

export const ButtonsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "auto",
}));

export const MainBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "height",
})(({ theme, height, bgColor,overflowY }) => ({
  overflowX: "auto !important",
  overflowY: overflowY,
  backgroundColor: bgColor || theme.palette.primary.mainBackground,
  padding: "2.5rem",
  width: "100%",
  height: height ? height : "100vh",
}));

export const StyledCell = styled(Box, {
  shouldForwardProp: (prop) => prop !== "marginBottom" && prop !== "color" && prop !== "fontSize",
})(({ theme, color, fontSize, marginBottom }) => ({
  marginBottom: marginBottom ? marginBottom : 0,
  color: color,
  fontFamily: theme.typography.fontFamily.inter,
  fontWeight: theme.typography.fontWeight.regular,
  fontSize: fontSize,
}));

export const LoaderWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "marginBottom" && prop !== "color" && prop !== "fontSize",
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "50vh",
  backgroundColor: theme.palette.neutral.white,
  "& img": {
    height: "100px",
    width: "100px",
  },
}));

export const SmallLoaderWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "marginBottom" && prop !== "color" && prop !== "fontSize",
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.neutral.white,
  "& img": {
    height: "50px",
    width: "50px",
  },
}));

export const StyledButtonBlock = styled(Grid)(() => ({
  display: "flex",
  gap: "25px",
  justifyContent: "end",
  width: "100%",
  flexDirection: "row",
}));
export const CardPaper = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4, 0, 4),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
}));
export const CardWrapper = styled(Grid)(({ theme, width, paddingSpacing }) => ({
  borderRadius: "10px",
  width: width ? width : "980px",
  backgroundColor: theme.palette.neutral.white,
  zIndex: "100",
  padding: theme.spacing(paddingSpacing ? paddingSpacing : 4),
  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
  border: "1px solid #DDDDDD",
}));
export const MessageBlock = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}));

export const MessageHeader = styled(Typography)(({ theme, width }) => ({
  fontSize: theme.typography.fontSize.l,
  fontWeight: theme.typography.fontWeight.xbold,
  lineHeight: "38px",
}));

export const MessageSubTitle = styled(Typography)(({ theme, width }) => ({
  fontWeight: theme.typography.fontWeight.regular,
  fontSize: theme.typography.fontSize.m2,
  lineHeight: "19px",
  paddingBottom: "32px",
  p: {
    paddingTop: "35px",
  },
}));

export const StyledTimerContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDireaction: "row",
  fontSize: theme.typography.fontSize.m1,
  fontWeight: theme.typography.fontWeight.regular,
  lineHeight: "16px",
  u: {
    color: theme.palette.border.focus,
    paddingLeft: "3px",
  },
  span: {
    color: theme.palette.border.focus,
    paddingLeft: "3px",
  },
}));

export const VerticalLine = styled("div", {
  shouldForwardProp: (prop) => prop !== "marginleft",
})(({ theme, marginleft }) => ({
  borderLeft: `1px solid ${theme.palette.border.main}`,
  left: "50%",
  marginLeft: marginleft ? marginleft : "0px",
}));

export const HorizontalDashLine = styled("div", {
  shouldForwardProp: (prop) => prop !== "marginTop",
})(({ theme }) => ({
  border: `1px dashed ${theme.palette.neutral.border}`,
  width: "100%"
}));
