const [LOGIN, ESCROW_ACCOUNT, CLIENT_BALANCE, VIRTUAL_CARD_AYOLIMIT, VIRTUAL_CARD_CLIENT, VIRTUAL_CARD_SETTING, SET_UP_PASSWORD, PRICE_CONFIGURATION, EMBEDDED_PAYMENTS, SETTLEMENT, TRANSFER_HISTORY, SUPPLIER_MANAGEMENT] = [
  "/",
  "/escrow-account",
  "/client-balance",
  "/virtual-card/ayo-limit",
  "/virtual-card/client",
  "/virtual-card/setting",
  "/setup-password",
  "/price-config",
  "/embedded-payments",
  "/settlement",
  "/transfer-history",
  "/supplier-management"
];
export const BROWSER_URLS = {
  LOGIN,
  ESCROW_ACCOUNT,
  CLIENT_BALANCE,
  VIRTUAL_CARD_AYOLIMIT,
  VIRTUAL_CARD_CLIENT,
  VIRTUAL_CARD_SETTING,
  SET_UP_PASSWORD,
  PRICE_CONFIGURATION,
  EMBEDDED_PAYMENTS,
  SETTLEMENT,
  TRANSFER_HISTORY,
  SUPPLIER_MANAGEMENT,
};
