import BaseApi from "../api/BaseApi";
import { generateUuidValue } from "../utils/utilFunctions";

export const useGetSupplierList = (setOpenToast, setToastType, setToastHeading, setToastMsg) => {

    const getToastSuccessHeading = (isActive, isSystemMaintainance) => {
        if ((parseInt(isActive) === 1 && isSystemMaintainance)) {
            return "Maintenance Mode Enabled"
        }
        else if (parseInt(isActive) === 1) {
            return "Supplier Deactivation Success"
        }
        else {
            return "Supplier Activation Success"
        }
    }

    const getToastSuccessMsg = (bCode, isActive, isSystemMaintainance) => {
        if ((parseInt(isActive) === 1 && isSystemMaintainance)) {
            return "All suppliers have been deactivated, and IT system have entered maintenance mode"
        }
        else if (parseInt(isActive) === 1) {
            return `${bCode === "503" ? "Nobu Bank" : "Bank BRI"} have been deactivated`
        }
        else {
            return `${bCode === "503" ? "Nobu Bank" : "Bank BRI"} have been reactivated`
        }
    }

    const getToastErrorHeading = (isActive, isSystemMaintainance) => {
        if (parseInt(isActive) === 1) {
            return "Supplier Deactivation Failed"
        }
        else {
            return "Supplier Activation Failed"
        }
    }

    const getToastErrorMsg = (bCode, isActive, isSystemMaintainance) => {
        if (parseInt(isActive) === 1) {
            return `A problem occurred while deactivating ${bCode === "503" ? "Nobu Bank" : "Bank BRI"}. Please try again later.`
        }
        else {
            return `A problem occurred while attempting to activate ${bCode === "503" ? "Nobu Bank" : "Bank BRI"}. Please try again later.`
        }
    }

    const getSupplierList = async (bankCode, query = "") => {
        try {
            const setParams = {
                transactionId: generateUuidValue(),
                referenceNumber: generateUuidValue(),
                merchantCode: "AYOPOP",
                productCode: 'FAST_DISBURSEMENT',
                priority: 1,
            };
            if (query) {
                setParams["query"] = query;
            }

            const response = await BaseApi("get", `/of-admin-dashboard/web/v1/banks/supplier/${bankCode}`, "", setParams);
            if (response?.data && response?.data?.code === 200) {
                return {
                    isSuccess: true,
                    data: response.data
                }
            }
        }
        catch (e) {
            if (parseInt(e.response?.data?.code) === 401) {
                window.location.href = "/session-expired";
            }
            setOpenToast(true);
            setToastType("error");
            if (query === "") {
                setToastHeading("Something went wrong");
                setToastMsg("Could not fetch list. Please try again later.");
            }
            else {
                setToastHeading("No records found.");
                setToastMsg("We did not find any records matching with the searched text.");
            }
            return {
                isSuccess: false,
                data: {}
            }
        };
    };

    const updateStatus = async (bankCode, isActive, isSystemMaintainance) => {
        try {

            let setPayload = {
                bankCode: bankCode,
                transactionId: generateUuidValue(),
                supplierActive: isActive ? "0" : "1"
            };
            const user_id = localStorage.getItem("user_id");

            const response = await BaseApi("patch", `/of-admin-dashboard/web/v1/banks/supplier/${bankCode}`, "", {}, setPayload, "application/json", "", "", user_id);
            if (response?.data && response?.data?.code === 200) {
                setOpenToast(true);
                setToastType("success");
                setToastHeading(getToastSuccessHeading(isActive, isSystemMaintainance));
                setToastMsg(getToastSuccessMsg(bankCode, isActive, isSystemMaintainance));
                return true;
            }
        }
        catch (e) {
            if (parseInt(e.response?.data?.code) === 401) {
                window.location.href = "/session-expired";
            }
            setOpenToast(true);
            setToastType("error");
            setToastHeading(getToastErrorHeading(isActive, isSystemMaintainance));
            setToastMsg(getToastErrorMsg(bankCode, isActive, isSystemMaintainance));
            return false;
        };
    }

    return { getSupplierList, updateStatus };
}
