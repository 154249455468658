import React from 'react'
import { Box, LinearProgress, Typography } from '@mui/material'
import { useSelector } from 'react-redux';


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const ProgressBar = () => {
    // const [progress, setProgress] = useState(10);
    const { progressBarValue } = useSelector(state => state.dragAndDropData)

    // React.useEffect(() => {
    // let timer
    // if (isUploading) {
    //     timer = setInterval(() => {
    //         setProgress((prevProgress) => (prevProgress + 10));
    //         if (progress === 100) {
    //             clearInterval(timer);
    //             dispatchEvent(setIsUploadedBoolean(true))
    //             dispatchEvent(setIsUploadingBoolean(false))
    //         }
    //     }, 800);
    // }

    // return () => {

    // };

    // }, [isUploading, isUploaded]);
    // console.log(progress)
    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgressWithLabel value={progressBarValue} />
        </Box>
    )
}

export default ProgressBar