import { Box, Button, FormControl, FormControlLabel, Grid, Input, TextField, styled } from "@mui/material";

export const ModalWrapper = styled(Grid)(({ theme }) => ({
  "&.MuiGrid-container": {
    marginLeft: 0,
    "& .MuiGrid-root": {
      width: "100%",
    },
    "& .MuiGrid-item": {
      paddingLeft: "0px",
    },
  },
}));

export const StyledSupplierModalDesc = styled(Box)(({ theme }) => ({
  width: "100%",
  fontSize: theme.typography.fontSize.m2,
  fontWeight: theme.typography.fontWeight.regular,
  color: theme.palette.text.textSix,
  marginBottom: "1rem"
}));

export const StyledBannerLine1 = styled(Box)(({ theme }) => ({
  width: "100%",
  fontSize: theme.typography.fontSize.m1,
  fontWeight: theme.typography.fontWeight.bold,
  color: theme.palette.text.body
}));

export const StyledBannerLine2 = styled(Box)(({ theme }) => ({
  width: "100%",
  fontSize: theme.typography.fontSize.m1,
  fontWeight: theme.typography.fontWeight.bold,
  color: theme.palette.text.body
}));

export const StyledSupplierModalHeading = styled(Box)(({ theme }) => ({
  width: "100%",
  fontSize: theme.typography.fontSize.xl,
  marginBottom: "0.75rem",
  fontWeight: theme.typography.fontWeight.semiBold,
  color: theme.palette.text.body
}));

export const StyledBanner = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  width: "100%",
  backgroundColor: "#E8F7FC",
  padding: "0.75rem 0.75rem",
  borderLeft: "4px solid #19A9E1",
  marginBottom: "16px",
}));

export const SuccessIconWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  marginTop: "1rem",
  "& img": {
    height: "70px",
    width: "70px",
    marginBottom: "31.02px",
  },
}));

export const SessionEndIconWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  marginTop: "1rem",
  "& img": {
    height: "100px",
    width: "100px",
  },
}));

export const SessionExpireParaText = styled(Box)(({ theme }) => ({
  textAlign: "center",
  width: "100%",
  marginTop: "5px",
  fontSize: "16px",
  marginBottom: "1rem"
}));

export const SessionExpireFirstText = styled(Box)(({ theme }) => ({
  textAlign: "center",
  width: "100%",
  fontSize: "20px",
}));

export const SessionExpireBoldText = styled(Box)(({ theme }) => ({
  textAlign: "center",
  width: "100%",
  fontSize: "22px",
  fontWeight: "500"
}));

export const ErrorIconWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  marginTop: "1rem",
  "& img": {
    height: "70px",
    width: "70px",
    marginBottom: "1rem",
  },
}));

export const ModalHeader = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "center" && prop !== "fontsize_",
})(({ theme, center, marginbottom, fontsize_ }) => ({
  marginBottom: marginbottom ? marginbottom : "36px",
  "& .MuiBox-root": {
    fontWeight: theme.typography.fontWeight.medium,
    fontSize: fontsize_ ? fontsize_ : 28,
    lineHeight: "23.7px",
    color: theme.palette.text.body,
    textAlign: center ? "center" : "inherit",
    "& p": {
      whiteSpace: "nowrap",
      fontSize: 18,
      fontWeight: theme.typography.fontWeight.regular,
    },
    "& span": {
      fontWeight: theme.typography.fontWeight.bold,
    },
    "& h4": {
      fontSize: 22,
      lineHeight: 1.5,
    },
  },
}));

export const ModalTextWrapper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "center",
})(({ theme, center, margin }) => ({
  "& .MuiGrid-item": {
    padding: "0px 0px 0px 16px",
  },
  fontWeight: theme.typography.fontWeight.regular,
  fontSize: 14,
  lineHeight: "16.94px",
  // marginBottom: "36px",
  color: theme.palette.text.textSix,
  margin: margin ? margin : "25px 0 7px 0",
  textAlign: center ? "center" : "inherit",
}));

export const ModalTextWrapperWithBg = styled(Grid)
  (({ theme, bgColor = "#DDF2FB" }) => ({
    fontWeight: 400,
    padding: "1rem",
    fontSize: 14,
    backgroundColor: bgColor,
    borderRadius: "10px",
    color: "#444444",
    "& .border-b-white": {
      borderBottom: "1px #FFF solid", paddingBottom: "5px", marginBottom: "5px"
    },
    "& .text-medium": {
      color: "#1F1F1F"
    },
    "& .text-semibold": {
      color: "#1F1F1F", fontWeight: 500
    },
  }));

export const AreUSureGrid = styled(Grid)(({ theme }) => ({
  "& .MuiBox-root": {
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: 20,
    lineHeight: "24.2px",
    color: theme.palette.primary.black,
    textAlign: "center",
  },
}));

export const AmountGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "marginbottom",
})(({ theme, marginbottom }) => ({
  marginBottom: marginbottom ? marginbottom : "0",
  "& .MuiBox-root": {
    textAlign: "center",
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: 18,
    lineHeight: "30.38px",
    color: theme.palette.text.textFour,
    "& span": {
      borderBottom: `1px solid ${theme.palette.text.textFour}`,
    },
  },
}));

export const CloseIconGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignContent: "flex-end",
  justifyContent: "flex-end",
  "& img": {
    cursor: "pointer",
  },
}));

export const LogoutBtnContainerGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "30px",
}));

export const LogoutIconWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  marginTop: "1rem",
  "& img": {
    height: "35px",
    width: "35px",
    marginBottom: "1rem",
  },
}));

export const LocalisationBtn = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  color: theme.palette.text.body,
}));

export const CancelBtnWrapper = styled(Button)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "301px",
  gap: "10px",
}));

export const ModalBtnWrapper = styled(Button)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: "10px",
  paddingLeft: "0 !important",
  "&:hover": {
    backgroundColor: "transparent !important",
  },
}));

export const LabelValueHeader = styled(Box)(({ theme }) => ({
  backgroundColor: "#EFF7FF",
  borderRadius: "8px",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: '10px',
  fontWeight: 500,
  fontSize: '16px'
}))

export const LabelValueColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "0px 10px",
  fontSize: 14,
  margin: "14px 0px",
  borderBottom: "1px solid #F2F2F2",
}))

export const LabelValueCell = styled(Box)(({ theme }) => ({
  margin: 0,
  width: "50%",
  "& p.itemvalue": {
    margin: "16px 0px 6px",
  },
  "& span.label": {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#333333',
  }
}))

export const VcWalletBox = styled(Box, {
  shouldForwardProp: prop => !["borderColor", "errorColor", "isError"].includes(prop)
})(({ theme, borderColor = "#EBEDF0", errorColor = "#CD2029", isError = false }) => ({
  borderRadius: "12px",
  border: `1px solid ${isError ? errorColor : borderColor}`,
  padding: "0.8rem",
  fontSize: "16px",

  ".label-text": {
    fontWeight: 500
  },
  ".value-text": {
    fontWeight: 600,
    color: isError ? errorColor : '#000000',
    paddingLeft: "0.5rem"
  }
}))

export const RoundedIconWrapper = styled(Box, {
  shouldForwardProp: prop => !["borderColor", "size", "sx"].includes(prop)
})(({ theme, borderColor = "#DFE2E6", sx, size = "33px" }) => ({
  width: size,
  height: size,
  border: `1px solid ${borderColor}`,
  padding: "5px",
  borderRadius: "50%"
}))

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "18px",
    fontWeight: 500
  }
}))

export const StyledRadioGroup = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "18px",
    fontWeight: 500
  }
}))

export const FormControlFullWidth = styled(FormControl)(({ theme }) => ({
  width: "100% !important"
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "12px !important",
    "& .MuiOutlinedInput-root": {
      "& input.Mui-disabled": {
        backgroundColor: "#EEEEEE"
      }
    }
  },
  "& .MuiFormHelperText-root": {
    marginLeft: "0 !important",
    marginRight: "0 !important",
  }
}))

export const StyledOtpModal = styled(Grid)(({ theme }) => ({
  minWidth: "450px",
  margin: "-0.5rem",

  "& .headerText": {
    fontSize: "20px",
    fontWeight: 600
  },

  "& .contentText": {
    fontSize: "16px",
    fontWeight: 400,
  },

  "& .blueText": {
    color: "#4E9EFF"
  },

  "& .blueLink": {
    color: "#4E9EFF",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  }
}))

export const StyledOtpInput = styled(Input)(({ theme }) => ({
  border: "0.658px solid rgba(0, 0, 0, 0.10)",
  borderRadius: "0.329rem",
  width: "70px",
  height: "65px",
  "&::before": {
    content: `''`,
    border: "none"
  },
  "&::after": {
    content: `''`,
    border: "none"
  },
  "& input": {
    padding: "0",
    color: "#333",
    fontSize: "1.7rem",
    fontWeight: "500",
    lineHeight: "0.8rem",
    textAlign: "center",
  },
  placeholder: {
    color: "red"
  },
  "&.Mui-error": {
    border: "1px solid #CB2029"
  },
  "&.MuiInputBase-root.Mui-disabled": {
    backgroundColor: "rgba(0, 0, 0, 0.12)"
  },
  "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
    border: "none"
  },
  "&.MuiInput-underline.Mui-disabled:before": {
    border: "none"
  }
}))
