import { useTheme } from '@mui/material'
import { Controls } from '../../controls'
import { FilterGridItem, FilterRowWrapper } from './filters.styles'
import { MainPageTopSection } from "../../globalStyles/global.styles"
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setbeneficiaryBankName, setCustomerPhone, setEndDate, setpartnerReferenceNumber, setRefundParams, setStartDate, setTransactionType } from '../../redux/slice/clientAccountSlice'
import dayjs from 'dayjs'
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";


const TableFilter = ({ getRefundList }) => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);
    const [transactionList, setTransactionList] = useState([]);
    const theme = useTheme()
    const { beneficiaryBankName, transactionType, startDate, endDate, customerPhone, partnerReferenceNumber } = useSelector(state => state.clientAccountData)
    const { currentLimit } = useSelector(state => state.paginationData)
    const dispatch = useDispatch()
    const transactionTypeList = [{ name: "Disbursement", code: "Disbursement", status: 1 }, { name: "Refund", code: "Refund", status: 1 }]


    const onClearHandler = () => {

        dispatch(setRefundParams({
            beneficiaryBankName: '',
            transactionType: '',
            startDate: null,
            endDate: null,
            customerPhone: '',
            partnerReferenceNumber: ''
        }))

        if (beneficiaryBankName !== "" || transactionType !== "" || startDate !== null || endDate !== null || customerPhone !== "" || partnerReferenceNumber !== "") {
            getRefundList(currentLimit, 1, true)
        }

    }

    const onApplyHandler = () => {
        if (beneficiaryBankName !== "" || transactionType !== "" || startDate !== null || endDate !== null || customerPhone !== "" || partnerReferenceNumber !== "") {
            getRefundList(50, 1)
        }
    }
    const applyBtnColor = () => {
        return (beneficiaryBankName === "" && transactionType === "" && startDate === null && endDate === null && customerPhone === "" && partnerReferenceNumber === "")
    }

    useEffect(() => {
        setTransactionList(transactionTypeList)
        //eslint-disable-next-line
    }, []);

    return (
        <MainPageTopSection>
            <FilterRowWrapper container item spacing={3} flexDirection={"column"}>
                <FilterGridItem item >
                    <Controls.DatePicker id="start-date" value={startDate} onChange={e => dispatch(setStartDate(dayjs(e).format("YYYY-MM-DD")))} height="40px" label={strings.StartDate} labelfontweight={theme.typography.fontWeight.medium} labelcolor={theme.palette.text.body} inputpadding="0.5px 16px" />
                </FilterGridItem >
                <FilterGridItem item>
                    <Controls.BaseTextField idText="mobile-textfield" value={customerPhone} onChange={e => dispatch(setCustomerPhone(e.target.value))} height="40px" label={strings.CustSendMobNum} placeholder={strings.EnterMobileNumber} textfieldmargin="10px 0px 0px 0px" inputpadding="0.5px 16px" />
                </FilterGridItem>
            </FilterRowWrapper >
            <FilterRowWrapper container item spacing={3} flexDirection={"column"}>
                <FilterGridItem item>
                    <Controls.DatePicker value={endDate} onChange={e => dispatch(setEndDate(dayjs(e).format("YYYY-MM-DD")))} height="40px" label={strings.EndDate} labelfontweight={theme.typography.fontWeight.medium} labelcolor={theme.palette.text.body} inputpadding="0.5px 16px" />
                </FilterGridItem >
                <FilterGridItem item>
                    <Controls.BaseSelectInput id="select-transaction-type" placeholderText={strings.TransactionType} label={strings.TransactionType} value={transactionType} onChange={e => dispatch(setTransactionType(e.target.value))} values={transactionList} disablemenuitemvalue={strings.EnterTransactionType} labelfontweight={theme.typography.fontWeight.medium} labelcolor={theme.palette.text.body} labelmarginbottom="12px" height="40px" width="100%" inputpadding="0.5px 16px" />
                </FilterGridItem>
            </FilterRowWrapper >
            <FilterRowWrapper container item spacing={3} flexDirection={"column"}>
                <FilterGridItem item>
                    <Controls.BaseTextField idText="transactionid-textfield" value={partnerReferenceNumber} onChange={e => dispatch(setpartnerReferenceNumber(e.target.value))} height="40px" label={strings.TransactionId} placeholder={strings.EnterTransactionId} inputpadding="0.5px 16px" />
                </FilterGridItem >
                <FilterGridItem item>
                    <Controls.BaseTextField idText="bankname-textfield" value={beneficiaryBankName} onChange={e => dispatch(setbeneficiaryBankName(e.target.value))} height="40px" label={strings.EnterBankName} placeholder={strings.EnterBankName} textfieldmargin="10px 0px 0px 0px" inputpadding="0.5px 16px" />
                </FilterGridItem>
            </FilterRowWrapper >
            <FilterRowWrapper container item spacing={3} flexDirection={"column"} margin="40px 4rem 0px 0px" justifyContent={"center"} alignItems={"center"}>
                <FilterGridItem item>
                    <Controls.BaseButton id="apply-btn" onClick={onApplyHandler} disabled={applyBtnColor()} text={strings.Apply} height="40px" width="160px" marginbottom="13px" />
                </FilterGridItem >
                <FilterGridItem item>
                    <Controls.BaseButton id="clear-btn" onClick={onClearHandler} text={strings.Clear} textcolor={theme.palette.text.textEight} height="40px" width="160px" btnborder={`1px solid ${theme.palette.neutral.border}`} disablemenuitem="true" labelcolor={theme.palette.text.textEight} bgcolor={theme.palette.neutral.white} />
                </FilterGridItem>
            </FilterRowWrapper >
        </MainPageTopSection >
    )
}

export default TableFilter