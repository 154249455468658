import { useDispatch, useSelector } from "react-redux";
import BaseApi from "../api/BaseApi";
import { setBalanceLoader, setCurrentClientBalance } from "../redux/slice/clientAccountSlice";
import { setEscrowBalance, setEscrowBalanceLoader, setTotalClientBalance, setTotalClientBalanceLoader } from "../redux/slice/escrowAccountSlice";
import { paramConstants } from "../utils/const";
import { generateUuidValue } from "../utils/utilFunctions";

export const useBalanceData = (setOpenErrorModal, setErrorText) => {
  const dispatch = useDispatch();
  const { merchantCode } = useSelector((state) => state.clientAccountData);
  const getClientBalance = async () => {
    if (merchantCode) {
      try {
        dispatch(setBalanceLoader(true));
        const setParams = {
          transactionId: generateUuidValue(),
          referenceNumber: generateUuidValue(),
          merchantCode: merchantCode,
          bankCode: paramConstants.bankCode,
          productCode: paramConstants.productCode,
        };
        const response = await BaseApi("get", "/of-admin-dashboard/web/v1/merchant/balance", "", setParams);
        if (response?.data && response?.data?.code === 200) {
          dispatch(setCurrentClientBalance(response?.data?.merchant?.amount));
          dispatch(setBalanceLoader(false));
        }
      } catch (e) {
        if (parseInt(e.response?.data?.code) === 401) {
          dispatch(setBalanceLoader(false));
          window.location.href = "/session-expired";
        }
        setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
        setOpenErrorModal(true);
        dispatch(setBalanceLoader(false));
      }
    } else {
      setErrorText("Please select a merchant.");
      setOpenErrorModal(true);
    }
  };

  const getClientTotalBalance = async () => {
    dispatch(setTotalClientBalanceLoader(true));
    try {
      const setParams = {
        transactionId: generateUuidValue(),
        referenceNumber: generateUuidValue(),
      };
      const response = await BaseApi("get", "/of-admin-dashboard/web/v1/merchants/balance", "", setParams);
      if (response?.data && response?.data?.code === 200) {
        dispatch(setTotalClientBalance(response?.data?.totalClientBalance?.totalBalance));
        dispatch(setTotalClientBalanceLoader(false));
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        dispatch(setBalanceLoader(false));
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
      dispatch(setTotalClientBalanceLoader(false));
    }
  };

  const getEscrowBalance = async () => {
    dispatch(setEscrowBalanceLoader(true));
    try {
      const setParams = {
        transactionId: generateUuidValue(),
        referenceNumber: generateUuidValue(),
        merchantCode: paramConstants.merchantCode,
        bankCode: paramConstants.bankCode,
        productCode: paramConstants.productCode,
      };
      const response = await BaseApi("get", "/of-admin-dashboard/web/v1/escrow/balance", "", setParams);
      if (response?.data && response?.data?.code === 200) {
        dispatch(setEscrowBalance(response?.data?.escrow?.amount));
        dispatch(setEscrowBalanceLoader(false));
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        dispatch(setBalanceLoader(false));
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
      dispatch(setEscrowBalanceLoader(false));
    }
  };
  return { getClientBalance, getClientTotalBalance, getEscrowBalance };
};
