import { Box, styled } from "@mui/material";

export const PaginationWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "12px 0",
}));

export const ShowingEntries = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily.inter,
  fontStyle: "normal",
  fontWeight: theme.typography.fontWeight.regular,
  fontSize: theme.typography.fontSize.m1,
  color: theme.palette.text.body,
}));

export const PaginationRightWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
}));

export const LeftArrowWrapper = styled(Box)(({ theme }) => ({
  marginRight: "1.25rem",
  cursor: "pointer",
  height: "24px",
  width: "24px",
  backgroundColor: theme.palette.neutral.white,
  border: `1px solid ${theme.palette.border.main}`,
  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const RightArrowWrapper = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  height: "24px",
  width: "24px",
  backgroundColor: theme.palette.neutral.white,
  border: `1px solid ${theme.palette.border.main}`,
  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& img": {
    marginLeft: "2px",
  },
}));

export const DisabledRightArrowWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "& img": {
    marginLeft: "2px",
  },
}));

export const DisabledLeftArrowWrapper = styled(Box)(({ theme }) => ({
  marginRight: "1.25rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& img": {
    width: "9px",
  },
}));
