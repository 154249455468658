import { useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LocalizedStrings from 'react-localization'
import { useDispatch, useSelector } from 'react-redux'
import { Components } from '../../components'
import { FilterGridItem } from '../../components/filters/filters.styles'
import { HtmlTooltip } from '../../components/sidebar/menubar.styles'
import { DrawerHeader } from '../../components/sidebar/Sidebar.styled'
import { StyledNoDataFound, StyledTableBody, StyledTableRow, StyledTableRowCell } from '../../components/table/PrimaryTable.styles'
import PriceConfigTooltipContent from '../../components/tooltipContent/PriceConfigTooltipContent'
import { Controls } from '../../controls'
import { usePriceConfigurationList } from '../../customHook/usePriceConfigurationList'
import { LoaderWrapper, MainBox, StyledCell } from '../../globalStyles/global.styles'
import { setCurrentLimit, setPaginationLimit, setPaginationPage, setShowData } from '../../redux/slice/paginationSlice'
import { setAllDataForPricingConfig, setConfigOneFilter, setCurrentBank, setEditableFlag } from '../../redux/slice/PriceConfigSlice'
import LocalData from '../../utils/localization'
import { STATIC_ASSETS } from '../../utils/staticAssets'
import { getBankName, getDate, getProductCode, getTime } from '../../utils/utilFunctions'
import { StyledTableTooltipContent } from './priceconfig.styles'

const PriceConfiguration = () => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const [errorText, setErrorText] = useState("");
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [exitEditModal, setExitEditModal] = useState(false);

    const { showFrom, showTo, limit } = useSelector(state => state.paginationData)
    const [totalCount, setTotalCount] = useState(0);
    const [loader, showLoader] = useState(false);

    const theme = useTheme()
    const dispatch = useDispatch()

    const TableHeaders = [
        strings["DateAndTime"],
        strings["ClientName"],
        strings["ProductName"],
        strings["BankName"],
        ""
    ]

    const getPageCount = (pageNo, data) => {
        const dataLength = data?.length;
        const currentPage = pageNo - 1;
        const startCount = currentPage * limit + 1;
        if (pageNo === 1) {
            if (dataLength) {
                dispatch(setShowData({ showFrom: 1, showTo: dataLength }));
            } else {
                dispatch(setShowData({ showFrom: 0, showTo: 0 }));
            }
        } else if (pageNo > 1) {
            dispatch(
                setShowData({
                    showFrom: startCount,
                    showTo: startCount - 1 + dataLength,
                })
            );
        }
    };

    const { priceConfigList, getConfigurationList } = usePriceConfigurationList(setTotalCount, showLoader, getPageCount)

    function handleEdit(row) {
        dispatch(setAllDataForPricingConfig({
            merchantCode: row?.merchantCode,
            product: getProductCode(row?.productCode),
            bankFeesType: row?.mdrDetails?.bankFeeType.toString(),
            bankFees: row?.mdrDetails?.bankFee.toString(),
            ayoconnectFeesType: row?.mdrDetails?.ayoconnectFeeType.toString(),
            ayoconnectFees: row?.mdrDetails?.ayoconnectFee.toString(),
            clientFeesType: row?.mdrDetails?.clientFeeType.toString(),
            clientFees: row?.mdrDetails?.clientFee.toString(),
        }))

        dispatch(setCurrentBank(row?.bankCode))
        dispatch(setConfigOneFilter(true));
        dispatch(setEditableFlag(true))

    }

    useEffect(() => {
        getConfigurationList(50, 1)
        return () => {
            dispatch(setAllDataForPricingConfig({
                merchantCode: "",
                product: "",
                bankFeesType: '',
                bankFees: '',
                ayoconnectFeesType: '',
                ayoconnectFees: '',
                clientFeesType: '',
                clientFees: '',
            }))
            dispatch(setCurrentBank(""))
            dispatch(setCurrentLimit(50))
            dispatch(setPaginationLimit(50))
            dispatch(setPaginationPage(1))
            dispatch(setShowData({ showFrom: 0, showTo: 0 }))
        }
    }, []);

    return (
        <Components.Layout>
            <DrawerHeader />
            <MainBox component="main" height="100%">
                <Components.PriceConfigSelectorOne setErrorText={setErrorText} setOpenErrorModal={setOpenErrorModal} />
                <Components.PriceConfigSelectorTwo setErrorText={setErrorText} setOpenErrorModal={setOpenErrorModal} getConfigList={getConfigurationList} />
                <Components.HeadRow
                    getList={getConfigurationList}
                    tableName={strings.ConfigurationDetails}
                />
                {loader && (
                    <LoaderWrapper><img src={STATIC_ASSETS.LOADER} alt="loader-img" /></LoaderWrapper>
                )}
                {!loader && (
                    <Components.PrimaryTable data-testid="primary-table" headers={TableHeaders} listlength={priceConfigList?.length}>
                        <StyledTableBody>
                            {priceConfigList?.length > 0 ? (
                                priceConfigList.map((row, index) => {
                                    return (
                                        <StyledTableRow key={index}>
                                            <StyledTableRowCell align="left">
                                                <StyledCell
                                                    marginBottom="0.25rem"
                                                    color={theme.palette.text.body}
                                                    fontSize={theme.typography.fontSize.m1}
                                                >{getDate(row?.dateTime)}</StyledCell>
                                                <StyledCell
                                                    color={theme.palette.text.textSix}
                                                    fontSize={theme.typography.fontSize.s}
                                                >{getTime(row?.dateTime)}</StyledCell>
                                            </StyledTableRowCell>
                                            <StyledTableRowCell
                                                color={theme.palette.primary.main}
                                                align="left"
                                            >
                                                <HtmlTooltip arrow interactive="true" title={<PriceConfigTooltipContent toolTipList={row?.mdrDetails} />} placement="right">
                                                    <StyledTableTooltipContent>{row?.merchantCode}</StyledTableTooltipContent>
                                                </HtmlTooltip>
                                            </StyledTableRowCell>
                                            <StyledTableRowCell align="left">
                                                {row?.productCode}
                                            </StyledTableRowCell>
                                            <StyledTableRowCell align="left">
                                                {getBankName(row?.bankCode) || row?.bankCode}
                                            </StyledTableRowCell>
                                            {/* <StyledTableRowCell align="left">
                                                <Controls.SlideToggleButton />
                                            </StyledTableRowCell> */}
                                            <StyledTableRowCell align="left">
                                                <FilterGridItem item><Controls.BaseButton onClick={() => handleEdit(row)} borderradius="4px" text={strings.Edit} width="58px" height="28px" fontsize_={Number(12)} /></FilterGridItem>
                                                {/* <FilterGridItem item><Controls.BaseButton borderradius="4px" text="Delete" width="58px" height="28px" fontsize_={Number(12)} btnborder={`1px solid ${theme.palette.neutral.border}`} bgcolor={theme.palette.neutral.white} textcolor={theme.palette.red.main} /></FilterGridItem> */}
                                            </StyledTableRowCell>
                                        </StyledTableRow>
                                    )
                                })
                            ) : (
                                <StyledTableRow>
                                    <StyledTableRowCell colSpan={12}>
                                        <StyledNoDataFound>No Transactions</StyledNoDataFound>
                                    </StyledTableRowCell>
                                </StyledTableRow>
                            )}
                        </StyledTableBody>
                    </Components.PrimaryTable>
                )}
                <Components.Pagination
                    totalCount={totalCount}
                    showTo={showTo} showFrom={showFrom}
                    getList={getConfigurationList}
                />
                <Components.BasePopup setOpen={setOpenErrorModal} show_dialog={openErrorModal}>
                    <Components.ErrorHandlerModal errorText={errorText} setClose={setOpenErrorModal} setErrorText={setErrorText} />
                </Components.BasePopup>
                <Components.BasePopup setOpen={setExitEditModal} show_dialog={exitEditModal}>
                    <Components.ExitEditModal setClose={setExitEditModal} />
                </Components.BasePopup>
            </MainBox>
        </Components.Layout>
    )
}

export default PriceConfiguration