import { useTheme } from "@mui/material";
import React from "react";
import { Controls } from "../../controls";
import { ModalWrapper, StyledSupplierModalHeading, StyledSupplierModalDesc, StyledBannerLine1, StyledBannerLine2, ModalBtnWrapper } from "./modal.styles";
import LocalData from '../../utils/localization';
import LocalizedStrings from 'react-localization';
import { useSelector } from 'react-redux';

const ConfirmSystemMaintainance = ({ setClose, errorText, setErrorText }) => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const theme = useTheme();

    const handleClose = () => {
        setClose(false);
    };


    return (
        <ModalWrapper width="560px" data-testid="supplier-switch-modal-wrapper" container spacing={2}>
            {/* <CloseIconGrid data-testid="error-close-icon" item xs={12} alignItems={"right"}>
                <img src={STATIC_ASSETS.CLOSE_ICON} alt="close-icon" onClick={handleClose} />
            </CloseIconGrid> */}
            <StyledSupplierModalHeading>{strings.supplierManagement.enterMaintenanceMode}</StyledSupplierModalHeading>
            <StyledSupplierModalDesc>{strings.supplierManagement.allSuppliersWillBeDown}</StyledSupplierModalDesc>
            <ModalBtnWrapper>
                <Controls.BaseButton data-testid="close-btn" fontweight_="500" onClick={handleClose} width="50%" fontsize_={theme.typography.fontSize.m1} height="40px" text={strings.Cancel} bgcolor={theme.palette.neutral.white} textcolor={theme.palette.text.body} btnborder={`1px solid ${theme.palette.neutral.border}`} />
                <Controls.BaseButton data-testid="close-btn" onClick={() => { console.log('deactivate code here') }} width="50%" fontsize_={theme.typography.fontSize.m1} bgcolor={theme.palette.primary.lightBlue} height="40px" text={strings.supplierManagementconfirmEnter} />
            </ModalBtnWrapper>
        </ModalWrapper>
    );
};

export default ConfirmSystemMaintainance;
