import React from "react";
import {
  LeftArrowWrapper,
  PaginationRightWrapper,
  PaginationWrapper,
  RightArrowWrapper,
  ShowingEntries,
  DisabledLeftArrowWrapper,
  DisabledRightArrowWrapper
} from "./Pagination.styles";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { setPaginationPage } from "../../redux/slice/paginationSlice";
import { useDispatch, useSelector } from "react-redux";
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";

const Pagination = ({ totalCount, getList, setPage }) => {
  const { currentLanguage } = useSelector((state) => state.languageData);
  const strings = new LocalizedStrings(LocalData);
  strings.setLanguage(currentLanguage);
  const dispatch = useDispatch()
  const { showFrom, showTo, page, limit, currentLimit } = useSelector(state => state.paginationData)
  const goPrevious = () => {
    if (page > 1) {
      dispatch(setPaginationPage(page - 1));
      getList(currentLimit, page - 1);
    }
  }

  const goNext = () => {
    if (totalCount > page * currentLimit) {
      dispatch(setPaginationPage(page + 1));
      getList(limit, page + 1);
    }
  }

  return (
    <PaginationWrapper>
      <ShowingEntries>{strings.Showing} {showFrom} {strings.To} {showTo} {strings.Of} {totalCount} {strings.Entry}</ShowingEntries>
      <PaginationRightWrapper>
        {page > 1 ? (
          <LeftArrowWrapper>
            <img src={STATIC_ASSETS.ARROW_LEFT} onClick={goPrevious} alt="previous" id="left-arrow-icon"/>
          </LeftArrowWrapper>
        ) : (
          <DisabledLeftArrowWrapper>
            <img src={STATIC_ASSETS.DISABLED_LEFT} alt="disabled_previous" id="disabled-left-arrow"/>
          </DisabledLeftArrowWrapper>
        )
        }
        {totalCount > page * currentLimit ? (
          <RightArrowWrapper>
            <img src={STATIC_ASSETS.EXPAND_RIGHT} onClick={goNext} alt="next" id="right-arrow-icon"/>
          </RightArrowWrapper>
        ) : (
          <DisabledRightArrowWrapper>
            <img src={STATIC_ASSETS.DISABLED_RIGHT} alt="disabled_next" id="disabled-right-arrow"/>
          </DisabledRightArrowWrapper>
        )
        }
      </PaginationRightWrapper>
    </PaginationWrapper>
  );
};

export default Pagination;
