import { createSlice } from "@reduxjs/toolkit";

export const escrowAccountSlice = createSlice({
  name: "escrowAccount",
  initialState: {
    totalClientBalance: 0,
    escrowBalance: 0,
    escrowBalanceLoader: false,
    totalClientBalanceLoader: false,
  },
  reducers: {
    setTotalClientBalance: (state, action) => {
      state.totalClientBalance = action.payload;
    },
    setEscrowBalance: (state, action) => {
      state.escrowBalance = action.payload;
    },
    setEscrowBalanceLoader: (state, action) => {
      state.escrowBalanceLoader = action.payload;
    },
    setTotalClientBalanceLoader: (state, action) => {
      state.totalClientBalanceLoader = action.payload;
    },
  },
});

export const { setTotalClientBalance, setEscrowBalance, setEscrowBalanceLoader, setTotalClientBalanceLoader } = escrowAccountSlice.actions;
export default escrowAccountSlice.reducer;
