import { styled } from "@mui/material";

export const DragAndDropWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: `dashed 1px ${theme.palette.text.textEight}`,
  borderRadius: "8px",
  padding: "22px 0px",
  "& input": {
    display: "none",
  },
}));

export const ImgAndHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "20px",
  "& img": {
    width: "24px",
    height: "24px",
    marginRight: "12px",
    verticalAlign: "middle",
    marginBottom: "2px",
  },
}));

export const MainUploadImg = styled("img")(() => ({
  width: "48px",
  height: "48px",
  marginBottom: "33px",
}));

export const StyledHeader = styled("header")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily.inter,
  fontWeight: theme.typography.fontWeight.regular,
  fontSize: 16,
  lineHeight: "16px",
  color: theme.palette.text.body,
  verticalAlign: "middle",
}));

export const StyledSubHeader = styled("header")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily.inter,
  fontWeight: theme.typography.fontWeight.regular,
  fontSize: 14,
  lineHeight: "16px",
  color: theme.palette.text.subheading,
  marginBottom: "16px",
}));

// upload file ui
export const UploadFileWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));

export const LeftElements = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  margin: "0px 13px 0px 13px",
  width: "inherit",
}));
export const FileNameAndSize = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  marginLeft: "38px",
  alignItems: "flex-start",
  justifyContent: "space-around",
  width: "100%",
}));

export const FileName = styled("div")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily.inter,
  fontWeight: theme.typography.fontWeight.medium,
  fontSize: 16,
  lineHeight: "19px",
}));
export const FileSize = styled("div")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily.inter,
  fontWeight: theme.typography.fontWeight.regular,
  fontSize: 12,
  lineHeight: "16px",
  color: theme.palette.text.textEight,
}));

export const RightElements = styled("div")(({ error }) => ({
  width: error ? "16rem" : "23rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginRight: "13px",
  "& input": {
    display: "none",
  },
}));

// error messages
export const ErrorMessageWrapper = styled("div")(({ theme, typeError }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: typeError ? "27rem" : "14rem",
  marginLeft: "38px",
  backgroundColor: theme.palette.red.background,
  border: `1px solid ${theme.palette.red.border}`,
  borderRadius: "8px",
  padding: "13px",
}));
