import React, { useCallback } from 'react'
import { useSettlementFileDownload } from '../../customHook/useSettlementFileDownload'
import { StyledLinkButton } from './button.styles'

const LinkButton = (props) => {
    const { id, value, setErrorText, setOpenErrorModal } = props
    const { handleSettlementFileDownload } = useSettlementFileDownload(setErrorText, setOpenErrorModal)

    const onDownloadHandler = useCallback(
        () => {
            handleSettlementFileDownload(id)
        },
        [id, handleSettlementFileDownload],
    )

    return (
        <StyledLinkButton onClick={onDownloadHandler}>{value}</StyledLinkButton>
    )
}

export default LinkButton