import { combineReducers, configureStore } from "@reduxjs/toolkit";
import currentLanguageReducer from "./slice/languageSlice";
import drawerReducer from "./slice/drawerSlice";
import authReducer from "./slice/authSlice";
import modalDataReducer from "./slice/modalSlice";
import PaginationReducer from "./slice/paginationSlice";
import ClientAccountReducer from "./slice/clientAccountSlice";
import PriceConfigReducer from "./slice/PriceConfigSlice";
import DragAndDropReducer from "./slice/dragAndDropSlice";
import RefundRowReducer from "./slice/refundRowSlice";
import EscrowAccountReducer from "./slice/escrowAccountSlice";
import EmbeddedPaymentReducer from "./slice/embeddedPaymentSlice";
import vcTransactionSlice from "./slice/vcTransactionSlice";
import rateLimiterSlice from "./slice/rateLimiterSlice";

import storage from "redux-persist/lib/storage";

import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import vcFeatureSlice from "./slice/vcFeatureSlice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    languageData: currentLanguageReducer,
    drawerData: drawerReducer,
    authData: authReducer,
    modalData: modalDataReducer,
    paginationData: PaginationReducer,
    clientAccountData: ClientAccountReducer,
    priceConfigData: PriceConfigReducer,
    dragAndDropData: DragAndDropReducer,
    refundRowData: RefundRowReducer,
    escrowAccountData: EscrowAccountReducer,
    embeddedPayments: EmbeddedPaymentReducer,
    vcTransactionData: vcTransactionSlice,
    vcFeatureData: vcFeatureSlice,
    rateLimiterData: rateLimiterSlice
  })
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
