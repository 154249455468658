import { Controls } from "../../controls";
import { CardPaper, CardWrapper, MessageBlock, MessageHeader, MessageSubTitle } from "../../globalStyles/global.styles";
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { useSelector } from "react-redux";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { Components } from "..";

const SuccessMessage = () => {
    const { currentLanguage } = useSelector((state) => state.languageData)
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage)

    return (<>
        <Components.Appbar />
        <CardPaper>
            <CardWrapper width={480}>
                <MessageBlock><img src={STATIC_ASSETS.CHECK_CIRCLE} alt="success-icon" width={50} height={50} />
                    <MessageHeader>Congratulations!</MessageHeader>
                    <MessageSubTitle variant="p">
                        {strings.Password_Success_Message}
                    </MessageSubTitle>
                    <Controls.BaseButton text={strings.Login_To_The_Dashboard} width={240} onClick={()=>{window.location.href = "/"}}/>
                </MessageBlock>
            </CardWrapper>
        </CardPaper>
    </>)
}
export default SuccessMessage;