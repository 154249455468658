export const customTheme = {
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
  },
  typography: {
    fontWeight: {
      light: "300",
      regular: "400",
      medium: "500",
      semiBold: "600",
      bold: "700",
      xbold: "800",
    },
    fontFamily: {
      inter: `"Inter", sans-serif`,
    },
    fontSize: {
      xs: 10,
      s: 12,
      m1: 14,
      m2: 16,
      m3: 18,
      l: 20,
      xl: 24,
      xl2: 28,
      xxl: 32,
    },
  },
  palette: {
    primary: {
      main: "#004C97",
      dark: "#00356A",
      light: "#EFF7FF",
      hover: "#1C60A2",
      focusStroke: "#85A9CD",
      mainBackground: "#F5F5F5",
      secondaryBackground: "#056DAE",
      black: "#000000",
      disabled: "#CCCCCC",
      lightBlue: "#19A9E1",
    },
    border: {
      main: "#DDDDDD",
      modal: "#EEEEEE",
      focus: "#4EA7FF",
      cardSeparation: "rgba(0, 0, 0, 0.10)",
    },
    neutral: {
      white: "#FFFFFF",
      lightBackground: "#F5F6F7",
      hover: "#EBEDF0",
      border: "#DFE2E6",
      disable: "#C2C7D0",
      lightGrey: "#F9F9F9",
    },
    text: {
      heading: "#1F1F1F",
      body: "#333333",
      subheading: "#555555",
      textFour: "#444444",
      textSix: "#666666",
      textSeven: "#777777",
      textEight: "#888888",
    },
    red: {
      light: "#FAE9EA",
      main: "#CD2029",
      dark: "#90161D",
      darker: "#7D1419",
      secondary: "#D80027",
      background: "#FBE9E8",
      border: "#D9261C",
    },
    green: {
      light: "#E6F3ED",
      main: "#00854A",
      dark: "#005D34",
      darker: "#00512D",
    },
    yellow: {
      light: "#F8F4E6",
      main: "#BC8D00",
      dark: "#846300",
      darker: "#735600",
    },
  },
};

export const paramConstants = {
  topUpMutation: 5,
  withdrawalMutation: 6,
  topUpType: 1,
  withdrawalType: 2,
  merchantCode: "AYORPM",
  bankCode: "002",
  productCode: "FAST_DISBURSEMENT",
  currency: "IDR",
};

export const paginationArray = [
  { name: 10, code: 10 },
  { name: 25, code: 25 },
  { name: 50, code: 50 },
  { name: 100, code: 100 },
];

export const transactionTypes = ["Disbursement", "MDR", "Refund", "Payment", "TopUp", "Withdrawal"];

export const localProductList = [{ name: "Direct Debit", code: "1", status: "Active" }];

export const BankList = [
  { name: "Bank BRI", code: "002" },
  { name: "Bank Danamon", code: "011" },
  { name: "Bank BNI", code: "009" },
  { name: "Bank CIMB Niaga", code: "022" },
  { name: "Bank Mandiri", code: "008" },
  { name: "Bank CIMB Niaga (SNAP)", code: "222" },
  { name: "PT. BANK NEO COMMERCE", code: "490" },
  { name: "Bank Nobu", code: "503" },
];

export const MDRType = [
  { name: "Fixed", code: "1", status: 1 },
  { name: "Percentage", code: "2", status: 1 },
];

export const BankFeesType = [
  { name: "Fixed", code: "1", status: 1 },
  { name: "Percentage", code: "2", status: 1 },
];

export const AyoconnectFeesType = [
  { name: "Fixed", code: "1", status: 1 },
  { name: "Percentage", code: "2", status: 1 },
];

export const ClientFeesType = [
  { name: "Fixed", code: "1", status: 1 },
  { name: "Percentage", code: "2", status: 1 },
];

// Default Format on Datepicker Filter Field
export const DatePickerFormat = "DD/MM/YYYY";
// Multiple Select Dropdown value to mark All Checkbox option
export const AllSelected = "all";
export const TransactionKind = {
  topup: "topup",
  spendRefund: "spendRefund",
}
export const TransactionKindList = [
  {
    key: TransactionKind.topup,
    label: "Top-up"
  },
  {
    key: TransactionKind.spendRefund,
    label: "Spend"
  }
]
export const TransactionStatus = {
  all: "all",
  success: "success",
  failed: "failed"
}
export const VcTopupTrxType = {
  topup: {
    id: 0,
    label: "Top-up",
    shortLabel: "Top-up",
  },
  partialReversal: {
    id: 2,
    label: "Top-up Partial Reversal",
    shortLabel: "P. Top-up Reversal",
  },
  fullReversal: {
    id: 1,
    label: "Top-up Full Reversal",
    shortLabel: "F. Top-up Reversal",
  }
}
export const VcSpendTrxType = {
  all: {
    id: "all",
    label: "All"
  },
  spend: {
    id: "S",
    label: "Spend"
  },
  fullReversal: {
    id: "F",
    label: "Full Reversal"
  },
  partialReversal: {
    id: "P",
    label: "Partial Reversal"
  }
}
export const VcFilterStorage = "VC_FILTER_PAYLOAD"
export const VcFeatureList = {
  VC_SETTING_OTP: 'VC_SETTING_OTP'
}
export const VcSettingTabName = {
  rulesNConfig: "rulesNConfig",
  emailAlerts: "emailAlerts",
  alertNDeposit: "alertNDeposit",
  bufferAmountOnDepo: "bufferAmountOnDepo"
}
export const VcSettingPayload = () => {
  return {
    clientThreshold: 0,
    cardIssuePerDay: 0,
    numberOfCardPerUser: 0,
    maxLimitPerCardPerMonth: 0,
    maxAmountPerTrx: 0,
    reduceLimitOnCard: false,
    emails: [],
    alertLevels: [],
    bufferAmount: 0
  }
} 