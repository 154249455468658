import { Box, useTheme } from "@mui/material";
import React from "react";
import { Controls } from "../../controls";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { ModalHeader, ModalWrapper, ErrorIconWrapper, CloseIconGrid, CancelBtnWrapper } from "./modal.styles";
import LocalData from '../../utils/localization';
import LocalizedStrings from 'react-localization';
import { useSelector } from 'react-redux';

const SaveHandlerModal = ({ setClose, errorText, setErrorText, buttonLableText, dialogIcon , setNextStep, setDefaultState}) => {
  const { currentLanguage } = useSelector((state) => state.languageData);
  const strings = new LocalizedStrings(LocalData);
  strings.setLanguage(currentLanguage);
  const theme = useTheme();
  const handleClose = () => {
    setClose(false);
    setErrorText("");
    setDefaultState();
  };
  const callUpdateAPI =()=>{
    setClose(false);
    setErrorText("");
    setNextStep(true);
  }
  return (
    <ModalWrapper data-testid="error-modal-wrapper" container spacing={2} direction="column" justifyContent={"center"} alignItems={"center"}>
      <CloseIconGrid data-testid="error-close-icon" item xs={12} alignItems={"right"}>
        <img src={STATIC_ASSETS.CLOSE_ICON} alt="close-icon" onClick={handleClose} />
      </CloseIconGrid>
      <ErrorIconWrapper>
        <img src= {STATIC_ASSETS.SAVE_ICON} alt="save-icon" />
      </ErrorIconWrapper>
      <ModalHeader data-testid="modal-header" marginbottom="30px" center item xs={12} fontsize_={theme.typography.fontSize.m3}>
        <Box>{errorText}</Box>
      </ModalHeader>
      <CancelBtnWrapper>
      <Controls.BaseButton data-testid="close-btn"  onClick={handleClose} width="50%" fontsize_={theme.typography.fontSize.m1} height="37px" text={strings.Cancel} bgcolor={theme.palette.neutral.white} textcolor={theme.palette.text.body} btnborder={`1px solid ${theme.palette.neutral.border}`}/>
      <Controls.BaseButton data-testid="close-btn" onClick={callUpdateAPI} width="50%" fontsize_={theme.typography.fontSize.m1} height="37px" text={strings.OK} />
      </CancelBtnWrapper>
    </ModalWrapper>
  );
};

export default SaveHandlerModal;
