import React, { useEffect, useState } from "react";
import { Components } from "../../components";
import SessionExpiredModal from "../../components/modals/SessionExpiredModal";
import { useSelector } from "react-redux";
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";

const SessionExpired = () => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);

    useEffect(() => {
        strings.setLanguage(currentLanguage);
    }, [])
    
    const [open, setOpen] = useState(true);

    return (
        <Components.BasePopup
            setOpen={setOpen}
            show_dialog={open}
        >
            <SessionExpiredModal strings={strings} />
        </Components.BasePopup>
    )
}

export default SessionExpired;
