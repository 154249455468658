import { List, ListItemButton } from "@mui/material"
import { Link } from "react-router-dom"
import { StyledListItemText, StyledSubListItem } from "./menubar.styles";
import React from 'react';

const ExpandableMenuPopover = (props) => {
    const { subList, currentLocation } = props;
    return (
        <List component="div" sx={{ py: 0 }}>
            {subList.map((item, index) => (
                <StyledSubListItem id={item.id || index} popover={true} component='div' active={currentLocation === item.to ? 1 : 0} key={index}>
                    <Link to={item.to} style={{ textDecoration: "none", height: 'inherit' }}>
                        <ListItemButton
                            sx={{
                                height: 'inherit',
                                justifyContent: 'initial',
                                px: 2.5,
                            }}
                        >
                            <StyledListItemText id={item.id ? `${item.id}-text` : `${index}-text`} primary={item.label} />
                        </ListItemButton>
                    </Link>
                </StyledSubListItem>
            ))}
        </List>
    )
}

export default ExpandableMenuPopover