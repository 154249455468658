import { createSlice } from "@reduxjs/toolkit";

export const embeddedPaymentSlice = createSlice({
  name: "embeddedPayment",
  initialState: {
    LabsMerchantList: [],
    merchantCode: "",
    LabsBankList: {},
    updatedPaymentModes: {}
  },
  reducers: {
    setLabsMerchantList: (state, action) => {
        state.LabsMerchantList = action.payload;
    },
    setMerchantCode: (state, action) => {
      state.merchantCode = action.payload;
    },
    setLabsBankList: (state, action) => {
      state.LabsBankList = { ...action.payload };
    },
    setUpdatedPaymentModes: (state, action) => {
        state.updatedPaymentModes = { ...action.payload };
    }
  },
});

export const {
    setLabsMerchantList,
    setMerchantCode,
    setLabsBankList,
    setUpdatedPaymentModes
} = embeddedPaymentSlice.actions;

export default embeddedPaymentSlice.reducer;

