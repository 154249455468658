import { useDispatch, useSelector } from "react-redux";
import BaseApi from "../api/BaseApi";
import { setPaginationLimit, setPaginationPage } from "../redux/slice/paginationSlice";
import { paramConstants } from "../utils/const";
import { generateUuidValue } from "../utils/utilFunctions";

export function useTransactionDetails(showLoader, getDetailsData, setErrorText, setOpenErrorModal) {
    const dispatch = useDispatch();

    const getTransactionDetails = async (url, row, merchantCode) => {
        showLoader(true);
        try {
            const setParams = {
                transactionId: generateUuidValue(),
                referenceNumber: generateUuidValue(),
                merchantCode: merchantCode,
                customerId: row.customerId,
            };

            const response = await BaseApi("get", url, "", setParams, {});
            if (response?.data && response?.data?.code === 200) {
                getDetailsData(response.data);
            }
        } catch (e) {
            if (parseInt(e.response?.data?.code) === 401) {
                window.location.href = "/session-expired";
            }
            setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
            setOpenErrorModal(true);
        }
        showLoader(false);
    };
    return { getTransactionDetails };
}
