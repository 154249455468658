import { createSlice } from "@reduxjs/toolkit";

export const languageSlice = createSlice({
  name: "languageData",
  initialState: {
    currentLanguage: "it",
  },
  reducers: {
    setCurrentLanguage: (state, action) => {
      state.currentLanguage = action.payload;
    },
  },
});

export const { setCurrentLanguage } = languageSlice.actions;
export default languageSlice.reducer;
