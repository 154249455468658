import { useTheme } from "@emotion/react";
import { Box, MenuItem, Select } from "@mui/material";
import React from "react";

const SelectWithPreIcon = ({
    value,
    list,
    listIdKey="code",
    listValueKey="name",
    placeholder="Choose Item",
    displayEmpty=true,
    fullWidth=false,
    iconElm=null,

    onChange=() => {},
    ...props
}) => {
    const theme = useTheme()

    const emptyRender = <div style={{color: theme.palette.text.textEight}}>{placeholder}</div>
    const isEmptyValue = ['', null].includes(value)
    const renderValue = (value) => {
        return <Box sx={{ display: "flex", gap: 1, padding: "0.5rem" }}>
            { iconElm }
            {!isEmptyValue ? <div>{value}</div> : emptyRender}
        </Box>
    }
    const namedValue = list.find(item => item.code === value)?.name || value
    const selectProps = {
        fullWidth, 
        displayEmpty,
        value: namedValue,
        ...props,

        // function
        onChange,
        renderValue
    }
    return (
        <Select {...selectProps}>
            {
                list.map(item => (
                    <MenuItem key={item[listIdKey]} value={item[listIdKey]}>{item[listValueKey]}</MenuItem>
                ))
            }
        </Select>
    );
}

export default SelectWithPreIcon;