import { FormControl, Select, styled } from "@mui/material";

export const SelectMain = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== "marginbottom",
})(({ theme, marginbottom, height, width, width_, disabledselect }) => ({
  width: width ? width : 322.32,
  marginBottom: marginbottom ? marginbottom : 0,
  display: "flex",
  "& .MuiInputBase-root": {
    height: height ? height : 45,
    width: width_ ? width_ : "inherit",
    padding: "0 0 0 12px",
    backgroundColor: theme.palette.neutral.white,
    borderRadius: 8,
    "&.Mui-focused": {
      border: `1px solid ${theme.palette.border.focus}`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.border.focus}`,
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: disabledselect === "true" ? `1px solid ${theme.palette.border.main}` : `1px solid ${theme.palette.border.focus}`,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.border.main}`,
      "&.Mui-focused": {
        border: `1px solid ${theme.palette.border.focus}`,
      },
    },
  },
  "& .MuiFormControl-root": {
    width: width_ ? width_ : "inherit",
    "& .MuiSelect-select": {
      height: "1rem",
    },
    "& .MuiInputBase-input": {
      "& div": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    }
  }
}));

export const ResizableSelect = styled(Select)({
  flex: "1 1 auto",
});
