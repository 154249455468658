import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login/Login";
import ClientAccount from "./pages/clientAccount/ClientAccount";
import VcClientAccount from "./pages/vcClientAccount/VcClientAccount";
import VcAyoLimit from "./pages/vcAyoLimit/VcAyoLimit";
import VirtualCard from "./pages/virtualCard/VirtualCard";
import SetUpPassword from "./pages/setupPassword/SetUpPassword";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import SuccessMessage from "./components/cards/SuccessMessage";
import PriceConfiguration from "./pages/priceConfiguration/PriceConfiguration";
import Settlement from "./pages/Settlement/Settlement";
import EscrowWrapper from "./pages/escrowAccount/EscrowWrapper";
import EmbeddedPayments from "./pages/EmbeddedPayments/EmbeddedPayments";
import { AuthRequiredRoute } from "./router/AuthRequiredRoute";
import SessionExpired from "./pages/sessionExpired/sessionExpired";
import VcFeature from "./pages/vcClientAccount/VcFeature";
import VcClientSetting from "./pages/vcClientSetting/VcClientSetting";
import TransferHistory from './pages/transferHistory/TransferHistory';
import SupplierManagement from "./pages/supplierManagement/supplierManagement";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="password-setup-success" element={<SuccessMessage />} />
            <Route path="setup-password" element={<SetUpPassword />} />
            <Route path="reset-password" element={<SetUpPassword />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="session-expired" element={<SessionExpired />} />
            <Route path="transfer-history" element={<AuthRequiredRoute><TransferHistory /></AuthRequiredRoute>} />
            <Route path="supplier-management" element={<AuthRequiredRoute><SupplierManagement /></AuthRequiredRoute>} />
            {/* <Route path="/escrow-account" element={<AuthRequiredRoute><EscrowWrapper /></AuthRequiredRoute>}/> */}
            <Route path="/client-balance" element={<AuthRequiredRoute><ClientAccount /></AuthRequiredRoute>} />
            <Route path="/virtual-card" element={<AuthRequiredRoute><VirtualCard /></AuthRequiredRoute>} />
            <Route path="/virtual-card/ayo-limit" element={<AuthRequiredRoute><VcAyoLimit /></AuthRequiredRoute>} />
            <Route path="/virtual-card/client" element={<AuthRequiredRoute><VcClientAccount /></AuthRequiredRoute>} />
            <Route path="/virtual-card/setting" element={<AuthRequiredRoute><VcClientSetting /></AuthRequiredRoute>} />
            <Route path="/virtual-card/feat" element={<AuthRequiredRoute><VcFeature /></AuthRequiredRoute>} />
            {/* <Route path="/embedded-payments" element={<AuthRequiredRoute><EmbeddedPayments /></AuthRequiredRoute>}/> */}
            <Route path="/price-config" element={<AuthRequiredRoute><PriceConfiguration /></AuthRequiredRoute>} />
            <Route path="/settlement" element={<AuthRequiredRoute><Settlement /></AuthRequiredRoute>} />

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
