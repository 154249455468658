import { styled, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export const StyledInputlabel = styled("div", {
  shouldForwardProp: (prop) => prop !== "error",
})(({ theme, labelfontweight, labelcolor, labelmarginbottom, fontsize_, lineheight_, whitespace }) => ({
  fontSize: fontsize_ ? fontsize_ : theme.typography.fontSize.m1,
  fontWeight: labelfontweight ? labelfontweight : 700,
  lineHeight: lineheight_ ? lineheight_ : "8px",
  marginBottom: labelmarginbottom ? labelmarginbottom : 2,
  color: labelcolor ? labelcolor : theme.palette.text.textFour,
  fontFamily: theme.typography.fontFamily.inter,
  whiteSpace: whitespace ? whitespace : "nowrap",
}));

export const StyledBaseTextField = styled(TextField, {
})(
  ({
    theme,
    lineheight,
    simpletextfield,
    inputpadding,
    inputmargin,
    fontWeight,
    textfieldmargin,
    hoverborder,
    normalborder,
    fontsize_,
    height,
    width_,
    textColor,
  }) => ({
    margin: textfieldmargin ? textfieldmargin : "10px 0px 24px 0px",
    "&.MuiTextField-root": {
      width: width_ ? width_ : "100%",
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.neutral.white,
        height: height ? height : "56px",
        borderRadius: 8,
        border: simpletextfield ? "none" : normalborder,
        "&::before": {
          borderBottom: simpletextfield ? `1px solid ${theme.palette.border.main}` : "none",
        },
        "&:hover": {
          border: simpletextfield ? "none" : hoverborder,
          "&::before": {
            borderBottom: simpletextfield ? `1px solid ${theme.palette.border.main}` : "none",
          },
        },
        "&::after": {
          borderBottom: simpletextfield ? `1px solid ${theme.palette.border.main}` : "none",
          "&:hover": {
            border: `1px solid ${theme.palette.border.focus}`,
          },
        },
        "&.Mui-focused": {
          border: simpletextfield ? "none" : hoverborder,
        },
        "& input": {
          padding: inputpadding ? inputpadding : "16.5px 16px",
          fontWeight: fontWeight ? fontWeight : theme.typography.fontWeight.regular,
          lineHeight: lineheight ? lineheight : 23.44,
          color: textColor ? textColor : theme.palette.text.body,
          margin: inputmargin ? inputmargin : 0,
          fontSize: fontsize_ ? fontsize_ : 18,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  })
);

export const StyledDatePicker = styled(DesktopDatePicker)(({ theme, disabled, textfieldmargin }) => ({
  margin: textfieldmargin ? textfieldmargin : "10px 0px 24px 0px",
  "& .MuiInputBase-root": {
    height: "47px",
    "& .MuiInputAdornment-root": {
      "& .MuiButtonBase-root": {
        "& .MuiSvgIcon-root": {
          width: "40px",
          height: "20px",
          color: disabled ? theme.palette.text.textEight : theme.palette.primary.main,
        },
      },
    },
  },
}));

export const StyledInputLabel = styled(Typography)(({ theme}) => ({
  fontWeight: 500,
  marginBottom: "5px"
}))
