import { createSlice } from "@reduxjs/toolkit";

export const drawerSlice = createSlice({
  name: "drawer",
  initialState: {
    open: false,
  },
  reducers: {
    setDrawerState: (state) => {
      state.open = !state.open;
    },
  },
});

export const { setDrawerState } = drawerSlice.actions;
export default drawerSlice.reducer;
