import { useTheme } from '@mui/material'
import { Components } from '../../../components'
import { StyledNoDataFound, StyledTableBody, StyledTableRow, StyledTableRowCell } from '../../../components/table/PrimaryTable.styles';
import { StyledCell, LoaderWrapper } from '../../../globalStyles/global.styles'
//import { transactionTypes } from '../../../utils/const';
import React, { useState, useEffect } from 'react';
import { STATIC_ASSETS } from '../../../utils/staticAssets';
import { setCurrentLimit, setPaginationLimit, setPaginationPage, setShowData } from '../../../redux/slice/paginationSlice';
import { getSplitDateTime, formatAmount } from '../../../utils/utilFunctions';
import { useTransactions } from '../../../customHook/useTransactions';
import { useDispatch, useSelector } from "react-redux";
import LocalizedStrings from "react-localization";
import LocalData from "../../../utils/localization";
import { setIsSubmitClicked } from '../../../redux/slice/clientAccountSlice';
import { useDownloadData } from '../../../customHook/useDownloadData';

const TopupWithdrawalTable = ({ openWithdrawal, openTopUp }) => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const theme = useTheme();
    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [loader, showLoader] = useState(false);
    const { showFrom, showTo, limit } = useSelector(state => state.paginationData)
    const { merchantCode, isSubmitClicked } = useSelector(state => state.clientAccountData)
    const updatedTransactionTypes = ["TopUp", "Withdrawal"]
    const getPageCount = (pageNo, data) => {
        const dataLength = data?.length;
        const currentPage = pageNo - 1;
        const startCount = currentPage * limit + 1;
        if (pageNo === 1) {
            if (dataLength) {
                dispatch(setShowData({ showFrom: 1, showTo: dataLength }));

            } else {
                dispatch(setShowData({ showFrom: 0, showTo: 0 }));
            }
        } else if (pageNo > 1) {
            dispatch(
                setShowData({
                    showFrom: startCount,
                    showTo: startCount - 1 + dataLength,
                })
            );
        }
    };

    const TableHeaders = [
        strings["DateAndTime"],
        strings["TransactionId"],
        strings["TransactionType"],
        strings["EmailId"],
        strings["CreditDebit"],
        strings["Amount"],
        strings["Description"],
    ]
    const creditDebit = [strings.Credit, strings.Debit]
    const { getTransactionList } = useTransactions("/of-admin-dashboard/web/v1/merchant/transactions", showLoader, setList, setTotalCount, setErrorText, setOpenErrorModal, getPageCount);
    const { clientAccountdownloadList } = useDownloadData("/of-admin-dashboard/web/v1/merchant/transaction/file", setErrorText, setOpenErrorModal)

    useEffect(() => {
        return () => {
            dispatch(setPaginationLimit(50))
            dispatch(setPaginationPage(1))
        };
    }, [])

    useEffect(() => {
        if (isSubmitClicked || openTopUp || openWithdrawal) {
            getTransactionList(50, 1)
            dispatch(setCurrentLimit(50))
            dispatch(setIsSubmitClicked(false))
        }
    }, [isSubmitClicked, openTopUp, openWithdrawal])


    return (
        <>
            <Components.HeadRow
                getList={getTransactionList}
                tableName={strings.TransactionDetails}
                handleDownload={clientAccountdownloadList}
                merchantCode={merchantCode}
                downloadBtnRequired={'true'}
            />
            {loader && (
                <LoaderWrapper data-testid="table-loader-image"><img src={STATIC_ASSETS.LOADER} alt={'loader'} /></LoaderWrapper>
            )}
            {!loader && (
                <Components.PrimaryTable headers={TableHeaders} listlength={list?.length}>
                    <StyledTableBody style={{ backgroundColor: 'black' }}>
                        {list?.length > 0 ? (
                            list.map((row, index) => {
                                return (
                                    <StyledTableRow key={index}>
                                        <StyledTableRowCell align="left">
                                            <StyledCell
                                                marginBottom="0.25rem"
                                                color={theme.palette.text.body}
                                                fontSize={theme.typography.fontSize.m1}
                                            >{getSplitDateTime(row?.transactionTime, "date")}</StyledCell>
                                            <StyledCell
                                                color={theme.palette.text.textSix}
                                                fontSize={theme.typography.fontSize.s}
                                            >{getSplitDateTime(row?.transactionTime, "time")}</StyledCell>
                                        </StyledTableRowCell>
                                        <StyledTableRowCell
                                            color={theme.palette.primary.main}
                                            align="left"
                                        >
                                            {row?.transactionId}
                                        </StyledTableRowCell>
                                        <StyledTableRowCell align="left">
                                            {updatedTransactionTypes[row?.type - 1]}
                                        </StyledTableRowCell>
                                        <StyledTableRowCell align="left">
                                            {row?.userEmail}
                                        </StyledTableRowCell>
                                        <StyledTableRowCell
                                            color={row?.type === 1 ? theme.palette.green.main : theme.palette.red.main}
                                            align="left"
                                        >
                                            {creditDebit[row?.type - 1]}
                                        </StyledTableRowCell>
                                        <StyledTableRowCell align="left">
                                            {`Rp ${formatAmount(row?.amount, true)}`}
                                        </StyledTableRowCell>
                                        <StyledTableRowCell align="left">
                                            {row?.remark}
                                        </StyledTableRowCell>
                                    </StyledTableRow>
                                )
                            })
                        ) : (
                            <StyledTableRow>
                                <StyledTableRowCell colSpan={12}>
                                    <StyledNoDataFound>No Transactions</StyledNoDataFound>
                                </StyledTableRowCell>
                            </StyledTableRow>
                        )}
                    </StyledTableBody>
                </Components.PrimaryTable>
            )}
            <Components.BasePopup setOpen={setOpenErrorModal} show_dialog={openErrorModal}>
                <Components.ErrorHandlerModal errorText={errorText} setClose={setOpenErrorModal} />
            </Components.BasePopup>
            <Components.Pagination
                totalCount={totalCount}
                showTo={showTo} showFrom={showFrom}
                getList={getTransactionList}
            /></>
    )
}

export default TopupWithdrawalTable