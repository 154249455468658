import React, { useEffect, useState } from 'react'
import LocalizedStrings from 'react-localization'
import { useDispatch, useSelector } from 'react-redux'
import { useSettlementFileUpload } from '../../customHook/useSettlementFileUpload'
import { setFileAddedBoolean, setSizeErrorBoolean, setTypeErrorBoolean } from '../../redux/slice/dragAndDropSlice'
import LocalData from '../../utils/localization'
import { STATIC_ASSETS } from '../../utils/staticAssets'
import { checkFileTypes } from '../../utils/utilFunctions'
import { DragAndDropWrapper, ImgAndHeader, MainUploadImg, StyledHeader, StyledSubHeader } from './dragAndDrop.styles'
import SelectFileComp from './SelectFileComp'
import UploadFile from './UploadFile'

const DragAndDrop = (props) => {
    const { getListApi, setOpenErrorModal, setErrorText } = props
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const [settlementFile, setSettlementFile] = useState(null);
    const fileTypes = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
    const dispatch = useDispatch()
    const { fileAdded } = useSelector(state => state.dragAndDropData)

    const { uploadSettleFileHandler } = useSettlementFileUpload(getListApi, setSettlementFile, setOpenErrorModal, setErrorText)
    function overrideEventDefaults(event) {
        event.preventDefault();
        event.stopPropagation();
    };

    function handleOnDragOver(e) {
        overrideEventDefaults(e)
    }

    function handleOnDragEnter(e) {
        overrideEventDefaults(e)
    }
    function handleOnDragLeave(e) {
        overrideEventDefaults(e)
    }
    function handleOnDrop(e) {
        overrideEventDefaults(e)
        if (!e.dataTransfer) return
        handleFiles(e.dataTransfer.files[0])
    }

    function handleFiles(file) {
        if (file) {
            dispatch(setFileAddedBoolean(true))
            if (!checkFileTypes(file.type, fileTypes)) {
                dispatch(setTypeErrorBoolean(true))
            } else if (file.size / 1024 > 5 * 1024) {
                dispatch(setSizeErrorBoolean(true))
            } else {
                dispatch(setTypeErrorBoolean(false))
                dispatch(setSizeErrorBoolean(false))

                setSettlementFile(file)
            }
        }
    }

    function handleUpload() {
        uploadSettleFileHandler(settlementFile)
    }

    useEffect(() => {
        return () => {
            setSettlementFile(null)
            dispatch(setFileAddedBoolean(false))
            dispatch(setTypeErrorBoolean(false))
            dispatch(setSizeErrorBoolean(false))
        };
    }, []);

    useEffect(() => {
        if (settlementFile === null) {
            dispatch(setFileAddedBoolean(false))
            dispatch(setTypeErrorBoolean(false))
            dispatch(setSizeErrorBoolean(false))
        }
    }, [settlementFile]);

    return (<>
        {fileAdded ? <UploadFile setFile={setSettlementFile} handleUpload={handleUpload} file={settlementFile} fileTypes={fileTypes} typeErrorMessage="Upload file in either .csv or .xlsx file format only." sizeErrorMessage="Max File Size is 5 MB" />
            : <DragAndDropWrapper onDragOver={handleOnDragOver} onDragEnter={handleOnDragEnter} onDragLeave={handleOnDragLeave} onDrop={handleOnDrop}>
                <MainUploadImg src={STATIC_ASSETS.UPLOAD_ICON} alt="upload_icon" />
                <ImgAndHeader>
                    <img src={STATIC_ASSETS.DRAG_COPY_ICON} alt="drag_copy_icon" />
                    <StyledHeader>Drag and drop CSV or Excel file format here or Select File from your device.</StyledHeader>
                </ImgAndHeader>
                <StyledSubHeader>Max File Size is 5MB And Limit of File Name's character is 35</StyledSubHeader>
                <SelectFileComp fileTypes={fileTypes} setFile={setSettlementFile} />
            </DragAndDropWrapper>
        }
    </>
    )
}

export default DragAndDrop