import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import LocalizedStrings from 'react-localization';
import { useSelector } from 'react-redux';
import { Controls } from '..';
import LocalData from '../../utils/localization';
import { StyledDatePicker, StyledInputlabel } from './BaseTextfield.styles';

const DatePicker = (props) => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const { value, onChange, label, labelcolor, labelfontweight, errorValue, height, disableddatepicker, id, ...others } = props

    return (<>
        {label && (<StyledInputlabel labelfontweight={labelfontweight} labelcolor={labelcolor}>{label} {errorValue && '*'}</StyledInputlabel>)}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledDatePicker
                id={id?id: 'date-picker-textfield' }
                disabled={disableddatepicker !== undefined && disableddatepicker === 'true' ? true : false}
                inputFormat="YYYY-MM-DD"
                value={value}
                onChange={onChange}
                renderInput={(params) => <Controls.BaseTextField idText={id ? id : 'date-picker-textfield'} {...params} disabledtextfield={disableddatepicker} height={height} inputProps={{
                    ...params.inputProps,
                    placeholder: strings.SelectDate
                }} />}
                {...others}
            />
        </LocalizationProvider>
    </>
    )
}

export default DatePicker