import { Autocomplete, Box, styled } from "@mui/material";

export const ResizableAutoComplete = styled(Autocomplete)({
    flex: "1 1 auto",
});

export const AutoCompleteWrapper = styled(Box, {
  })(({ theme, width, disabled }) => ({
    width: width || 322,
    "& .MuiInputBase-root": {
        padding: "6px 12px",
        backgroundColor: theme.palette.neutral.white,
        borderRadius: 8,
        "&.Mui-focused": {
        border: `1px solid ${theme.palette.border.focus}`,
        "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme.palette.border.focus}`,
        },
        },
        "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
            border: disabled === "true" ? `1px solid ${theme.palette.border.main}` : `1px solid ${theme.palette.border.focus}`,
        },
        },
    }
}));