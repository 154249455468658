import React, { useRef, useState, useEffect } from "react";
import { FormHelperText, Grid, Typography } from "@mui/material";
import { StyledOtpInput, StyledOtpModal } from "./modal.styles";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { Controls } from "../../controls";
import { batchUpdateState, formatSecond } from "../../utils/utilFunctions";
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from "uuid";

const OtpModal = ({
    maxRequestOtp=false,
    timerInit=60,
    errorText=null,
    disableField=false,
    otpEmailAddr=null,

    // function
    setClose,
    sendOTP,
    updateSettingWithOTP
}) => {
    let otpTimer = useRef(null)
    const handleClose = (e) => {
        setClose(false)
    }

    const [otpValue, setOtpValue] = useState([]);
    const [state, setState] = useState({
        retryStr: null
    })

    const handleOtpInputFocus = (element) => {
        const { key, target } = element
        const next = target.tabIndex
        if(key === 'Delete' || key === 'Backspace') {
            if(next > 0) {
                target.form[next-1].focus()
            }
        }
        else {
            if(next===0 && target.value.length===6) return 
            if (next < 5) {
                target.form[next+1].focus()
            }
        }
    }

    const handleOtpValue = (e,index) => {
        const value = e.target.value

        if(index===0 && value.length===6) {
            setOtpValue(value.split(''))
            return
        }
        if(value.length > 1) return
        if(!otpValue[index] || value === '') {
            const tempOtp = [...otpValue]
            if(value === '') {
                tempOtp.splice(index, 1, '')
            }
            else
                tempOtp[index] = value
            setOtpValue(tempOtp)
        }
    }

    const intervalTimer = (currentTimer) => {
        batchUpdateState(setState, {
            retryStr: formatSecond(currentTimer)
        })

        otpTimer.current = setInterval(() => {
        if(currentTimer > 0) {
            currentTimer -= 1
            const strSecond = formatSecond(currentTimer)
            batchUpdateState(setState, {
                retryStr: strSecond
            })
        } else {
            clearInterval(otpTimer.current)
            batchUpdateState(setState, {
                retryStr: null
            })
        } // endif
        }, 1000)
    }

    const resendOTP = async () => {
        await sendOTP(true)
        intervalTimer(timerInit)
    }

    const submitSetting = async () => {
        await updateSettingWithOTP(otpValue.join(''))
    }

    useEffect(() => {
        if(! maxRequestOtp) {
          intervalTimer(timerInit)
        } else {
          clearInterval(otpTimer.current)
          batchUpdateState(setState, {
            retryStr: null
        })
        } // endif
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [maxRequestOtp]);

    return (
        <StyledOtpModal container spacing={2} rowGap={2} direction="column" justifyContent="center">
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item className="headerText">OTP Verification</Grid>
                <Grid item sx={{cursor: "pointer"}} onClick={handleClose}><img src={STATIC_ASSETS.CLOSE_ICON} alt="close-icon" /></Grid>
            </Grid>
            <Typography className="contentText">
                <span>Please enter the 6 digit One-Time-Passcode that just sent to your Email ID at</span>
                <br />
                <strong>{otpEmailAddr}</strong>
            </Typography>
            <form>
                <Grid container justifyContent="space-between">
                    {
                        new Array(6).fill(0).map((_, idx) => (
                            <Grid item key={idx}>
                                <StyledOtpInput key={idx}
                                    placeholder={'-'}
                                    type={'number'}
                                    onChange={(e) => handleOtpValue(e,idx)}
                                    value={otpValue[idx] || ''}
                                    inputProps={{
                                        tabIndex:idx,
                                        onKeyUp: e => handleOtpInputFocus(e)                
                                    }}
                                    error={ errorText && (! disableField)}
                                    disabled={ disableField } />
                            </Grid>
                        ))
                    }
                </Grid>

                {
                    errorText && 
                    <FormHelperText sx={{color: "#CB2029"}}>{ errorText }</FormHelperText>
                }
            </form>
            <Grid container justifyContent="center" display={disableField ? "none" : "flex"}>
                <Grid item className="contentText">
                    {
                        state.retryStr ?
                        <>
                            <span style={{marginRight: "0.3rem"}}>Retry in</span>
                            <strong className="blueText">{state.retryStr}</strong>
                        </>
                        : 
                        <strong className="blueLink" onClick={resendOTP} role="button">Resend OTP</strong>
                    }
                </Grid>
            </Grid>

            <Controls.BaseButton text="Submit" bgcolor="#1078CA" onClick={submitSetting} disabled={disableField} />
        </StyledOtpModal>
    );
}

OtpModal.propTypes = {
    maxRequestOtp: PropTypes.bool,
    timerInit: PropTypes.number.isRequired,
    errorText: PropTypes.string,
    disableField: PropTypes.bool,
    otpEmailAddr: PropTypes.string.isRequired,

    // function
    setClose: PropTypes.func,
    sendOTP: PropTypes.func,
    updateSettingWithOTP: PropTypes.func
}
export default OtpModal;