import { Box, styled } from "@mui/material";

export const StyledSupplierHeading = styled(Box)(({ theme }) => ({
    fontWeight: theme.typography.fontWeight.medium,
    fontFamily: theme.typography.fontFamily.inter,
    fontSize: 20,
    lineHeight: "30px",
    color: theme.palette.text.heading,
    margin: "1rem 0"
}));

export const StyledCardsWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "1rem",
}));

export const StyledBanner = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    width: "100%",
    backgroundColor: "#E8F7FC",
    padding: "0.75rem 1rem",
    borderLeft: "4px solid #19A9E1",
    marginBottom: "16px"
}));

