import React, { useEffect, useState } from 'react';
import { Menu, MenuItem, Button, InputAdornment } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import BaseApi from '../../api/BaseApi';
import LocalizedStrings from "react-localization";
import LocalData from '../../utils/localization';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentLanguage } from '../../redux/slice/languageSlice';
import { resetVcFeature } from '../../redux/slice/vcFeatureSlice';
import { STATIC_ASSETS } from '../../utils/staticAssets';
import KEYS from '../../utils/keys';
import callConfig from '../../utils/callConfig'
import { Controls } from "../../controls/index";
import { LoginHeader, LoginMainGrid, StyledForgotPasswordBlock, StyledIconButton, StyledLangMainDiv, StyledLoginForm, StyledLoginGridMain, StyledLoginWrapper, StyledReCaptcha, StyledLoginLeftBlock } from './Login.styled';
import { setBaseurl, setUserData } from '../../redux/slice/authSlice';
import BaseTextField from '../../controls/inputs/BaseTextField';
import { validateEmail, validatePassword } from '../../utils/validation';
import { generateUuidValue } from '../../utils/utilFunctions';
import { useCallback } from 'react';

const Login = () => {
    const history = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [recaptchaValue, setRecaptchaValue] = useState(false);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch()
    const [loginData, setLoginData] = useState({ email: "", password: "", showPassword: false, disable: true });
    const [error, setError] = useState({
        emptyEmail: false,
        invalidEmail: false,
        emailChecked: false,
        emailErrFlag: false,
        emailErrApi: false,
        pwdErrApi: false,
        pwdInvalid: false,
        pwdChecked: false,
        pwdErrFlag: false
    })

    useEffect(() => {
        callConfig()
        dispatch(setBaseurl(localStorage.getItem('API_BASE_URL')));
    }, [dispatch])

    const { currentLanguage } = useSelector((state) => state.languageData)
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage)

    const handlelanguageData = useCallback((value) => {
        dispatch(setCurrentLanguage(value));
        setAnchorEl(null);
    }, [],)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEmail = (value) => {
        setLoginData({ ...loginData, email: value });
        validationEmail(value);

    };

    const handlePassword = (value) => {
        setLoginData({ ...loginData, password: value });
        validationPassword(value);
    };

    const validationEmail = (email) => {
        const { emptyEmail, inValidEmail } = validateEmail(email);
        if (emptyEmail) {
            setError({
                ...error,
                emptyEmail: true,
                invalidEmail: false,
                emailChecked: false,
                emailErrFlag: true,
                emailErrApi: false
            })
        } else if (inValidEmail) {
            setError({
                ...error,
                emptyEmail: false,
                invalidEmail: true,
                emailChecked: false,
                emailErrFlag: true,
                emailErrApi: false
            })
        } else {
            setError({
                ...error,
                emptyEmail: false,
                invalidEmail: false,
                emailChecked: true,
                emailErrFlag: false,
                emailErrApi: false
            })
        }
        return true;
    }
    const validationPassword = (password) => {
        const { emptyPwd } = validatePassword(password);
        if (emptyPwd) {
            setError({
                ...error,
                pwdInvalid: true,
                pwdChecked: false,
                pwdErrFlag: true,
                pwdErrApi: false
            })
        } else {
            setError({
                ...error,
                pwdInvalid: false,
                pwdChecked: true,
                pwdErrFlag: false,
                pwdErrApi: false
            })
        }
        return true
    }

    const handleLogin = async () => {
        if (!disableBtn()) {
            try {
                const response = await BaseApi('post', '/of-admin-users/web/v1/login', "", {}, {
                    transactionId: generateUuidValue(),
                    email: loginData.email,
                    password: loginData.password
                })
                if (response && response.data.code === 201) {
                    dispatch(setUserData(response.data.adminUser))
                    history("/client-balance");
                    localStorage.setItem("API_TOKEN", response.headers.get("jwt-token"))
                    localStorage.setItem("user_id", response.data?.adminUser?.userId)
                    dispatch(resetVcFeature())
                }
            } catch (e) {
                // console.log can be added here to debug
                const errorRes = e.response.data
                if (errorRes.code === 412) {
                    if (errorRes.errors[0].code === "0509") {
                        setError({
                            ...error,
                            emailErrFlag: true,
                            invalidEmail: true
                        })
                    } else if (errorRes.errors[0].code === "0502") {
                        setError({
                            ...error,
                            pwdErrApi: true,
                            pwdErrFlag: true
                        })
                    }
                }
            }
        }
    }

    const handleReCAPTCHA = () => {
        setRecaptchaValue(true);
        return recaptchaValue;
    }

    const languageText = () => {
        if (currentLanguage === "it") {
            return "indonesia";
        }
        return "English";
    }

    const languageSelect = useCallback(() => {
        if (currentLanguage === "it") {
            return <MenuItem id="menu-en" onClick={() => handlelanguageData("en")}>English</MenuItem>
        }
        return <MenuItem id="menu-id" onClick={() => handlelanguageData("it")}>Indonesia</MenuItem>
    }, [currentLanguage],)

    const areaControlled = () => {
        if (open) {
            return 'basic-menu'
        }
    }
    const areaExpand = () => {
        if (open) {
            return true
        }
    }
    const helperTextEmail = () => {
        if (error.invalidEmail) {
            return strings.Email_Incorrect
        } else if (error.emptyEmail) {
            return strings.Email_empty
        }
    }
    const helperTextPassword = () => {
        if (error.pwdInvalid) {
            return strings.Password_empty
        } else if (error.pwdErrApi) {
            return strings.Passoword_Incorrect
        }
    }

    const passwordFieldType = () => {
        if (loginData.showPassword) {
            return 'text'
        }
        return 'password'
    }
    const indormentPasswordIcon = () => {
        if (loginData.password) {
            return <StyledIconButton
                edge="end"
                aria-label="Toggle password visibility"
                onClick={() => setLoginData({ ...loginData, showPassword: !loginData.showPassword })}>
                {
                    loginData.showPassword ? <img src={STATIC_ASSETS.EYE_ON} alt={'eye-on'} /> : <img src={STATIC_ASSETS.EYE_OFF} alt={'eye-off'} />
                }
            </StyledIconButton>
        }
    }

    const disableBtn = () => {
        return (
            error.emailErrFlag === true ||
            error.pwdErrFlag === true ||
            loginData.email === '' ||
            loginData.password === '' ||
            (localStorage.getItem('CAPTCHA') === "true" && recaptchaValue === false )
        )
    }
    return (
        <>
            <LoginMainGrid container>
                <StyledLoginLeftBlock item xs={6} sm={6} >
                    <img src={STATIC_ASSETS.LOGO_WHITE} alt='logo' />
                    <div data-testid="login-text">{strings.Login_Title}</div>
                    <div>{strings.Admin_Portal}</div>
                    <img src={STATIC_ASSETS.LOGIN_PAGE_IMAGE} alt="login-flex" height={275} />
                </StyledLoginLeftBlock>
                <StyledLoginGridMain item xs={6} sm={6}>
                    <StyledLangMainDiv>
                        <Button
                            edge='start'
                            id="basic-button"
                            aria-controls={areaControlled()}
                            aria-haspopup="true"
                            aria-expanded={areaExpand()}
                            onClick={handleClick}
                            startIcon={<img src={STATIC_ASSETS.GLOBE} alt='language' />}
                            endIcon={<img src={STATIC_ASSETS.DOWN_ARROW} alt='down' />}
                        >
                            {languageText()}
                        </Button>

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            {languageSelect()}
                        </Menu>
                    </StyledLangMainDiv>
                    <StyledLoginWrapper>
                        <StyledLoginForm noValidate autoComplete="off" id="demo">
                            <LoginHeader>{strings.Login}</LoginHeader>
                            <BaseTextField
                                idText="email"
                                placeholder={strings.PlaceHolder_Email}
                                errorValue={error.emailErrFlag}
                                helperText={helperTextEmail()}
                                onChange={(e) => handleEmail(e.target.value)}
                                value={loginData.email}
                                type="email"
                                label={strings.Email}
                                data-testid="email-field"
                            />
                            <BaseTextField
                                idText="password"
                                placeholder={strings.PlaceHolder_Password}
                                errorValue={error.pwdErrFlag}
                                helperText={helperTextPassword()}
                                onChange={(e) => handlePassword(e.target.value)}
                                value={loginData.password}
                                type={passwordFieldType()}
                                label={strings.Password_Label}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {indormentPasswordIcon()}
                                        </InputAdornment>
                                    ),
                                }}
                                data-testid="password-field"
                            />

                            {(currentLanguage === "it" ?
                                <StyledReCaptcha
                                    data-testid="recaptcha-field"
                                    sitekey={KEYS.RECAPTCHA.SITEKEY}
                                    onChange={handleReCAPTCHA}
                                    hl="id" /> : ""
                            )}

                            {(currentLanguage === "en" ?
                                <StyledReCaptcha
                                    data-testid="recaptcha-field"
                                    sitekey={KEYS.RECAPTCHA.SITEKEY}
                                    onChange={handleReCAPTCHA}
                                    hl="en" /> : ""
                            )}

                            <Controls.BaseButton
                                id="button-login"
                                variant="contained"
                                height={'50px'}
                                text={strings.Login}
                                onClick={() => handleLogin()}
                                disabled={disableBtn()}
                                data-testid="login-btn"
                            />

                            <StyledForgotPasswordBlock>
                                <Link to='/forgot-password' id="link-forgot">
                                    <img src={STATIC_ASSETS.LOCK} alt="lock" height={20} width={20} />
                                    {strings.Forgot_Password} ?
                                </Link>
                            </StyledForgotPasswordBlock>

                        </StyledLoginForm>
                    </StyledLoginWrapper>
                </StyledLoginGridMain>
            </LoginMainGrid>
        </>
    )
}

export default Login;