import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { BankList, localProductList, transactionTypes } from "./const";

export const generateUuidValue = () => {
  const uuidValue = uuidv4().replaceAll("-", "");
  return uuidValue;
};

export const getSplitDateTime = (mainValue, getValue) =>{
  let data =mainValue.toString().split(" ");
  if(getValue === "date"){
    return data[0];
  }
  if(getValue === "time"){
    return data[1];
  }
}
// getting date and time converted from backend (returning date)
export const getFormattedDate = (dateTime, newFormat=false) => {
  if (dateTime && dateTime !== undefined) {
    let [datePart,] = dateTime.split("T");
    if(newFormat)
      datePart = moment(datePart, 'YYYY-MM-DD').format("DD MMM YYYY");
    return datePart
  }
}

// getting date and time converted from backend (returning time)
export const formattedTime = (dateTime, newFormat=false) => {
  if (dateTime && dateTime !== undefined) {
    let [, time] = dateTime.split("T");
    time = time?.trim();
    time = time?.slice(0, -1);
    if(newFormat)
      time = moment(time, ["hh:mm:ss"]).locale("en").format("hh:mm:ss A");
    return time;
  }
}
export const getDate = (dateValue) => {
  if (dateValue.includes("T") && dateValue.includes("Z")) {
    const date = moment(dateValue).format("DD MMM YYYY");
    return date;
  } else {
    const date = dateValue.slice(0, 11);
    return date;
  }
};

export const getTime = (timeValue) => {
  if (timeValue.includes("T") && timeValue.includes("Z")) {
    const formattedTime = timeValue?.split("T");
    const time = formattedTime[1].split("Z");
    return moment(Date(time)).utcOffset("+0700").format("hh:mm:ss");
  } else {
    const time = timeValue.slice(11);
    return moment(Date(time)).utcOffset("+0700").format("hh:mm:ss");
  }
};

export const timetoString = (time, locale) => {
  const momentObj = moment(time).locale(locale)
  const finalObj = {}

  finalObj.customFormat = (format='') => {
    return momentObj.format(format)
  }
  finalObj.timeDefault = () => {
    return finalObj.customFormat('HH:mm:ss A')
  }
  finalObj.dateDefault = () => {
    return finalObj.customFormat('DD MMM YYYY')
  }

  return finalObj
}

export const chunkArray = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
  }
  return res;
}

export const getOrEmpty = (value, defaultEmpty='-') => {
  return value ?? defaultEmpty
}
// {row.transactionTime && moment(row.transactionTime).utcOffset('+0700').format("hh:mm:ss")}

const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0;
}

export const formatAmount = (amt, nodecimal = false) => {
  if (amt) {
    if (!nodecimal) {
      amt = amt.toString().split(".");
      return amt[0]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return amt?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return "0";
};

export const formatRupiah = (amt, printDecimal=true) => {
  const isNegative = amt < 0
  amt = Math.abs(amt)
  if(! amt) {
    return "Rp 0"
  } // endif

  let resp =  isNegative ? "-Rp " : "Rp "
  resp += formatAmount(amt, false)
  if(printDecimal && isFloat(amt)) {
    amt = amt.toFixed(3)
    const decimalPoint = amt.toString().split(".")[1]
    resp = resp + ',' + decimalPoint
  } // endif
  return resp
};

export const getBankName = (code) => {
  return BankList.find((bank) => bank.code === code)?.name;
};

export const getProductCode = (name) => {
  return localProductList.find((product) => product.name === name)?.code;
};

export const checkFileTypes = (fileType, fileTypes) => {
  return fileTypes.some((type) => {
    return fileType === type;
  });
};

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const getTransactionType = (type) => {
  // return transactionTypes.find((transactionType) => transactionType === type);
};

export const getTwoDigitNumber = (num) =>{
  return num.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
}

export const vcBaseApi = (path) => {
  let baseUrl = localStorage.getItem("API_BASE_URL")
  baseUrl += "/lab-vcn/api/v1" + path
  return baseUrl
}

export const internalBaseApi = (path) => {
  return process.env.NODE_ENV === "production" ? path : ("http://localhost:8787" + path)
}

export const authTokenHeader = () => {
  const token = localStorage.getItem("API_TOKEN")
  return {"authorization": token}
}

export const generateCSV = ({rows=[], filename="data.csv"}) => {
  let csvContent = "data:text/csv;charset=utf-8,";

  rows.forEach(function(rowArray) {
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("id", "downloadTriggerComponent")
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename)
  document.body.appendChild(link)
  link.click()
  document.querySelector("#downloadTriggerComponent").remove()
}

export const batchUpdateState = (setState, newState) => {
  setState(prev => {
    return {
      ...prev,
      ...newState
    }
  })
}

export const getNumberOnly = (str) => {
  return str.replace(/[^[0-9]+/g,'')
}

export const validateEmail = (emailStr) => {
  const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,10}/
  return emailRegex.test(emailStr)
}

const prependZero = (number=1) => {
  if(typeof number != 'number') {
    return ''
  } else {
    return number < 10 ? ('0' + number.toString()) : number.toString()
  }
}

export const formatSecond = (second=70) => {
  const remaining = second % 60
  const minute = Math.floor(second / 60)
  return prependZero(minute) + ':' + prependZero(remaining)
}
