import { Box } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Controls } from '../../controls'
import { setInitialData } from '../../redux/slice/modalSlice'
import { STATIC_ASSETS } from '../../utils/staticAssets'
import { formatAmount } from "../../utils/utilFunctions";
import { AmountGrid, ModalHeader, ModalWrapper, SuccessIconWrapper } from './modal.styles'

const DoneModal = (props) => {
    const { setCurrentModal, amount, modalHeader, AmountSentenceInitial, AmountSentenceEnding, setClose } = props
    const dispatch = useDispatch()

    const handleClose = () => {
        setClose(false)
        dispatch(setInitialData({ amount: '', description: '' }))
        dispatch(setCurrentModal(0))
    }
    return (
        <ModalWrapper data-testid="modal-wrapper" container spacing={2} direction="column" justifyContent={'center'} alignItems={'center'}>
            <SuccessIconWrapper><img src={STATIC_ASSETS.SUCCESS} alt="success-icon" /></SuccessIconWrapper>
            <ModalHeader data-testid="modal-header" marginbottom="14px" center item xs={12}><Box>{modalHeader}</Box></ModalHeader>
            <AmountGrid data-testid="amount-grid" marginbottom="40px" item xs={12}><Box>{AmountSentenceInitial}<span> Rp {formatAmount(amount)} </span>{AmountSentenceEnding}</Box></AmountGrid>
            <Controls.BaseButton data-testid="close-btn" onClick={handleClose} width="301px" fontsize_={Number(14)} height="37px" text="Done" />
        </ModalWrapper>
    )
}

export default DoneModal