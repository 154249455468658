import { useSelector } from "react-redux";

export function useVcFeatures() {
  const vcFeatureRedux = useSelector((state) => state.vcFeatureData)

  function isFeatActive(featName) {
    const foundItem = vcFeatureRedux.find(x => x.name === featName)
    if(foundItem) {
        return foundItem.status === 1
    } // endif
    return false
}

  
  return { isFeatActive };
}
