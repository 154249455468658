import { Controls } from "../../controls";
import { CardPaper, CardWrapper, StyledButtonBlock } from "../../globalStyles/global.styles";
import { ForgotPasswordSubTitle, ForgotPasswordTitle, StyledSuccessMessage } from "./forgotPassword.styled";
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { validateEmail } from "../../utils/validation";
import Timer from "../../customHook/countTimer";
import BaseApi from "../../api/BaseApi";
import { generateUuidValue } from "../../utils/utilFunctions";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { useNavigate } from 'react-router-dom';
import { Components } from "../../components";

const ForgotPassword = () => {
  const [error, setError] = useState({ errorMessage: "", errorFlag: false });
  const [enable, setEnable] = useState(true);
  const [email, setEmail] = useState('');
  const [sendMail, setSendMail] = useState(false)
  const navigate = useNavigate();
  const theme = useTheme();

  const { currentLanguage } = useSelector((state) => state.languageData);
  const strings = new LocalizedStrings(LocalData);
  strings.setLanguage(currentLanguage);

  const handleEmailValidation = (value) => {
    setEmail(value)
    const { emptyEmail, inValidEmail } = validateEmail(value)
    if (emptyEmail) {
      setEnable(true)
      return setError({ ...error, errorFlag: true, errorMessage: 'emptyEmail' })
    } else if (inValidEmail) {
      setEnable(true)
      return setError({ ...error, errorFlag: true, errorMessage: 'inValidEmail' })
    } else {
      setError({ ...error, errorFlag: false, errorMessage: '' });
      setEnable(false)
    }
  }
  const handleResetEmail = async () => {
    if (!enable) {
      try {
        await BaseApi('post', '/of-admin-users/web/v1/resetPassword', '', {}, {
          transactionId: generateUuidValue(),
          email: email
        })
        setSendMail(true)
      } catch (e) {
        setError({ ...error, errorFlag: true, errorMessage: e?.response?.data?.errors[0]?.details || "Something went wrong" })
        setSendMail(false);
        setEnable(true)
      }
    } else {
      handleEmailValidation(email)
    }
  }

  const displayCounterText = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 60);
    if (sendMail) {
      return <Timer expiryTimestamp={time} resendLink={handleResetEmail} />
    }
  }

  const handleHelperText = (value) => {
    switch (value) {
      case 'emptyEmail':
        return strings.Email_empty;
      case 'inValidEmail':
        return strings.Email_Incorrect;
      case 'No record found':
        return strings.NoRecordsFound
      default:
        return ""
    }
  }

  const displayHeaderText = () => {
    if (sendMail) {
      return <StyledSuccessMessage>
        <img src={STATIC_ASSETS.CHECK_CIRCLE} alt='check-circle' width={40} height={40} />
        <div >We have sent a link to your email. Please Kindly check it!</div>
      </StyledSuccessMessage>
    }
    return (<>
      <ForgotPasswordTitle>{strings.TroubleLoggingIn}</ForgotPasswordTitle>
      <ForgotPasswordSubTitle>{strings.EnterYourRegisteredEmailAddress}
      </ForgotPasswordSubTitle></>)
  }

  return (<>
    <Components.Appbar />
    <CardPaper>
      <CardWrapper width={700}>
        {displayHeaderText()}
        <Controls.BaseTextField
          idText="email"
          placeholder={strings.PlaceHolder_Email}
          errorValue={error.errorFlag}
          helperText={handleHelperText(error.errorMessage)}
          onChange={(e) => handleEmailValidation(e.target.value)}
          value={email}
          type="email"
          label={strings.Email}
        />
        {displayCounterText()}
        <StyledButtonBlock>
          <Controls.BaseButton
            text={strings.Cancel}
            width={140}
            bgcolor={'white'}
            textcolor={theme.palette.primary.black}
            btnborder={theme.palette.primary.disabled}
            onClick={() => navigate(-1)}
          />
          <Controls.BaseButton
            text={'Send link'}
            width={140}
            disabled={enable}
            onClick={() => handleResetEmail()}
          />
        </StyledButtonBlock>
      </CardWrapper>
    </CardPaper>
  </>)
}
export default ForgotPassword;