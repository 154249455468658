import React, { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { Grid, MenuItem, TextField } from "@mui/material";
import { 
  BtnClear, BtnApply, TopFilterBox, 
  FilterTextField, 
  LabelFilter, DateField, 
  startDateIcon, endDateIcon,
  StyledSelect, StyledFormControl
} from "./filters.styles"
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TransactionKind, TransactionKindList, TransactionStatus, DatePickerFormat } from "../../utils/const";
import { momentDateRange } from "../../utils/timezoneUtil"
import MultipleSelect from "../../controls/selectInput/MultipleSelect";
import { useVcBalance } from "../../customHook/useVcBalance";

const VcClientAccFilterComponent = (props, ref) => {
  const { currentLanguage } = useSelector((state) => state.languageData);
  const strings = new LocalizedStrings(LocalData);
  strings.setLanguage(currentLanguage);

  const {
    merchantCode,
    onApplyFilter
  } = props

  const [state, setState] = useState({
    startDate: null,
    endDate: null,
    openStartDate: false,
    openEndDate: false,
    cardId: null,
    transactionId: null,
    transactionType: TransactionKind.spendRefund,
    transactionStatus: TransactionStatus.success,
    merchantList: [],
    merchantCodes: []
  });

  function updateState(newState) {
    setState(prev => {
      return {
        ...prev,
        ...newState
      }
    })
  } 

  function toggleState(stateKey) {
    setState(prev => {
      return {
        ...prev,
        [stateKey]: !prev[stateKey]
      }
    })
  }

  // if filter used by AyoLimit Page (multiple merchant, then merchantCode will empty)
  function isMultipleMerchant() {
    return !merchantCode
  }

  const filterRef = {
    merchantCodes: useRef(null),
    transactionType: useRef(null)
  }

  function selectAllMerchant(merchantList=[]) {
    if(merchantList.length < 1) {
      merchantList = state.merchantList
    } // endif
    const allMerchant = [...merchantList.map(x => x.code), 'all']

    filterRef.merchantCodes.current.setCurrentValue(allMerchant)
  }

  const updateFieldValue = useCallback((e, trim=true) => {
    const val = trim ? e.target.value.trim() : e.target.value
    const newState = {[e.target.name]: val}
    updateState(newState)
  }, [])
  
  const onClearClick = () => {
    updateState({
      startDate: null,
      endDate: null,
      cardId: "",
      transactionId: "",
      transactionStatus: TransactionStatus.success,
      transactionType: TransactionKind.spendRefund
    })

    if(isMultipleMerchant()) {
      selectAllMerchant()
    }
  }

  const onApplyClick = () => {
    const filterState = {
      startDate: state.startDate,
      endDate: state.endDate,
      cardId: state.cardId,
      transactionId: state.transactionId,
      transactionType: state.transactionType,
      transactionStatus: state.transactionStatus,
      merchantCodes: isMultipleMerchant() ? filterRef.merchantCodes.current.getValue() : [],
    }

    filterState.postBody = {
      filter: {
        // Add merchant code on the post body
        merchant_code: [merchantCode]
      }
    }

    if(isMultipleMerchant()) {
      if(filterState.merchantCodes.length > 0) {
        filterState.postBody.filter.merchant_code = 
        filterState.merchantCodes
          .filter(item => item !== "all")
          .map(item => item.toUpperCase())
      } else {
        filterState.postBody.filter.merchant_code = ['no-merchant']
      }
    } // endif

    if(filterState.startDate && filterState.endDate) {
      const {
        startDate,
        endDate
      } = momentDateRange(filterState.startDate, filterState.endDate)

      const dateRange = [
        startDate.unix(),
        endDate.unix()
      ]

      const dateColumn = filterState.transactionType === TransactionKind.spendRefund ? 'transaction_created_at' : 'created_at'
      filterState.postBody.range = {}
      filterState.postBody.range[dateColumn] = dateRange
    }

    if(filterState.transactionStatus && filterState.transactionStatus !== TransactionStatus.all) {
      filterState.postBody.filter.status = [filterState.transactionStatus]
    }

    if(filterState.cardId) {
      filterState.postBody.filter.card_uid = [filterState.cardId]
    }

    if(filterState.transactionId) {
      filterState.postBody.filter.transaction_id = [`%${filterState.transactionId}%`]
    }

    onApplyFilter(filterState)
  }

  const { getMerchantList } = useVcBalance({ updateState: null })

  const openStartDate = useCallback(() => {
    toggleState('openStartDate')
  }, [])
  const openEndDate = useCallback(() => {
    toggleState('openEndDate')
  }, [])

  useImperativeHandle(ref, () => ({
    onClearClick,
    onApplyClick
  }));

  useEffect(() => {
    if(isMultipleMerchant()) {
      getMerchantList().then(result => {
        updateState({merchantList: result.data})
        selectAllMerchant(result.data)
      }).catch(e => {
        console.error('Failed to set merchant list', e)
      })
    } // endif
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startEndDateHtml = <>
    <Grid item xs>
      <LabelFilter>{strings.StartDate}</LabelFilter>
      <DateField 
        id="start-date-textfield"
        value={state.startDate}
        onAccept={(newValue) => {
          updateState({startDate: newValue, openEndDate:true})
        }}
        open={state.openStartDate}
        onClose={() => updateState({openStartDate: false})}
        slots={{
          openPickerButton: () => startDateIcon(openStartDate)
        }}
        format={DatePickerFormat}
        maxDate={moment()}
      />
    </Grid>
    <Grid item xs>
      <LabelFilter>{strings.EndDate}</LabelFilter>
      <DateField
        value={state.endDate}
        onAccept={(newValue) => {
          updateState({endDate: newValue})
        }}
        open={state.openEndDate}
        onClose={() => updateState({openEndDate: false})}
        slots={{
          openPickerButton: () => endDateIcon(openEndDate)
        }}
        renderInput={(params) => <TextField {...params} id="my-date-picker" />}
        format={DatePickerFormat}
        minDate={state.startDate}
        maxDate={moment()}
      />
    </Grid>
  </>

  const trxTypeHtml = <Grid item xs>
    <LabelFilter>{strings.TransactionType}</LabelFilter>
    <StyledFormControl fullWidth>
      <StyledSelect
        id="select-trxn-type"
        value={state.transactionType}
        onChange={(e) => updateState({transactionType: e.target.value})}>
        {
          TransactionKindList.map(trxKind => (
            <MenuItem key={trxKind.key} value={trxKind.key}>{trxKind.label}</MenuItem>
          ))
        }
      </StyledSelect>
    </StyledFormControl>
  </Grid>

  const cardIdHtml = <Grid item xs>
    <LabelFilter>{strings.CardId}</LabelFilter>
    <StyledFormControl fullWidth>
      <FilterTextField placeholder="Card ID" 
        name="cardId"
        value={state.cardId}
        onChange={updateFieldValue}
        />
    </StyledFormControl>
  </Grid>

  const trxIdHtml = <Grid item xs>
    <LabelFilter>{strings.TransactionId}</LabelFilter>
    <StyledFormControl fullWidth>
      <FilterTextField placeholder="Transaction ID" 
        name="transactionId"
        value={state.transactionId}
        onChange={updateFieldValue}
        />
    </StyledFormControl>
  </Grid>

  const trxStatusHtml = <Grid item xs>
    <LabelFilter>Status</LabelFilter>
    <StyledFormControl fullWidth>
      <StyledSelect sx={{textTransform: 'capitalize'}}
        id="select-trx-status"
        value={state.transactionStatus}
        onChange={(e) => updateState({transactionStatus: e.target.value})}>
        {
          [
            TransactionStatus.all, 
            TransactionStatus.success,
            TransactionStatus.failed
          ].map(trxStatus => (
            <MenuItem key={trxStatus} value={trxStatus} sx={{textTransform: 'capitalize'}}>{trxStatus}</MenuItem>
          ))
        }
      </StyledSelect>
    </StyledFormControl>
  </Grid>

  const clientNameHtml = <Grid item xs>
    <LabelFilter>{strings.ClientName}</LabelFilter>
    <MultipleSelect id="select-client-name" ref={filterRef.merchantCodes} title="Client" 
      options={state.merchantList.map(merchant => {
        return {
          id: merchant.code,
          label: merchant.name
        }
      })} />
  </Grid>

  const leftContent = (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid container spacing={2} alignItems="flex-end">
        { startEndDateHtml }
        { trxTypeHtml }
        { cardIdHtml }
        { trxIdHtml }
        { trxStatusHtml }
      </Grid>
    </LocalizationProvider>
  )

  const leftContentMultipleMerchant = (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid container spacing={2} alignItems="flex-end">
        { startEndDateHtml }
        { clientNameHtml }
        { trxTypeHtml }
        { trxStatusHtml }
      </Grid>
    </LocalizationProvider>
  )

  const rightContent = (
    <Grid container spacing={2} justifyContent="flex-end" alignItems="flex-end">
      <Grid item>
        <BtnClear id="clr-btn" onClick={onClearClick}>{strings.Clear}</BtnClear>
      </Grid>
      <Grid item>
        <BtnApply id="apply-fltr-btn" onClick={onApplyClick}>{strings.Apply}</BtnApply>
      </Grid>
    </Grid>
  )

  return (
    <TopFilterBox elevation={0}>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={10}>{ isMultipleMerchant() ? leftContentMultipleMerchant : leftContent }</Grid>
        <Grid item xs={2}>{ rightContent }</Grid>
      </Grid>
    </TopFilterBox>
  );
}

const VcClientAccFilter = forwardRef(VcClientAccFilterComponent)
export default VcClientAccFilter;
