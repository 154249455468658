import { ListItemButton, ListItemIcon, } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HtmlTooltip, StyledListItem, StyledListItemText } from "./menubar.styles";
import React from 'react';

function MenuBar(props) {
    const { active, label, icon, to, iconText } = props
    const { open } = useSelector(state => state.drawerData)
    return (
        open ?

            <Link to={to} style={{ textDecoration: "none", height: 'inherit' }}>
                <StyledListItem active={active} disablePadding>
                    <ListItemButton
                        sx={{
                            height: 'inherit',
                            justifyContent: 'initial',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                maxWidth: 16,
                                mr: 3,
                                justifyContent: 'center',
                            }}
                        >

                            {icon ? <img src={icon} alt={`${label}-icon`} /> : <span>{iconText}</span>}
                        </ListItemIcon>
                        <StyledListItemText {...props} primary={label} active={active} />
                    </ListItemButton>
                </StyledListItem>
            </Link> :
            <Link to={to} style={{ textDecoration: "none", height: 'inherit' }}>
                <StyledListItem active={active} disablePadding>
                    <HtmlTooltip interactive="true" title={label} placement="right">
                        <ListItemButton
                            sx={{
                                height: 'inherit',
                                justifyContent: 'initial',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    maxWidth: 16,
                                    mr: 3,
                                    justifyContent: 'center',
                                }}
                            >

                                {icon ? <img src={icon} alt={`${label}-icon`} /> : <span>{iconText}</span>}
                            </ListItemIcon>
                            <StyledListItemText {...props} primary={label} active={active} />
                        </ListItemButton>
                    </HtmlTooltip>
                </StyledListItem>
            </Link>       
    )
}
export default MenuBar;
