import React from 'react';
import { useTheme } from "@mui/material";
import { StyledBaseButton } from "./button.styles";


const BaseButton = (props) => {
  const { borderradius, fontsize_, textcolor, text, onClick, width, height, marginbottom, bgcolor, btnborder, fontweight_, ...others } = props;
  const theme = useTheme()

  const buttoncolor = () => {
    if (others.disabled) {
      return theme.palette.primary.disabled
    }
    return theme.palette.primary.main
  }
  return (
    <StyledBaseButton
      onClick={onClick}
      height={height}
      marginbottom={marginbottom}
      width={width}
      bgcolor={bgcolor}
      btnborder={btnborder}
      textcolor={textcolor}
      fontsize_={fontsize_}
      fontweight_ = {fontweight_}
      {...others}
      buttoncolor={buttoncolor()}
      borderradius={borderradius}
    >
      {text}
    </StyledBaseButton>
  )
};

export default BaseButton;