export const validateEmail = (email) => {
  const userEmail = email;
  const regex = /^([\w.%+-]+)@ayoconnect\.id$/
  let emptyEmail = false;
  let inValidEmail = false;
  if (userEmail === "") {
    emptyEmail = true;
  } else {
    if (!regex.test(userEmail)) {
      inValidEmail = true;
    }
  }
  return { emptyEmail, inValidEmail };
};

export const validatePassword = (password) => {
  let emptyPwd = false;
  if (password === "") {
    emptyPwd = true;
  }
  return { emptyPwd };
};
