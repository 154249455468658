import { Box, styled } from "@mui/material";

export const StyledSelectBoxHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== "drawerstate",
})(({ theme, drawerstate }) => ({
  alignSelf: "baseline",
  margin: `0 0 26px 0`,
  fontWeight: theme.typography.fontWeight.bold,
  fontSize: 20,
  lineHeight: "27.32px",
  color: theme.palette.text.body,
}));
