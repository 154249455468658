import { useState } from "react";
import BaseApi from "../api/BaseApi";
import { generateUuidValue } from "../utils/utilFunctions";

export const useProductList = (setErrorText, setOpenErrorModal) => {
  const [productList, setProductList] = useState([]);

  async function fetchProductList() {
    try {
      const setParams = {
        transactionId: generateUuidValue(),
        referenceNumber: generateUuidValue(),
      };
      const response = await BaseApi("get", "/of-admin-dashboard/web/v1/products", "", setParams);

      if (response?.data && response?.data?.code === 200) {
        setProductList(response?.data?.products);
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
    }
  }
  return { productList, fetchProductList };
};
