import { useDispatch, useSelector } from "react-redux";
import { setPaginationLimit, setPaginationPage } from "../redux/slice/paginationSlice";
import { authTokenHeader, vcBaseApi } from "../utils/utilFunctions";
import { unixTimeToString } from "../utils/timezoneUtil";
import axios from "axios";
import { TransactionKind, VcFilterStorage, VcTopupTrxType } from "../utils/const";
import { v4 as uuidv4 } from "uuid";

export function useVcTransactions(showLoader, setList, setTotalCount, setErrorText, setOpenErrorModal, getPageCount) {
  const dispatch = useDispatch();
  const { limit, page } = useSelector((state) => state.paginationData);

  const getTransactionList = async (currentLimit, currentPage) => {
    showLoader(true);
    try {
      const pagination = {
        page: currentPage || page,
        limit: currentLimit || limit,
      }
      let filterPayload = localStorage.getItem(VcFilterStorage)
      filterPayload = JSON.parse(filterPayload)
      const isTopupTrx = filterPayload?.transactionType === TransactionKind.topup
      const bodyPayload = filterPayload.postBody

      bodyPayload.sort = {
        transaction_updated_at: -1
      }
      bodyPayload.pagination = {
        size: pagination.limit,
        start: pagination.page - 1
      }

      const topupListURL = vcBaseApi("/admin/topups")
      const spendListURL = vcBaseApi("/admin/transactions/spends")
      let apiURL = spendListURL
      if(isTopupTrx) {
        apiURL = topupListURL
        bodyPayload.sort = {
          created_at: -1
        }
      } // endif

      const response = await axios.post(apiURL, bodyPayload, {
        headers: authTokenHeader()
      })

      if (response?.data && response?.data?.code === 200) {
        let result = response?.data?.data ?? []
        result = result.map(row => {
          row.trxTimeObject = unixTimeToString(row?.transactionCreatedAt)
          row.trxUid = uuidv4()
          return row
        })

        setList(result);
        dispatch(setPaginationLimit(response?.data?.pagination?.count));
        dispatch(setPaginationPage(response?.data?.pagination?.page));
        getPageCount(response?.data?.pagination?.page, response?.data?.data);
        setTotalCount(response?.data?.pagination?.totalCount);
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
    }
    showLoader(false);
  };

  const getLastTopupTrxIds = async() => {
    try {
      let filterPayload = localStorage.getItem(VcFilterStorage)
      filterPayload = JSON.parse(filterPayload)
      const bodyPayload = {
        filter: {
          merchant_code: filterPayload.postBody?.filter?.merchant_code,
          transaction_type: [ VcTopupTrxType.topup.id ]
        },
        pagination: {
          size: 2,
          start: 0
        },
        sort: {
          created_at: -1
        }
      }

      const topupListURL = vcBaseApi("/admin/topups?ref=lastTrxId")
      let response = await axios.post(topupListURL, bodyPayload, {
        headers: authTokenHeader()
      })
      response =  response?.data?.data
      return response.map(item => {
        return {
          transactionID: item.transactionID,
          transactionCreatedAt: item.transactionCreatedAt
        }
      })
    } catch (e) {
      return []
    }
  }
  
  return { getTransactionList, getLastTopupTrxIds };
}
