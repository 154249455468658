import { useSelector } from "react-redux";
import BaseApi from "../api/BaseApi";
import { paramConstants } from "../utils/const";
import { generateUuidValue } from "../utils/utilFunctions";

export function useDownloadData(url, setErrorText, setOpenErrorModal, setDownloadLoader=undefined, tempMerchantCode=null, activeParams=null) {
  const { currentLimit, page, limit } = useSelector((state) => state.paginationData);
  const { beneficiaryBankName, transactionType, startDate, endDate, customerPhone, merchantCode, partnerReferenceNumber } = useSelector(
    (state) => state.clientAccountData
  );

  const escrowAccountdownloadList = async () => {
    try {
      const sendParams = {
        transactionId: generateUuidValue(),
        referenceNumber: generateUuidValue(),
        page: page,
        limit: currentLimit,
        bankCode: paramConstants.bankCode,
        productCode: paramConstants.productCode,
      };
      const response = await BaseApi("get", url, "", sendParams, {});
      if (response?.data && response?.data?.code === 200) {
        const url = response.data.data.url;
        window.location.assign(url);
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
    }
  };

  const clientAccountdownloadList = async () => {
    try {
      const setParams = {
        transactionId: generateUuidValue(),
        referenceNumber: generateUuidValue(),
        page: page,
        limit: currentLimit,
        merchantCode: merchantCode,
      };
      const response = await BaseApi("get", url, "", setParams, {});
      if (response?.data && response?.data?.code === 200) {
        const url = response.data.listMerchantTransactionsURL.url;
        window.location.assign(url);
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
    }
  };

  const refundDownloadList = async () => {
    try {
      const setParams = {};
      Object.assign(setParams, {
        transactionId: generateUuidValue(),
        referenceNumber: generateUuidValue(),
        page: page,
        limit: currentLimit || limit,
      });
      if (beneficiaryBankName !== "") {
        Object.assign(setParams, { beneficiaryBankName: beneficiaryBankName });
      }
      if (transactionType !== "") {
        Object.assign(setParams, { transactionType: transactionType });
      }
      if (startDate !== null) {
        Object.assign(setParams, { startDate: startDate });
      }
      if (endDate !== null) {
        Object.assign(setParams, { endDate: endDate });
      }
      if (customerPhone !== "") {
        Object.assign(setParams, { customerPhone: customerPhone });
      }
      if (partnerReferenceNumber !== "") {
        Object.assign(setParams, { partnerReferenceNumber: partnerReferenceNumber });
      }

      const response = await BaseApi("get", url, "", setParams, {});
      if (response?.data && response?.data?.code === 200) {
        window.location.assign(response?.data?.data?.url);
      }
    } catch (e) {
      if (parseInt(e.response?.data?.code) === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
    }
  };

  const transferHistoryDownloadList = async () => {
    try {
      const setParams = {};
      Object.assign(setParams, {
        transactionId: generateUuidValue(),
        referenceNumber: generateUuidValue(),
        page: page,
        limit: currentLimit || limit,
        merchantCode: tempMerchantCode,
        status: 0
      });
      if (activeParams) {
        Object.assign(setParams, Object.fromEntries(Object.entries(activeParams).filter(([_, value]) => value !== null)));
      }
      setDownloadLoader(true);
      const response = await BaseApi("get", url, "", setParams, {});
      if (response?.data && response?.data?.code === 200) {
        window.location.assign(response?.data?.transactionFile?.url);
        setDownloadLoader(false);
      }
    } catch (e) {
      if (e.response?.data?.code === 401) {
        window.location.href = "/session-expired";
      }
      setErrorText(e?.response?.data?.errors[0]?.details || "Something went wrong");
      setOpenErrorModal(true);
      setDownloadLoader(false);
    }
  };
  return { escrowAccountdownloadList, clientAccountdownloadList, refundDownloadList, transferHistoryDownloadList };
}
