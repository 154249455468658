import React from 'react'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Controls } from '../../controls'
import { STATIC_ASSETS } from '../../utils/staticAssets'
import LocalData from "../../utils/localization";
import LocalizedStrings from "react-localization";
import { formatAmount } from "../../utils/utilFunctions";
import { AmountGrid, AreUSureGrid, CloseIconGrid, ModalHeader, ModalTextWrapper, ModalWrapper } from './modal.styles'


const SubmitFormModal = (props) => {
    const { handleRefund, refundPopup, handleTopUp, handleWidthdrawal, mutationType, setCurrentModal, amount, setClose, modalHeader, modalTextAboveButton, AmountSentenceInitial, AmountSentenceEnding } = props

    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const dispatch = useDispatch()

    const handleClose = () => {
        setClose(false)
        dispatch(setCurrentModal(0))
    }

    const handleConfirm = () => {
        if (refundPopup) {
            handleRefund()
        } else {
            if (mutationType === 5) {
                handleTopUp();
            }
            else {
                handleWidthdrawal();
            }
        }
    }

    return (
        <ModalWrapper container spacing={2} direction="column" justifyContent={'center'} alignItems={'center'}>
            <CloseIconGrid data-testid="close-icon" item xs={12} alignItems={'right'}><img src={STATIC_ASSETS.CLOSE_ICON} alt="close-icon" onClick={handleClose} /></CloseIconGrid>
            <ModalHeader data-testid="modal-header" marginbottom="24px" item xs={12} center><Box>{modalHeader}</Box></ModalHeader>
            <AreUSureGrid data-testid="are-you-sure" item xs={12}><Box>{strings.AreYouSure}</Box></AreUSureGrid>
            <AmountGrid data-testid="amount-text" item xs={12}><Box>{AmountSentenceInitial} <span>Rp {formatAmount(amount, true)}</span> {AmountSentenceEnding}</Box></AmountGrid>
            <ModalTextWrapper data-testid="modal-text-wrapper" margin="25px 0 23px 0" item xs={12} center><Box>{modalTextAboveButton}</Box></ModalTextWrapper>
            <Controls.BaseButton data-testid="confirm-btn" id="confirm-btn" onClick={handleConfirm} width="301px" fontsize_={Number(14)} height="37px" text={strings.Confirm}/>
        </ModalWrapper>
    )
}

export default SubmitFormModal