import React from "react";
import Sidebar from "./sidebar/Sidebar";
import Appbar from "./appbar/Appbar";
import Layout from "./layout/Layout";
import PrimaryTable from "./table/PrimaryTable";
import BasePopup from "./popup/BasePopup";
import TopCard from "./cards/TopCard";
import TopBalanceValue from "./cards/TopBalanceValue";
import HeadRow from "./headRow/HeadRow";
import Pagination from "./pagination/Pagination";
import SubmitFormModal from "./modals/SubmitFormModal";
import VCTopupFormModal from "./modals/VCTopupFormModal";
import ConfirmModal from "./modals/ConfirmModal";
import DoneModal from "./modals/DoneModal";
import SuccessModal from "./modals/SuccessModal";
import ErrorHandlerModal from "./modals/ErrorHandlerModal";
import TransactiondetailsModal from "./modals/TransactiondetailsModal";
import ExitEditModal from "./modals/ExitEditModal";
import LogoutModal from "./modals/LogoutModal";
import DetailModal from "./modals/DetailModal";
import HoverTooltip from "./tooltip/Tooltip";
import TableFilter from "./filters/TableFilter";
import PriceConfigSelectorOne from "./filters/PriceConfigSelectorOne";
import PriceConfigSelectorTwo from "./filters/PriceConfigSelectorTwo";
import DragAndDrop from "./dragAndDrop/DragAndDrop";
import SaveHandlerModal from "./modals/SaveModal";
import SupplierSwitchModal from "./modals/SupplierSwitchModal";
import ConfirmSystemMaintainance from './modals/ConfirmSystemMaintainance';
export const Components = {
  PrimaryTable,
  BasePopup,
  TopCard,
  TopBalanceValue,
  HeadRow,
  Pagination,
  Appbar,
  Sidebar,
  Layout,
  SubmitFormModal,
  VCTopupFormModal,
  ConfirmModal,
  DoneModal,
  SuccessModal,
  ErrorHandlerModal,
  ExitEditModal,
  LogoutModal,
  DetailModal,
  HoverTooltip,
  TableFilter,
  PriceConfigSelectorOne,
  PriceConfigSelectorTwo,
  DragAndDrop,
  SaveHandlerModal,
  TransactiondetailsModal,
  SupplierSwitchModal,
  ConfirmSystemMaintainance,
};
