import { useTheme } from "@mui/material";
import React from "react";
import { Controls } from "../../controls";
import { ModalWrapper, StyledBanner, StyledSupplierModalHeading, StyledSupplierModalDesc, StyledBannerLine1, StyledBannerLine2, ModalBtnWrapper } from "./modal.styles";
import LocalData from '../../utils/localization';
import LocalizedStrings from 'react-localization';
import { useSelector } from 'react-redux';
import { formatAmount } from "../../utils/utilFunctions";

const SupplierSwitchModal = ({ setClose, updateStatus, getAllData, setLoader, bCode, isActive, totalBalanceBri, totalBalanceNobu, isSystemMaintainance }) => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const theme = useTheme();

    const handleClose = () => {
        setClose(false);
    };


    const switchSupplierStatus = async () => {
        setLoader(true);
        const updateRes = await updateStatus(bCode, isActive, isSystemMaintainance);
        const param = (bCode === "503") ? 'nobu' : 'bri'
        if (updateRes) {
            setClose(false);
            setTimeout(() => {
                getAllData(param);
            }, 3000)
        }
    }

    const fundsIcon = "https://storage.googleapis.com/dd-ui-static-dev/Admin-dash/SupplierManagement/funds-icon.png";

    return (
        <ModalWrapper width="560px" data-testid="supplier-switch-modal-wrapper" container spacing={2}>
            {(parseInt(isActive) === 1 && isSystemMaintainance) ? (
                <>
                    <StyledSupplierModalHeading>{strings.supplierManagement.enterMaintenanceMode}</StyledSupplierModalHeading>
                    <StyledSupplierModalDesc>{strings.supplierManagement.allSuppliersWillBeDown}</StyledSupplierModalDesc>
                    <ModalBtnWrapper>
                        <Controls.BaseButton data-testid="close-btn" fontweight_="500" onClick={handleClose} width="50%" fontsize_={theme.typography.fontSize.m1} height="40px" text={strings.Cancel} bgcolor={theme.palette.neutral.white} textcolor={theme.palette.text.body} btnborder={`1px solid ${theme.palette.neutral.border}`} />
                        <Controls.BaseButton data-testid="close-btn" onClick={() => { switchSupplierStatus() }} width="50%" fontsize_={theme.typography.fontSize.m1} bgcolor={theme.palette.primary.lightBlue} height="40px" text={strings.supplierManagement.confirmEnter} />
                    </ModalBtnWrapper>
                </>
            ) : (
                <>
                    <StyledSupplierModalHeading>{parseInt(isActive) === 1 ? strings.supplierManagement.deactivate : strings.supplierManagement.reactivate} {bCode === "503" ? "Nobu Bank?" : "Bank BRI?"}</StyledSupplierModalHeading>
                    <StyledSupplierModalDesc>{parseInt(isActive) === 1 ? strings.supplierManagement.switchModalMsg1 : strings.supplierManagement.reactivateMsg1} {bCode === "503" ? "Nobu" : "BRI"} {parseInt(isActive) === 1 ? strings.supplierManagement.switchModalMsg2 : strings.supplierManagement.reactivateMsg2}</StyledSupplierModalDesc>
                    <StyledBanner>
                        <img src={fundsIcon} alt="funds" />
                        <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                            <StyledBannerLine1>{parseInt(isActive) === 1 ? strings.supplierManagement.balanceToTransfer1 : strings.supplierManagement.reactivateTransferMsg1} {bCode === "503" ? "Nobu" : "BRI"} {parseInt(isActive) === 1 ? strings.supplierManagement.balanceToTransfer2 : strings.supplierManagement.reactivateTransferMsg2}: IDR {bCode === "503" ? formatAmount(totalBalanceNobu) : formatAmount(totalBalanceBri)}</StyledBannerLine1>
                            {/* <StyledBannerLine2>{strings.supplierManagement.transferTo} BRI: <span style={{ fontWeight: "400", color: "#555555" }}>IDR 2,150,450,809</span></StyledBannerLine2> */}
                        </div>
                    </StyledBanner>
                    <ModalBtnWrapper>
                        <Controls.BaseButton data-testid="close-btn" fontweight_="500" onClick={handleClose} width="50%" fontsize_={theme.typography.fontSize.m1} height="40px" text={strings.Cancel} bgcolor={theme.palette.neutral.white} textcolor={theme.palette.text.body} btnborder={`1px solid ${theme.palette.neutral.border}`} />
                        <Controls.BaseButton data-testid="close-btn" onClick={() => { switchSupplierStatus() }} width="50%" fontsize_={theme.typography.fontSize.m1} bgcolor={theme.palette.primary.lightBlue} height="40px" text={parseInt(isActive) === 1 ? strings.supplierManagement.deactivate : strings.supplierManagement.reactivate} />
                    </ModalBtnWrapper>
                </>
            )}
        </ModalWrapper>
    );
};

export default SupplierSwitchModal;
