import React from "react";
import { ModalWrapper, SessionEndIconWrapper, SessionExpireBoldText, SessionExpireFirstText, SessionExpireParaText } from "./modal.styles";
import { Controls } from "../../controls";
import { STATIC_ASSETS } from "../../utils/staticAssets";

const SessionExpiredModal = ({strings}) => {

    const handleClose = () => {
        localStorage.clear();
        window.location.href = "/"
    }

    return (
        <ModalWrapper data-testid="modal-wrapper" container spacing={2} direction="column" justifyContent={'center'} alignItems={'center'}>
            <SessionEndIconWrapper><img src={STATIC_ASSETS.SESSION_END_ICON} alt="session-expired" /></SessionEndIconWrapper>
            <SessionExpireFirstText>{strings.session_end_title_1} </SessionExpireFirstText>
            <SessionExpireBoldText>{strings.session_end_title_2}</SessionExpireBoldText>
            <SessionExpireParaText>{strings.session_end_text}</SessionExpireParaText>
            <Controls.BaseButton data-testid="close-btn" onClick={handleClose} width="301px" fontsize_={Number(14)} height="37px" text={strings.session_end_button} />
        </ModalWrapper>
    )
}

export default SessionExpiredModal;
