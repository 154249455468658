import React, { useEffect, useState } from "react";
import { Components } from "../../components/index";
import { DrawerHeader } from "../../components/sidebar/Sidebar.styled";
import { Grid, Box, Typography, Skeleton } from "@mui/material";
import { StyledTransactionHeading } from "./transferHistory.styles.js";
import {
  Filter,
  TopBar,
  TitleWrapper,
  SearchInput,
  CustomPagination as Pagination,
  DateRangePicker as SingleInputDateRangePicker,
  BreadCrumbs,
} from "@ayoconnect/common-ui-components";

import { Controls } from "../../controls";
import LocalizedStrings from "react-localization";
import LocalData from "../../utils/localization";
import { useClientList } from "../../customHook/useClientList";
import { useDownloadData } from "../../customHook/useDownloadData.js";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrentLimit,
  setPaginationLimit,
  setPaginationPage,
  setShowData,
} from "../../redux/slice/paginationSlice";
import { MainBox } from "../../globalStyles/global.styles";
import TransferHistoryTable from "./transferHistoryTables/TransferHistoryTable";
import { useTransferHistory } from "../../customHook/useTransferHistory";
import moment from "moment";

const TransferHistory = () => {
  const { currentLanguage } = useSelector((state) => state.languageData);
  const { limit, page, totalCount } = useSelector(
    (state) => state.paginationData
  );
  const strings = new LocalizedStrings(LocalData);
  strings.setLanguage(currentLanguage);
  const dispatch = useDispatch();
  const pageLimitArray = [10, 25, 50, 100];
  const dateFormat = "YYYY-MM-DD";
  const [tempMerchantCode, setTempMerchantCode] = useState("");
  const [errorText, setErrorText] = useState("");
  const [loader, showLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [disbursementList, setDisbursementList] = useState([]);
  const [merchantName, setMerchantName] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeSearchQuery, setActiveSearchQuery] = useState("");
  const [range, setRange] = useState({ from: null, to: null });
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
    customerId: null,
    beneficiaryName: null,
  });
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [dateError, setDateError] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);

  const getPageCount = (pageNo, data) => {
    const dataLength = data?.length;
    const currentPage = pageNo - 1;
    const startCount = currentPage * limit + 1;
    if (pageNo === 1) {
      if (dataLength) {
        dispatch(setShowData({ showFrom: 1, showTo: dataLength }));
      } else {
        dispatch(setShowData({ showFrom: 0, showTo: 0 }));
      }
    } else if (pageNo > 1) {
      dispatch(
        setShowData({
          showFrom: startCount,
          showTo: startCount - 1 + dataLength,
        })
      );
    }
  };

  const { clientList, fetchClientList } = useClientList(
    setErrorText,
    setOpenErrorModal
  );
  const { getTransactionList } = useTransferHistory(
    "/of-admin-dashboard-bff/web/v1/bi-fast-dashboard-bff/disbursement",
    showLoader,
    setDisbursementList,
    setErrorText,
    setOpenErrorModal,
    getPageCount,
    setIsEmpty
  );
  const { transferHistoryDownloadList } = useDownloadData(
    "/of-admin-dashboard-bff/web/v1/bi-fast-dashboard-bff/disbursement/file/download",
    setErrorText,
    setOpenErrorModal,
    setDownloadLoader,
    tempMerchantCode,
    activeFilterCount ? filter : activeSearchQuery ? {partnerReferenceNumber: activeSearchQuery} : null
  );

  useEffect(() => {
    fetchClientList();
    return () => {
      dispatch(setPaginationLimit(50));
      dispatch(setCurrentLimit(50));
      dispatch(setPaginationPage(1));
      dispatch(setShowData({ showFrom: 0, showTo: 0 }));
    };
  }, []);

  useEffect(() => {
    let filtersSelectedCount = 0;
    if (range.from && range.to) {
      filtersSelectedCount++;
      if(dateError) {
        setDateError("");
      }
      setFilter((prevState) => ({
        ...prevState,
        startDate: moment(range.from).format(dateFormat),
        endDate: moment(range.to).format(dateFormat)
      }));
    }
    if (customerId.trim()) {
      filtersSelectedCount++;
    }
    if (beneficiaryName.trim()) {
      filtersSelectedCount++;
    }

    setFilter((prevState) => ({
      ...prevState,
      customerId: customerId.trim(),
      beneficiaryName: beneficiaryName.trim()
    }));

    setFilterCount(filtersSelectedCount);
  }, [range, beneficiaryName, customerId]);

  const handleSubmitButton = (value) => {
    if (value) {
      setMerchantName(value.label);
      getTransactionList(50, 1, value?.id);
    }
  };

  const handlePageRows = (e) => {
    dispatch(setPaginationLimit(e.target.value));
    dispatch(setCurrentLimit(e.target.value));
    getTransactionList(
      e.target.value,
      1,
      tempMerchantCode,
      searchQuery.trim(),
      filter
    );
  };

  const handlePageClick = (e) => {
    if(e === page) {
      return;
    }
    let direction;
    switch (e) {
      case "next":
        direction = page + 1;
        break;
      case "previous":
        direction = page - 1;
        break;
      default:
        direction = e;
    }
    dispatch(setPaginationPage(direction));
    getTransactionList(
      limit,
      direction,
      tempMerchantCode,
      activeSearchQuery,
      filter
    );
  };

  const clearFilter = () => {
    
    if (activeFilterCount) {
      getTransactionList(limit, 1, tempMerchantCode, null, null);
    }
    setRange({ from: undefined, to: undefined });
    setBeneficiaryName("");
    setCustomerId("");
    setFilter({
      startDate: undefined,
      endDate: undefined,
      customerId: null,
      beneficiaryName: null,
    });
    setFilterCount(0);
    setActiveFilterCount(0);
    setDateError("");
  };

  const handleDownload = () => {
    transferHistoryDownloadList();
  }

  const applyFilter = () => {
    if((range.from && !range.to) || (!range.from && range.to)) {
      setDateError(strings['DateRangeRequired'])
      return;
    } else {
      setDateError("");
    }
    setActiveSearchQuery("");
    setSearchQuery("");
    setActiveFilterCount(filterCount)
    getTransactionList(limit, 1, tempMerchantCode, null , filter);
    setShowFilter((showFilter) => !showFilter);
  };

  const onSearch = () => {
    setActiveSearchQuery(searchQuery.trim());
    setFilter({
      startDate: null,
      endDate: null,
      customerId: null,
      beneficiaryName: null,
    });
    setActiveFilterCount(0);
    getTransactionList(50, 1, tempMerchantCode, searchQuery.trim());
  };

  const onSearchClear = () => {
    if(activeSearchQuery) {
      setActiveSearchQuery("");
      getTransactionList(limit, 1, tempMerchantCode);
    }
    setSearchQuery("");
  };

  return (
    <Components.Layout filteropen={showFilter}>
      <DrawerHeader />
      <MainBox component="main" height={disbursementList.length > 6  ? "100%" : "100vh"}>
        <BreadCrumbs
          items={[
            { text: strings["Home"] },
            { text: strings["InstantTransfer"] },
            { text: strings.TransferHistory.Transactions },
          ]}
        />
        <Grid
          container
          justifyContent="space-between"
          width="100%"
          alignItems={"center"}
          flexDirection="row"
        >
          <StyledTransactionHeading>
            {strings.TransferHistory.Transactions}
          </StyledTransactionHeading>
          <Controls.BaseAutoComplete
            id="select-merchant-combo-box"
            options={clientList.map((x) => {
              return { id: x.code, label: [x.name, x.code].join(" - ") };
            })}
            label={strings.TransferHistory.SelectMerchant}
            placeholder={strings.TransferHistory.SearchMerchant}
            value={tempMerchantCode}
            setValue={setTempMerchantCode}
            onChangeValue={(newValue) => {
              handleSubmitButton(newValue);
            }}
          />
        </Grid>
        {tempMerchantCode && (
          <Box
            sx={{
              padding: 2,
              border: "1px solid #CCCCCC",
              marginTop: 4,
              borderRadius: "12px",
              backgroundColor: "#fff",
            }}
          >
            <TopBar
              id={'search-input-transaction-id'}
              placeholder={strings.TransferHistory.SearchTransactionId}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onSearch={() => onSearch()}
              onFilter={() => setShowFilter(!showFilter)}
              onClear={() => onSearchClear()}
              onDownload={() => handleDownload()}
              downloadLoader={downloadLoader}
              filterCount={activeFilterCount}
              filterText={strings["Filter"]}
              exportText={strings["Export"]}
            />
            <Components.BasePopup
              setOpen={setOpenErrorModal}
              show_dialog={openErrorModal}
            >
              <Components.ErrorHandlerModal
                errorText={errorText}
                setClose={setOpenErrorModal}
                setErrorText={setErrorText}
              />
            </Components.BasePopup>

            {!loader && (
              <TransferHistoryTable
                refreshTable={handleSubmitButton}
                list={disbursementList}
                merchantCode={tempMerchantCode}
                merchantName={merchantName}
              />
            )}
            {loader &&
              Array.from({ length: 16 }).map((e, index) => (
                <Typography variant="h1" key={`${index}`}>
                  <Skeleton />
                </Typography>
              ))}
          </Box>
        )}
        {tempMerchantCode && (
          <Pagination
            totalCount={totalCount}
            showEntries={strings["ShowEntries"]}
            onPageChange={(e) => {
              handlePageClick(e);
            }}
            page={page}
            rowsPerPage={limit}
            handleChangeRowsPerPage={(e) => {
              handlePageRows(e);
            }}
            rowsPerPageOptions={pageLimitArray}
          />
        )}
      </MainBox>
      <Filter
        open={showFilter}
        filterCount={filterCount}
        filterText={strings["Filter"]}
        clearText={strings["Clear"]}
        ctaText={strings["Apply"] + " " + strings["Filter"]}
        onClose={() => setShowFilter(!showFilter)}
        onReset={() => clearFilter()}
        onAction={() => applyFilter()}
      >
        <TitleWrapper heading={strings["DateRange"]}>
          <SingleInputDateRangePicker
            placeholder={strings["SelectDateRange"]}
            range={range}
            setRange={setRange}
            errorMessage={dateError}
          />
        </TitleWrapper>
        <TitleWrapper heading={strings["BenificiaryName2"]}>
          <SearchInput
            id={'search-input-beneficiary-name'}
            placeholder={
              strings["TypeToSearch"] + " " + strings["BenificiaryName2"]
            }
            value={beneficiaryName}
            onChange={(e) => setBeneficiaryName(e.target.value)}
            onSearch={()=>{}}
            onClear={() => setBeneficiaryName("")}
          />
        </TitleWrapper>
        <TitleWrapper heading={strings["CustomerId"]}>
          <SearchInput
            id={'search-input-customer-id'}
            placeholder={strings["TypeToSearch"] + " " + strings["CustomerId"]}
            value={customerId}
            onSearch={()=>{}}
            onChange={(e) => setCustomerId(e.target.value)}
            onClear={() => setCustomerId("")}
          />
        </TitleWrapper>
      </Filter>
    </Components.Layout>
  );
};

export default TransferHistory;
