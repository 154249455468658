import { Box, styled } from "@mui/material";

export const StyledTransactionHeading = styled(Box, {
  shouldForwardProp: (prop) => prop !== "drawerstate",
})(({ theme, drawerstate }) => ({
  fontWeight: theme.typography.fontWeight.bold,
  fontSize: 20,
  lineHeight: "27.32px",
  color: theme.palette.text.body,
}));
