import { Box, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow } from "@mui/material";
import { styled } from "@mui/styles";

export const StyledTableHeader = styled(TableHead)(({ theme, secondarytable}) => ({
  backgroundColor: secondarytable? theme.palette.neutral.lightGrey : theme.palette.primary.main,
}));

export const StyledTableWrapper = styled("div")(({listlength}) => ({
  overflow: "auto",
  height: listlength <= 10 ? "auto" : "35rem",
  marginTop: "32px"
}));

export const StyledTableMain = styled(Table, {
  shouldForwardProp: (prop) => prop !== "children",
})(({ theme }) => ({
  "&.MuiTable-root": {
    overflowX: "auto",
    minWidth: 650,
    whiteSpace: "noWrap",
    borderRight: `1px solid ${theme.palette.border.main}`,
    borderLeft: `1px solid ${theme.palette.border.main}`,
    "& .MuiTableHead-root": {
      borderRadius: "4rem",

      "& .MuiTableRow-root": {
        "&:nth-of-type(1)": {
          borderRadius: "4rem",
        },
      },
    },
    "& .MuiTableRow-head": {
      borderRadius: "4rem",
    },
  },
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  "&.MuiTableBody-root": {
    backgroundColor: theme.palette.neutral.white,
    border: "none",
    borderRadius: 8,
    "& tr:nth-of-type(even)": {
      backgroundColor: theme.palette.neutral.lightBackground,
    },
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme, secondarytable }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: secondarytable ? theme.palette.text.textSix : theme.palette.neutral.white,
    fontFamily: theme.typography.fontFamily.inter,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.m1,
    lineHeight: "16px !important",
    "&:nth-of-type(1)": {
      borderTopLeftRadius: 8,
    },
    "&:last-child": {
      borderTopRightRadius: 8,
    },
  },
}));

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "key",
})(({ theme }) => ({
  "&.MuiTableRow-root": {
    fontSize: theme.typography.fontSize.m1,
    lineHeight: 16,
    backgroundColor: theme.palette.neutral.white,
    "&:last-child": {
      borderBottom: `1px solid ${theme.palette.border.main}`,
    },
  },
}));

export const StyledTableRowCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "colSpan" && prop !== "color",
})(({ theme, color, borderBottom, fixedwidth }) => ({
  "&.MuiTableCell-root": {
    color: color ? color : theme.palette.text.heading,
    fontFamily: theme.typography.fontFamily.inter,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.m1,
    lineHeight: "16px",
    borderBottom: borderBottom ?  `1px solid ${theme.palette.neutral.border}` : "none",
    align: "left",
    width: fixedwidth ? fixedwidth : ""
  },
}));

export const StyledNoDataFound = styled(Box)(({ theme }) => ({
  textAlign: "center",
  width: "100%",
  textTransform: "uppercase",
}));

export const EmptyTableBackground = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "140px 0px",
  "& img": {
    width: "257.2px",
  },
  "& div": {
    marginTop: "16px",
    color: theme.palette.text.textSix,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: 14,
  },
}));
