import { Grid, useTheme, InputAdornment } from '@mui/material';
import React, { useCallback, useEffect } from 'react'
import LocalizedStrings from 'react-localization';
import { useDispatch, useSelector } from 'react-redux';
import { Controls } from '../../controls';
import { useAddEditPricingConfiguration } from '../../customHook/useAddEditPricingConfiguration';
import { MainPageTopSection } from '../../globalStyles/global.styles';
import { setAyoconnectFees, setAyoconnectFeesType, setBankFees, setBankFeesType, setClientFees, setClientFeesType, setEditableFlag, setPriceConfigSelectorTwoToInitial } from '../../redux/slice/PriceConfigSlice';
import { AyoconnectFeesType, BankFeesType, ClientFeesType } from '../../utils/const';
import LocalData from '../../utils/localization';
import { FilterGridItem, FilterRowWrapper, TopSectionLabel } from './filters.styles';

const PriceConfigSelectorTwo = ({ setErrorText, setOpenErrorModal, getConfigList }) => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);

    const dispatch = useDispatch()
    const theme = useTheme()

    const { bankFeesType, bankFees, ayoconnectFeesType, ayoconnectFees, clientFeesType, clientFees, configOneFilter } = useSelector(state => state.priceConfigData)

    const onClearHandler = useCallback(() => {
        dispatch(setPriceConfigSelectorTwoToInitial({
            bankFeesType: '',
            bankFees: '',
            ayoconnectFeesType: '',
            ayoconnectFees: '',
            clientFeesType: '',
            clientFees: '',
        }))
    }, [],)

    const disabledButton = () => {
        return (
            bankFeesType === '' ||
            bankFees === '' ||
            ayoconnectFeesType === '' ||
            ayoconnectFees === '' ||
            clientFeesType === '' ||
            clientFees === '' ||
            configOneFilter === false)
    }

    const { addEditPriceConfig } = useAddEditPricingConfiguration(setErrorText, setOpenErrorModal, getConfigList)

    const onSubmitHandler = useCallback(() => {
        if (!disabledButton()) {
            addEditPriceConfig()
        }
        dispatch(setEditableFlag(false))
    }, [addEditPriceConfig],)

    const disabledColor = () => {
        if (configOneFilter) {
            return theme.palette.text.body
        }
        return theme.palette.text.textEight
    }
    const onChangeBankFeesType = useCallback((e) => {
        dispatch(setBankFeesType(e.target.value))
    }, [],)

    const onChangeBankFees = useCallback((e) => {
        const value = e.target.value
        if (value >= 0 && value <= 100) {
            dispatch(setBankFees(value))
        } else {
            dispatch(setBankFees(""))
        }
    }, [],)

    const onChangeAyoconnectFeesType = useCallback((e) => {
        dispatch(setAyoconnectFeesType(e.target.value))
    }, [],)

    const onChangeAyoconnectFees = useCallback((e) => {
        const value = e.target.value
        if (value >= 0 && value <= 100) {
            dispatch(setAyoconnectFees(value))
        } else {
            dispatch(setAyoconnectFees(""))
        }
    }, [],)

    const onChangeClientFeesType = useCallback((e) => {
        dispatch(setClientFeesType(e.target.value))
    }, [],)

    const onChangeClientFees = useCallback((e) => {
        const value = e.target.value
        if (value >= 0 && value <= 100) {
            dispatch(setClientFees(value))
        } else {
            dispatch(setClientFees(""))
        }
    }, [],)

    useEffect(() => {
        if (!configOneFilter) {
            dispatch(setPriceConfigSelectorTwoToInitial({
                bankFeesType: '',
                bankFees: '',
                ayoconnectFeesType: '',
                ayoconnectFees: '',
                clientFeesType: '',
                clientFees: '',
            }))
        }
    }, [configOneFilter, dispatch]);

    return (
        <MainPageTopSection flexdirection="column" alignitems="flex-start">
            <TopSectionLabel color={disabledColor()}>{strings.MDRDetails}</TopSectionLabel>
            <Grid container item flexDirection={"column"} flexWrap={"nowrap"}>
                <Grid container item flexDirection={"row"} flexWrap={"nowrap"}>
                    <FilterRowWrapper container item spacing={3} flexDirection={"column"} margin="26px 1rem 0px 0px">
                        <FilterGridItem item padding="0px">
                            <Controls.BaseSelectInput placeholderText={strings.SelectBankFeesType} label={strings.BankFeesType} value={bankFeesType} onChange={onChangeBankFeesType} disabledselect={configOneFilter ? 'false' : 'true'} values={BankFeesType} labelfontweight={theme.typography.fontWeight.medium} labelcolor={disabledColor()} labelmarginbottom="12px" height="40px" width="100%" inputpadding="0.5px 16px" marginbottom="24px" />
                        </FilterGridItem>
                    </FilterRowWrapper>
                    <FilterRowWrapper container item spacing={3} flexDirection={"column"} margin="26px 1rem 0px 0px">
                        <FilterGridItem item>
                            <Controls.BaseTextField value={bankFees}
                                InputProps={{
                                    startAdornment: bankFeesType === "1" && (
                                        <InputAdornment position="end">
                                            <div>Rp</div>
                                        </InputAdornment>
                                    ),
                                    endAdornment: bankFeesType === "2" && (
                                        <InputAdornment position='end'>%</InputAdornment>
                                    )
                                }}
                                type="number"
                                onChange={onChangeBankFees}
                                disabledtextfield={configOneFilter ? 'false' : 'true'} height="40px" label={strings.BankFees} labelfontweight={theme.typography.fontWeight.medium} labelcolor={disabledColor()} placeholder={strings.EnterBankFees} inputpadding="0.5px 16px" />
                        </FilterGridItem>
                    </FilterRowWrapper>
                    <FilterRowWrapper container item spacing={3} flexDirection={"column"} margin="26px 1rem 0px 0px">
                        <FilterGridItem item >
                            <Controls.BaseSelectInput id="select-input-ayo-fee-type" label={strings.AyoconnectFeesType} value={ayoconnectFeesType} onChange={onChangeAyoconnectFeesType} disabledselect={configOneFilter ? 'false' : 'true'} values={AyoconnectFeesType} placeholderText={strings.SelectAyoconnectFeesType} labelfontweight={theme.typography.fontWeight.medium} labelcolor={disabledColor()} labelmarginbottom="12px" height="40px" width="100%" inputpadding="0.5px 16px" />
                        </FilterGridItem>
                    </FilterRowWrapper>
                    <FilterRowWrapper container item spacing={3} flexDirection={"column"} margin="26px 1rem 0px 0px">
                        <FilterGridItem item>
                            <Controls.BaseTextField
                                value={ayoconnectFees}
                                type="number"
                                InputProps={{
                                    startAdornment: ayoconnectFeesType === "1" && (
                                        <InputAdornment position="end">
                                            <div>Rp</div>
                                        </InputAdornment>
                                    ),
                                    endAdornment: ayoconnectFeesType === "2" && (
                                        <InputAdornment position='end'>%</InputAdornment>
                                    )
                                }}
                                onChange={onChangeAyoconnectFees}
                                disabledtextfield={configOneFilter ? 'false' : 'true'} height="40px"
                                label={strings.AyoconnectFees} labelfontweight={theme.typography.fontWeight.medium}
                                labelcolor={disabledColor()} placeholder={strings.EnterAyoconnectFees}
                                inputpadding="0.5px 16px"
                            />
                        </FilterGridItem>
                    </FilterRowWrapper>
                    <FilterRowWrapper container item spacing={3} flexDirection={"column"} margin="26px 1rem 0px 0px">
                        <FilterGridItem item>
                            <Controls.BaseSelectInput placeholderText={strings.SelectClientFeesType} label={strings.ClientFeesType} value={clientFeesType} onChange={onChangeClientFeesType} disabledselect={configOneFilter ? 'false' : 'true'} values={ClientFeesType} labelfontweight={theme.typography.fontWeight.medium} labelcolor={disabledColor()} labelmarginbottom="12px" height="40px" width="100%" inputpadding="0.5px 16px" />
                        </FilterGridItem>
                    </FilterRowWrapper>
                    <FilterRowWrapper container item spacing={3} flexDirection={"column"} margin="26px 0px 0px 0px">
                        <FilterGridItem item>
                            <Controls.BaseTextField value={clientFees}
                                type="number"
                                InputProps={{
                                    startAdornment: clientFeesType === "1" && (
                                        <InputAdornment position="end">
                                            <div>Rp</div>
                                        </InputAdornment>
                                    ),
                                    endAdornment: clientFeesType === "2" && (
                                        <InputAdornment position='end'>%</InputAdornment>
                                    )
                                }}
                                onChange={onChangeClientFees} disabledtextfield={configOneFilter ? 'false' : 'true'} height="40px" label={strings.ClientFees} labelfontweight={theme.typography.fontWeight.medium} labelcolor={disabledColor()} placeholder={strings.EnterBankFees} inputpadding="0.5px 16px" />
                        </FilterGridItem>
                    </FilterRowWrapper>
                </Grid>
                <FilterRowWrapper container item spacing={3} flexDirection={"row"} margin="0px" flexWrap={"nowrap"} justifyContent="flex-end">
                    <FilterGridItem item padding="0px">
                        <Controls.BaseButton onClick={onClearHandler} text={strings.Clear} textcolor={theme.palette.text.textEight} height="40px" width="160px" btnborder={`1px solid ${theme.palette.neutral.border}`} disablemenuitem="true" labelcolor={theme.palette.text.textEight} bgcolor={theme.palette.neutral.white} />
                    </FilterGridItem >
                    <FilterGridItem item>
                        <Controls.BaseButton onClick={onSubmitHandler} disabled={disabledButton()} text={strings.Submit} height="40px" width="160px" marginbottom="13px" />
                    </FilterGridItem>
                </FilterRowWrapper>

            </Grid>
        </MainPageTopSection>
    )
}

export default PriceConfigSelectorTwo