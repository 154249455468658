import { Box, Typography } from "@mui/material"
import React from "react"
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { 
    ModalWrapper, ModalHeader, 
    LabelValueCell, LabelValueHeader, LabelValueColumn
} from "./modal.styles"
import { chunkArray } from "../../utils/utilFunctions"
import { v4 as uuidv4 } from "uuid";

const DetailModal = (props) => {
    const { setClose, icon, modalHeader, content } = props

    const handleClose = () => {
        setClose(false)
    }

    return (
        <ModalWrapper data-testid="submit-modal-wrapper" container spacing={2} direction="column" justifyContent={'center'} alignItems={'center'}>
            <ModalHeader sx={{marginTop: "1rem"}} data-testid="modal-header" container direction="row" justifyContent={'space-between'}>
                <Typography variant="h3" sx={{fontWeight: 600}}>
                    {icon && (
                        <img src={icon} alt="transaction-logo" style={{ paddingRight: "7px" }} height={'24px'} width={'24px'} />
                    )}
                    {modalHeader}
                </Typography>
                <Box sx={{cursor: "pointer"}} data-testid="submit-close-icon" xs>
                    <img src={STATIC_ASSETS.CLOSE_ICON} alt="close-icon" onClick={handleClose} />
                </Box>
            </ModalHeader>
            <Box width={{xs: "800px"}}>
                {
                    content.map((rowdata) => <>
                        <LabelValueHeader key={uuidv4()}>
                            {rowdata.sectionTitle}
                        </LabelValueHeader>
                        <div>
                            {
                            chunkArray(rowdata.subData.filter(subData => !subData.hidden), 2).map((chunkData, chunkKey) =>
                                <LabelValueColumn key={uuidv4()}>
                                    { 
                                    chunkData.map((subData) =>
                                        <LabelValueCell key={uuidv4()} style={{ width: "50%" }}>
                                            <span className="label">{subData.label}</span>
                                            <p className="itemvalue" style={{color: subData?.valueColor ?? "inherit"}}>{subData.value}</p>
                                        </LabelValueCell>
                                    )
                                    }
                                </LabelValueColumn>
                            )}
                        </div>
                    </>)
                }
            </Box>
        </ModalWrapper>
    )
}

export default DetailModal