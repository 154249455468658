import { vcBaseApi, formatRupiah, authTokenHeader, internalBaseApi } from "../utils/utilFunctions";
import { setVcMerchantBalanceRetry } from "../redux/slice/rateLimiterSlice";
import axios from "axios";
import { useDispatch } from "react-redux";
import { validateEmail } from "../utils/validation";
import LocalizedStrings from "react-localization";
import LocalData from "../utils/localization";

export function useVcBalance({ updateState }) {
  const dispatch = useDispatch()
	const strings = new LocalizedStrings(LocalData)

  const getAyoSpends = async ({from, to}) => {
    updateState({
        loader: true
    })
    const result = {
        data: null,
        errorMsg: null
    }
    const queryParam = [
        `from=${from}`,
        `to=${to}`
    ].join('&')

    try {
        const url = vcBaseApi("/admin/spends?" + queryParam)
        const resp = await axios.get(url, {
            headers: authTokenHeader()
        })
        result.data = resp.data.result
        updateState({
            amount: result.data.ayoconnectSpend,
            loader: false
        })
        return result
    } catch (err) {
        console.error(err)
        result.errorMsg = "Failed to Fetch Total Spends"
        updateState({
            loader: false
        })
        return result
    }
  }

  const fetchBalanceSummary = async () => {
    updateState({loadingBalance: true})
    const url = vcBaseApi("/admin/limits")
    try {
        const resp = await axios.get(url, {
            headers: authTokenHeader()
        })
        const result = resp?.data?.result
        const ayolimitStr = formatRupiah(result.ayoconnectLimit)
        let ayoBalanceStr = parseInt(result.ayoconnectLimit) - parseInt(result.ayoconnectSpend)
        ayoBalanceStr = formatRupiah(ayoBalanceStr)
        let totalClientBalanceStr = parseInt(result.merchantLimit) - parseInt(result.merchantSpend)
        totalClientBalanceStr = formatRupiah(totalClientBalanceStr)

        const newState = {
            ayoLimitBalanceStr: [ayolimitStr, ayoBalanceStr].join(' / '),
            totalClientBalanceStr: totalClientBalanceStr,
        }
        updateState({
            ...newState,
            loadingBalance: false
        })
    } catch (e) {
        console.error("fetchBalanceSummary", e)
        updateState({loadingBalance: false})
    }
  }

  const getMerchantList = async () => {
    const result = {
        data: [],
        errorMsg: null
    }
    try {
        const url = vcBaseApi("/admin/merchants")
        const resp = await axios.post(url, {}, {headers: authTokenHeader()})
        result.data = resp.data.result
        return result
    } catch (err) {
        console.error(err)
        result.errorMsg = "Failed to Fetch Client List"
        return result
    }
  }

  const getMerchantBalance = async (merchantCode) => {
    const result = {
        data: null,
        errorMsg: null,
        retryAfter: 0
    }
    try {
        // const url = internalBaseApi("/api/vc/merchant-limit?code=" + merchantCode)
        const url = vcBaseApi("/admin/limits/merchant?code=" + merchantCode)
        const resp = await axios.get(url, {
            headers: authTokenHeader()
        })
        result.data = resp.data.result
        return result
    } catch (err) {
        console.error(err)
        result.errorMsg = "Failed to Fetch Client Balance"

        if(err?.response?.status === 429) {
            const headers = err?.response?.headers
            result.retryAfter = headers ? parseInt(headers["ratelimit-reset"]) : 100
            dispatch(setVcMerchantBalanceRetry(result.retryAfter))
            result.errorMsg = `Too many request on Client Balance API, please try again later (${result.retryAfter} seconds)`
        }
        return result
    }
  }

  const getVcSetting = async (merchantCode) => {
    const result = {
        data: null,
        errorMsg: null
    }
    try {
        const url = vcBaseApi("/settings/rules")
        const resp = await axios.get(url, {
            headers: {
                ...authTokenHeader(),
                'A-Merchant-Code': merchantCode
            }
        })
        result.data = resp.data.data
        return result
    } catch (err) {
        console.error(err)
        result.errorMsg = "Failed to Fetch Client Setting"
        return result
    }
  }

  const updateVcSetting = async ({merchantCode, userEmail, otpCode, payload}) => {
    const result = {
        data: null,
        errorMsg: null
    }
    try {
        const url = vcBaseApi("/settings/rules")
        const resp = await axios.post(url, payload, {
            headers: {
                'A-Merchant-Code': merchantCode,
                'A-User-Email': userEmail,
                'A-OTP-Code': otpCode
            }
        })

        if(resp.data?.code !== 200) {
            result.errorMsg = "Invalid OTP Code"
            return result
        } // endif

        result.data = resp.data
        return result
    } catch (err) {
        console.error(err)
        result.errorMsg = "Failed to Update Client Setting"

        if(err?.response?.data?.message === "otp.invalid") {
            result.errorMsg = "Invalid OTP"
        } else if(err?.response?.data?.message === "otp.expired") {
            result.errorMsg = "OTP code was expired"
        }

        return result
    }
  }

  const generateVcSettingOTP = async ({
    merchantCode, merchantName,
    userName, userEmail}) => {
    
		const result = {
			data: null,
			errorMsg: null
    }

		const { inValidEmail } = validateEmail(userEmail)
		if (inValidEmail) {
			result.errorMsg = strings.Email_Incorrect
			return result
		} // endif

    try {
        const url = internalBaseApi("/api/vc/setting-otp")
        const resp = await axios.get(url, {
            headers: {
                'a-merchant-code': merchantCode,
                'a-merchant-name': merchantName,
                'a-user-name': userName,
                'a-user-email': userEmail,
            }
        })
        result.data = resp.data
        return result
    } catch (err) {
        console.error("generateVcSettingOTP", err)
        result.errorMsg = "Failed to Generate OTP"

        if(err?.response?.status === 429) {
            const headers = err?.response?.headers
            result.retryAfter = headers ? parseInt(headers["ratelimit-reset"]) : 100
            result.retryAfterMinutes = Math.ceil(result.retryAfter / 60)
            result.errorMsg = `Too many request on Client Configuration OTP, please try again after ${result.retryAfterMinutes} minute(s)`
        }
        return result
    }
	}

  return { 
    getAyoSpends, 
    fetchBalanceSummary, 
    getMerchantList, 
    getVcSetting,
    updateVcSetting,
    getMerchantBalance,
    generateVcSettingOTP
  }
}
