import { 
  Grid, Paper, Button, IconButton, 
  TextField, FormControl, Select, 
  Checkbox,
  styled 
} from "@mui/material";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const BtnDefault = {
  fontSize: "14px",
  fontWeight: 800,
  lineHeight: '19px',
  borderRadius: "8px",
  minWidth: "90px",
  padding: "0.7rem",
  textTransform: "capitalize",
  cursor: "pointer"
}

const fullWidthStyle = {
  width: "100% !important",
}

export const SvgCalendar = ({width="17", height="16"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 17 16"
    >
      <g clipPath="url(#clip0_825_1697)">
        <path
          fill="#004C97"
          d="M11.5 2.001h2.666a.667.667 0 01.667.667v10.667a.667.667 0 01-.667.666h-12a.666.666 0 01-.666-.666V2.668a.667.667 0 01.666-.667h2.667V.668h1.333v1.333h4V.668H11.5v1.333zm-1.334 1.334h-4v1.333H4.833V3.335h-2V6h10.666V3.335h-2v1.333h-1.333V3.335zm3.333 4H2.833v5.333h10.666V7.335z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_825_1697">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
            transform="translate(.167)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export const CalendarButton = ({onClick, width="auto", height="30"}) => {
  return (
    <IconButton color="primary" aria-label="Choose Date" onClick={onClick}>
      <SvgCalendar width={width} height={height} />
    </IconButton>
  )
}

export const startDateIcon = (openStartDate) => {
  return <CalendarButton onClick={openStartDate} />
}

export const endDateIcon = (openEndDate) => {
  return <CalendarButton onClick={openEndDate} />
}

export const FilterRowWrapper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "margin",
})(({ margin }) => ({
  margin: margin ? margin : "-7px 1rem 0px 0px",
  "& .MuiGrid-root": {
    paddingLeft: "0px",
  },
}));

export const FilterGridItem = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "padding",
})(({ padding }) => ({
  "&.MuiGrid-root": {
    "&.MuiGrid-item": {
      padding: padding ? padding : "0px 0px 0px 16px",
    },
  },
}));

export const TopSectionLabel = styled("div", {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  marginBottom: "40.5px",
  fontWeight: theme.typography.fontWeight.medium,
  fontSize: 24,
  lineHeight: "29px",
  color: color ? color : theme.palette.text.body,
}));

export const LabelFilter = styled("div", {})(
  ({ theme }) => ({
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "14px",
    marginBottom: "8px",
    color: "#333"
  })
)

export const BtnClear = styled("Button", {})(
  ({ theme }) => ({
    ...BtnDefault,
    color: "#CD2029",
    backgroundColor: "#FFFFFF",
    border: "1px solid #DDDDDD",
    "&:hover": {
      backgroundColor: "#f1f1f1",
    },
  })
)

export const BtnApply = styled(Button, {})(
  ({ theme }) => ({
    ...BtnDefault,
    backgroundColor: "#004C97",
    border: "none",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#2367ab",
    },

    "&:disabled, &[disabled]": {
      color: theme.palette.neutral.white,
      backgroundColor: theme.palette.text.disabled
    }
  })
)

export const TopFilterBox = styled(Paper, {})(
  ({ theme }) => ({
    marginBottom: "1rem",
    padding: "1rem",
    border: "1px solid #DDDDDD",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px"
  })
)

export const DateField = styled(DesktopDatePicker, {})(
  ({ theme }) => ({
    ".MuiInputBase-root": {
      paddingRight: "4px",
      height: "42px"
    }
  })
)

export const SubDateRangeField = styled(DesktopDatePicker, {})(
  ({ theme, textAlign="left" }) => ({
    ".MuiInputBase-root": {
      padding: "14px 0 14px 0",
      height: "42px",
      minWidth: "100px"
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiOutlinedInput-input": {
      fontWeight: 500,
      fontSize: "18px",
      color: "#333333"
    },
    "& .MuiInputBase-input": {
      textAlign
    }
  })
)

export const FilterTextField = styled(TextField, {})(
  ({ theme }) => ({
    width: "100%",
    "& .MuiInputBase-input": {
      height: "10px",
    }
  })
)

export const StyledFormControl = styled(FormControl, {})(
  ({ theme }) => ({
    ...fullWidthStyle
  })
)

export const StyledSelect = styled(Select, {})(
  ({ theme, sx }) => ({
    ...fullWidthStyle,
    minWidth: '200px', 
    padding: '5px',
    ...sx
  })
)

export const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => ! ["fontSize", "color"].includes(prop),
})(
  ({ theme, fontSize=20, color='#056DAE' }) => ({
    '&.Mui-checked': {
      color
    },
    '& .MuiSvgIcon-root': {
      fontSize
    }
  })
)